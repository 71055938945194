import setting from "./setting";

export default {
  getGanaderoData: ($axios) => {
    return $axios.get(`${setting.http.base_url}/portal/ganadero/`, {
      headers: setting.http.headers(),
    });
  },

  downloadManualUso: ($axios) => {
    return $axios.get(
      `${setting.http.base_url}/portal/ganadero/manual-de-usuario`,
      {
        headers: setting.http.headers(),
        observe: "response",
        responseType: "arraybuffer",
      }
    );
  },
};
