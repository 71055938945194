<template>
  <div v-show="show" class="custom-modal">
      <div class="container" :style="`max-width:${width}px`"> <!-- v-click-outside="hide" -->
          <div v-if="isHeader == true" class='header'>
            <slot name="header">
                <h4>{{title}}</h4>
                <i v-show="closeIcon" class='fa fa-times' @click="hide()"></i>
            </slot>
          </div>
          <div class="body">
            <slot name="body">
            </slot>
          </div>
          <div v-if="isFooter == true" class="footer">
            <slot name="footer">
                <a class="btn-cancel">Cerrar</a>
                <a class="btn-ok">Aceptar</a>
            </slot>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  props:{
    show:false,
    title:'',
    isHeader:false,
    isFooter:false,
    closeIcon: {
      type: [Boolean],
      default: true,
    },
    width:{
      type: [String, Number],
      default: "auto",
    },
  },
  watch: {
    show(val) {
      const body = document.getElementsByTagName("body")[0];

      if (val) {
        body.classList.add("modal-open");
      } else {
        body.classList.remove("modal-open");
      }

    }
  },
  created() {
    
  },
  methods:{
      hide() {

        this.$emit('modal:close', false);

      }
  },
  data() {
      return {

      }
  }
}
</script>
