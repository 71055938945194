/* eslint-disable no-new */
<template>
  <component
    :is="baseComponent"
    :to="link.path ? link.path : '/'"
    class="nav-item section-list-laive sub-menu-laive"
    :class="{ active: isActive, 'is-active-sub-menu': collapsedSubItems }"
    tag="li"
  >
    <a
      v-if="isMenuSub"
      :href="'#' + slugifySub(link.name)"
      class="sidebar-menu-item nav-link"
      :class="{'is-active': collapsedSubItems}"
      :aria-expanded="isActive"
      data-toggle="collapse"
      @click.prevent="collapseSubMenu"
      role="button"
      :aria-controls="link.name"
    >
      <template v-if="addLink">
        <span class="nav-link-text">
          {{ link.name }} <span class="caret"></span>
        </span>
      </template>
    </a>

    <transition
      name="sub-menu-list"
      @enter="startTransition"
      @before-enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition">
      <div
        v-if="this.isMenuSub"
        :id="slugifySub(link.name)"
        class="collapse show heading-list-laive"
        v-show="collapsedSubItems"
      >
        <ul class="nav nav-sm flex-column menu-sub-list-laive">
          <slot></slot>
        </ul>
      </div>
    </transition>

    <slot
      name="title"
      v-if="children.length === 0 && !$slots.default && link.path && isAllow"
    >
      <component
        :to="link.path"
        @click="linkSubClick"
        :is="elementType(link, false)"
        class="nav-link"
        :class="{ active: link.active }"
        :target="link.target"
        :href="link.path"
      >
        <template v-if="addLink">
          <span class="nav-link-text">{{ link.name }}</span>
        </template>
        <template v-else>
          <i v-if="link.icon" :class="link.icon"></i>
          <span class="nav-link-text">{{ link.name }}</span>
        </template>
      </component>
    </slot>
  </component>
</template>
<script>
import setting from '../../services/setting';

export default {
  name: "sidebar-item",
  props: {
    menu: {
      type: Boolean,
      default: false,
      description:
        "Whether the item is a menu. Most of the item it's not used and should be used only if you want to override the default behavior.",
    },
    link: {
      type: Object,
      default: () => {
        return {
          name: "",
          path: "",
          children: [],
        };
      },
      description:
        "Sidebar link. Can contain name, path, icon and other attributes. See examples for more info",
    },
    role: {
      type: Object
    },
  },
  provide() {
    return {
      addLink: this.addChild,
      removeLink: this.removeChild,
    };
  },
  inject: {
    addLink: { default: null },
    removeLink: { default: null },
    autoClose: {
      default: true,
    },
  },
  data() {
    return {
      children: [],
      collapsed: true,
      collapsedSubItems: false,
      isOpen: false,
    };
  },
  computed: {
    baseComponent() {
      return this.isMenuSub || this.link.isRoute ? "li" : "router-link";
    },
    linkPrefix() {
      if (this.link.name) {
        let words = this.link.name.split(" ");
        return words.map((word) => word.substring(0, 1)).join("");
      }
      return "";
    },
    isMenuSub() {
      return this.menu === true;
    },
    isActive() {
      if (this.$route && this.$route.path) {
        let matchingRoute = this.children.find((c) =>
          this.$route.path.startsWith(c.link.path)
        );
        if (matchingRoute !== undefined) {
          return true;
        }
      }
      return false;
    },
    isAllow() {

      let allow = false;

      if (this.role && this.role.hasOwnProperty('id')) {

        for (let index = 0; index < this.role.id.length; index++) {

          const item = this.role.id[index];

          if (this.isRoleAllow(item)) {
            allow = true;
            break;
          }

        }

      }

      return allow;

    }
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    startTransition(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    endTransition(el) {
      el.style.height = ''
    },
    addChild(item) {
      const index = this.$slots.default().indexOf(item.$vnode);
      this.children.splice(index, 0, item);
    },
    removeChild(item) {
      const tabs = this.children;
      const index = tabs.indexOf(item);
      tabs.splice(index, 1);
    },
    elementType(link, isParent = true) {
      if (link.isRoute === false) {
        return isParent ? "li" : "a";
      } else {
        return "router-link";
      }
    },
    linkSubClick() {
      this.$sidebar.displaySidebar(false);

      if (window.innerWidth < 1440) {
        document.getElementById("admin_sidebar").scroll(0,0);
        this.$sidebar.displaySidebar(false);
      }
    },
    collapseSubMenu() {
      this.collapsedSubItems = !this.collapsedSubItems;
    },
    slugifySub(string) {
      return string
            .toString()
            .trim()
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/[^\w\-]+/g, "")
            .replace(/\-\-+/g, "-")
            .replace(/^-+/, "")
            .replace(/-+$/, "");
    },
    isRoleAllow(roleId) {

      let roles = setting.tokenData().roles;

      if (this.isInRoles(roles, roleId)) {
        return true;
      } else {
        return false;
      }

    },
    isInRoles(rolesArray, roleId) {

      let exist = false;

      for (let index = 0; index < rolesArray.length; index++) {

        const item = rolesArray[index];

        if (item.id == roleId) {
          exist = true;
          break;
        } else {
          exist = false;
        }

      }

      return exist;

    }
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
    if (this.link.collapsed !== undefined) {
      this.collapsed = this.link.collapsed;
    }
    if (this.isActive && this.isMenuSub) {
      //this.collapsed = false;
      this.collapsedSubItems = true;
    }

    let nodes = document.querySelector(".menu-sub-list-laive").querySelectorAll(":empty");

    nodes.forEach(node => {

      node.remove();

    });

  },
  unmounted() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
};
</script>
<style lang="scss" scoped>
@use "sass:math";
.sidebar-menu-item {
  cursor: pointer;

  &.is-active {
    background: rgba(0, 101, 55, 0.25);
    border-radius: 5px;
  }
}
.sidebar-menu-item {
  .nav-link-text {
    font-size: 14px;
    line-height: 20px;
    color: white;
  }

  &.is-active .nav-link-text {
    color: #D1DE6E;
  }
}

.nav-item .nav-link {
  font-size: 14px !important;
  line-height: 18px !important;
  color: #FFFFFF !important;
  font-weight: 400 !important;
  white-space: normal !important;
  position: relative;

  &.router-link-exact-active {
    color: white !important;
    font-weight: 800 !important;
  }
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  margin: 0 23.5px !important;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .collapse .nav-link {
  margin: 0 !important;
}

.sidebar ul.links__nav {
  margin-top: 0;
  padding-top: 10px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
  height: auto !important;
}

.slide-fade-leave-active {
  transition: all 0.8s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  height: 0 !important;
}

.navbar-dark .sidebar-menu-item.active .nav-link-text {
  color: black;
}

ul.sub-menu .collapse {
  position: relative;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: 0 23.5px !important;
  padding-left: 30px;
}

ul.sub-menu .collapse > ul li.section-list-laive .heading-list-laive {
  //background-color: red;
  padding-left: 0;
  margin: 0 8.5px !important;
}

ul.sub-menu .collapse > ul li.section-list-laive .heading-list-laive ul.menu-sub-list-laive a.section-list-laive a {
  font-size: 13px !important;
  line-height: 17px !important;
  text-decoration: underline;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-weight: 300 !important;
}

ul.sub-menu .collapse > ul li.section-list-laive .heading-list-laive ul.menu-sub-list-laive a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 45%;
  width: 4px;
  height: 4px;
  background: white;
  border-radius: 50%;
}

ul.menu-sub-list-laive > .sub-menu-laive.is-active-sub-menu:after {
  top: 4.1% !important;
}


ul.menu-sub-list-laive > .sub-menu-laive .sidebar-menu-item {
  $size: 8px;

  &.is-active .nav-link-text {
    color: white;
    font-weight: 600;
  }

  .caret {
    display: block;
    position: absolute;
    top: 3px; right: 1.25rem; bottom: 0;
    margin: auto;
    width: $size;
    height: $size;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    transform: translateY(math.div(-$size, 4)) rotate(45deg);
    transition: transform 0.2s ease;
  }

  &.is-active .caret {
    transform: translateY(math.div($size, 4)) rotate(225deg);
    border-right: 2px solid white;
    border-bottom: 2px solid white;
  }
}


ul.sub-menu .collapse > ul li.section-list-laive .heading-list-laive ul.menu-sub-list-laive a::after {
  content: none;
}

ul.sub-menu .collapse ul .nav-item {
  position:relative;
  margin-bottom: 0;
}

ul.sub-menu .collapse ul .nav-item:empty {
  display: none;
}

ul.sub-menu .collapse ul .nav-item:before {
  content:"";
  position: absolute;
  left: -15px;
  border-left: 1px solid #D1DE6E;
  height: 100%;
  width: 1px;
}

ul.sub-menu .collapse ul .nav-item:after {
  content: "";
  position: absolute;
  width: 18px;
  height: 1px;
  background: #D1DE6E;
  top: 50%;
  //top: 30px;
  left: -15px;
}

ul.sub-menu .collapse ul .nav-item:first-child:before {
  top: 0;
}

ul.sub-menu .collapse ul .nav-item:last-child:before {
  height: 50%;
}

ul.sub-menu .collapse ul .nav-link:before {
  content: "";
  position: absolute;
  left: 0;
  top: 45%;
  width: 6px;
  height: 6px;
  background: #D1DE6E;
  border-radius: 50%;
}


.sub-menu-list-enter-active, .sub-menu-list-leave-active {
  will-change: height, opacity;
  transition: height 0.8s ease, opacity 0.8s ease;
  overflow: hidden;
}
.sub-menu-list-enter, .sub-menu-list-leave-to {
  height: 0 !important;
  opacity: 0;
}

.sidebar-menu-item {
  $size: 12px;

  .caret {
    display: block;
    position: absolute;
    top: 3px; right: 1.25rem; bottom: 0;
    margin: auto;
    width: $size;
    height: $size;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    transform: translateY(math.div(-$size, 4)) rotate(45deg);
    transition: transform 0.2s ease;
  }

  &.is-active .caret {
    transform: translateY(math.div($size, 4)) rotate(225deg);
    border-right: 2px solid #D1DE6E;
    border-bottom: 2px solid #D1DE6E;
  }
}
</style>
