<template>
  <div class="Radio">
    <label v-if="label">{{ label }}</label>

    <div class="radio-group">

      <template v-if="hasCustomId && hasCustomName">
        <div
          class="RadioButton"
          :class="{ 'has-error': !!errorMessage }"
          :style="styleColumns">

          <div
            class="custom-control custom-radio"
            v-for="(item, index) in items"
              :key="index"
          >

            <label class="container" :for="item[hasCustomId]">
              <input
                :id="item[hasCustomId]"

                :value="item[hasCustomId]"

                type="radio"
                :name="name"
                class="custom-control-input"
                :checked="item[hasCustomId] === inputValue"
                @input="handleChange"
                @change="onChange"
              >
              <span class="checkmark"></span>
              <span class="text">{{ item[hasCustomName] }}</span>
            </label>


          </div>
          <div class="help-message" v-show="errorMessage">
            {{ errorMessage }}
          </div>

        </div>
      </template>

      <template v-else>
        <div
          class="RadioButton"
          :class="{ 'has-error': !!errorMessage }"
          :style="styleColumns">

          <div
            class="custom-control custom-radio"
            v-for="(item, index) in items"
              :key="index"
          >

            <label class="container" :for="name + '-' + index">
              <input
                :id="name + '-' + index"
                type="radio"
                :name="name"
                :value="item.id"
                class="custom-control-input"
                :checked="item.id == inputValue"
                @input="handleChange"
                @change="onChange"
              >
              <span class="checkmark"></span>
              <span class="text">{{ item.nombre }}</span>
            </label>


          </div>
          <div class="help-message" v-show="errorMessage">
            {{ errorMessage }}
          </div>

        </div>
      </template>

    </div>
  </div>
</template>

<script>
import { useField } from "vee-validate";

export default {
  props: {
    name: String,
    label: String,
    items: {
      type: Array,
      default: [],
    },
    value: {
      type: String,
      default: "",
    },
    model: {
      type: [String, Number, Boolean],
      description: "Input value",
    },
    modelValue: {
      type: [Array, String, Number, Boolean],
      description: "Input value",
    },
    type: {
      type: String,
      default: "radio",
    },
    mobileColumns: {
      type: [Number, Boolean],
      default: false,
    },
    hasCustomId: {
      type: String,
      default: "",
    },
    hasCustomName: {
      type: String,
      default: "",
    },
  },
  computed: {
    styleColumns () {
      return this.mobileColumns ? 'grid-template-columns: repeat('+this.mobileColumns+', 1fr) !important' : 'grid-template-columns: repeat(2, 1fr) !important';
    }
  },
  setup(props, ctx) {
    const EMPTY_STRING = "";
    const {
        value: inputValue,
        checked,
        handleChange,
        errorMessage,
        meta
      } = useField(
      props.name,
      undefined,
      {
        validateOnMount: false,
        uncheckedValue: EMPTY_STRING,
        type: "radio",
        valueProp: props.value,
      }
    );

    const onChange = (event) => {
      let val = event.target.value;

      if (!event.target.checked) {
        val = "";
      }

      handleChange(val);
      ctx.emit("update:modelValue", val);
    };

    return {
      checked,
      onChange,
      errorMessage,
      inputValue,
      checked,
      meta,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals/_mixins.scss";
@import "../../assets/scss/globals/_variables.scss";

.Radio {

  display: flex;
  height: 100%;
  flex-direction: column;

  @media (max-width: $tablet_m) {
    //margin-bottom: calc(1em * 1.5);
  }

  > label {

    display: block;
    margin-bottom: 4px;
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #6E6E6E;

    @media (max-width: 1750px) {
      font-size: 10px;
    }

    @media (min-width: $tablet_m) and (max-width:1366px) {
      height: 38px;
      margin-bottom: 0;
      display: flex;
      align-items: flex-end;
    }
  }
}

input[type=radio] {
    appearance: none;
    display: none;
}

.radio-group {
  display: flex;
  gap: 10px;
  align-items: center;
  height: 100%;
}

/*.radio-group + label {
  @media (max-width: 1750px) {
    font-size: 10px;
  }

  @media (max-width: 1366px) {
    height: 24px;
    //margin-bottom: 8px;
    margin-bottom: 4px;
    display: flex;
    //align-items: center;
    align-items: flex-end;
  }
}*/
.custom-radio {
  display: inline-block;
  text-align: center;
  position: relative;
}
.custom-radio label {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: left;
  border-radius: 10px;
  //line-height: 400%;
  line-height: 25px;
  font-weight: 900;
  transition-duration: .5s;
  transition-property: transform, color, box-shadow;
  transform: none;
  margin-left: 0;
  margin-bottom: 0;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;

  @media (max-width: 1440px) {
    //font-size: 13px;
    font-size: 11px;
    line-height: 25px;
    height: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.custom-radio input[type=radio]:checked + label {
  background: #D1DE6E;
  color: #6E6E6E;
  font-weight: 600;
}

.help-message {
  position: absolute;
  bottom: calc(-1.5 * 1em);
  left: 0;
  margin: 0;
  font-family: $font-family-poppins;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.RadioButton {
  display: flex;
  width: 100%;
  //gap: 18px;
  gap: 0;
  position: relative;

  @media (max-width: 640px) {
    display: grid;
    width: 100%;
    position: relative;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }

  .custom-radio {

    @media (max-width: 640px) {
      gap: 10px;
    }

  }
}
.RadioButton.has-error input {
  border-color: $error;
}

.RadioButton.has-error .help-message {
  color: $error;
  border-color: $error;
}


.container {
  display: block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  font-size: 11px;
  user-select: none;
  margin: 0;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.container .text {
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #33352A;
  text-transform: uppercase;
  margin-left: 10px;

  @include responsive-min-width($desktop_s) {
    font-size: 14px;
    line-height: 16px;
  }
}


.checkmark {
  position: absolute;
  top: 0;
  //top: 3px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border: 2px solid #6E6E6E;
  border-radius: 50px;

  @include responsive-min-width($desktop_s) {
    top: 0;
  }
}

.container:hover input ~ .checkmark {
  background-color: #ffffff;
}

/*.container input:checked ~ .checkmark {
  background-color: #7D8067;
}*/

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container input:checked ~ .checkmark {
  border: 2px solid #D1DE6E !important;
}

.container .checkmark:after {
  left: auto;
  top: auto;
  right: auto;
  width: 20px;
  height: 20px;
  border: solid white;
  background-color: #02A54F;
  border-width: 3px 2px 2px 3px;
  border-radius: 50px;
}

.full-height {

  .radio-group {
    padding: 14px 0px;
  }

}
</style>
