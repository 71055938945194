import Numeral from 'numeral';

export default {
  install: (app, options) => {
    app.config.globalProperties.$convert = {
      acidez(num) {//Check
        //console.log('acidez', num);
        if (num == 0) {
          return 0;
        } else if (num > 0) {
          var number = Numeral(num);
          return number.format('0.000');
        } else if (num < 0) {
          return "-";
        }
      },
      aguado(num) {//Check
        //console.log('aguado', num);
        num = Math.ceil(parseFloat(num));
        //console.log('aguado parse', num);
        if (num < 0) {
          return 0;
        }
        return num;
      },
      antibioticos(num) {
        //console.log('antibioticos', num);
        if (num == 1) {
          return 'Sí';
        } else if (num == 0) {
          return 'No';
        } else if (num !== '') {
          return num;
        } else {
          return 'No';
        }
      },
      caseina(num) {
        //console.log('caseina', num);
        if (num > 0) {
          var number = Numeral(num);
          return number.format('0.00');
        } else {
          return "-";
        }
      },
      crioscopia(num) {//Check
        //console.log('crioscopia', num);
        if (num < 0) {
          var number = Numeral(num);
          return number.format('0.0000');
        } else {
          return "-";
        }
      },
      lactosa(num) {
        //console.log('lactosa', num);
        if (num > 0) {
          var number = Numeral(num);
          return number.format('0.00');
        } else {
          return "-";
        }
      },
      celulasSomaticas(num) {
        //console.log('celulas somaticas', num);
        if (num > 0) {
          var number = Numeral(num);
          return number.format('0,0');
        } else {
          return "-";
        }
      },
      densidad(num) {//Check
        //console.log('densidad', num);
        if (num > 0) {
          var number = Numeral(num);
          return number.format('0.0000');
        } else {
          return "-";
        }
      },
      grasa(num) {
        //console.log('grasa', num);
        if (num > 0) {
          var number = Numeral(num);
          return number.format('0.00');
        } else {
          return "-";
        }
      },
      proteina(num) {
        //console.log('proteina', num);
        if (num > 0) {
          var number = Numeral(num);
          return number.format('0.00');
        } else {
          return "-";
        }
      },
      solidosTotales(num) {
        //console.log('solidos totales', num);
        var number = Numeral(num);
        return number.format('0.00');
      },
      solidosNoGrasos(num) {
        //console.log('solidos no grasos', num);
        var number = Numeral(num);
        return number.format('0.00');
      },
      tram(str) {//Check
        //console.log('tram', num);
        if (!str) {
          return "-";
        }
        return str;
      },
      ufc(num) {
        //console.log('ufc', num);
        if (num > 0) {
          var number = Numeral(num);
          return number.format('0,0');
        } else {
          return "-";
        }
      }
    }
  }
}