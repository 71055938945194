import { mapActions, mapGetters } from 'vuex';
import AcopioService from '../services/AcopioService';
import axios from 'axios';
export default {
    namespaced: true,
    state:{},
    getters:{},
    mutations:{},
    methods:{ },
    actions:{

      async getAcopioQuincena({state,commit}, data) {
        return await AcopioService.getAcopioQuincena(axios,data);
      },
      async getAcopioDiario({state,commit}, data) {
        return await AcopioService.getAcopioDiario(axios,data);
      },
      async downloadAcopio({state,commit}, data) {
        return await AcopioService.downloadAcopio(axios,data);
      },

    }
}
