<template>
  <div class="m-container">
    <div class="row">
      <div class="col-12">
        <div class="c-card welcome">

            <div class="card-header n-table pb-0">

              <div :class="[{'welcome-frame-transp': user.tipoUsuario == 170}, {'welcome-frame': user.tipoUsuario !== 170}]"></div>

              <div class="card-header-container">
                <h4 class="welcome-heading">Bienvenido, {{ user.nombreProveedor ? user.nombreProveedor : user.nombreCompleto }}</h4>
                <div class="hr mb-0"></div>
              </div>

            </div>

            <div class="card-body pt-0">

              <div class="row" v-if="user.tipoUsuario !== 170">

                <div class="col-12">

                  <h3 class="black-title">Información actual</h3>

                  <div class="row">

                    <div class="col-md-4 mb-4 mb-md-0">

                      <div class="welcome-info-box">
                        <div class="metadata">
                          <h4 class="title">acopio diario</h4>
                          <span class="date">{{ proveedor.fecha }}</span>
                        </div>
                        <div class="quantity">
                          <span class="kg">{{ $filters.toChar(proveedor.acopioDia) }} kg</span>
                        </div>
                      </div>

                    </div>

                    <div class="col-md-4 mb-4 mb-md-0">

                      <div class="welcome-info-box">
                        <div class="metadata">
                          <h4 class="title">acopio acumulado</h4>
                          <span class="date">{{ proveedor.nombreQuincena }}</span>
                        </div>
                        <div class="quantity">
                          <span class="kg">{{ $filters.toChar(proveedor.acopioTotal) }} kg</span>
                        </div>
                      </div>

                    </div>

                    <div class="col-md-4">

                      <div class="welcome-info-box">
                        <div class="metadata">
                          <h4 class="title">días de entrega</h4>
                          <span class="date">{{ proveedor.nombreQuincena }}</span>
                        </div>
                        <div class="quantity">
                          <span class="kg">{{ proveedor.dias }}</span>
                        </div>
                      </div>

                    </div>

                  </div>

                </div>

              </div>


              <template v-if="user.tipoUsuario == 170">
                <div>
                  <div class="row">

                    <div class="col-12">

                      <h3 class="black-title">Accesos directos</h3>

                      <div class="row">

                        <div class="col-md-2">

                          <router-link to="/seguimiento-rutas">
                            <div class="direct-link">
                              <img class="image" src="/assets/images/welcome-page/acopio.svg" alt="Acopio"/>
                              <h4 class="title">Seguimiento de<br> rutas</h4>
                            </div>
                          </router-link>

                        </div>

                      </div>

                    </div>
                  
                  </div>
                </div>
              </template>

              <template v-else>

                <div>

                  <div class="col-12">

                    <h3 class="black-title">Accesos directos</h3>

                    <div class="row">

                      <div class="col-md-3">

                        <router-link to="/acopio">
                          <div class="direct-link">
                            <img class="image" src="/assets/images/welcome-page/acopio.svg" alt="Acopio"/>
                            <h4 class="title">Acopio</h4>
                          </div>
                        </router-link>

                      </div>

                      <div class="col-md-3">
                      
                        <router-link to="/calidad/lc" v-if="userType == 'Caliente'">
                          <div class="direct-link">
                            <img class="image" src="/assets/images/welcome-page/calidad.svg" alt="Calidad"/>
                            <h4 class="title">Calidad</h4>
                          </div>
                        </router-link>

                        <router-link to="/calidad/lf" v-if="userType == 'Fria'">
                          <div class="direct-link">
                            <img class="image" src="/assets/images/welcome-page/calidad.svg" alt="Calidad"/>
                            <h4 class="title">Calidad</h4>
                          </div>
                        </router-link>

                      </div>

                      <div class="col-md-3">

                        <router-link to="/pagos">
                          <div class="direct-link">
                            <img class="image" src="/assets/images/welcome-page/pagos.svg" alt="Pagos"/>
                            <h4 class="title">Pagos</h4>
                          </div>
                        </router-link>

                      </div>

                      <div class="col-md-3">

                        <router-link to="/creditos-descuentos">
                          <div class="direct-link">
                            <img class="image" src="/assets/images/welcome-page/descuentos.svg" alt="Descuentos"/>
                            <h4 class="title">Descuentos</h4>
                          </div>
                        </router-link>

                      </div>

                    </div>

                  </div>

                </div>
              </template>


              <div class="row">

                <div class="col-12">

                  <div class="welcome-info-text">
                    <p>En caso necesites ayuda no dudes en comunicarte a los celulares: <a class="email" href="tel:+51972738675">972738675</a>, <a class="email" href="tel:+51987214747">987214747</a>, <a class="email" href="tel:+51994103351">994103351</a>.</p>
                  </div>

                </div>

              </div>


              <div class="row mb-4"  v-if="user.tipoUsuario !== 170">

                <div class="col-12">

                  <div class="download-section">
                    <div class="action" @click="downloadManual()">
                      <a class="title">Manual de uso</a>
                      <img class="ms-2" src="/assets/images/icons/download.svg">
                    </div>
                  </div>

                </div>

              </div>

            </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

const CACHE_NAME = "site-cache-v2";

import { mapState, mapActions, mapGetters } from 'vuex';
import { Util } from "@/util/util";

export default {
  name: "Home",
  components: {

  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  data() {
    return {
      username: '',
      userType: null,
      proveedor: {},
      latitude: '',
      longitude: '',
      geolocationEnabled: false,
      isFirstTimeGeolocationEnabled: localStorage.getItem('firstTimeGeolocationEnabled') || false,
      images: [
        "/assets/images/check-circle-fill.svg",
        "/assets/images/warning-icon.svg",
        "/assets/images/icons/timer.svg",
        "/assets/images/icons/milk.svg",
        "/assets/images/icons/camera.svg",
        "/assets/images/icons/incident.svg",
        "/assets/images/icons/trash-red.svg"
      ],
    }
  },
  created() {
    this.images.forEach((img) => {
      const image = new Image();
      image.src = img;
    });
  },
  methods: {
    ...mapActions({
      getParams: "seguimiento/getParams",
      obtenerRutas: 'offline/obtenerRutas',
      guardarTiposLeche: 'offline/guardarTiposLeche',
      getGanaderoData: 'dashboard/getGanaderoData',
      downloadManualUso: 'dashboard/downloadManualUso',
    }),
    downloadManual() {
      const data = {
        "year": this.anioPrecioUnitario,
      }

      this.downloadManualUso(data).then((response) => {

        if (response.status == 200) {

          let type = response.headers['content-type'];

          const file = new Blob([response.data], {type: type});
          const fileURL = URL.createObjectURL(file);

          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', `portal-ganadero/manual-de-usuario.pptx`);
          document.body.appendChild(link);
          link.click();

        }

      }).catch((e)=>{
        console.log(e);
      });


    },
    cargarTiposLeche() {
      this.getParams().then((response) => {
        if (response.status == 200) {

          this.guardarTiposLeche(response.data.productosTransportados);

        }
      });
    },
    enableGeoLocation() {

      console.log('navigator.geolocation', navigator.geolocation);

      this.isFirstTimeGeolocationEnabled = localStorage.getItem('firstTimeGeolocationEnabled') || false;

      //console.log('was enabled', this.isFirstTimeGeolocationEnabled);

      if (navigator.geolocation) {

        try {

          navigator.geolocation.getCurrentPosition((position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            
            localStorage.setItem('latitude', latitude);            
            localStorage.setItem('longitude', longitude);

            localStorage.setItem('firstTimeGeolocationEnabled', true);
            this.isFirstTimeGeolocationEnabled = true;

            this.geolocationEnabled = true;

            // haz algo con la latitud y longitud
            this.latitude = latitude ? latitude : localStorage.getItem('latitude') || 0;
            this.longitude = longitude ? longitude : localStorage.getItem('longitude') || 0;

          }, () => {
            console.log("No se pudo obtener la ubicación");
          });
          
        } catch (err) {

        }

      } else {

        console.log("La geolocalización no está disponible o ya está habilitada");

      }

    },

  },
  mounted() {

    this.username = Util.getUserName();
    this.userType = Util.getTipo();

    let user = Util.getUser();

    this.enableGeoLocation();

    this.cargarTiposLeche();
    this.obtenerRutas();
    //this.cargarTransportistas(); //Solo al iniciar sesión


    if (this.user.tipoUsuario !== 170) {

      // Intenta obtener los datos del caché
      caches.open(CACHE_NAME)
        .then((cache) => {
          const cacheUrl = `/offline/proveedor`;

          cache.match(cacheUrl)
            .then((response) => {
              if (response) {
                return response.json();
              }
            })
            .then((data) => {
              if (data) {

                // Los datos están disponibles en caché
                this.proveedor = data;

              } else {

                

              }
            });
        });

      this.getGanaderoData(user).then((response) => {

        if (response.status == 200) {

          caches.open(CACHE_NAME)
            .then((cache) => {
              cache.put("/offline/proveedor", new Response(JSON.stringify(response.data)));
            });

          this.proveedor = response.data;

        }

      }).catch((err) => {

        console.log(err);

      });

    }

  },
};
</script>
