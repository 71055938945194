import setting from "./setting";

export default {
  getQuincenas: ($axios, data) => {
    return $axios.post(
      `${setting.http.base_url}/mantenimiento/quincenas`,
      data,
      {
        headers: setting.http.headers(),
      }
    );
  },

  getOfflineTransportista: ($axios, data) => {
    return $axios.get(
      `${setting.http.base_url}/portal/transportista/data/offline`,
      {
        headers: setting.http.headers(),
      }
    );
  },

  
};
