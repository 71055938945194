import { mapActions, mapGetters } from "vuex";
import DashboardService from "../services/DashboardService";
import axios from "axios";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  methods: {},
  actions: {
    async getGanaderoData({ state, commit }, data) {
      return await DashboardService.getGanaderoData(axios, data);
    },
    async downloadManualUso({ state, commit }, data) {
      return await DashboardService.downloadManualUso(axios);
    },
  },
};
