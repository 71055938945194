<template>
    <div class="m-container container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="c-card">
  
            <div class="card-header pb-0">
              <h1 class="mb-4">Calidad de leche</h1>
              <div class="hr mb-0"></div>
            </div>
  
            <div class="card-body no-scroll pt-4">
  
            <ul class="tabs-selector nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="first-tab" data-bs-toggle="tab" data-bs-target="#first-tab-pane" type="button" role="tab" aria-controls="first-tab-pane" aria-selected="true">Sólidos Totales</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="second-tab" data-bs-toggle="tab" data-bs-target="#second-tab-pane" type="button" role="tab" aria-controls="second-tab-pane" aria-selected="false">TRAM</button>
              </li>
            </ul>
  
            <div class="tab-content" id="nav-tabContent">
  
              <div class="tab-pane show active" id="first-tab-pane" role="tabpanel" aria-labelledby="first-tab" tabindex="0">
  
                <div class="tab-body w-fixed-s calidad-pagos">

                  <div class="fixed-section">
                    <Form
                      :validation-schema="schema"
                      ref="veeForm"
                      :initial-values="formValuesSolidos"
                      v-slot="{ meta }"
                    >

                      <div class="action-box justify-content-start">
                        <div class="grid grid-container-1">
                          <div class="grid-item-1">
                            <SelectButton
                              name="anio"
                              label="año"
                              v-model="model.anioSolidos"
                              @change="getDataByUserSelection()"
                              :options="yearsOptions"
                            />
                          </div>
                        </div>
                      </div>

                    </Form>
                  </div>

                  <div class="scrollable-content">
                    
                    <h3 class="title-acceso-ganaderos mb-4">Gráfica de Sólidos totales por quincena</h3>

                    <template v-if="model.anioSolidos == 0">

                        <div class="col-12">
                          <GradientLineChart
                            id="solidos_totales"
                            title="Sólidos Totales"
                            :yAxisTicksDecimals="true"
                            description=""
                            :enlarge="true"
                            @open-modal="openModal"
                            :chart="{
                              labels: this.labels,
                              yAxisTitle: '%',
                              suggestedMin: 9,
                              suggestedMax: 15,
                              datasets: dataPointsSolidos
                            }"
                            />
                        </div>

                      </template>


                      <template v-else>

                        <div class="col-12">
                          <GradientLineChart
                            id="solidos_totales"
                            title="Sólidos Totales"
                            :yAxisTicksDecimals="true"
                            description=""
                            :enlarge="true"
                            @open-modal="openModal"
                            :chart="{
                              labels: this.labels,
                              yAxisTitle: '%',
                              
                              suggestedMin: 9,
                              suggestedMax: 15,
                              datasets: [
                                {
                                  legendTitle: this.model.anio,
                                  data: dataPointsSolidosUNO,
                                  borderColor: '#F78D2C',
                                }
                              ]
                            }"
                          />
                        </div>

                      </template>

                    
                    <h3 class="title-acceso-ganaderos mt-5 mb-4">Detalle de Sólidos totales por quincena</h3>

                    <template v-if="model.anioSolidos == 0">
                    
                      <div class="b-table blue acceso-ganaderos-acopio">

                        <div class="t-header">
                          <div class="t-row">
                            <div class="t-cell">quincena</div>
                            <div class="t-cell">2021</div>
                            <div class="t-cell">2022</div>
                            <div class="t-cell">2023</div>
                            <div class="t-cell"></div>
                          </div>
                        </div>

                        <div class="t-body">

                          <div
                            class="t-row"
                            v-for="i, index in multidimensionalTablePromedio"
                            :key="index"
                          >
                            <div class="t-cell"><span class="mobile-cell-title">quincena</span> {{ i.half+"Q " + $filters.getMonthAbr(i.month)  }} </div>
                            <div class="t-cell"><span class="mobile-cell-title">2021</span>{{ $filters.getMonth(i.col1) ? $filters.roundToTwo(i.col1) : '-' }}</div>
                            <div class="t-cell"><span class="mobile-cell-title">2022</span>{{ $filters.getMonth(i.col2) ? $filters.roundToTwo(i.col2) : '-' }}</div>
                            <div class="t-cell"><span class="mobile-cell-title">2023</span>{{ $filters.getMonth(i.col3) ? $filters.roundToTwo(i.col3) : '-' }}</div>
                            <div class="t-cell action">
                            </div>
                          </div>

                        </div>

                        <div class="t-body" v-if="multidimensionalTablePromedio.length == 0">
                          <div class="t-body no-records">
                            <p>No existe data para esta fecha</p>
                          </div>
                        </div>


                      </div>
                    
                    </template>


                    <template v-else>


                      <div class="row one-mobile-table mt-4 mb-2 mb-md-5">

                        <div class="col-md-4">

                          <div class="b-table blue lista-calidad">

                            <div class="t-header">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">sólidos<br>totales (%)</div>
                              </div>
                            </div>
                            
                            <div class="t-body">
                              <div
                                class="t-row"
                                v-for="record, index in dataSTOne"
                                :key="index"
                              >
                                <div class="t-cell"><span class="mobile-cell-title">quincena</span>{{ this.$filters.getPeriod(record.quincena, record.mes) }}</div>
                                <div class="t-cell"><span class="mobile-cell-title">sólidos<br>totales (%)</span>{{ record.valor ? $filters.roundToTwo(record.valor) : '-' }}</div>
                              </div>
                            </div>

                          </div>

                        </div>

                        <div class="col-md-4">

                          <div class="b-table blue lista-calidad">

                            <div class="t-header">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">sólidos<br>totales (%)</div>
                              </div>
                            </div>

                            <div class="t-body">
                              <div
                                class="t-row"
                                v-for="record, index in dataSTTwo"
                                :key="index"
                              >
                                <div class="t-cell"><span class="mobile-cell-title">quincena</span>{{ this.$filters.getPeriod(record.quincena, record.mes) }}</div>
                                <div class="t-cell"><span class="mobile-cell-title">sólidos<br>totales (%)</span>{{ record.valor ? $filters.roundToTwo(record.valor) : '-' }}</div>
                              </div>
                            </div>

                          </div>

                        </div>

                        <div class="col-md-4">

                          <div class="b-table blue lista-calidad">

                            <div class="t-header">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">sólidos<br>totales (%)</div>
                              </div>
                            </div>

                            <div class="t-body">
                              <div
                                class="t-row"
                                v-for="record, index in dataSTThree"
                                :key="index"
                              >
                                <div class="t-cell"><span class="mobile-cell-title">quincena</span>{{ this.$filters.getPeriod(record.quincena, record.mes) }}</div>
                                <div class="t-cell"><span class="mobile-cell-title">sólidos<br>totales (%)</span>{{ record.valor ? $filters.roundToTwo(record.valor) : '-' }}</div>
                              </div>
                            </div>

                          </div>

                        </div>

                      </div>


                    </template>



                    <div class="form-actions mb-5">

                      <Button
                        class="mt-3 primary-button shadow-none"
                        size="lg"
                        type="button"
                        @click="downloadSolidosExcel()"
                        >Exportar</Button
                      >

                    </div>
                  </div>

                </div>
                
              </div>
  
              <div class="tab-pane" id="second-tab-pane" role="tabpanel" aria-labelledby="second-tab" tabindex="0">
  
                <div class="tab-body w-fixed-s calidad-pagos">
                  
                  <div class="fixed-section">
                    <Form
                      :validation-schema="schema"
                      ref="veeForm"
                      :initial-values="formValuesTRAM"
                      v-slot="{ meta }"
                    >
    
                      <div class="action-box justify-content-start">
                        <div class="grid grid-container-1">
                          <div class="grid-item-1">
                            <SelectButton
                              name="anio"
                              label="año"
                              v-model="model.anioTRAM"
                              @change="getTRAM()"
                              :options="aniosOptions"
                            />
                          </div>
                        </div>
                      </div>
    
                    </Form>
                  </div>

                  <div class="scrollable-content">

                    <h3 class="title-acceso-ganaderos mb-4">Detalle de TRAM por quincena</h3>

                    <div class="row one-mobile-table mb-2 mb-md-5">
    
                      <div class="col-md-4">
    
                        <div class="b-table blue lista-calidad">
    
                          <div class="t-header">
                            <div class="t-row">
                              <div class="t-cell">quincena</div>
                              <div class="t-cell">tram</div>
                            </div>
                          </div>
    
                          <div class="t-body">
                            <div
                              class="t-row"
                              v-for="record in dataTRAMOne"
                              :key="record.id"
                            >
                              <div class="t-cell"><span class="mobile-cell-title">quincena</span>{{ this.$filters.getPeriod(record.quincena, record.mes) }}</div>
                              <div class="t-cell" :style="{ color: getTRAMColor(record.valor) }"><span class="mobile-cell-title">tram</span>{{ record.valor ? record.valor : '-' }}</div>
                            </div>
                          </div>
    
                        </div>
    
                      </div>
    
                      <div class="col-md-4">
    
                        <div class="b-table blue lista-calidad">
    
                          <div class="t-header">
                            <div class="t-row">
                              <div class="t-cell">quincena</div>
                              <div class="t-cell">tram</div>
                            </div>
                          </div>
    
                          <div class="t-body">
                            <div
                              class="t-row"
                              v-for="record in dataTRAMTwo"
                              :key="record.id"
                            >
                              <div class="t-cell"><span class="mobile-cell-title">quincena</span>{{ this.$filters.getPeriod(record.quincena, record.mes) }}</div>
                              <div class="t-cell" :style="{ color: getTRAMColor(record.valor) }"><span class="mobile-cell-title">tram</span>{{ record.valor ? record.valor : '-' }}</div>
                            </div>
                          </div>
    
                        </div>
    
                      </div>
    
                      <div class="col-md-4">
    
                        <div class="b-table blue lista-calidad">
    
                          <div class="t-header">
                            <div class="t-row">
                              <div class="t-cell">quincena</div>
                              <div class="t-cell">tram</div>
                            </div>
                          </div>
    
                          <div class="t-body">
                            <div
                              class="t-row"
                              v-for="record in dataTRAMThree"
                              :key="record.id"
                            >
                              <div class="t-cell"><span class="mobile-cell-title">quincena</span>{{ this.$filters.getPeriod(record.quincena, record.mes) }}</div>
                              <div class="t-cell" :style="{ color: getTRAMColor(record.valor) }"><span class="mobile-cell-title">tram</span>{{ record.valor ? record.valor : '-' }}</div>
                            </div>
                          </div>
    
                        </div>
    
                      </div>
    
                    </div>
                    
                    <div class="form-actions">

                      <!--<div class="tram-legend h-100">
                        <h6 class="title">Leyenda:</h6>

                        <ul class="legend">
                          <li v-for="legend in tramLegend">
                            <div class="square" :style="{ backgroundColor: legend.color }"></div>
                            <span class="text">{{ legend.title }}</span>
                          </li>
                        </ul>
                      </div>-->
    
                      <Button
                        class="mt-3 mb-0 primary-button shadow-none"
                        style="align-self: flex-end;"
                        size="lg"
                        type="button"
                        @click="downloadTRAMExcel()"
                        >Exportar</Button
                      >
    
                    </div>

                  </div>
  
                </div>
  
              </div>
  
            </div>
  
  
            </div>
  
          </div>
        </div>
      </div>
  
  
  
      <transition name="fade">
        <CustomModal
          :show="dataModal2.showModal"
          :isHeader="false"
          :isFooter="false"
          :width="1600"
          :title="dataModal2.title"
          v-on:modal:close="hideModal()"
        >
          <template v-slot:body>



            <template v-if="model.anioSolidos == 0">

              <div class="col-12">
                <GradientLineChart
                  id="solidos_totales_modal"
                  title="Sólidos Totales"
                  :yAxisTicksDecimals="true"
                  description=""
                  :enlarge="false"
                  @open-modal="openModal"
                  :chart="{
                    labels: this.labels,
                    yAxisTitle: '%',
                    suggestedMin: 9,
                    suggestedMax: 15,
                    datasets: dataPointsSolidos
                  }"
                  />
              </div>

            </template>


            <template v-else>

              <div class="col-12">
                <GradientLineChart
                  id="solidos_totales_modal"
                  title="Sólidos Totales"
                  :yAxisTicksDecimals="true"
                  description=""
                  :enlarge="false"
                  @open-modal="openModal"
                  :chart="{
                    labels: this.labels,
                    yAxisTitle: '%',
                    
                    suggestedMin: 9,
                    suggestedMax: 15,
                    datasets: [
                      {
                        legendTitle: this.model.anio,
                        data: dataPointsSolidosUNO,
                        borderColor: '#F78D2C',
                      }
                    ]
                  }"
                />
              </div>

            </template>
  
            <div class="form-actions">
  
              <Button
                class="mt-3 ghost-button shadow-none"
                size="lg"
                type="button"
                @click="hideModal2()"
                >Cerrar</Button
              >
  
            </div>
  
          </template>
  
        </CustomModal>
      </transition>
  
    </div>
  </template>
  
<script>
const CACHE_NAME = "site-cache-v2";

import { markRaw } from "vue";
import { Form, Field } from "vee-validate";
import { mapActions } from 'vuex';
import GradientLineChart from "@/components/Charts/GradientLineChart.vue";
import TextInput from "@/components/Inputs/TextInput";
import FilterBox from "@/components/FilterBox";
import DateInput from "@/components/Inputs/Date";
import SelectButton from "@/components/Inputs/SelectButton";
import Button from "@/components/Inputs/Button";
import Pagination from '@/components/Pagination.vue';
import CustomModal from "@/components/CustomModal.vue";
import ConfirmationScreen from "@/components/ConfirmationScreen";
import Tag from "@/components/Tags/Tag";
import RowAccordion from "@/components/Accordion/RowAccordion";
import RadioButton from "@/components/Inputs/RadioButtonOriginalType.vue";
import Checkbox from "@/components/Inputs/Checkbox";
import * as Yup from "yup";

export default {
  components: {
      GradientLineChart,
      TextInput,
      FilterBox,
      SelectButton,
      Button,
      Pagination,
      CustomModal,
      ConfirmationScreen,
      RadioButton,
      Checkbox,
      Tag,
      RowAccordion,
      DateInput,
      Form,
      Field,
  },
  name: "Aprobacion de Ganadero",
  data() {
    const schema = markRaw(
      Yup.object().shape({
        fechaInicio: Yup.string().required(),
        fechaFin: Yup.string().required(),
        zona: Yup.string().nullable(),
        codInterno: Yup.string().nullable(),
      })
    );
    const formValuesSolidos = {

    };

    const formValuesTRAM = {

    }

    return {
      schema,
      formValuesSolidos,
      formValuesTRAM,
      
      labels: this.$filters.getPeriodsLabels(),

      aniosOptions: this.$filters.getYears(2021),
      yearsOptions: [],

      records: [],
      multidimensionalTable: [],

      multidimensionalTablePromedio: [],

      dataSTActual: [],
      dataSTActualMontos: [],
      dataSTAnterior: [],
      dataSTAnteriorMontos: [],
      dataSTOne: [],
      dataSTTwo: [],
      dataSTThree: [],


      dataTRAMOne: [],
      dataTRAMTwo: [],
      dataTRAMThree: [],

      allYearsDatasets: [],
      dataPointsSolidos: [],
      

      dataPointsSolidosUNO: [],

      tramLegend: [
        { title: 'A-AA', color: '#0D6EFD' },
        { title: 'B-C', color: '#02A54F' },
        { title: 'D-E', color: '#FF3143' }
      ],


      model: {
        anioSolidos: '',
        anioTRAM: '',
      },
      dataModal: {
        showModal: false,
        title: "",
        active: "",
        request: "",
        response: "",
      },
      dataModal2: {
        showModal: false,
        title: "",
        active: "",
        request: "",
        response: "",
      },
    };
  },
  computed: {

  },
  watch: {

  },
  methods: {
    ...mapActions({
      getListSolidos: 'calidad/getListSolidos',
      getListTRAM: 'calidad/getListTRAM',
      downloadSolidos: 'calidad/downloadSolidos',
      downloadTRAM: 'calidad/downloadTRAM',
    }),
    openModal(id) {

      if (id == 'solidos_totales') {
        this.dataModal2.showModal = true;
      }

    },
    hideModal() {
      this.dataModal.showModal = false;
    },
    hideModal2() {
      this.dataModal2.showModal = false;
    },
    downloadSolidosExcel() {

      const data = {
        //"year": this.model.anioSolidos,
        "years": this.model.anioSolidos == 0 ? this.yearsServer : this.model.anioSolidos        
      }

      this.downloadSolidos(data).then((response) => {

        if (response.status == 200) {

          let type = response.headers['content-type'];

          const file = new Blob([response.data], {type: type});
          const fileURL = URL.createObjectURL(file);

          let todayDate = this.$filters.generateFileDate();

          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', `reporte-solidos-totales_${todayDate}.xlsx`);
          document.body.appendChild(link);
          link.click();

        }

      });

    },
    getTRAM() {

      this.dataTRAM = [];
      this.dataTRAMMontos = [];

      this.dataTRAMOne = [];
      this.dataTRAMTwo = [];
      this.dataTRAMThree = [];



      caches.open(CACHE_NAME)
        .then((cache) => {
          const cacheUrl = "/offline/tram";

          cache.match(cacheUrl)
            .then((response) => {
              if (response) {
                return response.json();
              }
            })
            .then((dataResponse) => {
              if (dataResponse) {

                // Los datos están disponibles en caché
                let data = dataResponse;

                this.dataTRAM = this.$filters.getTRAMDataByPeriods(data, parseInt(this.model.anioTRAM));
                this.dataTRAMMontos = this.$filters.getValuesByPeriods(this.dataTRAM);

                const chunkSize = 8;
                for (let i = 0; i < this.dataTRAM.length; i += chunkSize) {

                  if (i == 0) {
                    const chunk = this.dataTRAM.slice(i, i + chunkSize);
                    this.dataTRAMOne = chunk;
                  }

                  if (i == 8) {
                    const chunk = this.dataTRAM.slice(i, i + chunkSize);
                    this.dataTRAMTwo = chunk;
                  }

                  if (i == 16) {
                    const chunk = this.dataTRAM.slice(i, i + chunkSize);
                    this.dataTRAMThree = chunk;
                  }
                  
                }

              }

            });
        });



        const data = {
          "years": this.model.anioTRAM
        }

        this.getListTRAM(data).then((response) => {

          if (response.status == 200) {

            caches.open(CACHE_NAME)
              .then((cache) => {
                cache.put("/offline/tram", new Response(JSON.stringify(response.data)));
              });

            let data = response.data[0].data;
            //console.log('data TRAM', data);

            this.dataTRAM = this.$filters.getTRAMDataByPeriods(data, parseInt(this.model.anioTRAM));


            this.dataTRAMMontos = this.$filters.getValuesByPeriods(this.dataTRAM);

            const chunkSize = 8;
            for (let i = 0; i < this.dataTRAM.length; i += chunkSize) {

              if (i == 0) {
                const chunk = this.dataTRAM.slice(i, i + chunkSize);
                this.dataTRAMOne = chunk;
              }

              if (i == 8) {
                const chunk = this.dataTRAM.slice(i, i + chunkSize);
                this.dataTRAMTwo = chunk;
              }

              if (i == 16) {
                const chunk = this.dataTRAM.slice(i, i + chunkSize);
                this.dataTRAMThree = chunk;
              }
              
            }

          }

        }).catch((err) => {

          //console.log(err.message);

        });

      

    },
    getTRAMColor(val) {

      if (val !== '' && val !== '-') {
        
        if (val == 'A') {

          return 'green';

        } else if (val == 'AA') {
          
          return 'green';

        } else if (val == 'B') {
          
          return 'orange';

        } else if (val == 'C') {
          
          return 'orange';

        } else if (val == 'D') {
          
          return 'red';

        } else if (val == 'E') {
          
          return 'red';

        }

      }

    },
    downloadTRAMExcel() {

      const data = {
        "years": this.model.anioTRAM
      }

      this.downloadTRAM(data).then((response) => {

        if (response.status == 200) {

          let type = response.headers['content-type'];

          const file = new Blob([response.data], {type: type});
          const fileURL = URL.createObjectURL(file);

          let todayDate = this.$filters.generateFileDate();

          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', `reporte-tram_${todayDate}.xlsx`);
          document.body.appendChild(link);
          link.click();

        }

      });

    },



    createMultidimensionalTablePromedio(oData) {
      // parse years data
      let yMax = new Date().getFullYear();
      let yMin = yMax - 2;

      let oYears = [];
      for (let y = yMin; y <= yMax; y++) {
          let dataYear = findByYear(y, oData);
          oYears[y] = dataYear;
      }
      //console.log('oYears', oYears);

      // make months data 
      let oMonth = [];

      for (let m = 1; m <= 12; m++) {
          oMonth.push({ 
              half: 1, month: m,
              col1: getByHalfMonth(1, m, oYears[yMin]), 
              col2: getByHalfMonth(1, m, oYears[yMin+1]),
              col3: getByHalfMonth(1, m, oYears[yMax]) 
          });
          oMonth.push({ 
              half: 2, month: m, 
              col1: getByHalfMonth(2, m, oYears[yMin]), 
              col2: getByHalfMonth(2, m, oYears[yMin+1]),
              col3: getByHalfMonth(2, m, oYears[yMax]) 
          });
      }

      function findByYear(year, data) {
          let result = [];
          for (var key in data) {
              if (data.hasOwnProperty(key) && data[key].year === year){
                  result = data[key].data;
              }
          }
          return result;
      }

      function getByHalfMonth(half, month, data) {
          if (data.length > 0) {
              for (let i = 0; i < data.length; i++) {
                  const item = data[i];
                  if (item.mes == month && item.numeroQuincena == half) {
                      return item.valor;
                  }
              }
          }
          return '';
      }

      this.multidimensionalTablePromedio = oMonth;

    },


    getDataByUserSelection() {

      let anio = this.model.anioSolidos;

      if (anio == 0) {

        this.createMultidimensionalTablePromedio(this.dataRaw);

        let anios = [...this.yearsOptions];

        let index = anios.findIndex(objeto => objeto.id === 0);
        if (index !== -1) {
          anios.splice(index, 1);
        }

        this.allYearsDatasets = [];

        anios.map((x, i)=> {
          
          let aYear = x.id;
          

          let arrYear1 = this.$filters.getSolidosTotalesByYear(this.dataRaw, aYear, 'valor', 'numeroQuincena');
          let dataValuesYear1 = this.$filters.generarSecuenciaQuincenas(arrYear1, aYear, 'valor', 'numeroQuincena');
          let points = dataValuesYear1.map(objeto => objeto.valor);

          //console.log('points solidos', points);

          let borderColor = '';

          if (i == 0) {
            borderColor = '#F78D2C';
          } else if (i == 1 ) {
            borderColor = '#1D4193';
          } else if (i == 2) {
            borderColor = '#07C25E';
          }

          this.allYearsDatasets.push({
            legendTitle: aYear,
            data: points,
            borderColor: borderColor,
          });

        })

        this.dataPointsSolidos = Object.values(this.allYearsDatasets);

        //console.log('allYearsDatasets', this.dataPointsSolidos);

      } else {

        let aYear = anio;

        let arrYear1 = this.$filters.getSolidosTotalesByYear(this.dataRaw, aYear, 'valor', 'numeroQuincena');
        let dataValuesYear1 = this.$filters.generarSecuenciaQuincenas(arrYear1, aYear, 'valor', 'numeroQuincena');
        let points = dataValuesYear1.map(objeto => objeto.valor);

        this.dataPointsSolidosUNO = points;
        let dataActual = arrYear1;
        this.dataSTActual = this.$filters.getDataByPeriodsV2(dataActual, aYear);

        console.log('dataSTActual', this.dataSTActual);
        
        const chunkSize = 8;
        for (let i = 0; i < this.dataSTActual.length; i += chunkSize) {

          if (i == 0) {
            const chunk = this.dataSTActual.slice(i, i + chunkSize);
            this.dataSTOne = chunk;
          }

          if (i == 8) {
            const chunk = this.dataSTActual.slice(i, i + chunkSize);
            this.dataSTTwo = chunk;
          }

          if (i == 16) {
            const chunk = this.dataSTActual.slice(i, i + chunkSize);
            this.dataSTThree = chunk;
          }
          
        }




        
      }

    },


    getSolidos() {


      caches.open(CACHE_NAME)
        .then((cache) => {
          const cacheUrl = "/offline/solidoslc";

          cache.match(cacheUrl)
            .then((response) => {
              if (response) {
                return response.json();
              }
            })
            .then((data) => {
              if (data) {

                // Los datos están disponibles en caché
                this.dataRaw = data;

                this.getDataByUserSelection();

              }

            });
        });


        const data = {
          "years": this.yearsServer, 
          "decrease": 2
        }

        this.getListSolidos(data).then((response) => {

          if (response.status == 200) {

            caches.open(CACHE_NAME)
              .then((cache) => {
                cache.put("/offline/solidoslc", new Response(JSON.stringify(response.data)));
              });

            let data = response.data;

            this.dataRaw = data;

            this.getDataByUserSelection();

          }

        }).catch((err) => {

          console.log(err.message);

        });


    },
    
  },
  async mounted() {

    let loader = this.$loading.show();


    const dateFiltersV4 = this.$filters.getYearsV4();

    this.yearsOptions = dateFiltersV4.yearsOptions;
    this.yearsServer = dateFiltersV4.yearsServer;

    this.formValuesSolidos.anio = this.yearsOptions[0].id;
    this.model.anioSolidos = this.yearsOptions[0].id;

    let dateFilters = this.$filters.getCurrentDatePeriodFilter();

    this.formValuesTRAM.anio = dateFilters.anio;
    this.model.anioTRAM = dateFilters.anio;
    
    this.getSolidos();
    this.getTRAM();

    loader.hide();

  },
};
</script>
