import { mapActions, mapGetters } from 'vuex';
import SeguimientoService from '../services/SeguimientoService';
import axios from 'axios';
export default {
    namespaced: true,
    state:{},
    getters:{},
    mutations:{},
    methods:{ },
    actions:{
      async getParams({ state, commit }, data) {
        return await SeguimientoService.getParams(axios, data);
      },
      async getList({state,commit}, data) {
        return await SeguimientoService.getList(axios,data);
      },
      async getDetalle({state,commit}, data) {
        return await SeguimientoService.getDetalle(axios,data);
      },
      async postSeguimiento({state,commit}, data) {
        return await SeguimientoService.postSeguimiento(axios,data);
      },
      async postEvidencia({state,commit}, data) {
        return await SeguimientoService.postEvidencia(axios,data);
      },
      async postIncidencia({state,commit}, data) {
        return await SeguimientoService.postIncidencia(axios,data);
      },

    }
}
