import swal from "sweetalert2";
import { format, subYears } from "date-fns";
import Numeral from "numeral";

export default {
  install: (app, options) => {
    app.config.globalProperties.$filters = {
      twoDigits(number) {
        if (number < 9) {
          return String(number).padStart(2, "0");
        } else {
          return number;
        }
      },
      threeDigits(number) {
        if (number < 100) {
          return String(number).padStart(3, "0");
        } else {
          return number;
        }
      },
      roundToTwo(num) {
        var number = Numeral(num);
        return number.format("0,0.00");
      },
      roundToThree(num) {
        var number = Numeral(num);
        return number.format("0,0.000");
      },
      roundToFour(num) {
        var number = Numeral(num);
        return number.format("0,0.0000");
      },
      roundToFive(num) {
        var number = Numeral(num);
        return number.format("0,0.00000");
      },
      twoDecimals(num) {
        var number = Numeral(num);
        return number.format("0.00");
      },
      fourDecimals(num) {
        var number = Numeral(num);
        return number.format("0.0000");
      },
      fiveDecimals(num) {
        var number = Numeral(num);
        return number.format("0.00000");
      },
      toChar(num) {
        var number = Numeral(num);
        return number.format("0,0");
      },
      formatCurrency(num) {
        var number = Numeral(num);
        return "S/ " + number.format("0,0.00");
      },
      formatCurrencyWithFourDecimals(num) {
        var number = Numeral(num);
        return "S/ " + number.format("0,0.0000");
      },
      formatCurrencyWithFiveDecimals(num) {
        var number = Numeral(num);
        return "S/ " + number.format("0,0.00000");
      },
      convertToNumber(string) {
        return Numeral(string).value();
      },
      converToInt(number) {
        //return parseInt(number, 10);
        return Math.ceil(parseFloat(number));
      },
      validateOnlyNumbers(userInput) {
        if (!/^[0-9]+$/.test(userInput)) {
          return false;
        }

        return true;
      },
      convertToBool(number) {
        if (number === 1) {
          return true;
        } else if (number === 0) {
          return false;
        } else {
          return number === "true" || number === true;
        }
      },
      isCodigoProveedor(evt, model) {
        /*if (type == 'paste') {
          if (evt.originalEvent.clipboardData.getData('Text').match(/[^\d]/)) {
            evt.preventDefault();
          }
        }*/

        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          if (model.length >= 6) {
            evt.preventDefault();
          }

          return true;
        }
      },
      toFixNumber(number, digits) {
        return parseFloat(number).toFixed(digits);
      },
      getCurrentDatePeriodFilter() {
        return {
          mes: format(new Date(), "M"),
          anio: format(new Date(), "YYYY"),
          quincena: format(new Date(), "D") <= 15 ? 1 : 2,
        };
      },
      getBooleanOptions() {
        return [
          { id: 1, nombre: "Sí" },
          { id: 0, nombre: "No" },
        ];
      },
      getPeriods() {
        return [
          { id: 1, nombre: "01" },
          { id: 2, nombre: "02" },
        ];
      },
      getYears(startYear, currentYear = "") {
        var years = [];

        if (currentYear == "") {
          var currentYear = new Date().getFullYear();
        } else {
          var currentYear = currentYear;
        }

        startYear = startYear || 1980;
        let year = "";

        while (startYear <= currentYear) {
          year = startYear++;
          years.unshift({ id: year, nombre: year });
        }

        return years;
      },
      getMonthAbr(num) {
        num = parseInt(num);
        let month;

        switch (num) {
          case 1:
            month = "Ene";
            break;
          case 2:
            month = "Feb";
            break;
          case 3:
            month = "Mar";
            break;
          case 4:
            month = "Abr";
            break;
          case 5:
            month = "May";
            break;
          case 6:
            month = "Jun";
            break;
          case 7:
            month = "Jul";
            break;
          case 8:
            month = "Ago";
            break;
          case 9:
            month = "Sep";
            break;
          case 10:
            month = "Oct";
            break;
          case 11:
            month = "Nov";
            break;
          case 12:
            month = "Dic";
            break;
          default:
            month = num;
        }

        return month;
      },
      getMonth(num) {
        num = parseInt(num);
        let month;

        switch (num) {
          case 1:
            month = "Enero";
            break;
          case 2:
            month = "Febrero";
            break;
          case 3:
            month = "Marzo";
            break;
          case 4:
            month = "Abril";
            break;
          case 5:
            month = "Mayo";
            break;
          case 6:
            month = "Junio";
            break;
          case 7:
            month = "Julio";
            break;
          case 8:
            month = "Agosto";
            break;
          case 9:
            month = "Septiembre";
            break;
          case 10:
            month = "Octubre";
            break;
          case 11:
            month = "Noviembre";
            break;
          case 12:
            month = "Diciembre";
            break;
          default:
            month = num;
        }

        return month;
      },
      getPeriod(quincena, mes) {
        let list = [
          { id: "1", nombre: "Ene" },
          { id: "2", nombre: "Feb" },
          { id: "3", nombre: "Mar" },
          { id: "4", nombre: "Abr" },
          { id: "5", nombre: "May" },
          { id: "6", nombre: "Jun" },
          { id: "7", nombre: "Jul" },
          { id: "8", nombre: "Ago" },
          { id: "9", nombre: "Sep" },
          { id: "10", nombre: "Oct" },
          { id: "11", nombre: "Nov" },
          { id: "12", nombre: "Dic" },
        ];

        let month = list.find((x) => x.id == mes);
        let period = `${quincena}Q ${month.nombre}`; //`${quincena}\u00b0 ${month.nombre}`

        return period;
      },
      getPeriodsLabels() {
        //\u00b0

        return [
          "1Q Ene",
          "2Q Ene",
          "1Q Feb",
          "2Q Feb",
          "1Q Mar",
          "2Q Mar",
          "1Q Abr",
          "2Q Abr",
          "1Q May",
          "2Q May",
          "1Q Jun",
          "2Q Jun",
          "1Q Jul",
          "2Q Jul",
          "1Q Ago",
          "2Q Ago",
          "1Q Sep",
          "2Q Sep",
          "1Q Oct",
          "2Q Oct",
          "1Q Nov",
          "2Q Nov",
          "1Q Dic",
          "2Q Dic",
        ];
      },
      getMonths(config) {
        let months = [
          { id: 1, nombre: "Enero" },
          { id: 2, nombre: "Febrero" },
          { id: 3, nombre: "Marzo" },
          { id: 4, nombre: "Abril" },
          { id: 5, nombre: "Mayo" },
          { id: 6, nombre: "Junio" },
          { id: 7, nombre: "Julio" },
          { id: 8, nombre: "Agosto" },
          { id: 9, nombre: "Septiembre" },
          { id: 10, nombre: "Octubre" },
          { id: 11, nombre: "Noviembre" },
          { id: 12, nombre: "Diciembre" },
        ];

        if (isEmptyObject(config)) {
          return months;
        }

        let newMaxMonth = addMonths(new Date(), config.max);

        if (config.max == "today") {
          newMaxMonth = addMonths(new Date(), 0);
        }

        let date = getDateByMonth(newMaxMonth);

        let minMonthId = subtractMonths(new Date(), config.min) + 1;
        let maxMonthId = newMaxMonth + 1;

        /*return {
          minMonthId: minMonthId,
          maxMonthId: maxMonthId
        };*/

        let filteredMonths = [];

        for (let index = minMonthId - 1; index < maxMonthId; index++) {
          const element = months[index];
          filteredMonths.push(element);
        }

        return filteredMonths;
      },
      generateFileTime() {
        return format(new Date(), "HH-mm-ss");
      },
      generateFileDate() {
        return format(new Date(), "DD-MM-YYYY_HH-mm-ss");
      },
      capitalize(str) {
        const arr = String(str).toLowerCase().split(" ");

        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }

        const str2 = arr.join(" ");
        return str2;
      },
      formatDateFromServer(date) {
        date = date.split("/"); //'12/10/2022' - '2022-10-12'

        return date[2] + "-" + date[1] + "-" + date[0];
      },
      getNameById(itemId, array, optId = "", optName = "") {
        var obj = array.filter(function (item) {
          if (optId !== "") {
            if (item[optId] == itemId) {
              return optName !== "" ? item[optName] : item.nombre;
            }
          } else {
            if (item.id == itemId) {
              return optName !== "" ? item[optName] : item.nombre;
            }
          }
        });

        return optName !== "" ? obj[0][optName] : obj[0].nombre;
      },
      getTurno() {
        var today = new Date();
        var curHr = today.getHours();
        var turno = {
          id: "",
          nombre: "",
        };

        if (curHr < 12) {
          turno = {
            id: "M",
            nombre: "Mañana",
          };
        } else {
          turno = {
            id: "T",
            nombre: "Tarde",
          };
        }

        return turno;
      },
      makeSlug(title) {
        var slug = "";
        // Change to lower case
        var titleLower = title.toLowerCase();
        // Letter "e"
        slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
        // Letter "a"
        slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
        // Letter "o"
        slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
        // Letter "u"
        slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
        // Letter "d"
        slug = slug.replace(/đ/gi, "d");
        // Trim the last whitespace
        slug = slug.replace(/\s*$/g, "");
        // Change whitespace to "-"
        slug = slug.replace(/\s+/g, "-");

        return slug;
      },
      scrollToTop(className) {
        setTimeout(() => {
          let elem = document.querySelector(className);

          if (elem) {
            elem.scrollBy({
              top: -elem.scrollHeight,
              behavior: "smooth",
            });
          }
        }, 200);
      },
      scrollToBottom(className) {
        setTimeout(() => {
          let elem = document.querySelector(className);
          //elem.scrollTop = elem.scrollHeight;

          if (elem) {
            elem.scrollBy({
              top: elem.scrollHeight,
              behavior: "smooth",
            });
          }
        }, 50);
      },
      calcTimeFromSeconds(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor((d % 3600) / 60);
        var s = Math.floor((d % 3600) % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hora" : " horas") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " minuto" : " minutos") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " segundo" : " segundos") : "";
        return hDisplay + mDisplay + sDisplay;
      },

      getIPAddress() {
        return new Promise((resolve, reject) => {
          const RTCPeerConnection = window.RTCPeerConnection || window.webkitRTCPeerConnection;
          const peerConnection = new RTCPeerConnection({
            iceServers: []
          });
          peerConnection.createDataChannel("");
          peerConnection.createOffer().then((offer) => {
            return peerConnection.setLocalDescription(offer);
          }).then(() => {
            const regex = /(\d+\.\d+\.\d+\.\d+)/;
            const ipAddress = regex.exec(peerConnection.localDescription.sdp)[1];
            console.log(ipAddress);
            resolve(ipAddress);
          }).catch((err) => {
            console.error(err);
            reject('Dirección IP no disponible');
          });
        });
      },   


      showOnlyTime(str) {
        return str.split('-')[0].trim()
      },



      getDataByYear(arr, year) {

        if (year == 0) {
          return arr;
        }

        if (arr) {
          const resp = arr.find((x) => x.year == year);
          return resp ? resp.data : [];
        }
      
      },

      getYearsV4() {
        const currentDate = new Date();

        const currentYear = format(currentDate, 'YYYY');
        const previousYear1 = format(subYears(currentDate, 1), 'YYYY');
        const previousYear2 = format(subYears(currentDate, 2), 'YYYY');
  
        let yearsOptions = []
        const yearsServer = `${currentYear},${previousYear1},${previousYear2}`;

        yearsOptions.push({ id: 0, nombre: 'Todos' });
        yearsOptions.push({ id: this.convertToNumber(currentYear), nombre: this.convertToNumber(currentYear) });
        yearsOptions.push({ id: this.convertToNumber(previousYear1), nombre: this.convertToNumber(previousYear1) });
        yearsOptions.push({ id: this.convertToNumber(previousYear2), nombre: this.convertToNumber(previousYear2) });
  
        
  
        return {
          yearsOptions,
          yearsServer
        }

      },




      fillData(arr, year) {
        const array1 = this.getFormatPeriodsOfYear(year); /* Primer array */
        const array2 = arr; /* Segundo array */
        
        let mezcla = array1.map((elemento) => {
          const elementoActualizado = array2.find(
            (e) => e.id === elemento.id
          );
          if (elementoActualizado) {
            return { ...elemento, ...elementoActualizado };
          } else {
            return elemento;
          }
        });
  
        return mezcla;
      },


      sumarTotalesPorAnio(arr) {
        let total = 0;
        for (let i = 0; i < arr.length; i++) {
          
          let array = arr[i];
          
          for (let index = 0; index < array.length; index++) {
            const element = array[index];

            let nro = element.total == '' ? 0 : element.total;
            total += this.convertToNumber(nro);
            
          }
          
        }
        return total;
      },



      sumarTotalesPorQuincena(arr, mes, quincena) {
        let total = 0;

        if (arr == undefined) {
          return [];
        }

        console.log('arr', arr);

        for (let i = 0; i < arr.length; i++) {
          
          let element = arr[i];
          

            if (element.mes == mes && element.numeroQuincena == quincena) {
              let nro = element.total == '' ? 0 : element.total;
              total += this.convertToNumber(nro);
            }
          
        }
        return total;
      },



      getFormatPeriodsOfYear(year) {

        return [
          { id:`${year}.1.1`, mes: 1, quincena: 1, year: year, numeroQuincena: 1, total: '', promedio: '', dias: '' },
          { id:`${year}.1.2`, mes: 1, quincena: 2, year: year, numeroQuincena: 2, total: '', promedio: '', dias: '' },
          { id:`${year}.2.1`, mes: 2, quincena: 1, year: year, numeroQuincena: 1, total: '', promedio: '', dias: '' },
          { id:`${year}.2.2`, mes: 2, quincena: 2, year: year, numeroQuincena: 2, total: '', promedio: '', dias: '' },
          { id:`${year}.3.1`, mes: 3, quincena: 1, year: year, numeroQuincena: 1, total: '', promedio: '', dias: '' },
          { id:`${year}.3.2`, mes: 3, quincena: 2, year: year, numeroQuincena: 2, total: '', promedio: '', dias: '' },
          { id:`${year}.4.1`, mes: 4, quincena: 1, year: year, numeroQuincena: 1, total: '', promedio: '', dias: '' },
          { id:`${year}.4.2`, mes: 4, quincena: 2, year: year, numeroQuincena: 2, total: '', promedio: '', dias: '' },
          { id:`${year}.5.1`, mes: 5, quincena: 1, year: year, numeroQuincena: 1, total: '', promedio: '', dias: '' },
          { id:`${year}.5.2`, mes: 5, quincena: 2, year: year, numeroQuincena: 2, total: '', promedio: '', dias: '' },
          { id:`${year}.6.1`, mes: 6, quincena: 1, year: year, numeroQuincena: 1, total: '', promedio: '', dias: '' },
          { id:`${year}.6.2`, mes: 6, quincena: 2, year: year, numeroQuincena: 2, total: '', promedio: '', dias: '' },
          { id:`${year}.7.1`, mes: 7, quincena: 1, year: year, numeroQuincena: 1, total: '', promedio: '', dias: '' },
          { id:`${year}.7.2`, mes: 7, quincena: 2, year: year, numeroQuincena: 2, total: '', promedio: '', dias: '' },
          { id:`${year}.8.1`, mes: 8, quincena: 1, year: year, numeroQuincena: 1, total: '', promedio: '', dias: '' },
          { id:`${year}.8.2`, mes: 8, quincena: 2, year: year, numeroQuincena: 2, total: '', promedio: '', dias: '' },
          { id:`${year}.9.1`, mes: 9, quincena: 1, year: year, numeroQuincena: 1, total: '', promedio: '', dias: '' },
          { id:`${year}.9.2`, mes: 9, quincena: 2, year: year, numeroQuincena: 2, total: '', promedio: '', dias: '' },
          { id:`${year}.10.1`, mes: 10, quincena: 1, year: year, numeroQuincena: 1, total: '', promedio: '', dias: '' },
          { id:`${year}.10.2`, mes: 10, quincena: 2, year: year, numeroQuincena: 2, total: '', promedio: '', dias: '' },
          { id:`${year}.11.1`, mes: 11, quincena: 1, year: year, numeroQuincena: 1, total: '', promedio: '', dias: '' },
          { id:`${year}.11.2`, mes: 11, quincena: 2, year: year, numeroQuincena: 2, total: '', promedio: '', dias: '' },
          { id:`${year}.12.1`, mes: 12, quincena: 1, year: year, numeroQuincena: 1, total: '', promedio: '', dias: '' },
          { id:`${year}.12.2`, mes: 12, quincena: 2, year: year, numeroQuincena: 2, total: '', promedio: '', dias: '' },
        ]
      
      },




      
































      getDataByPeriods(arr, year) {
        let periods = [
          { mes: 1, quincena: 1 },
          { mes: 1, quincena: 2 },
          { mes: 2, quincena: 1 },
          { mes: 2, quincena: 2 },
          { mes: 3, quincena: 1 },
          { mes: 3, quincena: 2 },
          { mes: 4, quincena: 1 },
          { mes: 4, quincena: 2 },
          { mes: 5, quincena: 1 },
          { mes: 5, quincena: 2 },
          { mes: 6, quincena: 1 },
          { mes: 6, quincena: 2 },
          { mes: 7, quincena: 1 },
          { mes: 7, quincena: 2 },
          { mes: 8, quincena: 1 },
          { mes: 8, quincena: 2 },
          { mes: 9, quincena: 1 },
          { mes: 9, quincena: 2 },
          { mes: 10, quincena: 1 },
          { mes: 10, quincena: 2 },
          { mes: 11, quincena: 1 },
          { mes: 11, quincena: 2 },
          { mes: 12, quincena: 1 },
          { mes: 12, quincena: 2 },
        ];

        let newArr = [];

        if (arr.length == 0) {
          periods.map((item) => {
            newArr.push({
              year: year,
              mes: item.mes,
              quincena: item.quincena,
              monto: null,
            });
          });
        } else {
          periods.map((item) => {
            let itemFound = arr.find(
              (i) => i.mes == item.mes && i.quincena == item.quincena
            );

            if (!itemFound) {
              newArr.push({
                year: year,
                mes: item.mes,
                quincena: item.quincena,
                monto: null,
              });
            } else {
              newArr.push(itemFound);
            }
          });
        }

        return newArr;
      },
      getAmountsByPeriods(arr, year) {
        let periods = [
          { mes: 1, quincena: 1 },
          { mes: 1, quincena: 2 },
          { mes: 2, quincena: 1 },
          { mes: 2, quincena: 2 },
          { mes: 3, quincena: 1 },
          { mes: 3, quincena: 2 },
          { mes: 4, quincena: 1 },
          { mes: 4, quincena: 2 },
          { mes: 5, quincena: 1 },
          { mes: 5, quincena: 2 },
          { mes: 6, quincena: 1 },
          { mes: 6, quincena: 2 },
          { mes: 7, quincena: 1 },
          { mes: 7, quincena: 2 },
          { mes: 8, quincena: 1 },
          { mes: 8, quincena: 2 },
          { mes: 9, quincena: 1 },
          { mes: 9, quincena: 2 },
          { mes: 10, quincena: 1 },
          { mes: 10, quincena: 2 },
          { mes: 11, quincena: 1 },
          { mes: 11, quincena: 2 },
          { mes: 12, quincena: 1 },
          { mes: 12, quincena: 2 },
        ];

        let newArr = [];

        if (arr.length == 0) {
          periods.map((item) => {
            newArr.push({
              year: year,
              mes: item.mes,
              quincena: item.quincena,
              monto: null,
            });
          });
        } else {
          periods.map((item) => {
            let itemFound = arr.find(
              (i) => i.mes == item.mes && i.quincena == item.quincena
            );

            if (!itemFound) {
              newArr.push({
                year: year,
                mes: item.mes,
                quincena: item.quincena,
                monto: null,
              });
            } else {
              newArr.push(itemFound);
            }
          });
        }

        let montos = [];

        montos = newArr.map((x) => {
          let m;

          if (x.monto) {
            m = this.twoDecimals(x.monto);
          } else {
            m = null;
          }

          return m;
        });

        return montos;
      },
      getDataByPeriodsPU(arr, year) {
        let periods = [
          { mes: 1, quincena: 1 },
          { mes: 1, quincena: 2 },
          { mes: 2, quincena: 1 },
          { mes: 2, quincena: 2 },
          { mes: 3, quincena: 1 },
          { mes: 3, quincena: 2 },
          { mes: 4, quincena: 1 },
          { mes: 4, quincena: 2 },
          { mes: 5, quincena: 1 },
          { mes: 5, quincena: 2 },
          { mes: 6, quincena: 1 },
          { mes: 6, quincena: 2 },
          { mes: 7, quincena: 1 },
          { mes: 7, quincena: 2 },
          { mes: 8, quincena: 1 },
          { mes: 8, quincena: 2 },
          { mes: 9, quincena: 1 },
          { mes: 9, quincena: 2 },
          { mes: 10, quincena: 1 },
          { mes: 10, quincena: 2 },
          { mes: 11, quincena: 1 },
          { mes: 11, quincena: 2 },
          { mes: 12, quincena: 1 },
          { mes: 12, quincena: 2 },
        ];

        let newArr = [];

        if (arr.length == 0) {
          periods.map((item) => {
            newArr.push({
              year: year,
              mes: item.mes,
              quincena: item.quincena,
              monto: null,
            });
          });
        } else {
          periods.map((item) => {
            let itemFound = arr.find(
              (i) => i.mes == item.mes && i.quincena == item.quincena
            );

            if (!itemFound) {
              newArr.push({
                year: year,
                mes: item.mes,
                quincena: item.quincena,
                monto: null,
              });
            } else {
              newArr.push({
                year: year,
                mes: itemFound.mes,
                quincena: itemFound.quincena,
                monto: this.fiveDecimals(itemFound.monto),
              });
            }
          });
        }

        return newArr;
      },
      getAmountsByPeriodsPU(arr, year) {
        let periods = [
          { mes: 1, quincena: 1 },
          { mes: 1, quincena: 2 },
          { mes: 2, quincena: 1 },
          { mes: 2, quincena: 2 },
          { mes: 3, quincena: 1 },
          { mes: 3, quincena: 2 },
          { mes: 4, quincena: 1 },
          { mes: 4, quincena: 2 },
          { mes: 5, quincena: 1 },
          { mes: 5, quincena: 2 },
          { mes: 6, quincena: 1 },
          { mes: 6, quincena: 2 },
          { mes: 7, quincena: 1 },
          { mes: 7, quincena: 2 },
          { mes: 8, quincena: 1 },
          { mes: 8, quincena: 2 },
          { mes: 9, quincena: 1 },
          { mes: 9, quincena: 2 },
          { mes: 10, quincena: 1 },
          { mes: 10, quincena: 2 },
          { mes: 11, quincena: 1 },
          { mes: 11, quincena: 2 },
          { mes: 12, quincena: 1 },
          { mes: 12, quincena: 2 },
        ];

        let newArr = [];

        if (arr.length == 0) {
          periods.map((item) => {
            newArr.push({
              year: year,
              mes: item.mes,
              quincena: item.quincena,
              monto: null,
            });
          });
        } else {
          periods.map((item) => {
            let itemFound = arr.find(
              (i) => i.mes == item.mes && i.quincena == item.quincena
            );

            if (!itemFound) {
              newArr.push({
                year: year,
                mes: item.mes,
                quincena: item.quincena,
                monto: null,
              });
            } else {
              newArr.push({
                year: year,
                mes: itemFound.mes,
                quincena: itemFound.quincena,
                monto: this.fiveDecimals(itemFound.monto),
              });
            }
          });
        }

        let montos = [];

        montos = newArr.map((x) => {
          let m;

          if (x.monto) {
            m = x.monto;
          } else {
            m = null;
          }

          return m;
        });

        return montos;
      },
      getDataByPeriodsV2(arr, year) {
        let periods = [
          { mes: 1, quincena: 1 },
          { mes: 1, quincena: 2 },
          { mes: 2, quincena: 1 },
          { mes: 2, quincena: 2 },
          { mes: 3, quincena: 1 },
          { mes: 3, quincena: 2 },
          { mes: 4, quincena: 1 },
          { mes: 4, quincena: 2 },
          { mes: 5, quincena: 1 },
          { mes: 5, quincena: 2 },
          { mes: 6, quincena: 1 },
          { mes: 6, quincena: 2 },
          { mes: 7, quincena: 1 },
          { mes: 7, quincena: 2 },
          { mes: 8, quincena: 1 },
          { mes: 8, quincena: 2 },
          { mes: 9, quincena: 1 },
          { mes: 9, quincena: 2 },
          { mes: 10, quincena: 1 },
          { mes: 10, quincena: 2 },
          { mes: 11, quincena: 1 },
          { mes: 11, quincena: 2 },
          { mes: 12, quincena: 1 },
          { mes: 12, quincena: 2 },
        ];

        let newArr = [];

        

        if (arr.data.length == 0) {
          periods.map((item) => {
            newArr.push({
              year: year,
              mes: item.mes,
              quincena: item.quincena,
              valor: null,
            });
          });
        } else {
          periods.map((item) => {

            let itemFound = arr.data.find(
              (i) => i.mes == item.mes && i.numeroQuincena == item.quincena
            );

            if (!itemFound) {
              newArr.push({
                year: year,
                mes: item.mes,
                quincena: item.quincena,
                valor: null,
              });
            } else {
              newArr.push({
                year: year,
                mes: itemFound.mes,
                quincena: itemFound.numeroQuincena,
                valor: this.twoDecimals(itemFound.valor),
              });
            }
          });
        }
        return newArr;
      },
      getAmountsByPeriodsV2(arr, year) {
        let periods = [
          { mes: 1, quincena: 1 },
          { mes: 1, quincena: 2 },
          { mes: 2, quincena: 1 },
          { mes: 2, quincena: 2 },
          { mes: 3, quincena: 1 },
          { mes: 3, quincena: 2 },
          { mes: 4, quincena: 1 },
          { mes: 4, quincena: 2 },
          { mes: 5, quincena: 1 },
          { mes: 5, quincena: 2 },
          { mes: 6, quincena: 1 },
          { mes: 6, quincena: 2 },
          { mes: 7, quincena: 1 },
          { mes: 7, quincena: 2 },
          { mes: 8, quincena: 1 },
          { mes: 8, quincena: 2 },
          { mes: 9, quincena: 1 },
          { mes: 9, quincena: 2 },
          { mes: 10, quincena: 1 },
          { mes: 10, quincena: 2 },
          { mes: 11, quincena: 1 },
          { mes: 11, quincena: 2 },
          { mes: 12, quincena: 1 },
          { mes: 12, quincena: 2 },
        ];

        let newArr = [];

        if (arr.data.length == 0) {
          periods.map((item) => {
            newArr.push({
              year: year,
              mes: item.mes,
              quincena: item.quincena,
              valor: null,
            });
          });
        } else {
          periods.map((item) => {
            let itemFound = arr.data.find(
              (i) => i.mes == item.mes && i.numeroQuincena == item.quincena
            );

            if (!itemFound) {
              newArr.push({
                year: year,
                mes: item.mes,
                quincena: item.quincena,
                valor: null,
              });
            } else {
              newArr.push({
                year: year,
                mes: itemFound.mes,
                quincena: itemFound.numeroQuincena,
                valor: itemFound.valor,
              });
            }
          });
        }

        let montos = [];

        montos = newArr.map((x) => {
          let m = null;

          if (x.valor) {
            m = this.twoDecimals(x.valor);
          }
          return m;
        });

        return montos;
      },
      getTRAMDataByPeriods(arr, year) {
        let periods = [
          { mes: 1, quincena: 1 },
          { mes: 1, quincena: 2 },
          { mes: 2, quincena: 1 },
          { mes: 2, quincena: 2 },
          { mes: 3, quincena: 1 },
          { mes: 3, quincena: 2 },
          { mes: 4, quincena: 1 },
          { mes: 4, quincena: 2 },
          { mes: 5, quincena: 1 },
          { mes: 5, quincena: 2 },
          { mes: 6, quincena: 1 },
          { mes: 6, quincena: 2 },
          { mes: 7, quincena: 1 },
          { mes: 7, quincena: 2 },
          { mes: 8, quincena: 1 },
          { mes: 8, quincena: 2 },
          { mes: 9, quincena: 1 },
          { mes: 9, quincena: 2 },
          { mes: 10, quincena: 1 },
          { mes: 10, quincena: 2 },
          { mes: 11, quincena: 1 },
          { mes: 11, quincena: 2 },
          { mes: 12, quincena: 1 },
          { mes: 12, quincena: 2 },
        ];

        let newArr = [];

        if (arr.length == 0) {
          periods.map((item) => {
            newArr.push({
              year: year,
              mes: item.mes,
              quincena: item.quincena,
              valor: null,
            });
          });
        } else {
          periods.map((item) => {
            let itemFound = arr.find(
              (i) => i.mes == item.mes && i.numeroQuincena == item.quincena
            );

            if (!itemFound) {
              newArr.push({
                year: year,
                mes: item.mes,
                quincena: item.quincena,
                valor: null,
              });
            } else {
              newArr.push({
                year: year,
                mes: itemFound.mes,
                quincena: itemFound.numeroQuincena,
                valor: itemFound.valor,
              });
            }
          });
        }

        return newArr;
      },
      getValuesByPeriods(arr) {
        let values = [];

        values = arr.map((x) => {
          let m = null;

          if (x.valor !== null || x.valor !== "") {
            m = x.valor;
          }

          return m;
        });

        return values;
      },
      getAcopioData(arr, year) {
        const d = new Date();
        let mesActual = d.getMonth() + 1;
        let quincenaActual = d.getDate() < 15 ? 1 : 2;
        let anioActual = d.getFullYear();

        console.log("mesActual", mesActual);
        console.log("quincenaActual", quincenaActual);

        let periods = [
          { mes: 1, quincena: 1 },
          { mes: 1, quincena: 2 },
          { mes: 2, quincena: 1 },
          { mes: 2, quincena: 2 },
          { mes: 3, quincena: 1 },
          { mes: 3, quincena: 2 },
          { mes: 4, quincena: 1 },
          { mes: 4, quincena: 2 },
          { mes: 5, quincena: 1 },
          { mes: 5, quincena: 2 },
          { mes: 6, quincena: 1 },
          { mes: 6, quincena: 2 },
          { mes: 7, quincena: 1 },
          { mes: 7, quincena: 2 },
          { mes: 8, quincena: 1 },
          { mes: 8, quincena: 2 },
          { mes: 9, quincena: 1 },
          { mes: 9, quincena: 2 },
          { mes: 10, quincena: 1 },
          { mes: 10, quincena: 2 },
          { mes: 11, quincena: 1 },
          { mes: 11, quincena: 2 },
          { mes: 12, quincena: 1 },
          { mes: 12, quincena: 2 },
        ];

        let newArr = [];

        if (arr.length == 0) {
          /*periods.map((item) => {

            newArr.push({
              'id': null,
              'year': year,
              'mes': item.mes,
              'quincena': item.quincena,
              'total': null,
              'promedio': null,
              'dias': null
            });

          });*/
        } else {
          periods.map((item) => {
            let itemFound = arr.find(
              (x) => x.mes == item.mes && x.numeroQuincena == item.quincena
            );

            //console.log('period', item);

            if (item.mes < mesActual || year < anioActual) {
              //if (item.mes == mesActual && item.quincena > quincenaActual) {} else { // }

              if (!itemFound) {
                newArr.push({
                  id: null,
                  year: year,
                  mes: item.mes,
                  quincena: item.quincena,
                  total: null,
                  promedio: null,
                  dias: null,
                });
              } else {
                newArr.push({
                  id: itemFound.id,
                  year: year,
                  mes: itemFound.mes,
                  quincena: itemFound.numeroQuincena,
                  total: itemFound.total,
                  promedio: itemFound.promedio,
                  dias: itemFound.dias,
                });
              }
            } else if (
              item.mes == mesActual &&
              item.quincena <= quincenaActual
            ) {
              if (!itemFound) {
                newArr.push({
                  id: null,
                  year: year,
                  mes: item.mes,
                  quincena: item.quincena,
                  total: null,
                  promedio: null,
                  dias: null,
                });
              } else {
                newArr.push({
                  id: itemFound.id,
                  year: year,
                  mes: itemFound.mes,
                  quincena: itemFound.numeroQuincena,
                  total: itemFound.total,
                  promedio: itemFound.promedio,
                  dias: itemFound.dias,
                });
              }
            } /*else if (year < anioActual){
              if (!itemFound) {

                newArr.push({
                  'id': null,
                  'year': year,
                  'mes': item.mes,
                  'quincena': item.quincena,
                  'total': null,
                  'promedio': null,
                  'dias': null
                });

              } else {

                newArr.push({
                  'id': itemFound.id,
                  'year': year,
                  'mes': itemFound.mes,
                  'quincena': itemFound.numeroQuincena,
                  'total': itemFound.total,
                  'promedio': itemFound.promedio,
                  'dias': itemFound.dias
                });
              }
            }*/
          });
        }

        return newArr;
      },
      getAcopioTotal(arr, year) {
        let periods = [
          { mes: 1, quincena: 1 },
          { mes: 1, quincena: 2 },
          { mes: 2, quincena: 1 },
          { mes: 2, quincena: 2 },
          { mes: 3, quincena: 1 },
          { mes: 3, quincena: 2 },
          { mes: 4, quincena: 1 },
          { mes: 4, quincena: 2 },
          { mes: 5, quincena: 1 },
          { mes: 5, quincena: 2 },
          { mes: 6, quincena: 1 },
          { mes: 6, quincena: 2 },
          { mes: 7, quincena: 1 },
          { mes: 7, quincena: 2 },
          { mes: 8, quincena: 1 },
          { mes: 8, quincena: 2 },
          { mes: 9, quincena: 1 },
          { mes: 9, quincena: 2 },
          { mes: 10, quincena: 1 },
          { mes: 10, quincena: 2 },
          { mes: 11, quincena: 1 },
          { mes: 11, quincena: 2 },
          { mes: 12, quincena: 1 },
          { mes: 12, quincena: 2 },
        ];

        let newArr = [];

        if (arr.length == 0) {
          periods.map((item) => {
            newArr.push({
              year: year,
              mes: item.mes,
              quincena: item.quincena,
              valor: null,
            });
          });
        } else {
          periods.map((item) => {
            let itemFound = arr.find(
              (i) => i.mes == item.mes && i.numeroQuincena == item.quincena
            );

            if (!itemFound) {
              newArr.push({
                year: year,
                mes: item.mes,
                quincena: item.quincena,
                valor: null,
              });
            } else {
              newArr.push({
                year: year,
                mes: itemFound.mes,
                quincena: itemFound.numeroQuincena,
                valor: itemFound.total,
              });
            }
          });
        }

        let montos = [];

        montos = newArr.map((x) => {
          let m = null;

          if (x.valor) {
            m = this.twoDecimals(x.valor);
          }
          return m;
        });

        return montos;
      },
      getAcopioDias(arr, year) {
        let periods = [
          { mes: 1, quincena: 1 },
          { mes: 1, quincena: 2 },
          { mes: 2, quincena: 1 },
          { mes: 2, quincena: 2 },
          { mes: 3, quincena: 1 },
          { mes: 3, quincena: 2 },
          { mes: 4, quincena: 1 },
          { mes: 4, quincena: 2 },
          { mes: 5, quincena: 1 },
          { mes: 5, quincena: 2 },
          { mes: 6, quincena: 1 },
          { mes: 6, quincena: 2 },
          { mes: 7, quincena: 1 },
          { mes: 7, quincena: 2 },
          { mes: 8, quincena: 1 },
          { mes: 8, quincena: 2 },
          { mes: 9, quincena: 1 },
          { mes: 9, quincena: 2 },
          { mes: 10, quincena: 1 },
          { mes: 10, quincena: 2 },
          { mes: 11, quincena: 1 },
          { mes: 11, quincena: 2 },
          { mes: 12, quincena: 1 },
          { mes: 12, quincena: 2 },
        ];

        let newArr = [];

        if (arr.length == 0) {
          periods.map((item) => {
            newArr.push({
              year: year,
              mes: item.mes,
              quincena: item.quincena,
              valor: null,
            });
          });
        } else {
          periods.map((item) => {
            let itemFound = arr.find(
              (i) => i.mes == item.mes && i.numeroQuincena == item.quincena
            );

            if (!itemFound) {
              newArr.push({
                year: year,
                mes: item.mes,
                quincena: item.quincena,
                valor: null,
              });
            } else {
              newArr.push({
                year: year,
                mes: itemFound.mes,
                quincena: itemFound.numeroQuincena,
                valor: itemFound.promedio,
              });
            }
          });
        }

        let montos = [];

        montos = newArr.map((x) => {
          let m = null;

          if (x.valor) {
            m = this.twoDecimals(x.valor);
          }
          return m;
        });

        return montos;
      },





      getSolidosTotalesByYear(objeto, anio, userProp, quincenaProp) {

        const nuevosDatos = []; // arreglo para almacenar los nuevos objetos

        //const year = '';

        for (let j = 0; j < objeto.length; j++) {

          const { year, data } = objeto[j];

          //year = yearL;

          for (let i = 0; i < data.length; i++) {
            const { mes } = data[i]; // extraer el mes y el número de quincena de cada objeto existente
      
            // crear un nuevo objeto con el mismo año, mes y número de quincena
            if (anio == year) {

              const nuevoObjeto = {
                year,
                mes,
                [quincenaProp]: data[i][quincenaProp],
                [userProp]: data[i][userProp]
              };
          
              nuevosDatos.push(nuevoObjeto); // agregar el nuevo objeto al arreglo

            }
          
          }
          
        }

        //console.log('nuevosDatos', nuevosDatos);
        // retornar un nuevo objeto con el mismo año y los nuevos datos generados
        return {
          data: nuevosDatos ? nuevosDatos : []
        };

      },



      generarSecuenciaQuincenasDesdeAnio(anio, userProp, quincenaProp) {
        const secuencia = [];
        
        for (let mes = 1; mes <= 12; mes++) {
          for (let quincena = 1; quincena <= 2; quincena++) {
            const registro = {
              "year": anio,
              "mes": mes,
              [quincenaProp]: quincena,
              [userProp]: null //valor
            };
            //console.log('púsh registro', registro);
            secuencia.push(registro);
          }
        }
        
        return secuencia;
      },
      generarSecuenciaQuincenas(arreglo, anioProp, userProp, quincenaProp) {

        anioProp = parseInt(anioProp);

        let arr = arreglo.data;

        if (arr.length == 0) {

          var secuencia = this.generarSecuenciaQuincenasDesdeAnio(anioProp, userProp, quincenaProp);

          return secuencia;

        } else {
        

          let hoy = new Date();
          let anioActual = hoy.getFullYear();
          let mesActual = hoy.getMonth() + 1;
          let quincenaActual = Math.floor((hoy.getDate() - 1) / 15) + 1;

          let meses = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
          let quincenas = [1, 2];
          let secuencia = [];

          for (let anio = anioProp; anio <= anioActual; anio++) {
            for (let mes of meses) {
              let numQuincenas = quincenas.length;
              if (anio === anioActual && mes === mesActual) {
                numQuincenas = quincenaActual;
              }
              for (let q of quincenas) {
                let valor = null;
                let existente = arr.find(item => item.year === anioProp && item.mes === mes && item[quincenaProp] === q);
                if (existente) {
                  valor = existente[userProp];
                }
                secuencia.push({
                  year: anioProp,
                  mes,
                  [quincenaProp]: q,
                  [userProp]: valor
                });
              }
            }
          }


          let data = arr.concat(secuencia);

          let dataObj = data.reduce((obj, item) => {
            let key = `${item.mes}-${item[quincenaProp]}`;
            obj[key] = item;
            return obj;
          }, {});

          let sortedData = Object.values(dataObj).sort((a, b) => {
            if (a.mes === b.mes) {
              return a[quincenaProp] - b[quincenaProp];
            } else {
              return a.mes - b.mes;
            }
          });


          return sortedData;


        }


      },



























      showSuccessMessage(obj, callback) {
        const swalButtons = swal.mixin({
          customClass: {
            confirmButton: "button success mt-0 primary-button button-md",
          },
          buttonsStyling: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: true,
        });

        swalButtons
          .fire({
            html: swalSuccessTemplate(
              obj.message ? obj.message : "Solicitud exitosa"
            ),
            confirmButtonText: obj.confirmButtonText
              ? obj.confirmButtonText
              : "Cerrar",
          })
          .then((result) => {
            if (result.isConfirmed) {
              if (!obj.withCallback) {
                result.dismiss === "cancel";
              } else {
                callback(true);
              }
            }
          });
      },
      showWarningMessage(obj, callback) {
        const swalButtons = swal.mixin({
          customClass: {
            confirmButton: "button success mt-0 primary-button button-md",
            cancelButton: "button mt-0 ms-3 ghost-button button-md",
          },
          buttonsStyling: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: true,
        });

        swalButtons
          .fire({
            html: swalWarningTemplate(
              obj.message ? obj.message : "Advertencia"
            ),
            showCancelButton: obj.cancelButtonText ? true : false,
            confirmButtonText: obj.confirmButtonText
              ? obj.confirmButtonText
              : "Si",
            cancelButtonText: obj.cancelButtonText
              ? obj.cancelButtonText
              : "No",
          })
          .then((result) => {
            if (result.isConfirmed) {
              if (!obj.withCallback) {
                result.dismiss === "cancel";
              } else {
                callback(true);
              }
            }
          });
      },
      showErrorMessage(errorMessage) {
        const swalButtons = swal.mixin({
          customClass: {
            confirmButton: "button success mt-0 primary-button button-md",
          },
          buttonsStyling: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: true,
        });

        swalButtons
          .fire({
            html: swalErrorTemplate(errorMessage),
            confirmButtonText: "Cerrar",
          })
          .then((result) => {
            if (result.isConfirmed) {
              result.dismiss === "cancel";
            }
          });
      },
      onErrorBase(error) {
        if (error.request.status == 0) {
          onError500(error);
          return;
        }

        if (error.response.status == 400) {
          onError400(error);
        } else if (error.response.status == 401) {
          onError401(error);
        } else if (error.response.status == 404) {
          onError404(error);
        } else if (error.response.status == 500) {
          onError500(error);
        } else {
          console.log("error inesperado");

          onError401(error);
        }
      },
      logoutWithoutRefresh() {
        localStorage.removeItem("token");
        localStorage.removeItem("userinfo");
        localStorage.removeItem("username");
        localStorage.removeItem("rememberMe");
        localStorage.removeItem("maxAge");
      },
    };
  },
};

function swalSuccessTemplate(errorMessage) {
  return `<div>
  <img src="/assets/images/check-circle-fill.svg" style="margin-top: 26px;">
  <h3 style="font-weight: 600;font-size: 18px;line-height: 24px;color: black;margin-bottom: 21px;margin-top: 21px;">${errorMessage}</h3>
  </div>`;
}

function swalWarningTemplate(errorMessage) {
  return `<div>
  <img src="/assets/images/warning-icon.svg" style="margin-top: 26px;width: 60px;">
  <h3 style="font-weight: 600;font-size: 18px;line-height: 24px;color: black;margin-bottom: 21px;margin-top: 21px;">${errorMessage}</h3>
  </div>`;
}

function swalErrorTemplate2Opt(errorMessage) {
  return `<div class="swal-error">
<div class="header">
  <img class="image" src="/assets/images/warning-icon.png" alt="Home" width="20"/>
  <div class="title">
    <h3>Error al realizar la acción</h3>
  </div>
</div>
<p class="message">${errorMessage}</p>
</div>`;
}

function swalErrorTemplate(errorMessage) {
  //Lamentablemente, se ha presentado un error al cerrar el periodo. Por favor, reinténtalo.

  /*
  `<div>
    <img src="/assets/images/warning-icon.png" style="margin-top: 26px;">
    <h3 style="font-weight: 600;font-size: 20px;line-height: 24px;color: black;margin-bottom: 21px;margin-top: 21px;">Error</h3>
    <p style="font-weight: 400;font-size: 14px;line-height: 16px;max-width: 391px;margin: auto;">No se puede editar el registro cuando ya se inició el proceso de evaluación</p>
    </div>`
  */

  return `<div>
  <img src="/assets/images/warning-icon.png" style="margin-top: 26px;">
  <h3 style="font-weight: 600;font-size: 18px;line-height: 24px;color: black;margin-bottom: 21px;margin-top: 21px;">${errorMessage}</h3>
  </div>`;
}

function onError400(error) {
  /*console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);*/

  if (error.response.data.errors !== undefined) {
    //alguno de los datos ingresados no son correctos
    console.log("error 400", error.response.data.errors);

    let errorMessage = "";

    Object.values(error.response.data.errors).map((key, value) => {
      //console.log('error', key, value);
      errorMessage = key[0];
    });

    const swalButtons = swal.mixin({
      customClass: {
        confirmButton: "button success mt-0 primary-button button-md",
      },
      buttonsStyling: false,
      allowOutsideClick: false,
    });

    swalButtons
      .fire({
        html: swalErrorTemplate(errorMessage),
        confirmButtonText: "Cerrar",
      })
      .then((result) => {
        if (result.isConfirmed) {
          result.dismiss === "cancel";
        }
      });
  } else if (error.response.data.error !== undefined) {
    console.log("error 400", error.response.data.error);

    let errorMessage = error.response.data.error;

    const swalButtons = swal.mixin({
      customClass: {
        confirmButton: "button success mt-0 primary-button button-md",
      },
      buttonsStyling: false,
      allowOutsideClick: false,
    });

    swalButtons
      .fire({
        html: swalErrorTemplate(errorMessage),
        confirmButtonText: "Cerrar",
      })
      .then((result) => {
        if (result.isConfirmed) {
          result.dismiss === "cancel";
        }
      });
  } else {
    console.log("error 400");
  }
}
function onError401(error) {
  //token expirado, mostrar modal
  console.log(error.response.data);
  console.log(error.response.status);
  console.log(error.response.headers);
  //console.log('Error', error.message);

  let errorMessage = "Su sesión ha expirado";

  const swalButtons = swal.mixin({
    customClass: {
      confirmButton: "button success mt-0 primary-button button-md",
    },
    buttonsStyling: false,
    allowOutsideClick: false,
  });

  swalButtons
    .fire({
      html: swalErrorTemplate(errorMessage),
      confirmButtonText: "Cerrar",
    })
    .then((result) => {
      if (result.isConfirmed) {
        result.dismiss === "cancel";
        logout();
      }
    });
}
function onError404(error) {
  let errorMessage =
    "La petición solicitada no se encuentra en el servidor - 404";

  const swalButtons = swal.mixin({
    customClass: {
      confirmButton: "button success mt-0 primary-button button-md",
    },
    buttonsStyling: false,
    allowOutsideClick: false,
  });

  swalButtons
    .fire({
      html: swalErrorTemplate(errorMessage),
      confirmButtonText: "Cerrar",
    })
    .then((result) => {
      if (result.isConfirmed) {
        result.dismiss === "cancel";
      }
    });
}
function onError500(error) {
  let errorMessage = "";

  if (error.response) {
    errorMessage = error.response.data.error;
  } else {
    errorMessage =
      "Ocurrió un error en el servidor. Por favor intentalo nuevamente";
  }

  const swalButtons = swal.mixin({
    customClass: {
      confirmButton: "button success mt-0 primary-button button-md",
    },
    buttonsStyling: false,
    allowOutsideClick: false,
  });

  swalButtons
    .fire({
      html: swalErrorTemplate(errorMessage),
      confirmButtonText: "Cerrar",
    })
    .then((result) => {
      if (result.isConfirmed) {
        result.dismiss === "cancel";
      }
    });
}
function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("userinfo");
  localStorage.removeItem("maxAge");

  setTimeout(() => {
    location.reload();
  }, 200);
}

function isEmptyObject(obj) {
  var name;
  for (name in obj) {
    if (obj.hasOwnProperty(name)) {
      return false;
    }
  }
  return true;
}

function addMonths(date = new Date(), numOfMonths) {
  date.setMonth(date.getMonth() + numOfMonths);

  return date.getMonth();
}

function subtractMonths(date = new Date(), numOfMonths) {
  date.setMonth(date.getMonth() - numOfMonths);

  return date.getMonth();
}

function getDateByMonth(date) {
  let nDate = new Date();
  nDate.setMonth(date);

  return nDate;
}
