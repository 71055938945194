import setting from "./setting";

export default {
  getOptions: ($axios, data) => {
    return $axios.post(`${setting.http.base_url}/acopio/parametros`, data, {
      headers: setting.http.headers(),
    });
  },

  getListAcopioV2: ($axios, data) => {
    return $axios.post(`${setting.http.base_url}/acopio/manual/lista`, data, {
      headers: setting.http.headers(),
    });
  },

  postIngresoLote: ($axios, data) => {
    return $axios.post(`${setting.http.base_url}/acopio/ingreso/lote`, data, {
      headers: setting.http.headers(),
    });
  },
  generateSAPFile: ($axios, id) => {
    return $axios.get(
      `${setting.http.base_url}/acopio/generar/excel-sap/lote/${id}`,
      {
        headers: setting.http.headers(),
        observe: "response",
        responseType: "arraybuffer",
      }
    );
  },
  deleteAcopio: ($axios, id) => {
    return $axios.get(`${setting.http.base_url}/acopio/eliminar/${id}`, {
      headers: setting.http.headers(),
    });
  },

  //Administración de Usuarios
  getListUsuarios: ($axios, data) => {
    return $axios.post(
      `${setting.http.base_url}/ganadero/lista/usuario`,
      data,
      { headers: setting.http.headers() }
    );
  },
  postInsertarUsuario: ($axios, data) => {
    return $axios.post(`${setting.http.base_url}/ganadero/insertar`, data, {
      headers: setting.http.headers(),
    });
  },
  updateUsuario: ($axios, data) => {
    return $axios.post(`${setting.http.base_url}/ganadero/actualizar`, data, {
      headers: setting.http.headers(),
    });
  },
  unlockUsuario: ($axios, data) => {
    return $axios.post(`${setting.http.base_url}/ganadero/desbloquear`, data, {
      headers: setting.http.headers(),
    });
  },
  generatePassword: ($axios, data) => {
    return $axios.post(
      `${setting.http.base_url}/ganadero/generar-contrasena`,
      data,
      { headers: setting.http.headers() }
    );
  },

  //Módulo Pagos:
  getListPagoUnitario: ($axios, data) => {
    return $axios.post(
      `${setting.http.base_url}/portal/ganadero/lista/pago/unitario`,
      data,
      { headers: setting.http.headers() }
    );
  },
  downloadPagoUnitario: ($axios, data) => {
    return $axios.post(
      `${setting.http.base_url}/portal/ganadero/lista/pago/unitario/exportar`,
      data,
      {
        headers: setting.http.headers(),
        observe: "response",
        responseType: "arraybuffer",
      }
    );
  },
  getListMontosFacturados: ($axios, data) => {
    return $axios.post(
      `${setting.http.base_url}/portal/ganadero/lista/pago/facturado`,
      data,
      { headers: setting.http.headers() }
    );
  },
  downloadMontosFacturados: ($axios, data) => {
    return $axios.post(
      `${setting.http.base_url}/portal/ganadero/lista/pago/facturado/exportar`,
      data,
      {
        headers: setting.http.headers(),
        observe: "response",
        responseType: "arraybuffer",
      }
    );
  },
};
