import { createRouter, createWebHistory } from "vue-router";

import { store } from "@/store/index";
import Constants from '@/constants';

// Layout
import AuthLayout from "@/views/layout/AuthLayout";
import DashboardLayout from "@/views/layout/DashboardLayout";

// Pages
import Login from "@/views/pages/Login.vue";
import ResetPassword from "@/views/pages/ResetPassword.vue";
import Home from "@/views/pages/Home.vue";
import PagosAccesoGanaderos from "@/views/pages/AccesoGanaderos/Pagos.vue";
import AcopioAccesoGanaderos from "@/views/pages/AccesoGanaderos/Acopio/Lista.vue";
import DetalleAcopioAccesoGanaderos from "@/views/pages/AccesoGanaderos/Acopio/Detalle.vue";
import CalidadLecheCalienteAccesoGanaderos from "@/views/pages/AccesoGanaderos/Calidad/LecheCaliente.vue";
import CalidadLecheFriaAccesoGanaderos from "@/views/pages/AccesoGanaderos/Calidad/LecheFria.vue";
import CreditosDescuentosAccesoGanaderos from "@/views/pages/AccesoGanaderos/CreditosDescuentos.vue";
import DevolucionDeLeche from "@/views/pages/Devolucion/DeLeche.vue";

import SeguimientoRutas from "@/views/pages/AccesoTransportistas/Seguimiento.vue";
import RegistroLecheFria from "@/views/pages/AccesoTransportistas/RegistroLecheFria.vue";
import RegistroLecheCaliente from "@/views/pages/AccesoTransportistas/RegistroLecheCaliente.vue";
import RegistroLecheProcesada from "@/views/pages/AccesoTransportistas/RegistroLecheProcesada.vue";
import RegistroLecheAcopio from "@/views/pages/AccesoTransportistas/RegistroLecheAcopio.vue";

const TIPO_LECHE_CALIENTE = Constants.TIPO_LECHE_CALIENTE;
const TIPO_LECHE_FRIA = Constants.TIPO_LECHE_FRIA;

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    name: "Home",
    meta: { requiresAuth: true, hideFooter: true },
    children: [
      {
        path: "/dashboard",
        name: "Home",
        meta: { role: ['*'] },
        component: Home
      }
    ],
  },


  {
    path: "/",
    redirect: "/pagos",
    component: DashboardLayout,
    name: "Pagos",
    meta: { requiresAuth: true, hideFooter: true },
    children: [
      {
        path: "/pagos",
        name: "Pagos [L]",
        meta: { role: ['*'], show: false },
        component: PagosAccesoGanaderos
      },
    ],
  },
  {
    path: "/",
    redirect: "/acopio",
    component: DashboardLayout,
    name: "Acopio",
    meta: { requiresAuth: true, hideFooter: true },
    children: [
      {
        path: "/acopio",
        name: "Acopio [L]",
        meta: { role: ['*'], show: false },
        component: AcopioAccesoGanaderos
      },
      {
        path: "/acopio/detalle/:id",
        name: "Reporte de acopio diario",
        meta: { role: ['*'] },
        component: DetalleAcopioAccesoGanaderos
      }
    ],
  },

  {
    path: "/",
    redirect: "/calidad/lc",
    component: DashboardLayout,
    name: "Calidad leche caliente",
    meta: { requiresAuth: true, hideFooter: true, title: "Calidad de leche" },
    children: [
      {
        path: "/calidad/lc",
        name: "Calidad leche caliente [L]",
        meta: { role: [TIPO_LECHE_CALIENTE], show: false },
        component: CalidadLecheCalienteAccesoGanaderos
      },
    ],
  },

  {
    path: "/",
    redirect: "/calidad/lf",
    component: DashboardLayout,
    name: "Calidad leche fría",
    meta: { requiresAuth: true, hideFooter: true, title: "Calidad de leche" },
    children: [
      {
        path: "/calidad/lf",
        name: "Calidad leche fría [L]",
        meta: { role: [TIPO_LECHE_FRIA], show: false },
        component: CalidadLecheFriaAccesoGanaderos
      },
    ],
  },


  {
    path: "/",
    redirect: "/seguimiento-rutas",
    component: DashboardLayout,
    name: "Seguimiento de rutas",
    meta: { requiresAuth: true, hideFooter: true, title: "Seguimiento de rutas" },
    children: [
      {
        path: "/seguimiento-rutas",
        name: "Seguimiento de rutas [L]",
        meta: { role: ['*'], show: false },
        component: SeguimientoRutas
      },
      {
        path: "/seguimiento-rutas/detalle-lf/:id",
        name: "REGISTRO_LF",
        meta: { role: ['*'], show: false },
        component: RegistroLecheFria
      },
      {
        path: "/seguimiento-rutas/detalle-lc/:id",
        name: "REGISTRO_LC",
        meta: { role: ['*'], show: false },
        component: RegistroLecheCaliente
      },
      {
        path: "/seguimiento-rutas/detalle-lp/:id",
        name: "REGISTRO_LP",
        meta: { role: ['*'], show: false },
        component: RegistroLecheProcesada
      },
      {
        path: "/seguimiento-rutas/detalle-la/:id",
        name: "REGISTRO_LA",
        meta: { role: ['*'], show: false },
        component: RegistroLecheAcopio
      },
    ],
  },

  {
    path: "/",
    redirect: "/creditos-descuentos",
    component: DashboardLayout,
    name: "Créditos / Descuentos",
    meta: { requiresAuth: true, hideFooter: true },
    children: [
      {
        path: "/creditos-descuentos",
        name: "Créditos / Descuentos [L]",
        meta: { role: ['*'], show: false },
        component: CreditosDescuentosAccesoGanaderos
      },
    ],
  },

  {
    path: "/",
    redirect: "/devoluciones",
    component: DashboardLayout,
    name: "Devoluciones",
    meta: { requiresAuth: true, hideFooter: true },
    children: [
      {
        path: "/devoluciones",
        name: "Devoluciones [L]",
        meta: { role: ['*'], show: false },
        component: DevolucionDeLeche
      },
    ],
  },

  {
    path: "/",
    redirect: "/",
    component: AuthLayout,
    children: [
      {
        path: "/usernamerecovery",
        name: "Resetear contraseña",
        components: { default: ResetPassword },
      },
    ],
  },

  {
    path: "/",
    redirect: "/",
    component: AuthLayout,
    children: [
      {
        path: "/signin",
        name: "Login",
        components: { default: Login },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
  hashbang: false,
});

router.beforeEach((to, from, next) => {

  document.title = to.meta.title || 'Laive Ganaderos';

  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.matched.some(route => route.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.auth.isLogged) {
      next('/signin')
    } else {

      for (let index = 0; index < to.meta.role.length; index++) {

        const item = to.meta.role[index];

        let tipoLeche = store.state.auth.user.tipoLeche;
        //let tipoLeche = 3
        //let tipoLeche = 4

        let sameTipoLeche = (tipoLeche == item);
        let allowAccess = ( item == '*' || sameTipoLeche ) ?  true : false;

        if (allowAccess) {

          const jwtPayload = parseJwt(localStorage.token);

          if (jwtPayload.exp < Date.now() / 1000) {
              //token expired

              //deleteTokenFromLocalStorage();
              //this.$filters.onErrorBase({ response: { status: response.status } });
              store.dispatch('auth/logout');
              router.push('/signin');
              //next("/");
          }

          next();
          break;
        }

      }

      //next();

    }
  } else {
    next();
  }
})


export default router;


function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
