<template>
  <div class="chart">

    <div class="card chart-card z-index-2">
      <div class="pb-0 card-header mb-0">
        
        <div class="d-flex align-items-center justify-content-between">
          <h6 class="title">{{ title }}</h6>
        </div>
        
        <p v-if="description" class="text-sm" v-html="description" />
      </div>
      <div class="p-3 card-body">
        <div class="y-axis-title">
          <span class="symbol">{{ chart.yAxisTitle ? chart.yAxisTitle : 'S/' }}</span>
        </div>

        <div class="chart">
          <canvas :id="id" class="chart-canvas" :height="height" style="height: 420px;"></canvas>
        </div>
      </div>
    </div>

    <div v-if="infoText || legend" class="d-flex align-items-start mt-3" :class="[{ 'justify-content-between': infoText }, { 'justify-content-end': !infoText }]">
      <div class="chart-disclaimer" v-if="infoText">
        <p class="m-0">{{ infoText }}</p>
      </div>
      <ul class="legend" v-if="legend">
        <li v-for="dataset in chart.datasets">
          <div class="square" :style="{ backgroundColor: dataset.borderColor }"></div>
          <span class="text">{{ dataset.label }}</span>
        </li>
      </ul>
    </div>

  </div>
</template>


<script>
import Chart from "chart.js/auto";
import Numeral from 'numeral';

export default {
  name: "GradientLineChart",
  props: {
    id: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: "300",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    infoText: {
      type: [Boolean, String],
      default: false
    },
    legend: {
      type: [Boolean, String],
      default: false
    },
    chartDataToChar: {
      type: Boolean,
      default: false
    },
    decimals: {
      type: [Boolean, Number],
      default: false
    },
    dataSymbolPosition: {
      type: [Boolean, String],
      default: false
    },
    yAxisTicksDecimals: {
      type: Boolean,
      default: false
    },
    chart: {
      type: Object,
      required: true,
      labels: Array,
      datasets: {
        type: Array,
        label: String,
        data: Array,
      },
    },
  },
  watch: {
    chart() {
      this.setCharts();
    }
  },
  data() {

    return {
      yAxisTitle: this.chart.yAxisTitle || 'S/',
    }

  },
  methods: {
    openModal() {
      this.$emit('open-modal', this.id);
    },
    setCharts() {

      var gradientLineChart = document.getElementById(this.id).getContext("2d");

      let chartStatus = Chart.getChart(this.id);

      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      if (this.chart.datasets.length == 1) {
        new Chart(gradientLineChart, {
          type: "bar",
          data: {
            labels: this.chart.labels,
            datasets: [
              {
                label: this.chart.datasets[0].label,
                tension: 0,
                borderWidth: 0,
                pointRadius: 0,
                borderColor: this.chart.datasets[0].borderColor,
                backgroundColor: this.chart.datasets[0].borderColor,
                // eslint-disable-next-line no-dupe-keys
                borderWidth: 1,
                fill: true,
                data: this.chart.datasets[0].data,
                maxBarThickness: 30,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {

                  label: (tooltipItem, data) =>  {
                    
                    if (this.dataSymbolPosition == 'start') {
                      if (this.chartDataToChar) {
                        return ' ' + this.yAxisTitle + ' ' + Numeral(tooltipItem.raw).format('0,0');  
                      } else {
                        if (this.decimals == 5) {
                          return ' ' + this.yAxisTitle + ' ' + Numeral(tooltipItem.raw).format('0,0.00000');
                        } else {
                          return ' ' + this.yAxisTitle + ' ' + Numeral(tooltipItem.raw).format('0,0.00');
                        }                    
                      }
                    } else {
                      if (this.chartDataToChar) {
                        return ' ' + Numeral(tooltipItem.raw).format('0,0') + ' ' + this.yAxisTitle;
                      } else {
                        return ' ' + Numeral(tooltipItem.raw).format('0,0.00') + ' ' + this.yAxisTitle;
                      }
                    }
                    
                  }
                  
                }
              }
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: true,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                },
                ticks: {
                  display: true,
                  padding: 10,
                  color: "#6E6E6E",
                  font: {
                    size: 11,
                    family: "Poppins",
                    style: "normal",
                    lineHeight: 2,
                  },
                  callback: function (value) {
                    return Numeral(value).format('0,0');
                  }
                },
              },
              x: {
                title: {
                  display: true,
                  text: this.chart.xAxisTitle || 'Acopio Diario',
                  color: '#02A54F',
                  font: {
                    size: 14,
                    family: "Poppins",
                    style: "normal",
                  },
                  align: 'center',
                },
                grid: {
                  drawBorder: true,
                  display: true,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: true,
                  color: "#6E6E6E",
                  padding: 20,
                  font: {
                    size: 11,
                    family: "Poppins",
                    style: "normal",
                    lineHeight: 2,
                  },
                }
              },
              
            },
          },
        });
      }

    }
  },
  mounted() {

    this.setCharts();

  },
};
</script>
