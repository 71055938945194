import setting from "./setting";

export default {
  getOptions: ($axios, data) => {
    return $axios.post(`${setting.http.base_url}/portal/proveedor/parametros`, data, {
      headers: setting.http.headers(),
    });
  },

  getList: ($axios, data) => {
    return $axios.post(`${setting.http.base_url}/portal/proveedor`, data, {
      headers: setting.http.headers(),
    });
  },

  getDetalle: ($axios, codigoProveedor) => {
    return $axios.get(
      `${setting.http.base_url}/proveedor/detalle/${codigoProveedor}`,
      { headers: setting.http.headers() }
    );
  },

  downloadContratoPDF: ($axios, fileName) => {
    return $axios.get(
      `${setting.http.base_url}/portal/ganadero/prestamo/detalle/contrato/${fileName}`,
      { headers: setting.http.headers(), responseType: "blob" }
    );
  },

  /*updateCodSAP: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/proveedor/actualizar/codigosap`, data, {headers:setting.http.headers()});
  },

  updateState: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/proveedor/actualizar/estado`, data, {headers:setting.http.headers()});
  },

  updateDatosFinancieros: ($axios, data) => {
    return $axios.post(`${setting.http.base_url}/proveedor/actualizar/datofinanciero`, data, {headers:setting.http.headers()});
  },

  updateDatosContactos: ($axios, data) => {
    return $axios.post(`${setting.http.base_url}/proveedor/actualizar/contactos`, data, {headers:setting.http.headers()});
  },*/

  getListAcopio: ($axios, data) => {
    return $axios.post(
      `${setting.http.base_url}/portal/proveedor/acopio/lista`,
      data,
      { headers: setting.http.headers() }
    );
  },

  downloadReporteAcopio: ($axios, data) => {
    return $axios.post(
      `${setting.http.base_url}/portal/proveedor/acopio/generar/excel`,
      data,
      {
        headers: setting.http.headers(),
        observe: "response",
        responseType: "arraybuffer",
      }
    );
  },

  downloadLiquidacionPDF: ($axios, data) => {
    return $axios.post(
      `${setting.http.base_url}/portal/ganadero/pago/detalle/liquidacion/`,
      data,
      { headers: setting.http.headers(), responseType: "blob" }
    );
  },

  getOptionsPrestamo: ($axios, data) => {
    return $axios.get(`${setting.http.base_url}/portal/ganadero/prestamo/estados`, {
      headers: setting.http.headers(),
    });
  },

  getListPrestamo: ($axios, data) => {
    return $axios.post(
      `${setting.http.base_url}/portal/ganadero/prestamo/lista`,
      data,
      { headers: setting.http.headers() }
    );
  },

  getDetallePrestamo: ($axios, id) => {
    return $axios.get(
      `${setting.http.base_url}/portal/ganadero/prestamo/detalle/${id}`,
      { headers: setting.http.headers() }
    );
  },

  downloadReportePrestamos: ($axios, data) => {
    return $axios.post(
      `${setting.http.base_url}/portal/ganadero/prestamo/descargar`,
      data,
      {
        headers: setting.http.headers(),
        observe: "response",
        responseType: "arraybuffer",
      }
    );
  },

  downloadPrestamoPDF: ($axios, fileName) => {
    return $axios.get(
      `${setting.http.base_url}/portal/ganadero/prestamo/detalle/contrato/${fileName}`,
      { headers: setting.http.headers(), responseType: "blob" }
    );
  },

  downloadReporteDetallePrestamo: ($axios, id) => {
    return $axios.get(
      `${setting.http.base_url}/portal/ganadero/prestamo/detalle/solicitud/${id}`,
      { headers: setting.http.headers(), responseType: "blob" }
    );
  },

  getOptionsDescuento: ($axios, data) => {
    return $axios.get(`${setting.http.base_url}/portal/ganadero/descuento/estados`, {
      headers: setting.http.headers(),
    });
  },

  getListDescuento: ($axios, data) => {
    return $axios.post(
      `${setting.http.base_url}/portal/ganadero/descuento/lista`,
      data,
      { headers: setting.http.headers() }
    );
  },

  getDetalleDescuento: ($axios, id) => {
    return $axios.get(
      `${setting.http.base_url}/portal/ganadero/descuento/detalle/${id}`,
      { headers: setting.http.headers() }
    );
  },

  downloadReporteDescuento: ($axios, data) => {
    return $axios.post(
      `${setting.http.base_url}/portal/ganadero/descuento/generar/excel`,
      data,
      {
        headers: setting.http.headers(),
        observe: "response",
        responseType: "arraybuffer",
      }
    );
  },

  /*downloadReporteDetalleDescuento: ($axios, id) => {
    return $axios.get(`${setting.http.base_url}/proveedor/descuento/detalle/generar/excel/${id}`, {headers:setting.http.headers(), observe: 'response', responseType: 'arraybuffer'});
  },

  getListActivo: ($axios, data) => {
    return $axios.post(`${setting.http.base_url}/proveedor/activo/lista`, data, {headers:setting.http.headers()});
  },
  
  getDetalleActivo: ($axios, id)=>{
    return $axios.get(`${setting.http.base_url}/proveedor/activo/detalle/${id}`, {headers:setting.http.headers()});
  },
  
  downloadReporteActivo: ($axios, data) => {
    return $axios.post(`${setting.http.base_url}/proveedor/activo/generar/excel`, data, {headers:setting.http.headers(), observe: 'response', responseType: 'arraybuffer'});
  },*/
};
