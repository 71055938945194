<template>
  <div class="m-container container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="c-card">

          <div class="card-header pb-0">
            <h1 class="mb-4">Registro de leche fría</h1>
            <div class="hr mb-0"></div>
          </div>

          <div class="card-body pt-0 pb-0">

            <template v-if="!isFirstTimeGeolocationEnabled">
              
              <div class="mt-4 mb-4">
               <p>Por favor, habilite su GPS para continuar.</p>
               
               <div class="d-flex justify-content-center align-items-center">
                <img width="70" src="/assets/images/icons/gps.png">
               </div>
              </div>

            </template>

            <template v-else>

              <ConfirmationScreen
                class="pt-4"
                :withHeader="false"
              >
                <template v-slot:body>

                  <div class="field">
                      <div class="key">placa del vehículo</div>
                      <div class="value">{{ infoRuta.numeroPlaca ? infoRuta.numeroPlaca : '-' }}</div>
                  </div>
                  <div class="field">
                      <div class="key">ruta</div>
                      <div class="value">{{ infoRuta.nombreRuta ? infoRuta.nombreRuta : '-' }}</div>
                  </div>
                  <div class="field">
                      <div class="key">región</div>
                      <div class="value">{{ infoRuta.nombreRegion ? infoRuta.nombreRegion : '-' }}</div>
                  </div>

                </template>
              </ConfirmationScreen>

              <div class="add-incidencia" v-if="infoRuta">
                <template v-if="infoRuta && infoRuta.horaLlegada">
                  <div class="inc-cta closed">
                    <span>Agregar incidencia</span><img class="ms-2" src="/assets/images/icons/incident.svg">
                  </div>
                </template>
                <template v-else>
                  <div class="inc-cta" @click="addIncidencia">
                    <span>Agregar incidencia</span><img class="ms-2" src="/assets/images/icons/incident.svg">
                  </div>
                </template>
                
              </div>


              <div class="hr mt-3 mb-3"></div>


              <div v-if="listaRecojo.length" class="n-table sticky-header" :class="tableClassName">
  
                  <div class="t-header">
                  <div class="t-row">
                      <div class="t-cell">
                        <span class="text-start">código<br> ganadero</span>
                      </div>
                      <div class="t-cell">
                        <span class="text-start">nombre de<br>ganadero</span>
                      </div>
                      <div class="t-cell">
                        <span class="text-start">hora inicio<br>carga</span>
                      </div>
                      <div class="t-cell">
                        <span class="text-start">cantidad de leche</span>
                      </div>
                      <div class="t-cell">
                        <span class="text-start">temperatura</span>
                      </div>
                      <div class="t-cell">
                        <span class="text-start">hora final<br>carga</span>
                      </div>
                      <div class="t-cell">
                        <span>campo obligatorio</span>
                      </div>
                  </div>
                  </div>
                  <div class="t-body">
                      <div
                        class="t-row"
                        v-for="record, index in listaRecojo"
                        :key="record.id"
                      >
                          <div class="t-cell">
                            <img v-if="record.horaEvidencia" src="/assets/images/check-circle-fill.svg" class="me-1" alt="Home" width="20"/>
                            <span>{{ record.codigoProveedor }}</span>
                          </div>

                          <div class="t-cell">
                            <span class="break-word">{{ record.nombreProveedor }}</span>
                          </div>
                          

                          <div class="t-cell">
                            <Button
                            :disabled="record.horaInicio !== '' || record.existeHoraFin || infoRuta.horaLlegada  !== ''"
                            class="m-0 info-button shadow-none w-100"
                            size="sm"
                            type="button"
                            @click="confirmInicioCarga1(record.idRutaRecojo)"
                            >
                              <img class="me-2" src="/assets/images/icons/timer.svg" alt="Cantidad de leche"/>
                              <span>Marcar</span>
                            </Button>
                        </div>

                        <div class="t-cell">
                          <Button
                          class="m-0 ghost-button ghost-info shadow-none w-100"
                          size="sm"
                          @click="updateCantidadKilos(record.idRutaRecojo)"
                          :disabled="record.existeHoraFin || infoRuta.horaLlegada !== ''"
                          >
                            <img class="me-2" src="/assets/images/icons/milk.svg" alt="Cantidad de leche"/>
                            <span v-if="record.existeHoraFin">{{ record.cantidadKilos }} kg</span>
                            <span v-else-if="record.cantidadKilos">{{ record.cantidadKilos }} kg</span>
                            <span v-else>{{ '__' }} kg</span>
                          </Button>
                        </div>

                        <div class="t-cell">
                          <Button
                          class="m-0 ghost-button ghost-info shadow-none w-100"
                          size="sm"
                          @click="registerTemperature(record.idRutaRecojo)"
                          :disabled="record.existeHoraFin || infoRuta.horaLlegada !== ''"
                          >
                            <span v-if="record.existeHoraFin">{{ record.temperaturaRecojo }} &deg;</span>
                            <span v-else-if="record.temperaturaRecojo !== 0">{{ record.temperaturaRecojo }} &deg;</span>
                            <span v-else>{{ '__' }}</span>
                          </Button>
                        </div>

                        <div class="t-cell">
                            <Button
                            :disabled="record.horaInicio == ''|| record.horaFin !== '' || record.existeHoraFin || infoRuta.horaLlegada !== ''"
                            class="m-0 info-button shadow-none w-100"
                            size="sm"
                            type="button"
                            @click="confirmFinCarga2(record.idRutaRecojo)"
                            >
                              <img class="me-2" src="/assets/images/icons/timer.svg" alt="Cantidad de leche"/>
                              <span>Marcar</span>
                            </Button>
                        </div>

                        <div class="t-cell">
                            <Button
                            class="m-0 ghost-button ghost-info shadow-none w-100"
                            size="sm"
                            type="button"
                            :disabled="infoRuta.horaLlegada ? true : false"
                            @click="addEvidencia(record.idRutaRecojo, index)"
                            >
                                <img class="me-2" src="/assets/images/icons/camera.svg" alt="Cantidad de leche"/>
                                <span>Adjuntar evidencia</span>
                            </Button>
                        </div>
      
                          <RowAccordion :index="index" :openedIndex="record.isOpen">
                              <template v-slot:title>
                                  <ul>
                                    <li class="cell">
                                      <div class="key">
                                        <img v-if="record.horaEvidencia" src="/assets/images/check-circle-fill.svg" class="me-1" alt="Home" width="20"/>
                                        <span>{{ record.nombreProveedor ? record.nombreProveedor : '- Nombre Proveedor' }}</span>
                                      </div>
                                      <div class="value">{{ record.codigoProveedor ? record.codigoProveedor : '- Código Proveedor' }}</div>
                                    </li>
                                  </ul>
                              </template>
                              <template v-slot:content>

                                  <div class="hr my-2"></div>

                                  <div class="cell">
                                      <div class="key font-x2">
                                        <span>Inicio de carga</span>
                                        <span class="user-timedate" v-if="record.horaInicio" :class="{'disabled':record.horaInicio !== ''||record.existeHoraFin}">{{ $filters.showOnlyTime(record.horaInicio) }}</span>
                                        <span class="user-timedate" v-else>{{ formattedTime }}</span>
                                      </div>
                                      <div class="value">
                                        <Button
                                        :disabled="record.horaInicio !== '' || record.existeHoraFin || infoRuta.horaLlegada  !== ''"
                                        class="m-0 info-button shadow-none w-100"
                                        size="md"
                                        type="button"
                                        @click="confirmInicioCarga1(record.idRutaRecojo)"
                                        >
                                          <img class="me-2" src="/assets/images/icons/timer.svg" alt="Cantidad de leche"/>
                                          <span>Marcar</span>
                                        </Button>
                                      </div>
                                  </div>

                                  <div class="hr my-2"></div>

                                 <div class="cell">
                                      <div class="key font-x2">Cantidad de leche</div>
                                      <div class="value">

                                        <template v-if="infoRuta && infoRuta.horaLlegada">
                                          <Button
                                          class="m-0 ghost-button ghost-info shadow-none w-100"
                                          size="md"
                                          :disabled="true"
                                          >
                                            <img class="me-2" src="/assets/images/icons/milk.svg" alt="Cantidad de leche"/>
                                            <span>{{ record.cantidadKilos }} kg</span>
                                          </Button>
                                        </template>

                                        <template v-else>
                                          <Button
                                          class="m-0 ghost-button ghost-info shadow-none w-100"
                                          size="md"
                                          @click="updateCantidadKilos(record.idRutaRecojo)"
                                          >
                                            <img class="me-2" src="/assets/images/icons/milk.svg" alt="Cantidad de leche"/>
                                            <span v-if="record.existeHoraFin">{{ record.cantidadKilos }} kg</span>
                                            <span v-else-if="record.cantidadKilos !== 0">{{ record.cantidadKilos }} kg</span>
                                            <span v-else>{{ '__' }} kg</span>
                                          </Button>
                                        </template>

                                      </div>
                                  </div>

                                  <div class="hr my-2"></div>

                                  <div class="cell">
                                      <div class="key font-x2">Temperatura</div>
                                      <div class="value">

                                        <template v-if="infoRuta && infoRuta.horaLlegada">
                                          <Button
                                          class="m-0 ghost-button ghost-info shadow-none w-100"
                                          size="md"
                                          :disabled="true"
                                          >
                                            <!--<img class="me-2" src="/assets/images/icons/milk.svg" alt="Cantidad de leche"/>-->
                                            <span>{{ record.temperaturaRecojo }} &deg;</span>
                                          </Button>
                                        </template>

                                        <template v-else>
                                          <Button
                                          class="m-0 ghost-button ghost-info shadow-none w-100"
                                          size="md"
                                          @click="registerTemperature(record.idRutaRecojo)"
                                          >
                                            <!--<img class="me-2" src="/assets/images/icons/milk.svg" alt="Cantidad de leche"/>-->
                                            <span v-if="record.existeHoraFin">{{ record.temperaturaRecojo }} &deg;</span>
                                            <span v-else-if="record.temperaturaRecojo !== 0">{{ record.temperaturaRecojo }} &deg;</span>
                                            <span v-else>{{ '__' }}</span>
                                          </Button>
                                        </template>

                                      </div>
                                  </div>

                                  <div class="hr my-2"></div>

                                  <div class="cell">
                                      <div class="key font-x2">
                                        <span>Final de carga</span>
                                        <span class="user-timedate" v-if="record.existeHoraFin" :class="{'disabled':record.horaInicio == ''|| record.horaFin !== '' ||record.existeHoraFin}">{{ $filters.showOnlyTime(record.horaFin) }}</span>
                                        <span class="user-timedate" v-else>{{ formattedTime }}</span>
                                      </div>
                                      <div class="value">
                                          <Button
                                          :disabled="record.horaInicio == ''|| record.horaFin !== '' || record.existeHoraFin || infoRuta.horaLlegada !== ''"
                                          class="m-0 info-button shadow-none w-100"
                                          size="md"
                                          type="button"
                                          @click="confirmFinCarga2(record.idRutaRecojo)"
                                          >
                                            <img class="me-2" src="/assets/images/icons/timer.svg" alt="Cantidad de leche"/>
                                            <span>Marcar</span>
                                          </Button>
                                      </div>
                                  </div>

                                  <div class="hr my-2"></div>

                                  <div class="cell">
                                      <div class="key font-x2">
                                          <span>Evidencia</span>
                                          <!--<span class="user-timedate" v-if="record.horaEvidencia !== ''" :class="{'disabled': record.horaEvidencia !== '' }">{{ record.horaEvidencia }}</span>
                                          <span class="user-timedate" v-else>{{ formattedTime }}</span>-->
                                      </div>
                                      <div class="value">
                                          <Button
                                          class="m-0 ghost-button ghost-info shadow-none w-100"
                                          size="md"
                                          type="button"
                                          :disabled="infoRuta.horaLlegada ? true : false"
                                          @click="addEvidencia(record.idRutaRecojo, index)"
                                          >
                                              <img class="me-2" src="/assets/images/icons/camera.svg" alt="Cantidad de leche"/>
                                              <span>Adjuntar evidencia</span>
                                          </Button>
                                      </div>
                                  </div>

                              </template>
                          </RowAccordion>
                          
                      </div>
                  </div>
  
              </div>

              <div v-else>
                <div class="t-body no-records">
                  <p>No existe rutas asignadas</p>
                </div>
              </div>
  
              <div v-if="listaRecojo.length > 0" class="sticky-footer-box">
                
                <div class="form-actions" v-if="!infoRuta.horaLlegada">
                  <div>
                    <h4 class="title-accesorios show-mobile-only">Llegada a planta</h4>

                    <Button
                    class="m-0 info-button shadow-none w-100"
                    size="md"
                    type="button"
                    @click="checkLlegadaPlanta()"
                    :disabled="!todosTienenHoraEvidencia"
                    >
                      <img class="me-2" src="/assets/images/icons/timer.svg" alt="Cantidad de leche"/>
                      <span class="show-desktop-only">Marcar llegada a planta</span>
                      <span class="show-mobile-only">Marcar</span>
                    </Button>
                  </div>
                </div>
  
              </div>

            </template>

          </div>

        </div>
      </div>
    </div>






    <transition name="fade">
      <CustomModal
        :show="dataModalTemperatura.showModal"
        :isHeader="true"
        :isFooter="false"
        :width="450"
        :title="'Ingresar temperatura'"
        :closeIcon="false"
        v-on:modal:close="hideModalTemperatura()"
      >
        <template v-slot:body>

          <Form
            @submit="submitTemperatura"
            :validation-schema="schemaTemperatura"
            :initial-values="formValuesTemperatura"
            ref="formDataTemperatura"
          >

            <div class="row">
              <div class="col-md-12">
                <TextInput
                  name="temperatura"
                  type="text"
                  label="temperatura"
                  v-model="virtual.temperatura"
                  placeholder=""
                />
              </div>
            </div>

            <div class="hr my-4"></div>

            <div class="form-actions">
              <Button
                class="mt-3 ghost-button shadow-none"
                size="lg"
                type="button"
                @click="hideModalTemperatura()"
                >Cancelar</Button
              >

              <Button
                class="mt-3 primary-button shadow-none"
                size="lg"
                type="submit"
                >Guardar</Button
              >
            </div>

          </Form>

        </template>

      </CustomModal>
    </transition>






    <transition name="fade">
      <CustomModal
        :show="dataModal.showModal"
        :isHeader="true"
        :isFooter="false"
        :width="450"
        :title="'Ingresar cantidad de leche'"
        :closeIcon="false"
        v-on:modal:close="hideModal()"
      >
        <template v-slot:body>

          <Form
            @submit="submitCantidadKilos"
            :validation-schema="schemaCantidadKilos"
            :initial-values="formValuesCantidadKilos"
            ref="formValuesCantidadKilos"
          >

            <div class="row">
              <div class="col-md-12">
                <TextInput
                  name="cantidadKilos"
                  type="text"
                  label="cantidad de leche"
                  v-model="virtual.cantidadKilos"
                  placeholder=""
                />
              </div>
            </div>

            <div class="hr my-4"></div>

            <div class="form-actions">
              <Button
                class="mt-3 ghost-button shadow-none"
                size="lg"
                type="button"
                @click="hideModal()"
                >Cancelar</Button
              >

              <Button
                class="mt-3 primary-button shadow-none"
                size="lg"
                type="submit"
                >Guardar</Button
              >
            </div>

          </Form>

        </template>

      </CustomModal>
    </transition>

    <transition name="fade">
      <CustomModal
        :show="dataModalEvidencia.showModal"
        :isHeader="true"
        :isFooter="false"
        :width="450"
        :title="'Agregar evidencia'"
        :closeIcon="false"
        v-on:modal:close="hideModalEvidencia()"
      >
        <template v-slot:body>

          <Form
            @submit="submitEvidencia"
            :validation-schema="schemaEvidencia"
            :initial-values="formValuesEvidencia"
            ref="formDataEvidencia"
          >

            <div class="row">

              <div class="col-md-12">

                <ul class="metadata-ev-inc">
                  <li>
                    <img src="/assets/images/icons/calendar.svg" alt="Ubicación" class="me-2">
                    <span>{{ date }}</span>
                  </li>
                  <li>
                    <img src="/assets/images/icons/time.svg" alt="Ubicación" class="me-2">
                    <span>{{ formattedTime }}</span>
                  </li>
                  <li>
                    <img src="/assets/images/icons/pin.svg" alt="Ubicación" class="me-2">
                    <span>{{ direccion }}</span>
                  </li>
                </ul>

                <div class="dropZoneEvidencias">

                  <label class="button-file-upload">
                    <input class="m-0 button camera-button shadow-none" type="file" @change.prevent="onFileChange" accept="image/*">
                      <img class="me-2"  src="/assets/images/icons/takePhoto.svg">
                      <span>Tomar foto</span>
                  </label>

                </div>

              </div>

              <div class="col-md-12">
                <p class="info-evidencia">Recuerda: Cada foto no debe pasar el tamaño de 300MB</p>
              </div>

              <div class="col-md-12">

                <ul class="grid-item-image">
                  <li class="grid-item" v-for="(image, index) in images" :key="index">
                    <div class="image">
                      <img :src="image.url" />
                    </div>
                    <div class="text-image">
                      <p class="m-0">{{ image.name }}</p>
                    </div>
                    <div class="delete-action">
                      <DeleteButton
                        :idx="index"
                        :deleteR="deleteImage"
                      />
                    </div>
                  </li>                  
                </ul>

              </div>

            </div>

            <div class="hr my-4"></div>

            <div class="form-actions">
              <Button
                class="mt-3 ghost-button shadow-none"
                size="lg"
                type="button"
                @click="hideModalEvidencia()"
                :disabled="isSubmittingModal"
                >Cancelar</Button
              >

              <Button
                class="mt-3 primary-button shadow-none"
                size="lg"
                type="submit"
                :class="{ 'loading-btn': isSubmittingModal }"
                :disabled="isSubmittingModal"
                ><span class="btn-text">Guardar</span></Button
              >
            </div>

          </Form>

        </template>

      </CustomModal>
    </transition>


    <transition name="fade">
      <CustomModal
        :show="dataModalIncidencia.showModal"
        :isHeader="true"
        :isFooter="false"
        :width="450"
        :title="'Agregar incidencia'"
        :closeIcon="false"
        v-on:modal:close="hideModalIncidencia()"
      >
        <template v-slot:body>

          <Form
            @submit="submitIncidencia"
            :validation-schema="schemaIncidencia"
            :initial-values="formValuesIncidencia"
            ref="formDataIncidencia"
          >

            <div class="row">

              <div class="col-md-12">

                <ul class="metadata-ev-inc">
                  <li>
                    <img src="/assets/images/icons/calendar.svg" alt="Ubicación" class="me-2">
                    <span>{{ date }}</span>
                  </li>
                  <li>
                    <img src="/assets/images/icons/time.svg" alt="Ubicación" class="me-2">
                    <span>{{ formattedTime }}</span>
                  </li>
                  <li>
                    <img src="/assets/images/icons/pin.svg" alt="Ubicación" class="me-2">
                    <span>{{ direccion }}</span>
                  </li>
                </ul>

                <Textarea
                  label="describir la incidencia"
                  name="incidencia"
                  class="message mt-4"
                  v-model="incidenciaDescripcion"
                ></Textarea>

                <div class="dropZoneEvidencias">

                  <label class="button-file-upload">
                    <input class="m-0 button camera-button shadow-none" type="file" @change.prevent="onFileChangeIncidencia" accept="image/*">
                      <img class="me-2"  src="/assets/images/icons/takePhoto.svg">
                      <span>Tomar foto</span>
                  </label>

                </div>

              </div>

              <div class="col-md-12">
                <p class="info-evidencia">Recuerda: Cada foto no debe pasar el tamaño de 300MB</p>
              </div>

              <div class="col-md-12">

                <ul class="grid-item-image">
                  <li class="grid-item" v-for="(image, index) in imagesIncidencia" :key="index">
                    <div class="image">
                      <img :src="image.url" />
                    </div>
                    <div class="text-image">
                      <p class="m-0">{{ image.name }}</p>
                    </div>
                    <div class="delete-action">
                      <DeleteButton
                        :idx="index"
                        :deleteR="deleteImageIncidencia"
                      />
                    </div>
                  </li>                  
                </ul>

              </div>

            </div>

            <div class="hr my-4"></div>

            <div class="form-actions">
              <Button
                class="mt-3 ghost-button shadow-none"
                size="lg"
                type="button"
                @click="hideModalIncidencia()"
                >Cancelar</Button
              >

              <Button
                class="mt-3 primary-button shadow-none"
                size="lg"
                type="submit"
                >Guardar</Button
              >
            </div>

          </Form>

        </template>

      </CustomModal>
    </transition>



  </div>
</template>

<script>
import { markRaw } from "vue";
import { Form, Field } from "vee-validate";
import {mapActions, mapGetters} from 'vuex';
import TextInput from "@/components/Inputs/TextInput";
import Textarea from "@/components/Inputs/Textarea";
import Button from "@/components/Inputs/Button";
import SelectButton from "@/components/Inputs/SelectButton";
import Pagination from '@/components/Pagination.vue';
import CustomModal from "@/components/CustomModal.vue";
import RowAccordion from "@/components/Accordion/RowAccordion";
import ConfirmationScreen from "@/components/ConfirmationScreen";
import DeleteButton from '@/components/DeleteButton.vue'
import { format } from "date-fns";
import Tag from "@/components/Tags/Tag";
import html2canvas from 'html2canvas'
import * as Yup from "yup";
import { VOffline } from 'v-offline';

export default {
  components: {
    TextInput,
    Textarea,
    Button,
    Pagination,
    CustomModal,
    RowAccordion,
    ConfirmationScreen,
    Tag,
    DeleteButton,
    html2canvas,
    Form,
    Field,
    SelectButton,
    VOffline
  },
  name: "NegociacionDePrecio",
  data() {
    
    const schemaCantidadKilos = markRaw(
      Yup.object().shape({

      })
    );
    const formValuesCantidadKilos = {};


    const schemaEvidencia = markRaw(
      Yup.object().shape({

      })
    );
    const formValuesEvidencia = {};

    const schemaIncidencia = markRaw(
      Yup.object().shape({

      })
    );
    const formValuesIncidencia = {};


    const schemaTemperatura = markRaw(
      Yup.object().shape({

      })
    );
    
    const formValuesTemperatura = {};
    
    return {

      schemaTemperatura,
      formValuesTemperatura,

      evidenciasEnviadas: false,

      isInProcess: false,
      google: null,
      googleMapsLoaded: false,
      latitude: localStorage.getItem('latitude') || 0,
      longitude: localStorage.getItem('longitude') || 0,
      direccion: '',
      date: format(new Date(), 'DD/MM/YYYY'),

      idTipoSeguimientoIncidenciaOLlegada: 1,
      idTipoSeguimiento: 2,
      idTipoRecorrido: 1,

      idRutaRecojoIncidencia: '',
      direccionIncidencia: '',
      descripcionIncidencia: '',
      isSubmittingModalIncidencia: false,

      filesImagesIncidencia: [],
      imagesIncidencia: [],

      isSubmittingModal: false,

      isFirstTimeGeolocationEnabled: localStorage.getItem('firstTimeGeolocationEnabled') || false,
      geolocationEnabled: false,


      step1InicioCarga: true,
      step2FinalCarga: false,
      loadKg: false,

      latitudInicioCarga: '',
      longitudInicioCarga: '',

      latitudFinCarga: '',
      longitudFinCarga: '',


      idRutaRecojo: '',

      idRutaRecojoEvidencia: '',
      latitudEvidencia: '',
      longitudEvidencia: '',
      fechaRegistroEvidencia: '',
      direccionEvidencia: '',
      descripcionEvidencia: '',
      filesImages: [],
      images: [],

      time: new Date(),


      schemaCantidadKilos,
      formValuesCantidadKilos,

      schemaEvidencia,
      formValuesEvidencia,

      schemaIncidencia,
      formValuesIncidencia,


      imageUrl: null,

      incidenciaDescripcion: '',

      page: 1,
      ganaderosActivos: 0,
      ganaderosInactivos: 0,
      ganaderoCodigo: '',
      roleAllowed: true,
      pagination: {},
      records: [],

      tableClassName: 'lista-registro-leche-fria',
      infoRuta: {
        numeroPlaca: '',
        nombreRuta: '',
        nombreRegion: '',
        horaLlegada: ''
      },
      listaRecojo: [],
      model: {
        cantidadKilos: '',
        temperatura: ''
      },
      virtual: {
        cantidadKilos: 0,
        temperatura: 0,
        time1: {
          id: '',
          name: ''
        },
        time2: {
          id: '',
          name: ''
        }
      },
      dataModal: {
        showModal: false,
        title: "",
        active: "",
        request: "",
        response: "",
      },
      dataModalTemperatura: {
        showModal: false,
        title: "",
        active: "",
        request: "",
        response: "",
      },
      dataModalEvidencia: {
        showModal: false,
        title: "",
        active: "",
        request: "",
        response: "",
      },
      dataModalIncidencia: {
        showModal: false,
        title: "",
        active: "",
        request: "",
        response: "",
      },
      virtualIndex: ''
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),

    formattedTime() {

      const date = new Date(this.time);
      //console.log('date', date);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      return `${formattedHours}:${formattedMinutes} ${ampm}`;

    },

    todosTienenHoraEvidencia() {
      console.log('this.listaRecojo', this.listaRecojo);
      const objetosSinHoraEvidencia = this.listaRecojo.filter(objeto => !objeto.tieneEvidencia);

      return objetosSinHoraEvidencia.length === 0;
    }
  },
  watch: {
    geolocationEnabled: function(newVal, oldVal) {
      if (newVal === true) {
        console.log('La ubicación geográfica del usuario está habilitada');
        // haz algo más aquí
        this.geolocationEnabled = newVal;
      }
    },
  },
  methods: {
    ...mapActions({
      getDetalle: 'seguimiento/getDetalle',
      postSeguimiento: 'seguimiento/postSeguimiento',
      postEvidencia: 'seguimiento/postEvidencia',
      postIncidencia: 'seguimiento/postIncidencia',
      guardarCoordenadas: 'offline/guardarCoordenadas',
      getDetalleOffline: 'offline/getDetalleOffline',
      guardarSeguimientoOffline: 'offline/guardarSeguimientoOffline',
      obtenerSeguimientoOffline: 'offline/obtenerSeguimientoOffline',
      getOfflineTransportista: 'mantenimiento/getOfflineTransportista',
      getOfflineTrackingByAction: 'offline/getOfflineTrackingByAction',
      saveOfflineTracking: 'offline/saveOfflineTracking',
      mixOffline: 'offline/mixOffline',
      guardarRutas: 'offline/guardarRutas',
      updateOfflineTracking: 'offline/updateOfflineTracking',
      deleteOfflineTracking: 'offline/deleteOfflineTracking',
      eliminarTodasEvidenciaOffline: 'offline/eliminarTodasEvidenciaOffline',
      guardarEvidenciaOffline: 'offline/guardarEvidenciaOffline',
      obtenerEvidenciaOffline: 'offline/obtenerEvidenciaOffline',
      deleteOfflineEvidencia: 'offline/deleteOfflineEvidencia',
      guardarIncidenciaOffline: 'offline/guardarIncidenciaOffline',
      obtenerIncidenciaOffline: 'offline/obtenerIncidenciaOffline',
    }),
    closeAccordion() {
      const index = this.virtualIndex;
      this.listaRecojo[index].isOpen = index;
    },
    goToDetalle(id) {
      this.$router.push({ name: "Registro Maestro Ganadero", params: { id: id } })
    },
    hideModal() {
      this.dataModal.showModal = false;
    },
    registerTemperature(idRutaRecojo) {

      this.idRutaRecojo = idRutaRecojo;
      this.formValuesTemperatura.temperatura = this.model.temperatura;
      this.virtual.temperatura = this.model.temperatura;
      this.dataModalTemperatura.showModal = true;

    },
    hideModalTemperatura() {
      this.dataModalTemperatura.showModal = false;
    },
    updateCantidadKilos(idRutaRecojo) {

      this.idRutaRecojo = idRutaRecojo;
      this.formValuesCantidadKilos.cantidadKilos = this.model.cantidadKilos;
      this.virtual.cantidadKilos = this.model.cantidadKilos;
      this.dataModal.showModal = true;

    },
    
    





    showModalEvidencia() {
      this.dataModalEvidencia.showModal = true;
    },
    hideModalEvidencia() {
      this.dataModalEvidencia.showModal = false;
      this.images = [];
      this.$refs.formDataEvidencia.resetForm();
    },
    setVirtualIndex(index) {
      console.log('set index', index);
      this.virtualIndex = index;
    },
    addEvidencia(idRutaRecojo, index) {
      this.idRutaRecojoEvidencia = idRutaRecojo;

      this.setVirtualIndex(index);
      this.showModalEvidencia();
    },
    onFileChange(event) {
      if (event.target.files && event.target.files[0]) {

        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            
          const image = new Image();
          image.src = e.target.result;

          image.onload = () => {
            const canvas = document.createElement('canvas');
            let width = image.width;
            let height = image.height;

            // Redimensiona la imagen si es mayor a 700 de ancho o alto
            if (width > 1200 || height > 1200) {
              if (width > height) {
                height *= 1200 / width;
                width = 1200;
              } else {
                width *= 1200 / height;
                height = 1200;
              }
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);
            const imageUrl = canvas.toDataURL();
            const imageName = file.name;
            console.log('canvas 2d');
            this.images.push({ url: imageUrl, name: imageName });
            this.filesImages.push(file);
          };
        };

        reader.readAsDataURL(file);

      }
    },
    deleteImage(index) {
      this.images.splice(index, 1);
      this.filesImages.splice(index, 1);

      console.log('this.images', this.images);
    },




    showModalIncidencia() {
      this.dataModalIncidencia.showModal = true;
    },
    hideModalIncidencia() {
      this.dataModalIncidencia.showModal = false;
      this.images = [];
      this.clearIncidencias();
    },
    clearIncidencias() {
      this.incidenciaDescripcion = '';
      this.imagesIncidencia = [];
      this.filesImagesIncidencia = [];
    },
    addIncidencia() {
      
      this.idRutaRecojoIncidencia = 1;
      this.showModalIncidencia();

    },
    onFileChangeIncidencia(event, allowDuplicates = true) {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          const imageUrl = e.target.result;
          const imageName = `${file.name}?${new Date().getTime()}&rand=${Math.random()}`;
          if (allowDuplicates || !this.imagesIncidencia.some(img => img.url === imageUrl)) {
            this.imagesIncidencia.push({ url: imageUrl, name: imageName });
            this.filesImagesIncidencia.push(file);
          }
        };
        reader.readAsDataURL(file);
      }
    },
    deleteImageIncidencia(index) {
      this.imagesIncidencia.splice(index, 1);
      this.filesImagesIncidencia.splice(index, 1);
    },


    async submitIncidencia(values) {


      //////////////////////////////////////////////////////

      if (this.imagesIncidencia.length == 0) {
        this.hideModalEvidencia();
        this.$filters.showErrorMessage('Ingrese imágenes para enviar la incidencia');
        return;
      }

      this.isSubmittingModal = true;

      this.descripcionIncidencia = values.incidencia;

      var formData = new FormData();
      formData.append('idRutaReferencia', this.$route.params.id);
      formData.append('idTipoSeguimiento', this.idTipoSeguimientoIncidenciaOLlegada);
      formData.append('fechaRegistro', `${this.$route.query.fecha}`); //format(new Date(), 'YYYY-MM-DD')
      formData.append('hora', this.formattedTime);

      formData.append('latitud', this.latitude); //Latitud
      formData.append('longitud', this.longitude); //Longitud

      formData.append('direccion', this.direccionIncidencia ? this.direccionIncidencia : "-");
      formData.append('descripcion', this.descripcionIncidencia ? this.descripcionIncidencia : "-");
      formData.append('idRutaProgramacion', this.$route.query.idRP);
      
      this.filesImagesIncidencia.map((x)=> {
        formData.append('files', x);
      });


      if (navigator.onLine) {

        const response = await this.postIncidencia(formData);
        if (response.status == 200) {

          this.hideModalIncidencia();

          const obj = {
            message: 'Se agregaron las incidencias con éxito',
            confirmButtonText: 'Cerrar',
            withCallback: false
          }

          this.$filters.showSuccessMessage(obj);

          this.$refs.formDataIncidencia.resetForm();

        }

      } else {


        // Manejar el offline aquí
        localStorage.setItem('pendingRequests', 3);
        
        try {

          console.log('offline enviar ruta recojo', this.$route.params.id);
          const data = {
            idRutaReferencia: this.$route.params.id,
            idTipoSeguimiento: this.idTipoSeguimientoIncidenciaOLlegada,
            fechaRegistro: `${this.$route.query.fecha}`,
            hora: this.formattedTime,

            latitud: this.latitude,
            longitud: this.longitude,

            direccion: this.direccionIncidencia ? this.direccionIncidencia : "-",
            descripcion: this.descripcionIncidencia ? this.descripcionIncidencia : "-",
            idRutaProgramacion: this.$route.query.idRP,
            files: this.filesImagesIncidencia
          }
          
          await this.saveOfflineTracking({action: 'INCIDENCIA_LF', idRutaRecojo: this.$route.params.id, needToSend: true});

          console.log('guardar incidencia Offline', data);
          await this.guardarIncidenciaOffline(data);

          this.hideModalIncidencia();

          const obj = {
            message: 'Se agregaron las incidencias con éxito',
            confirmButtonText: 'Cerrar',
            withCallback: false
          }

          this.$filters.showSuccessMessage(obj);

          this.$refs.formDataIncidencia.resetForm();


          this.isSubmittingModal = false;
          
          this.clearIncidencias();

          /*setTimeout(() => {
            location.reload();
          }, 1000);*/

        } catch (error) {

          console.log(error);

        }


      }
      //////////////////////////////////////////////////////

    },





    checkLlegadaPlanta() {

        if (!navigator.onLine) {
          const obj = {
          message: `Por favor habilita los datos antes de marcar llegada a planta`,
          confirmButtonText: 'Aceptar',
          withCallback: false
        }

        this.$filters.showWarningMessage(obj);
        return;
      }

      const obj = {
        message: `Estas marcando tu llegada a planta<br><br><span class="user-time-modal">${this.formattedTime}<span>`,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        withCallback: true
      }

      this.$filters.showWarningMessage(obj, (confirmed) => {
          
        if (confirmed) {

          //Obtener coordenadas

            this.enableGeoLocation();
            console.log('llegada a planta coordenadas');

            const data = {
              "idRutaReferencia": this.$route.params.id,
              "idTipoSeguimiento": this.idTipoSeguimientoIncidenciaOLlegada,
              "idTipoRecorrido": this.idTipoRecorrido,
              "fechaRegistro": this.$route.query.fecha,
              "horaLlegada": this.formattedTime + " - " + format(new Date(), 'DD/MM/YYYY'),
              "latitudLlegada": this.latitud,
              "longitudLlegada": this.longitud,
              "idRutaProgramacion": this.$route.query.idRP
            }

            this.postSeguimiento(data).then((response) => {

              if (response.status == 200) {

                console.log('llegada a planta', data);

                this.step1InicioCarga = false;
                this.step2FinalCarga = false;

                this.getList();

              }

            }).catch((err)=>{

              this.$filters.onErrorBase(err);

            });


        }
          
      });

    },
    enableGeoLocation() {

      console.log('navigator.geolocation', navigator.geolocation);

      this.isFirstTimeGeolocationEnabled = localStorage.getItem('firstTimeGeolocationEnabled') || false;

      //console.log('was enabled', this.isFirstTimeGeolocationEnabled);

      if (navigator.geolocation) {

        try {

          navigator.geolocation.getCurrentPosition((position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            
            localStorage.setItem('latitude', latitude);            
            localStorage.setItem('longitude', longitude);

            localStorage.setItem('firstTimeGeolocationEnabled', true);
            this.isFirstTimeGeolocationEnabled = true;

            this.geolocationEnabled = true;

            // haz algo con la latitud y longitud
            this.latitude = latitude ? latitude : localStorage.getItem('latitude') || 0;
            this.longitude = longitude ? longitude : localStorage.getItem('longitude') || 0;

          }, () => {
            console.log("No se pudo obtener la ubicación");
          });
          
        } catch (err) {

        }

      } else {

        console.log("La geolocalización no está disponible o ya está habilitada");

      }

    },
    getGeoLocation() {
      const API_KEY_GOOGLE_MAPS = 'AIzaSyDrHYuv4x2ZC0nDA39DMo4i7VsXX5wzFuo';

      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY_GOOGLE_MAPS}&libraries=places`;
      script.defer = true;
      script.async = true;

      let scriptLoaded = false;

      script.onload = () => {
        scriptLoaded = true;
        const coordenadas = {
          lat: this.latitude, //37.7749
          lng: this.longitude //-122.4194
        };

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: coordenadas }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              console.log(results[0].formatted_address);
              this.direccion = results[0].formatted_address;
            } else {
              console.log("No se encontraron resultados");
              this.direccion = 'Ubicación no disponible';
            }
          } else {
            console.log("Falló la búsqueda con el siguiente estado: " + status);
          }
        });
      };

      script.onerror = () => {
        console.error("Error al cargar la biblioteca de Google Maps");
      };

      // verifica si el script de Google Maps se ha cargado después de 5 segundos
      setTimeout(() => {
        if (!scriptLoaded) {
          console.error("No se pudo cargar la biblioteca de Google Maps");
        }
      }, 5000);

      document.head.appendChild(script);
    },

    async getList() {
      var data = {
        "idRuta": this.$route.params.id,
        "idProductoTransportado": this.$route.query.prod,
        "fecha": this.$route.query.fecha,
        "idRutaProgramacion": this.$route.query.idRP
      }

      if (navigator.onLine) {
        const response = await this.getDetalle(data);
        
        if (response.status == 200) {

          if (response.data.infoRuta === undefined) {
            this.infoRuta = {};
          } else {
            this.infoRuta = response.data.infoRuta;
          }          

          console.log('this.infoRuta', this.infoRuta);

          this.listaRecojo = response.data.listaRecojo.map(recojo => ({
            ...recojo,
            existeHoraFin: recojo.horaFin !== '',
            tieneEvidencia: recojo.horaEvidencia !== '' ? true : false,
            isOpen: true
          }));

        }
      } else {

        try {

          const data = {
            "idRuta": this.$route.params.id,
            "idProductoTransportado": this.$route.query.prod,
            "fecha": this.$route.query.fecha,
            "idRutaProgramacion": this.$route.query.idRP
          }

          const response = await this.getDetalleOffline(data);
        
          console.log('info ruta offline', response.data.infoRuta);
          this.infoRuta = response.data.infoRuta;


          this.listaRecojo = response.data.listaRecojo.map(recojo => ({
            ...recojo,
            existeHoraFin: recojo.horaFin !== '',
            tieneEvidencia: recojo.horaEvidencia !== '' ? true : false,
            isOpen: true
          }));

        } catch (error) {

          console.log(error);

        }

      }
    },

    async confirmInicioCarga1(idRutaRecojo) {

      const obj = {
        message: `Estas marcando la hora de inicio de carga<br><br><span class="user-time-modal">${this.formattedTime}<span>`,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        withCallback: true
      }

      this.$filters.showWarningMessage(obj, async (confirmed) => {

        if (confirmed) {
          const data = {
            "idRutaReferencia": idRutaRecojo,
            "idTipoSeguimiento": this.idTipoSeguimiento,
            "idTipoRecorrido": this.idTipoRecorrido,
            "fechaRegistro": this.$route.query.fecha,
            "horaInicio": this.formattedTime + " - " + format(new Date(), 'DD/MM/YYYY'),
            "latitudInicio": this.latitude,
            "longitudInicio": this.longitude,
            "temperaturaRecojo": this.model.temperatura ? this.model.temperatura : 0,
            "idRutaProgramacion": this.$route.query.idRP,

            //IDB
            "idRuta": this.$route.params.id,
            "idProductoTransportado": this.$route.query.prod,
            "codigoTransportista": this.user.usuario
          }


          if (navigator.onLine) {

            try {
              const response = await this.postSeguimiento(data);
              if (response.status == 200) {
                this.step1InicioCarga = false;
                this.step2FinalCarga = true;
                
                await this.actualizarOffline();
              }
            } catch (error) {}

          } else {

            // Manejar el offline aquí
            localStorage.setItem('pendingRequests', 1);

            try {

              //await this.saveOfflineTracking({action: 'HORA_INICIO_LF', idRutaRecojo: idRutaRecojo, needToSend: true});

              await this.saveOfflineTracking({action: 'OFFLINE_TRACK_LF', idRutaRecojo: idRutaRecojo, needToSend: true});
              

              await this.guardarSeguimientoOffline(data);
              this.step1InicioCarga = false;
              this.step2FinalCarga = true;

              this.getList();

            } catch (error) {

              console.log(error);

            }

          }

        }

      });

    },
    async confirmFinCarga2(idRutaRecojo) {


      /*if (this.model.temperatura == '' || this.model.cantidadKilos == '') {
        this.$filters.showErrorMessage('Ingrese la cantidad de leche y temperatura antes de marcar final de carga');
        return;
      }*/


      const obj = {
        message: `Estas marcando la hora de fin de carga<br><br><span class="user-time-modal">${this.formattedTime}<span>`,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        withCallback: true
      }

      this.$filters.showWarningMessage(obj, async (confirmed) => {
          
        if (confirmed) {


            const data = {
              "idRutaReferencia": idRutaRecojo,
              "idTipoSeguimiento": this.idTipoSeguimiento,
              "idTipoRecorrido": this.idTipoRecorrido,
              "fechaRegistro": this.$route.query.fecha,
              "horaFin": this.formattedTime + " - " + format(new Date(), 'DD/MM/YYYY'),
              "latitudFin": this.latitude,
              "longitudFin": this.longitude,
              "cantidadKilos": this.model.cantidadKilos ? this.model.cantidadKilos : 0,
              "temperaturaRecojo": this.model.temperatura ? this.model.temperatura : 0,
              "idRutaProgramacion": this.$route.query.idRP,

              //IDB
              "idRuta": this.$route.params.id,
              "idProductoTransportado": this.$route.query.prod,
              "codigoTransportista": this.user.usuario
            }


            if (navigator.onLine) {

              try {
                const response = await this.postSeguimiento(data);
                if (response.status == 200) {
                  this.step1InicioCarga = false;
                  this.step2FinalCarga = false;

                  await this.actualizarOffline();
                }
              } catch (error) {}

            } else {

              // Manejar el offline aquí
              localStorage.setItem('pendingRequests', 2);
              
              try {

                //await this.saveOfflineTracking({action: 'HORA_FIN_LF', idRutaRecojo: idRutaRecojo, needToSend: true});

                await this.saveOfflineTracking({action: 'OFFLINE_TRACK_LF', idRutaRecojo: idRutaRecojo, needToSend: true});

                await this.guardarSeguimientoOffline(data);

                this.step1InicioCarga = false;
                this.step2FinalCarga = false;

                this.getList();

              } catch (error) {

                console.log(error);

              }

            }
            

        }
          
      });

    },

    async submitCantidadKilos(values) {

      const cantidadKilos = this.$filters.convertToNumber(values.cantidadKilos);

      if (cantidadKilos <= 0) {
        
        this.hideModal();
        this.$filters.showErrorMessage('Ingrese un número mayor a 0');
        return;

      }

      const data = {
        "idRutaReferencia": this.idRutaRecojo,
        "idRutaProgramacion": this.$route.query.idRP,
        "idTipoSeguimiento": this.idTipoSeguimiento,
        "idTipoRecorrido": this.idTipoRecorrido,
        "fechaRegistro": this.$route.query.fecha,

        "cantidadKilos": cantidadKilos,

        //IDB
        "idRuta": this.$route.params.id,
        "idProductoTransportado": this.$route.query.prod,
        "codigoTransportista": this.user.usuario
      }


      if (navigator.onLine) {

        try {
          const response = await this.postSeguimiento(data);
          if (response.status == 200) {
            this.step1InicioCarga = false;
            this.step2FinalCarga = true;

            await this.actualizarOffline();

            this.dataModal.showModal = false;

          }
        } catch (error) {}

      } else {

        // Manejar el offline aquí
        localStorage.setItem('pendingRequests', 2);
        
        try {

          //await this.saveOfflineTracking({action: 'CANTIDAD_KILOS_LF', idRutaRecojo: this.idRutaRecojo, needToSend: true});
          console.log('kilos this.idRutaRecojo', this.idRutaRecojo);
          await this.saveOfflineTracking({action: 'OFFLINE_TRACK_LF', idRutaRecojo: this.idRutaRecojo, needToSend: true});

          await this.guardarSeguimientoOffline(data);

          this.step1InicioCarga = false;
          this.step2FinalCarga = true;

          this.getList();

          this.dataModal.showModal = false;

          this.formValuesCantidadKilos.cantidadKilos = '';
          this.virtual.cantidadKilos = '';

        } catch (error) {

          console.log(error);

        }

      }

      /*this.formValuesCantidadKilos.cantidadKilos = '';
      this.virtual.cantidadKilos = '';*/

    },

    async submitTemperatura(values) {

      const temperaturaRecojo = this.$filters.convertToNumber(values.temperatura);

      /*if (this.model.temperatura <= 0) {
        
        this.hideModal();
        this.$filters.showErrorMessage('Ingrese un número mayor a 0');
        return;

      }*/

      const data = {
        "idRutaReferencia": this.idRutaRecojo,
        "idRutaProgramacion": this.$route.query.idRP,
        "idTipoSeguimiento": this.idTipoSeguimiento,
        "idTipoRecorrido": this.idTipoRecorrido,
        "fechaRegistro": this.$route.query.fecha,

        "temperaturaRecojo": temperaturaRecojo,

        //IDB
        "idRuta": this.$route.params.id,
        "idProductoTransportado": this.$route.query.prod,
        "codigoTransportista": this.user.usuario
      }


      if (navigator.onLine) {

        try {
          const response = await this.postSeguimiento(data);
          if (response.status == 200) {
            this.step1InicioCarga = false;
            this.step2FinalCarga = true;

            await this.actualizarOffline();

            this.dataModalTemperatura.showModal = false;

          }
        } catch (error) {}

      } else {

        // Manejar el offline aquí
        localStorage.setItem('pendingRequests', 2);
        
        try {

          //await this.saveOfflineTracking({action: 'TEMPERATURA_LF', idRutaRecojo: this.idRutaRecojo, needToSend: true});

          await this.saveOfflineTracking({action: 'OFFLINE_TRACK_LF', idRutaRecojo: this.idRutaRecojo, needToSend: true});

          await this.guardarSeguimientoOffline(data);

          this.step1InicioCarga = false;
          this.step2FinalCarga = true;

          this.getList();

          this.dataModalTemperatura.showModal = false;

          this.formValuesTemperatura.temperatura = '';
          this.virtual.temperatura = '';

        } catch (error) {

          console.log(error);

        }

      }

      /*this.formValuesTemperatura.temperatura = '';
      this.virtual.temperatura = '';*/

    },

    async submitEvidencia() {

      if (this.images.length == 0) {
        this.hideModalEvidencia();
        this.$filters.showErrorMessage('Ingrese imágenes para enviar la evidencia');
        return;
      }

      this.isSubmittingModal = true;


      var formData = new FormData();
      formData.append('idRutaReferencia', this.idRutaRecojoEvidencia);
      formData.append('idTipoSeguimiento', 2);
      formData.append('fechaRegistro', `${this.$route.query.fecha}`);
      formData.append('hora', this.formattedTime);

      formData.append('latitud', this.latitude);
      formData.append('longitud', this.longitude);

      formData.append('direccion', this.direccionEvidencia ? this.direccionEvidencia : "-");
      formData.append('descripcion', this.descripcionEvidencia ? this.descripcionEvidencia : "-");
      formData.append('idRutaProgramacion', this.$route.query.idRP);
      
      
      this.filesImages.map((x)=> {
        formData.append('files', x);
      });

      console.log('this.filesImages', this.filesImages);



        if (navigator.onLine) {

          const response = await this.postEvidencia(formData);
          if (response.status == 200) {

            this.hideModalEvidencia();
            this.closeAccordion();

            this.listaRecojo[this.virtualIndex].tieneEvidencia = true;

            const obj = {
              message: 'Se agregaron las evidencias con éxito',
              confirmButtonText: 'Cerrar',
              withCallback: false
            }

            this.$filters.showSuccessMessage(obj);

          }

        } else {


          // Manejar el offline aquí
          localStorage.setItem('pendingRequests', 3);
          
          try {

            console.log('offline enviar ruta recojo', this.idRutaRecojoEvidencia);
            const data = {
              idRutaReferencia: this.idRutaRecojoEvidencia,
              idTipoSeguimiento: 2,
              fechaRegistro: `${this.$route.query.fecha}`,
              hora: this.formattedTime,

              latitud: this.latitude,
              longitud: this.longitude,

              direccion: this.direccionEvidencia ? this.direccionEvidencia : "-",
              descripcion: this.descripcionEvidencia ? this.descripcionEvidencia : "-",
              idRutaProgramacion: this.$route.query.idRP,
              files: this.filesImages
            }
            
            //await this.deleteOfflineEvidencia({action: 'EVIDENCIA_LF', idRutaReferencia: this.idRutaRecojoEvidencia, needToSend: false});
            await this.saveOfflineTracking({action: 'EVIDENCIA_LF', idRutaRecojo: this.idRutaRecojoEvidencia, needToSend: true});

            
            console.log('guardarEvidenciaOffline', data);
            await this.guardarEvidenciaOffline(data);

            this.hideModalEvidencia();
            this.closeAccordion();

            this.listaRecojo[this.virtualIndex].tieneEvidencia = true;

            const obj = {
              message: 'Se agregaron las evidencias con éxito',
              confirmButtonText: 'Cerrar',
              withCallback: false
            }

            await this.$filters.showSuccessMessage(obj);



            this.isSubmittingModal = false;
            this.formValuesCantidadKilos.cantidadKilos = '';
            this.virtual.cantidadKilos = '';

            this.formValuesTemperatura.temperatura = '';
            this.virtual.temperatura = '';
            
            this.images = [];
            this.filesImages = [];
            /*setTimeout(() => {
              location.reload();
            }, 1000);*/

          } catch (error) {

            console.log(error);

          }


        }


    },




    async sendOffline() {

      try {

        const needed = await this.getOfflineTrackingByAction({ action: 'OFFLINE_TRACK_LF' });

        if (!needed) {
          return;
        }

        if (needed.needToSend) {
          
          let loaderOffline = this.$loading.show();

          const data = {
            "idRutaProgramacion": this.$route.query.idRP,
            "idRuta": this.$route.params.id,
            "idProductoTransportado": this.$route.query.prod,
            "codigoTransportista": this.user.usuario
          }

          const list = await this.obtenerSeguimientoOffline(data);

          console.log('list offline to send', list);

          list.detalle.map(async(x) => {

            let latitudInicio = this.latitude;
            let longitudInicio = this.longitude;

            let latitudFin = this.latitude;
            let longitudFin = this.longitude;

            const payload = {
              "idRutaReferencia": x.idRutaRecojo,
              "idTipoSeguimiento": this.idTipoSeguimiento,
              "idTipoRecorrido": this.idTipoRecorrido,
              "fechaRegistro": this.$route.query.fecha,
              
              "horaInicio": x.horaInicio ? x.horaInicio : "",
              "latitudInicio": latitudInicio,
              "longitudInicio": longitudInicio,


              "horaFin": x.horaFin ? x.horaFin : "",
              "latitudFin": latitudFin,
              "longitudFin": longitudFin,

              "cantidadKilos": x.cantidadKilos ? x.cantidadKilos : 0,
              "temperaturaRecojo": x.temperaturaRecojo ? x.temperaturaRecojo : 0,


              "idRutaProgramacion": this.$route.query.idRP,

              //IDB
              "idRuta": this.$route.params.id,
              "idProductoTransportado": this.$route.query.prod,
              "codigoTransportista": this.user.usuario
            }

            try {
              const response = await this.postSeguimiento(payload);
              if (response.status == 200) {

                await this.updateOfflineTracking({action: 'OFFLINE_TRACK_LF', idRutaRecojo: x.idRutaRecojo, needToSend: false}); //eliminar
                await this.deleteOfflineTracking({action: 'OFFLINE_TRACK_LF', idRutaRecojo: x.idRutaRecojo, needToSend: false});
                console.log('post seguimiento');
                this.step1InicioCarga = false;
                this.step2FinalCarga = true;


                const needed = await this.getOfflineTrackingByAction({ action: 'EVIDENCIA_LF' });

                
                if (!needed) {
                  
                  loaderOffline.hide();

                  setTimeout(() => {
                    location.reload();
                  }, 100);

                }

                const needed2 = await this.getOfflineTrackingByAction({ action: 'INCIDENCIA_LF' });
                  
                if (!needed2) {
                  
                  loaderOffline.hide();

                  setTimeout(() => {
                    location.reload();
                  }, 100);

                }
                
              }
            } catch (err) {
              //
              console.log(err);
            }


          });

        }

      } catch (error) {

        console.log(error);

      }

    },


    async sendEvidencias() {

      if (this.evidenciasEnviadas) {
        return; // Evita el envío si las evidencias ya se enviaron
      }

      try {

        const needed = await this.getOfflineTrackingByAction({ action: 'EVIDENCIA_LF' });

        //console.log('needed evidencias', needed);
        if (!needed) {
          return;
        }
      
        const list = await this.obtenerEvidenciaOffline();

        console.log('evidencias', list);
        if (list.length > 0) {

          for (const x of list) {

            console.log('xxxxx ', x);

            const formData = new FormData();
            formData.append('idRutaReferencia', x.idRutaReferencia);
            formData.append('idTipoSeguimiento', x.idTipoSeguimiento);
            formData.append('fechaRegistro', x.fechaRegistro);
            formData.append('hora', x.hora);

            formData.append('latitud', x.latitud);
            formData.append('longitud', x.longitud);

            formData.append('direccion', x.direccion);
            formData.append('descripcion', x.descripcion);
            formData.append('idRutaProgramacion', x.idRutaProgramacion);

            for (const file of x.files) {
              formData.append('files', file.data);
            }

            try {
              const response = await this.postEvidencia(formData);

              if (response.status == 200) {            
                console.log([{action: 'EVIDENCIA_LF', idRutaRecojo: x.idRutaReferencia}]);
                await this.updateOfflineTracking({action: 'EVIDENCIA_LF', idRutaRecojo: x.idRutaReferencia, needToSend: false});
                await this.deleteOfflineTracking({action: 'EVIDENCIA_LF', idRutaRecojo: x.idRutaReferencia, needToSend: false});
                await this.deleteOfflineEvidencia({action: 'EVIDENCIA_LF', idRutaReferencia: x.idRutaReferencia, needToSend: false});
              }

            } catch (err) {
              //
              console.log(err);
            }


          }

          this.evidenciasEnviadas = true;

          setTimeout(() => {
            location.reload();
          }, 100);
          

        }
        

      } catch (error) {

        console.log(error);

      }

    },
    

    verifyOffline() {
      let offline = localStorage.getItem('offline');

      if (!navigator.onLine) {
        if (!offline) {

          const obj = {
            message: `Sin conexión.<br> Puedes seguir trabajando en modo offline`,
            confirmButtonText: 'Ok',
            cancelButtonText: false,
            withCallback: false
          }

          this.$filters.showWarningMessage(obj);
          
          localStorage.setItem('offline', '1');
          
          setTimeout(() => {
            location.reload();
          }, 2000);
          
        } else {
          console.log('Estás offline');
        }
      } else {
        localStorage.removeItem('offline');
      }
    },


    async actualizarOffline() {

      await this.getList();

      const data = {
        "idRutaProgramacion": this.$route.query.idRP,
        "idRuta": this.$route.params.id,
        "idProductoTransportado": this.$route.query.prod,
        "codigoTransportista": this.user.usuario,
        "listaRecojo": this.listaRecojo
      }

      this.mixOffline(data);

    },

    async sendRequests() {
    
      //Enviar Offline
      await this.sendOffline();
  
      //Enviar Evidencia
      await this.sendEvidencias();

      //Enviar Incidencia
      await this.sendIncidencias();

      localStorage.setItem('pendingRequests', 0);
    
    },

    async sendIncidencias() {

      if (this.incidenciasEnviadas) {
        return; // Evita el envío si las evidencias ya se enviaron
      }

      try {

        const needed = await this.getOfflineTrackingByAction({ action: 'INCIDENCIA_LF' });

        //console.log('needed evidencias', needed);
        if (!needed) {
          return;
        }
      
        const list = await this.obtenerIncidenciaOffline();

        console.log('incidencias', list);
        if (list.length > 0) {

          for (const x of list) {

            console.log('xxxxx ', x);

            const formData = new FormData();
            formData.append('idRutaReferencia', x.idRutaReferencia);
            formData.append('idTipoSeguimiento', x.idTipoSeguimiento);
            formData.append('fechaRegistro', x.fechaRegistro);
            formData.append('hora', x.hora);

            formData.append('latitud', x.latitud);
            formData.append('longitud', x.longitud);

            formData.append('direccion', x.direccion);
            formData.append('descripcion', x.descripcion);
            formData.append('idRutaProgramacion', x.idRutaProgramacion);

            for (const file of x.files) {
              formData.append('files', file.data);
            }

            try {
              const response = await this.postIncidencia(formData);

              if (response.status == 200) {            
                console.log([{action: 'INCIDENCIA_LF', idRutaRecojo: this.$route.params.id}]);
                await this.updateOfflineTracking({action: 'INCIDENCIA_LF', idRutaRecojo: this.$route.params.id, needToSend: false});
                await this.deleteOfflineTracking({action: 'INCIDENCIA_LF', idRutaRecojo: this.$route.params.id, needToSend: false});
                await this.deleteOfflineEvidencia({action: 'INCIDENCIA_LF', idRutaReferencia: this.$route.params.id, needToSend: false});
              }

            } catch (err) {
              //
              console.log(err);
            }


          }

          this.incidenciasEnviadas = true;

          setTimeout(() => {
            location.reload();
          }, 100);
          

        }
        

      } catch (error) {

        console.log(error);

      }

    },

  },
  
  async created() {

    setInterval(() => {
      this.time = new Date();
      //console.log('this.time', this.time);
    }, 1000);

    setInterval(async() => {
      
      this.verifyOffline();

      if (navigator.onLine) {

        // Verificar si hay solicitudes pendientes en el almacenamiento local
        const pendingRequests = JSON.parse(localStorage.getItem('pendingRequests') || 0);

        if (pendingRequests > 0 && !this.isInProcess) {

          this.isInProcess = true;

          //Hay requests por enviar
          this.enableGeoLocation();
          await this.sendRequests();
          await this.actualizarOffline();

          this.isInProcess = false;

        } else {

          //await this.actualizarOffline();

        }
      }

    }, 2000);
  },
  async mounted() {

    this.google = window.google;
    
    //Habilitar geolocalización
    this.enableGeoLocation();

    //Actualizar Offline
    await this.actualizarOffline();

    //Obtener dirección
    if (!this.googleMapsLoaded) {
      this.googleMapsLoaded = true;
      this.getGeoLocation();
    }
    

    let loader = this.$loading.show();

    await this.getList();

    this.isLoading = false;
    loader.hide();

  }
};
</script>
<style lang="scss">

.sticky-footer-box {
  position: sticky;
  bottom: 0px;
  background-color: white;
  width: 100%;
  padding-bottom: 20px;
}

.user-time-modal {
  color: #F78D2C;
}

.dropZoneEvidencias {
  border: 1px dashed #B8B8B8;
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 15px;
  background-color: #fff;
  transition: 0.3s ease all;
  padding: 15px;
}

.camera-button,
.adjunt-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera-button {
  background: #F7F8F2;
  border-radius: 5.22091px;
  border: 1px solid #02a54f !important;
}
.adjunt-button {
  background: #ECF7EA;
  border-radius: 5.22091px;
  border: 1px solid #02a54f !important;
}


.button-file-upload input[type="file"] {
    display: none;
}

.button-file-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  letter-spacing: -0.025rem;
  text-transform: none;
  line-height: 18px;
  width: 100%;
  font-weight: 500;
  background: #ECF7EA;
  border-radius: 5.22091px;
  border: 1px solid #02a54f !important;
  -webkit-appearance: button;
  padding: 0.875rem 4rem !important;
  font-size: 0.875rem;
  color: black;
}


.info-evidencia {
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  color: #6E6E6E;
  margin: 15px 0;
  font-family: "Poppins", sans-serif;
}

#canvas {
  max-width: 100%;
  height: auto;
}

#preview {
  max-width: 100%;
  height: 450px;
  object-fit: cover;
  width: 100%;
}

#video {
  position: absolute;
  top: -9999px;
  left: -9999px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.grid-item-image {
  .grid-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .image {
      width: 40%;
      display: flex;
      justify-content: flex-start;
      img {
        width: 100%;
        height: 120px;
        object-fit: cover;
        border: 1px solid;
      }
    }

    .text-image {
      justify-content: flex-start;
      display: flex;
      width: 50%;
      word-break: break-word;
      p {
        font-size: 12px;
      }
    }

    .delete-action {
      display: flex;
      justify-content: flex-end;
      width: 10%;
    }
  }
}

.metadata-ev-inc {
  
  li {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    span {
      font-size: 13px;
    }
  }
}
</style>