//import setting from '@/services/setting';
import { store } from "@/store/index";

const roles = store.state.auth.token ? store.state.auth.user.roles : [];
/*const roles = [
    { id: 104, nombre: 'Jefatura de Campo' }
]*/

export default {
  install: (app, options) => {
    app.config.globalProperties.$roles = {
        isRoleFound(roleId) {
    
            return (this.isInRoles(roles, roleId)) ? true : false;
    
        },
        isInRoles(rolesArray, roleId) {

            /*let access = rolesArray.filter(x => x.id == roleId);
            return ( roleId == '*' || access.length > 0 ) ?  true : false;*/
    
            let exist = false;
        
            if (roleId == '*') {
        
                exist = true;
        
            } else {
        
                for (let index = 0; index < rolesArray.length; index++) {
        
                    const item = rolesArray[index];
            
                    if (item.id == roleId) {
                        exist = true;
                        break;
                    } else {
                        exist = false;
                    }
        
                }
        
            }
        
            return exist;
    
        },
        getRoles() {
            return roles;
        }
    }
  }
}