import setting from './setting';

export default {

  notificacionResumen: ($axios, data)=>{
    return $axios.get(`${setting.http.base_url}/portal/transportista/notificacion/resumen`, {headers:setting.http.headers()});
  },
  notificacionLista: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/portal/transportista/notificacion/lista`, data, {headers:setting.http.headers()});
  },

  notificacionResumenGanadero: ($axios, data)=>{
    return $axios.get(`${setting.http.base_url}/portal/ganadero/notificacion/resumen`, {headers:setting.http.headers()});
  },
  notificacionListaGanadero: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/portal/ganadero/notificacion/lista`, data, {headers:setting.http.headers()});
  },

}
