import { mapActions, mapGetters } from 'vuex';
import MaestroGanaderoService from '../services/MaestroGanaderoService';
import axios from 'axios';
export default {
    namespaced: true,
    state:{},
    getters:{},
    mutations:{},
    methods:{ },
    actions:{

      async getOptions({state,commit}, data) {
        return await MaestroGanaderoService.getOptions(axios,data);
      },
      async getList({state,commit}, data) {
        return await MaestroGanaderoService.getList(axios,data);
      },
      async getDetalle({state,commit}, data) {
        return await MaestroGanaderoService.getDetalle(axios,data);
      },
      async updateCodSAP({state,commit}, data) {
        return await MaestroGanaderoService.updateCodSAP(axios,data);
      },
      async updateState({state,commit}, data) {
        return await MaestroGanaderoService.updateState(axios,data);
      },
      async updateDatosFinancieros({state,commit}, data) {
        return await MaestroGanaderoService.updateDatosFinancieros(axios,data);
      },
      async updateDatosContactos({state,commit}, data) {
        return await MaestroGanaderoService.updateDatosContactos(axios,data);
      },

      async getListAcopio({state,commit}, data) {
        return await MaestroGanaderoService.getListAcopio(axios,data);
      },
      async downloadReporteAcopio({state,commit}, data) {
        return await MaestroGanaderoService.downloadReporteAcopio(axios,data);
      },
      async getListCalidad({state,commit}, data) {
        return await MaestroGanaderoService.getListCalidad(axios,data);
      },
      async getDetalleCalidad({state,commit}, data) {
        return await MaestroGanaderoService.getDetalleCalidad(axios,data);
      },
      async downloadReporteCalidad({state,commit}, data) {
        return await MaestroGanaderoService.downloadReporteCalidad(axios,data);
      },
      async getListPago({state,commit}, data) {
        return await MaestroGanaderoService.getListPago(axios,data);
      },
      async getDetallePago({state,commit}, data) {
        return await MaestroGanaderoService.getDetallePago(axios,data);
      },
      async downloadContratoPDF({state,commit}, data) {
        return await MaestroGanaderoService.downloadContratoPDF(axios,data);
      },
      async downloadLiquidacionPDF({state,commit}, data) {
        return await MaestroGanaderoService.downloadLiquidacionPDF(axios,data);
      },
      async downloadReportePago({state,commit}, data) {
        return await MaestroGanaderoService.downloadReportePago(axios,data);
      },
      async getOptionsPrestamo({state,commit}, data) {
        return await MaestroGanaderoService.getOptionsPrestamo(axios,data);
      },
      async getListPrestamo({state,commit}, data) {
        return await MaestroGanaderoService.getListPrestamo(axios,data);
      },
      async getDetallePrestamo({state,commit}, data) {
        return await MaestroGanaderoService.getDetallePrestamo(axios,data);
      },
      async downloadReportePrestamos({state,commit}, data) {
        return await MaestroGanaderoService.downloadReportePrestamos(axios,data);
      },      
      async downloadPrestamoPDF({state,commit}, data) {
        return await MaestroGanaderoService.downloadPrestamoPDF(axios,data);
      },
      async downloadReporteDetallePrestamo({state,commit}, data) {
        return await MaestroGanaderoService.downloadReporteDetallePrestamo(axios,data);
      },
      async getOptionsDescuento({state,commit}, data) {
        return await MaestroGanaderoService.getOptionsDescuento(axios,data);
      },
      async getListDescuento({state,commit}, data) {
        return await MaestroGanaderoService.getListDescuento(axios,data);
      },
      async getDetalleDescuento({state,commit}, data) {
        return await MaestroGanaderoService.getDetalleDescuento(axios,data);
      },
      async downloadReporteDescuento({state,commit}, data) {
        return await MaestroGanaderoService.downloadReporteDescuento(axios,data);
      },
      async downloadReporteDetalleDescuento({state,commit}, data) {
        return await MaestroGanaderoService.downloadReporteDetalleDescuento(axios,data);
      },
      async getListActivo({state,commit}, data) {
        return await MaestroGanaderoService.getListActivo(axios,data);
      },
      async getDetalleActivo({state,commit}, data) {
        return await MaestroGanaderoService.getDetalleActivo(axios,data);
      },
      async downloadReporteActivo({state,commit}, data) {
        return await MaestroGanaderoService.downloadReporteActivo(axios,data);
      }

    }
}
