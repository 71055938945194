<template>
  <div class="m-container pb-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="c-card">
          <div class="card-header pb-0">
              <h1 class="mb-4">Devolución de Leche</h1>
              <div class="hr mb-0"></div>
          </div>
          <div class="card-body p-0">
            <div class="w-fixed-s acopio">
              <div class="fixed-section">
                <Form
                  ref="veeForm"
                  :initial-values="formValues"
                  @submit="changeQuincena"
                >
                  <div class="action-box justify-content-start">
                    <div class="grid grid-container-2">
                      <div class="grid-item-1">
                        <SelectButton
                          name="periodo"
                          label="Periodo"
                          v-model="dataSend.quincena"
                          :options="periodOptions"
                          hasCustomId="codigo"
                        />
                      </div>
                      <div class="grid-item-2">
                        <Button
                          class="m-0 primary-button"
                          size="md"
                          type="submit"
                          >Consultar</Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
              <div class="scrollable-content">
                <div class="n-table devolucion-deleche sticky-header" v-if="dataTable.length">
                  <div class="t-header">
                    <div class="t-row">
                      <div class="t-cell">
                          <span class="text-start">FECHA DE<br>DEVOLUCIÓN</span>
                      </div>
                      <div class="t-cell">
                          <span class="text-start">TOTAL KG</span>
                      </div>
                      <div class="t-cell">
                          <span class="text-start">TOTAL PORONGOS</span>
                      </div>
                    </div>
                  </div>
                  <div class="t-body">
                    <template v-for="item in dataTable" :key="item.idDevolucionLeche">
                      <div class="t-row">
                        <div class="t-cell">{{item.fechaDevolucion}}</div>
                        <div class="t-cell">{{item.totalKilos}}</div>
                        <div class="t-cell">{{item.totalPorongos}}</div>
                        <div class="t-cell justify-content-center">
                          <Button
                            class="primary-button m-0"
                            size="md"
                            type="button"
                            @click="getPDF(item.idDevolucionLeche)"
                          >
                            Descargar
                          </Button>
                        </div>
                        <RowAccordion>
                          <template v-slot:title>
                            <ul>
                              <li class="cell">
                                <div class="key">FECHA DE DEVOLUCIÓN</div>
                                <div class="value">{{item.fechaDevolucion}}</div>
                              </li>
                            </ul>
                          </template>
                          <template v-slot:content>
                            <ul>
                              <li class="cell">
                                  <div class="key">TOTAL KG</div>
                                  <div class="value">{{item.totalKilos}}</div>
                              </li>
                              <li class="cell">
                                  <div class="key">TOTAL PORONGOS</div>
                                  <div class="value">{{item.totalPorongos}}</div>
                              </li>
                              <li class="cell">
                                <Button
                                  class="primary-button"
                                  size="md"
                                  type="button"
                                >
                                  Descargar
                                </Button>
                              </li>
                            </ul>
                          </template>
                        </RowAccordion>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { markRaw } from "vue";
import { Form, Field } from "vee-validate";
import TextInput from "@/components/Inputs/TextInput";
import SelectButton from "@/components/Inputs/SelectButton";
import RowAccordion from "@/components/Accordion/RowAccordion";
import Button from "@/components/Inputs/Button";
import { mapActions } from 'vuex';

export default {
  components: {
    Form,
    Field,
    SelectButton,
    TextInput,
    RowAccordion,
    Button
  },
  name: "Devolucion de Leche",
  methods: {
    ...mapActions({
      getQuincenas: "mantenimiento/getQuincenas",
      devolucionLecheList: 'calidad/devolucionLecheList',
      devolucionLechePDF: 'calidad/devolucionLechePDF',
    }),
    getDataDevolucionLeche(data) {
      this.devolucionLecheList(data).then((response) => {
        if (response.status == 200) {
          this.dataTable = response.data
        }
      }).catch((err) => {
      //console.log(err.message);
      });
    },
    changeQuincena() {
      this.getDataDevolucionLeche(this.dataSend)
    },
    getPDF(id) {
      this.devolucionLechePDF(id).then((response) => {
        if (response.status == 200) {
          console.log(response.data)
          const file = new Blob([response.data], {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          let todayDate = this.$filters.generateFileDate();
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', `devolucion_leche_${id}-${todayDate}.pdf`);
          document.body.appendChild(link);
          link.click();
        }
      }).catch((err) => {
      //console.log(err.message);
      });
    }
  },
  data() {
    const formValues = {

    }
    return {
      formValues,
      periodOptions: [],
      dataSend: {
        quincena: "",
      },
      dataTable: [
        {
          "idDevolucionLeche": 77,
          "totalKilos": 2,
          "totalPorongos": 4,
          "fechaDevolucion": "22/03/2023"
        }
      ]
    }
  },
  async mounted() {
    let loader = this.$loading.show();
    const dataPeriods = {
      ultimasQuincenas: true,
    };
    await this.getQuincenas(dataPeriods).then((response) => {
      if (response.status == 200) {
        this.periodOptions = response.data;

        this.formValues.periodo = this.periodOptions[0].codigo;
        this.dataSend.quincena = this.periodOptions[0].codigo;

        this.changeQuincena();

        loader.hide();
      }
    });
    
  },
}

</script>
