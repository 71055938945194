import { mapActions, mapGetters } from 'vuex';
import PrestamosService from '../services/PrestamosService';
import axios from 'axios';
export default {
    namespaced: true,
    state:{},
    getters:{},
    mutations:{},
    methods:{ },
    actions:{
      async getListSolicitudes({state,commit}, data) {
        return await PrestamosService.getListSolicitudes(axios,data);
      },
      async getOptions({state,commit}, data) {
        return await PrestamosService.getOptions(axios,data);
      },
      async getGanadero({state,commit}, data) {
        return await PrestamosService.getGanadero(axios,data);
      },
      async postPrestamo({state,commit}, data) {
        return await PrestamosService.postPrestamo(axios,data);
      },
      async getPrestamo({state,commit}, data) {
        return await PrestamosService.getPrestamo(axios,data);
      },
      async updatePrestamo({state,commit}, data) {
        return await PrestamosService.updatePrestamo(axios,data);
      },
      async updatePrestamoEstado({state,commit}, data) {
        return await PrestamosService.updatePrestamoEstado(axios,data);
      },
      async getListReporte({state,commit}, data) {
        return await PrestamosService.getListReporte(axios,data);
      },
      async getDetalleReporte({state,commit}, data) {
        return await PrestamosService.getDetalleReporte(axios,data);
      },

    }
}
