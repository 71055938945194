<template>
  <div class="m-container container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="c-card">
          <div class="card-header pb-0">
            <h1 class="mb-4">Acopio</h1>
            <div class="hr mb-0"></div>
          </div>

          <div class="card-body p-0 no-scroll">
            <ul class="tabs-selector nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="first-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#first-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="first-tab-pane"
                  aria-selected="true"
                >
                  Quincena
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="second-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#second-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="second-tab-pane"
                  aria-selected="false"
                >
                  Promedio Diario
                </button>
              </li>
            </ul>

            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane show active"
                id="first-tab-pane"
                role="tabpanel"
                aria-labelledby="first-tab"
                tabindex="0"
              >
                <div class="tab-body w-fixed-s acopio">
                  <div class="fixed-section">
                    <Form
                      :validation-schema="schema"
                      ref="veeForm"
                      :initial-values="formValues"
                      v-slot="{ meta }"
                    >
                      <div class="action-box justify-content-start">
                        <div class="grid grid-container-1">
                          <div class="grid-item-1">
                            <SelectButton
                              name="anio"
                              label="año"
                              v-model="model.anio"
                              @change="getDataByUserSelection(model.anio)"
                              :options="yearsOptions"
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>

                  <div class="scrollable-content">
                    <div class="row">
                      <h3 class="title-acceso-ganaderos mb-4">
                        Gráfica de acopio por quincena
                      </h3>

                      <template v-if="model.anio == 0">
                        <div class="col-12">
                          <GradientLineChart
                            id="acumulado"
                            title="Acumulado por Quincena"
                            :yAxisTicksDecimals="false"
                            :chartDataToChar="true"
                            description=""
                            :enlarge="true"
                            @open-modal="openModal"
                            :chart="{
                              labels: this.labels,
                              yAxisTitle: 'KG',
                              suggestedMin: 9,
                              suggestedMax: 15,
                              datasets: allYearsDatasets,
                            }"
                          />
                        </div>
                      </template>

                      <template v-else>
                        <div class="col-12">
                          <GradientLineChart
                            id="acumulado"
                            :title="`Acumulado por Quincena ${this.model.anio}`"
                            :enlarge="true"
                            :yAxisTicksDecimals="false"
                            @open-modal="openModal"
                            :chartDataToChar="true"
                            description=""
                            :legend="false"
                            :chart="{
                              labels: this.labels,
                              yAxisTitle: 'KG',
                              datasets: [
                                {
                                  legendTitle: this.model.anio,
                                  data: dataAcopioTotal,
                                  borderColor: '#F78D2C',
                                },
                              ],
                            }"
                          />
                        </div>
                      </template>
                    </div>

                    <div class="row mb-5">
                      <div class="col-md-12">
                        <h3 class="title-acceso-ganaderos mt-5 mb-4">
                          Detalle de acopio por quincena
                        </h3>

                        <template v-if="model.anio == 0">
                          <div class="b-table blue acceso-ganaderos-acopio">
                            <div class="t-header">
                              <tr class="t-row">
                                <th class="t-cell">QUINCENA</th>
                                <th
                                  class="t-cell"
                                  v-for="year in getYears2()"
                                  :key="year"
                                >
                                  {{ year }}
                                </th>
                              </tr>
                            </div>
                            <div class="t-body">
                              <tr
                                v-for="quin in getQuincenas2()"
                                :key="quin"
                                class="t-row"
                              >
                                <td class="t-cell">{{ quin }}</td>
                                <td
                                  v-for="year in getYears2()"
                                  :key="year"
                                  class="t-cell"
                                >
                                  <span v-if="getData2(year, quin , 'total')">{{getData2(year, quin , 'total')}}</span>
                                </td>
                              </tr>
                            </div>
                          </div>

                          <!-- dgb1 -->
                          <div class="t-body" v-if="multidimensionalTable.length == 0">
                            <div class="t-body no-records">
                              <p>No existe data para esta fecha</p>
                            </div>
                          </div>
                          
                        </template>

                        <template v-else>
                          <div class="b-table blue mt-5 acceso-ganaderos-acopio">
                            <div class="t-header">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">total</div>
                                <div class="t-cell">promedio<br />diario</div>
                                <div class="t-cell">días efectivos<br />de quincena</div>
                                <div class="t-cell"></div>
                              </div>
                            </div>

                            <div class="t-body">
                              <div
                                class="t-row"
                                v-for="(record, index) in dataAcopio"
                                :key="index"
                              >
                                <div class="t-cell">
                                  <span class="mobile-cell-title">quincena</span
                                  >{{
                                    this.$filters.getPeriod(record.quincena, record.mes)
                                  }}
                                </div>
                                <div class="t-cell">
                                  <span class="mobile-cell-title">total</span
                                  >{{
                                    record.total ? $filters.toChar(record.total) : "-"
                                  }}
                                </div>
                                <div class="t-cell">
                                  <span class="mobile-cell-title">promedio diario</span
                                  >{{
                                    record.promedio
                                      ? $filters.toChar(record.promedio)
                                      : "-"
                                  }}
                                </div>
                                <div class="t-cell">
                                  <span class="mobile-cell-title"
                                    >días efectivos de quincena</span
                                  >{{ record.dias ? record.dias : "-" }}
                                </div>
                                <div class="t-cell action">
                                  <Button
                                    class="m-0 primary-button"
                                    size="sm"
                                    :disabled="!record.id"
                                    @click="verRegistro(record.id)"
                                    >Ver detalle</Button
                                  >
                                </div>
                              </div>
                            </div>

                            <div class="t-body" v-if="dataAcopio.length == 0">
                              <div class="t-body no-records">
                                <p>No existe data para esta fecha</p>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>

                    <div class="form-actions mb-5">
                      <Button
                        class="mt-3 primary-button shadow-none"
                        size="lg"
                        type="button"
                        @click="downloadAcopioExcel()"
                        >Exportar</Button
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane"
                id="second-tab-pane"
                role="tabpanel"
                aria-labelledby="second-tab"
                tabindex="0"
              >
                <div class="tab-body w-fixed-s calidad-pagos">
                  <div class="fixed-section">
                    <Form
                      :validation-schema="schema"
                      ref="veeForm"
                      :initial-values="formValuesMontoFacturado"
                      v-slot="{ meta }"
                    >
                      <div class="action-box justify-content-start">
                        <div class="grid grid-container-1">
                          <div class="grid-item-1">
                            <SelectButton
                              name="anio"
                              label="año"
                              v-model="model.anioMontoFacturado"
                              :options="yearsOptions2"
                              @change="
                                getDataByUserSelectionPromedioDiario(
                                  model.anioMontoFacturado
                                )
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>

                  <div class="scrollable-content">
                    <div class="row">
                      <h3 class="title-acceso-ganaderos mb-4">
                        Gráfica de acopio por quincena histórico - prom diario (días
                        efectivos)
                      </h3>

                      <div class="col-12">
                        <template v-if="model.anioMontoFacturado == 0">
                          <GradientLineChart
                            id="prom_diario"
                            title="Acumulado por Promedio diario"
                            :yAxisTicksDecimals="false"
                            :chartDataToChar="true"
                            description=""
                            :enlarge="true"
                            @open-modal="openModal"
                            :chart="{
                              labels: this.labels,
                              yAxisTitle: 'KG',
                              suggestedMin: 9,
                              suggestedMax: 15,
                              datasets: allYearsDatasetsPromedio,
                            }"
                          />
                        </template>

                        <template v-else>
                          <GradientLineChart
                            id="prom_diario"
                            :enlarge="true"
                            @open-modal="openModal"
                            :yAxisTicksDecimals="false"
                            title="Promedio diario (Días efectivos)"
                            :dataSymbolPosition="'start'"
                            :chartDataToChar="true"
                            :legend="false"
                            :chart="{
                              labels: this.labels,
                              yAxisTitle: 'KG',
                              suggestedMin: 0,
                              datasets: [
                                {
                                  legendTitle: this.model.anio,
                                  data: dataAcopioDias,
                                  borderColor: '#1D4193',
                                },
                              ],
                            }"
                          />
                        </template>
                      </div>
                    </div>

                    <div class="row mb-5">
                      <div class="col-md-12">
                        <h3 class="title-acceso-ganaderos mt-5 mb-4">
                          Detalle promedio diario (Kg leche)
                        </h3>

                        <template v-if="model.anioMontoFacturado == 0">
                          <div class="b-table blue mt-5 acceso-ganaderos-acopio">
                            <!-- dgb prom -->
                            <div class="t-header">
                              <tr class="t-row">
                                <th class="t-cell">QUINCENA</th>
                                <th
                                  class="t-cell"
                                  v-for="year in getYears2()"
                                  :key="year"
                                >
                                  Promedio diario {{ year }}
                                </th>
                              </tr>
                            </div>
                            <div class="t-body">
                              <tr
                                v-for="quin in getQuincenas2()"
                                :key="quin"
                                class="t-row"
                              >
                                <td class="t-cell">{{ quin }}</td>
                                <td
                                  v-for="year in getYears2()"
                                  :key="year"
                                  class="t-cell"
                                >
                                  <span v-if="getData2(year, quin, 'promedio')">{{
                                    getData2(year, quin , 'promedio')
                                  }}</span>
                                </td>
                              </tr>
                            </div>
                            <!-- </div> -->

                            <!-- dgb prom -->

                            <!-- <div class="t-header">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">promedio diario 2021</div>
                                <div class="t-cell">promedio diario 2022</div>
                                <div class="t-cell">promedio diario 2023</div>
                                <div class="t-cell"></div>
                              </div>
                            </div>

                            <div class="t-body">
                              <div
                                class="t-row"
                                v-for="(i, index) in multidimensionalTablePromedio"
                                :key="index"
                              >
                                <div class="t-cell">
                                  <span class="mobile-cell-title">quincena</span>
                                  {{ i.half + "Q " + $filters.getMonthAbr(i.month) }}
                                </div>
                                <div class="t-cell">
                                  <span class="mobile-cell-title"
                                    >promedio diario 2021</span
                                  >{{
                                    $filters.getMonth(i.col1)
                                      ? $filters.toChar(i.col1)
                                      : "-"
                                  }}
                                </div>
                                <div class="t-cell">
                                  <span class="mobile-cell-title"
                                    >promedio diario 2022</span
                                  >{{
                                    $filters.getMonth(i.col2)
                                      ? $filters.toChar(i.col2)
                                      : "-"
                                  }}
                                </div>
                                <div class="t-cell">
                                  <span class="mobile-cell-title"
                                    >promedio diario 2023</span
                                  >{{
                                    $filters.getMonth(i.col3)
                                      ? $filters.toChar(i.col3)
                                      : "-"
                                  }}
                                </div>
                                <div class="t-cell action"></div>
                              </div>
                            </div> -->

                            <div
                              class="t-body"
                              v-if="multidimensionalTablePromedio.length == 0"
                            >
                              <div class="t-body no-records">
                                <p>No existe data para esta fecha</p>
                              </div>
                            </div>
                          </div>
                        </template>

                        <template v-else>
                          <div class="b-table blue acceso-ganaderos-acopio">
                            <div class="t-header">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">total</div>
                                <div class="t-cell">promedio<br />diario</div>
                                <div class="t-cell">días efectivos<br />de quincena</div>
                                <div class="t-cell"></div>
                              </div>
                            </div>

                            <div class="t-body">
                              <div
                                class="t-row"
                                v-for="(record, index) in dataAcopioDiario"
                                :key="index"
                              >
                                <div class="t-cell">
                                  <span class="mobile-cell-title">fecha</span
                                  >{{
                                    record.quincena +
                                    "Q " +
                                    $filters.getMonthAbr(record.mes)
                                  }}
                                </div>
                                <div class="t-cell">
                                  <span class="mobile-cell-title">total</span
                                  >{{
                                    record.total ? $filters.toChar(record.total) : "-"
                                  }}
                                </div>
                                <div class="t-cell">
                                  <span class="mobile-cell-title">promedio diario</span
                                  >{{
                                    record.promedio
                                      ? $filters.toChar(record.promedio)
                                      : "-"
                                  }}
                                </div>
                                <div class="t-cell">
                                  <span class="mobile-cell-title"
                                    >días efectivos de quincena</span
                                  >{{ record.dias ? record.dias : "-" }}
                                </div>
                                <div class="t-cell action">
                                  <Button
                                    class="m-0 primary-button"
                                    size="sm"
                                    :disabled="!record.id"
                                    @click="verRegistro(record.id)"
                                    >Ver detalle</Button
                                  >
                                </div>
                              </div>
                            </div>

                            <div class="t-body" v-if="dataAcopioDiario.length == 0">
                              <div class="t-body no-records">
                                <p>No existe data para esta fecha</p>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>

                    <div class="form-actions mb-5">
                      <Button
                        class="mt-3 primary-button shadow-none"
                        size="lg"
                        type="button"
                        @click="downloadAcopioExcel()"
                        >Exportar</Button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="fade">
      <CustomModal
        :show="dataModal.showModal"
        :isHeader="false"
        :isFooter="false"
        :width="1600"
        :title="dataModal.title"
        v-on:modal:close="hideModal()"
      >
        <template v-slot:body>
          <template v-if="model.anio == 0">
            <div class="col-12">
              <GradientLineChart
                id="acumulado_modal"
                title="Acumulado por Quincena"
                :yAxisTicksDecimals="false"
                :chartDataToChar="true"
                description=""
                :enlarge="false"
                @open-modal="openModal"
                :chart="{
                  labels: this.labels,
                  yAxisTitle: 'KG',
                  suggestedMin: 9,
                  suggestedMax: 15,
                  datasets: allYearsDatasets,
                }"
              />
            </div>
          </template>

          <template v-else>
            <div class="col-12">
              <GradientLineChart
                id="acumulado_modal"
                :title="`Acumulado por Quincena ${this.model.anio}`"
                :enlarge="true"
                :yAxisTicksDecimals="false"
                @open-modal="openModal"
                :chartDataToChar="true"
                description=""
                :legend="false"
                :chart="{
                  labels: this.labels,
                  yAxisTitle: 'KG',
                  datasets: [
                    {
                      legendTitle: this.model.anio,
                      data: dataAcopioTotal,
                      borderColor: '#F78D2C',
                    },
                  ],
                }"
              />
            </div>
          </template>

          <div class="form-actions">
            <Button
              class="mt-3 ghost-button shadow-none"
              size="lg"
              type="button"
              @click="hideModal()"
              >Cerrar</Button
            >
          </div>
        </template>
      </CustomModal>
    </transition>

    <transition name="fade">
      <CustomModal
        :show="dataModal2.showModal"
        :isHeader="false"
        :isFooter="false"
        :width="1600"
        :title="dataModal2.title"
        v-on:modal:close="hideModal2()"
      >
        <template v-slot:body>
          <template v-if="model.anioMontoFacturado == 0">
            <GradientLineChart
              id="prom_diario_modal"
              title="Acumulado por Promedio diario"
              :yAxisTicksDecimals="false"
              :chartDataToChar="true"
              description=""
              :enlarge="false"
              @open-modal="openModal"
              :chart="{
                labels: this.labels,
                yAxisTitle: 'KG',
                suggestedMin: 9,
                suggestedMax: 15,
                datasets: allYearsDatasetsPromedio,
              }"
            />
          </template>

          <template v-else>
            <GradientLineChart
              id="prom_diario_modal"
              :enlarge="false"
              :chartDataToChar="true"
              :yAxisTicksDecimals="false"
              @open-modal="openModal"
              title="Promedio diario (Días efectivos)"
              :dataSymbolPosition="'start'"
              :legend="false"
              :chart="{
                labels: this.labels,
                yAxisTitle: 'KG',
                suggestedMin: 0,
                datasets: [
                  {
                    legendTitle: this.model.anio,
                    data: dataAcopioDias,
                    borderColor: '#1D4193',
                  },
                ],
              }"
            />
          </template>

          <div class="form-actions">
            <Button
              class="mt-3 ghost-button shadow-none"
              size="lg"
              type="button"
              @click="hideModal2()"
              >Cerrar</Button
            >
          </div>
        </template>
      </CustomModal>
    </transition>
  </div>
</template>

<script>
const CACHE_NAME = "site-cache-v2";

import { markRaw } from "vue";
import { Form, Field } from "vee-validate";
import { mapActions } from "vuex";
import GradientLineChart from "@/components/Charts/GradientLineChart.vue";
import TextInput from "@/components/Inputs/TextInput";
import FilterBox from "@/components/FilterBox";
import DateInput from "@/components/Inputs/Date";
import SelectButton from "@/components/Inputs/SelectButton";
import Button from "@/components/Inputs/Button";
import Pagination from "@/components/Pagination.vue";
import CustomModal from "@/components/CustomModal.vue";
import ConfirmationScreen from "@/components/ConfirmationScreen";
import Tag from "@/components/Tags/Tag";
import RowAccordion from "@/components/Accordion/RowAccordion";
import RadioButton from "@/components/Inputs/RadioButtonOriginalType.vue";
import Checkbox from "@/components/Inputs/Checkbox";
import * as Yup from "yup";

export default {
  components: {
    GradientLineChart,
    TextInput,
    FilterBox,
    SelectButton,
    Button,
    Pagination,
    CustomModal,
    ConfirmationScreen,
    RadioButton,
    Checkbox,
    Tag,
    RowAccordion,
    DateInput,
    Form,
    Field,
  },
  name: "Aprobacion de Ganadero",
  data() {
    const schema = markRaw(
      Yup.object().shape({
        fechaInicio: Yup.string().required(),
        fechaFin: Yup.string().required(),
        zona: Yup.string().nullable(),
        codInterno: Yup.string().nullable(),
      })
    );
    const formValues = {
      fechaInicio: "",
      fechaFin: "",
      zona: "",
      codInterno: "",
    };

    const formValuesMontoFacturado = {};

    return {
      schema,
      formValues,
      formValuesMontoFacturado,

      labels: this.$filters.getPeriodsLabels(),

      aniosOptions: this.$filters.getYears(2020),
      records: [],
      dataAcopio: [],
      dataAcopioTotal: [],
      dataAcopioDias: [],

      dataAcopioDiario: [],
      dataAcopioTotalDiario: [],

      periodOptions: [],

      completeData: [],

      allYearsDatasets: [],
      allYearsDatasetsPromedio: [],

      yearsOptions: [],

      filteredYearsOptions: [],
      yearsServer: [],

      yearsOptions2: [],
      yearsServer2: [],

      dataRaw: [],
      dataRawPromedioDiario: [],

      dataAcopio: [],
      dataAcopioMontos: [],
      dataMFAnteriorMontos: [],

      multidimensionalTable: [],
      multidimensionalTablePromedio: [],

      processedData2: [],
      years2: [],
      quincenas2: [],

      model: {
        anio: "",
      },
      dataModal: {
        showModal: false,
        title: "",
        active: "",
        request: "",
        response: "",
      },
      dataModal2: {
        showModal: false,
        title: "",
        active: "",
        request: "",
        response: "",
      },
    };
  },
  computed: {
    filteredYearsOptions() {
      return this.$filters
        .getYears(2021)
        .filter((year) => year.id !== 0)
        .sort((a, b) => a.nombre - b.nombre);
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      getAcopioQuincena: "acopio/getAcopioQuincena",
      getAcopioDiario: "acopio/getAcopioDiario",
      downloadAcopio: "acopio/downloadAcopio",
      getQuincenas: "mantenimiento/getQuincenas",
    }),
    verRegistro(id) {
      this.$router.push({
        name: "Reporte de acopio diario",
        params: { id: id },
      });
    },
    openModal(id) {
      if (id == "acumulado") {
        this.dataModal.showModal = true;
      } else if (id == "prom_diario") {
        this.dataModal2.showModal = true;
      }
    },
    hideModal() {
      this.dataModal.showModal = false;
    },
    hideModal2() {
      this.dataModal2.showModal = false;
    },
    downloadAcopioExcel() {
      const data = {
        years: this.model.anio == 0 ? this.yearsServer : this.model.anio,
      };

      this.downloadAcopio(data).then((response) => {
        if (response.status == 200) {
          let type = response.headers["content-type"];

          const file = new Blob([response.data], { type: type });
          const fileURL = URL.createObjectURL(file);

          let todayDate = this.$filters.generateFileDate();

          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute("download", `reporte-acopio_${todayDate}.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      });
    },

    createMultidimensionalTable(oData) {
      // parse years data
      let yMax = new Date().getFullYear();
      let yMin = yMax - 2;

      let oYears = [];
      for (let y = yMin; y <= yMax; y++) {
        let dataYear = findByYear(y, oData);
        oYears[y] = dataYear;
      }
      // console.log('dgb this.oYears:',oYears.filter(e=>!!e));

      // make months data
      let oMonth = [];

      for (let m = 1; m <= 12; m++) {
        oMonth.push({
          half: 1,
          month: m,
          col1: getByHalfMonth(1, m, oYears[yMin]),
          col2: getByHalfMonth(1, m, oYears[yMin + 1]),
          col3: getByHalfMonth(1, m, oYears[yMax]),
        });
        oMonth.push({
          half: 2,
          month: m,
          col1: getByHalfMonth(2, m, oYears[yMin]),
          col2: getByHalfMonth(2, m, oYears[yMin + 1]),
          col3: getByHalfMonth(2, m, oYears[yMax]),
        });
      }

      function findByYear(year, data) {
        let result = [];
        for (var key in data) {
          if (data.hasOwnProperty(key) && data[key].year === year) {
            result = data[key].data;
          }
        }
        return result;
      }

      function getByHalfMonth(half, month, data) {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            if (item.mes == month && item.numeroQuincena == half) {
              return item.total;
            }
          }
        }
        return "";
      }

      this.multidimensionalTable = oMonth;

      // console.log('dgb this.multidimensionalTable:',this.multidimensionalTable);
    },

    createMultidimensionalTablePromedio(oData) {
      // parse years data
      let yMax = new Date().getFullYear();
      let yMin = yMax - 2;

      let oYears = [];
      for (let y = yMin; y <= yMax; y++) {
        let dataYear = findByYear(y, oData);
        oYears[y] = dataYear;
      }

      // make months data
      let oMonth = [];

      for (let m = 1; m <= 12; m++) {
        oMonth.push({
          half: 1,
          month: m,
          col1: getByHalfMonth(1, m, oYears[yMin]),
          col2: getByHalfMonth(1, m, oYears[yMin + 1]),
          col3: getByHalfMonth(1, m, oYears[yMax]),
        });
        oMonth.push({
          half: 2,
          month: m,
          col1: getByHalfMonth(2, m, oYears[yMin]),
          col2: getByHalfMonth(2, m, oYears[yMin + 1]),
          col3: getByHalfMonth(2, m, oYears[yMax]),
        });
      }

      function findByYear(year, data) {
        let result = [];
        for (var key in data) {
          if (data.hasOwnProperty(key) && data[key].year === year) {
            result = data[key].data;
          }
        }
        return result;
      }

      function getByHalfMonth(half, month, data) {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            if (item.mes == month && item.numeroQuincena == half) {
              return item.promedio;
            }
          }
        }
        return "";
      }

      this.multidimensionalTablePromedio = oMonth;
    },

    //Obtener dato por año
    getDataByUserSelection(year) {
      this.dataAcopio = [];
      this.dataAcopioTotal = [];

      if (year == 0) {
        const data = this.dataRaw;

        this.createMultidimensionalTable(data);

        var dataset = [];

        var completeData = [];

        this.yearsOptions.map((x, i) => {
          const dataResponse = this.$filters.getDataByYear(data, x.id);
          const dataAcopioTotal = this.$filters.getAcopioTotal(
            dataResponse,
            parseInt(this.model.anio)
          );

          if (x.id !== 0 && x !== null) {
            let borderColor = "";

            if (i == 1) {
              borderColor = "#F78D2C";
            } else if (i == 2) {
              borderColor = "#1D4193";
            } else if (i == 3) {
              borderColor = "#07C25E";
            }

            dataset.push({
              legendTitle: x.nombre,
              data: dataAcopioTotal,
              borderColor: borderColor,
            });

            const dataTemp = this.$filters.fillData(dataResponse, x.id);

            if (completeData[x.id] === undefined) {
              completeData[x.id] = [];
            }

            completeData[x.id].push(dataTemp);
          }
        });

        this.allYearsDatasets = dataset;
      } else {
        const data = this.dataRaw;
        const dataResponse = this.$filters.getDataByYear(data, year);

        this.dataAcopio = this.$filters.getAcopioData(
          dataResponse,
          parseInt(this.model.anio)
        );
        this.dataAcopioTotal = this.$filters.getAcopioTotal(
          dataResponse,
          parseInt(this.model.anio)
        );
      }
    },

    getDataByUserSelectionPromedioDiario(year) {
      this.dataAcopioDiario = [];
      this.dataAcopioTotalDiario = [];
      this.dataAcopioDias = [];

      if (year == 0) {
        const data = this.dataRaw;

        this.createMultidimensionalTablePromedio(data);

        var dataset = [];

        var completeData = [];

        this.yearsOptions.map((x, i) => {
          const dataResponse = this.$filters.getDataByYear(data, x.id);
          const dataPromedioDiarioTotal = this.$filters.getAcopioDias(
            dataResponse,
            parseInt(this.model.anio)
          );

          if (x.id !== 0 && x !== null) {
            let borderColor = "";

            if (i == 1) {
              borderColor = "#F78D2C";
            } else if (i == 2) {
              borderColor = "#1D4193";
            } else if (i == 3) {
              borderColor = "#07C25E";
            }

            dataset.push({
              legendTitle: x.nombre,
              data: dataPromedioDiarioTotal,
              borderColor: borderColor,
            });
          }
        });

        this.allYearsDatasetsPromedio = dataset;
      } else {
        const data = this.dataRaw;
        const dataResponse = this.$filters.getDataByYear(data, year);

        this.dataAcopioDiario = this.$filters.getAcopioData(
          dataResponse,
          parseInt(this.model.anio)
        );
        this.dataAcopioTotalDiario = this.$filters.getAcopioTotal(
          dataResponse,
          parseInt(this.model.anio)
        );
        this.dataAcopioDias = this.$filters.getAcopioDias(
          dataResponse,
          parseInt(this.model.anio)
        );
      }
    },
    //Obtener Acopio por primera vez
    getAcopio() {
      this.dataAcopio = [];
      this.dataAcopioTotal = [];
      this.dataAcopioDias = [];

      // Intenta obtener los datos del caché
      caches.open(CACHE_NAME).then((cache) => {
        const cacheUrl = "/offline/acopioquincena";

        cache
          .match(cacheUrl)
          .then((response) => {
            if (response) {
              return response.json();
            }
          })
          .then((data) => {
            if (data) {
              // Los datos están disponibles en caché
              this.dataRaw = data;

              this.getDataByUserSelection(this.model.anio);
            } else {
            }
          });
      });

      const data = {
        years: this.yearsServer,
      };

      this.getAcopioQuincena(data)
        .then((response) => {
          if (response.status == 200) {
            caches.open(CACHE_NAME).then((cache) => {
              cache.put(
                "/offline/acopioquincena",
                new Response(JSON.stringify(response.data))
              );
            });

            console.log("acopio", response.data);
            let data = response.data;

            this.dataRaw = data;
            this.getDataByUserSelection(this.model.anio);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    },

    getPromedioDiario() {
      // Intenta obtener los datos del caché
      caches.open(CACHE_NAME).then((cache) => {
        const cacheUrl = "/offline/acopioquincena";

        cache
          .match(cacheUrl)
          .then((response) => {
            if (response) {
              return response.json();
            }
          })
          .then((data) => {
            if (data) {
              // Los datos están disponibles en caché
              this.dataRaw = data;

              this.getDataByUserSelectionPromedioDiario(this.model.anioMontoFacturado);
            } else {
            }
          });
      });

      const data = {
        years: this.yearsServer,
      };

      this.getAcopioQuincena(data)
        .then((response) => {
          if (response.status == 200) {
            caches.open(CACHE_NAME).then((cache) => {
              cache.put(
                "/offline/acopioquincena",
                new Response(JSON.stringify(response.data))
              );
            });

            console.log("acopio", response.data);
            let data = response.data;

            this.dataRaw = data;
            this.getDataByUserSelectionPromedioDiario(this.model.anioMontoFacturado);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    },


    getYears2() {
      return this.dataRaw.map((item) => item.year).sort((a, b) => a - b);
    },
    
    getQuincenas2() {
      let quincenasSet = new Set();
      this.dataRaw.forEach((item) => {
        item.data.forEach((quin) => {
          quincenasSet.add(quin.quincena);
        });
      });
      return Array.from(quincenasSet).sort((a, b) => {
        let [aNum, aMes] = a.split(" ");
        let [bNum, bMes] = b.split(" ");
        return (
          new Date(2020, this.mesANumero2(aMes) - 1, 1) -
            new Date(2020, this.mesANumero2(bMes) - 1, 1) || aNum - bNum
        );
      });
      //.reverse();
    },
    mesANumero2(mes) {
      const meses = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ];
      return meses.indexOf(mes) + 1;
    },

    getData2(year, quincena, total) {
      let yearData = this.dataRaw.find((item) => item.year === year);
      if (yearData) {
        let data = yearData.data.find((quin) => quin.quincena === quincena);
        if (data) {
          console.log("dgb data:::", data);
          if (total === "total") {
            return this.formatNumber(data.total);
          } else {
            return this.formatNumber(data.promedio);
          }
        } else {
          return "-";
        }
      }
      return null;
    },
    formatNumber(number) {
      return new Intl.NumberFormat("es-MX", {
        style: "decimal",
        minimumFractionDigits: 0,
      }).format(number);
    },



  },
  async mounted() {
    let loader = this.$loading.show();

    ///////////////////////////////////////////////////////////
    const dateFilters = this.$filters.getYearsV4();

    this.yearsOptions = dateFilters.yearsOptions; // this.$filters.getYears(2020)//dgb ;
    this.yearsServer = dateFilters.yearsServer;

    ///////////////////////////////////////////////////////////

    //let dateFilters = this.$filters.getCurrentDatePeriodFilter();

    this.formValues.anio = this.yearsOptions[0].id;
    this.model.anio = this.yearsOptions[0].id;

    const dataPeriods = {
      ultimasQuincenas: true,
    };

    await this.getQuincenas(dataPeriods).then((response) => {
      if (response.status == 200) {
        this.periodOptions = response.data;
      }
    });

    const dateFilters2 = this.$filters.getYearsV4();

    this.yearsOptions2 = dateFilters2.yearsOptions;
    this.yearsServer2 = dateFilters2.yearsServer;

    /*console.log('this.yearsOptions2', this.yearsOptions2);
    console.log('this.yearsServer2', this.yearsServer2);*/

    this.formValues.anioMontoFacturado = this.yearsOptions2[0].id; //this.periodOptions[0].codigo;
    this.model.anioMontoFacturado = this.yearsOptions2[0].id; //this.periodOptions[0].codigo;

    this.getAcopio();
    this.getPromedioDiario();
    ///////////////////////////////////////////////////////////

    loader.hide();
  },
};
</script>
