<template>
    <div class="m-container container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="c-card">
  
            <div class="card-header pb-0">
              <h1 class="mb-4">Calidad de leche</h1>
              <div class="hr mb-0"></div>
            </div>
  
            <div class="card-body no-scroll pt-4">
  
            <ul class="tabs-selector nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="first-tab" data-bs-toggle="tab" data-bs-target="#first-tab-pane" type="button" role="tab" aria-controls="first-tab-pane" aria-selected="true">Sólidos Totales</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="second-tab" data-bs-toggle="tab" data-bs-target="#second-tab-pane" type="button" role="tab" aria-controls="second-tab-pane" aria-selected="false">R. Bacteriano</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="third-tab" data-bs-toggle="tab" data-bs-target="#third-tab-pane" type="button" role="tab" aria-controls="third-tab-pane" aria-selected="false">Células somáticas</button>
              </li>
            </ul>
  
            <div class="tab-content" id="nav-tabContent">
  
              <div class="tab-pane show active" id="first-tab-pane" role="tabpanel" aria-labelledby="first-tab" tabindex="0">
  
                <div class="tab-body w-fixed-s calidad-pagos">
                  
                  <div class="fixed-section">

                    <Form
                      :validation-schema="schema"
                      ref="veeForm"
                      :initial-values="formValuesSolidos"
                      v-slot="{ meta }"
                    >
    
                      <div class="action-box justify-content-start">
                        <div class="grid grid-container-1">
                          <div class="grid-item-1">
                            <SelectButton
                              name="anio"
                              label="año"
                              v-model="model.anioSolidos"
                              @change="getSolidos()"
                              :options="yearsOptions"
                            />
                          </div>
                        </div>
                      </div>
    
                    </Form>

                  </div>
                  
                  <div class="scrollable-content">

                    <h3 class="title-acceso-ganaderos mb-4">Gráfica de Sólidos totales por quincena</h3>

                    <template v-if="model.anioSolidos == 0">

                      <GradientLineChart
                        id="solidos_totales"
                        title="Sólidos Totales"
                        :yAxisTicksDecimals="true"
                        description=""
                        :enlarge="true"
                        @open-modal="openModal"
                        :chart="{
                          labels: this.labels,
                          yAxisTitle: '%',
                          suggestedMin: 11,
                          suggestedMax: 12,
                          datasets: dataPointsSolidos
                        }"
                      />

                    </template>


                    <template v-else>

                      <GradientLineChart
                        id="solidos_totales"
                        title="Sólidos Totales"
                        :yAxisTicksDecimals="true"
                        :enlarge="true"
                        @open-modal="openModal"
                        description=""
                        :chart="{
                          labels: this.labels,
                          yAxisTitle: '%',
                          suggestedMin: 11,
                          suggestedMax: 12,
                          datasets: [
                            {
                              legendTitle: this.model.anioSolidos,
                              data: dataPointsSolidosUNO,
                              borderColor: '#F78D2C',
                            }
                          ]
                        }"
                        />

                    </template>
    





                    <template v-if="model.anioSolidos == 0">
                  
                      <h3 class="title-acceso-ganaderos mt-5 mb-4">Detalle de Sólidos totales por quincena</h3>

                      <div class="b-table blue acceso-ganaderos-acopio" v-if="yearsOptions.length > 0">

                        <div class="t-header">
                          <div class="t-row">
                            <div class="t-cell">quincena</div>
                            <div class="t-cell">{{ yearsOptions[3].id }}</div>
                            <div class="t-cell">{{ yearsOptions[2].id }}</div>
                            <div class="t-cell">{{ yearsOptions[1].id }}</div>
                            <div class="t-cell"></div>
                          </div>
                        </div>

                        <div class="t-body">

                          <div
                            class="t-row"
                            v-for="i, index in multidimensionalTablePromedio"
                            :key="index"
                          >
                            <div class="t-cell"><span class="mobile-cell-title">quincena</span> {{ i.half+"Q " + $filters.getMonthAbr(i.month)  }} </div>
                            <div class="t-cell"><span class="mobile-cell-title">{{ yearsOptions[3].id }}</span>{{ $filters.getMonth(i.col1) ? $filters.roundToTwo(i.col1) : '-' }}</div>
                            <div class="t-cell"><span class="mobile-cell-title">{{ yearsOptions[2].id }}</span>{{ $filters.getMonth(i.col2) ? $filters.roundToTwo(i.col2) : '-' }}</div>
                            <div class="t-cell"><span class="mobile-cell-title">{{ yearsOptions[1].id }}</span>{{ $filters.getMonth(i.col3) ? $filters.roundToTwo(i.col3) : '-' }}</div>
                            <div class="t-cell action">
                            </div>
                          </div>

                        </div>

                        <div class="t-body" v-if="multidimensionalTablePromedio.length == 0">
                          <div class="t-body no-records">
                            <p>No existe data para esta fecha</p>
                          </div>
                        </div>


                      </div>
                    
                    </template>


                    <template v-else>

                      <div class="row one-mobile-table mb-5">
      
                        <div class="col-md-4">
      
                          <div class="b-table blue mt-5 lista-calidad">
      
                            <div class="t-header">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">sólidos<br>totales (%)</div>
                              </div>
                            </div>
                            
                            <div class="t-body">
                              <div
                                class="t-row"
                                v-for="record, index in dataSTOne"
                                :key="index"
                              >
                                <div class="t-cell"><span class="mobile-cell-title">quincena</span>{{ this.$filters.getPeriod(record.quincena, record.mes) }}</div>
                                <div class="t-cell"><span class="mobile-cell-title">sólidos<br>totales (%)</span>{{ record.valor ? $filters.roundToTwo(record.valor) : '-' }}</div>
                              </div>
                            </div>
      
                          </div>
      
                        </div>
      
                        <div class="col-md-4">
      
                          <div class="b-table blue mt-5 lista-calidad">
      
                            <div class="t-header">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">sólidos<br>totales (%)</div>
                              </div>
                            </div>
      
                            <div class="t-body">
                              <div
                                class="t-row"
                                v-for="record, index in dataSTTwo"
                                :key="index"
                              >
                                <div class="t-cell"><span class="mobile-cell-title">quincena</span>{{ this.$filters.getPeriod(record.quincena, record.mes) }}</div>
                                <div class="t-cell"><span class="mobile-cell-title">sólidos<br>totales (%)</span>{{ record.valor ? $filters.roundToTwo(record.valor) : '-' }}</div>
                              </div>
                            </div>
      
                          </div>
      
                        </div>
      
                        <div class="col-md-4">
      
                          <div class="b-table blue mt-5 lista-calidad">
      
                            <div class="t-header">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">sólidos<br>totales (%)</div>
                              </div>
                            </div>
      
                            <div class="t-body">
                              <div
                                class="t-row"
                                v-for="record, index in dataSTThree"
                                :key="index"
                              >
                                <div class="t-cell"><span class="mobile-cell-title">quincena</span>{{ this.$filters.getPeriod(record.quincena, record.mes) }}</div>
                                <div class="t-cell"><span class="mobile-cell-title">sólidos<br>totales (%)</span>{{ record.valor ? $filters.roundToTwo(record.valor) : '-' }}</div>
                              </div>
                            </div>
      
                          </div>
      
                        </div>
      
                      </div>

                    </template>
    
                    <div class="form-actions mb-5">
    
                      <Button
                        class="mt-3 primary-button shadow-none"
                        size="lg"
                        type="button"
                        @click="downloadSolidosExcel()"
                        >Exportar</Button
                      >
    
                    </div>
                  </div>
  
                </div>
                
              </div>

              <div class="tab-pane" id="second-tab-pane" role="tabpanel" aria-labelledby="second-tab" tabindex="0">
                
                <div class="tab-body w-fixed-s calidad-pagos">

                  <div class="fixed-section">

                    <Form
                    :validation-schema="schema"
                    ref="veeForm"
                    :initial-values="formValuesUFC"
                    v-slot="{ meta }"
                    >

                    <div class="action-box justify-content-start">
                        <div class="grid grid-container-1">
                          <div class="grid-item-1">
                            <SelectButton
                              name="anio"
                              label="año"
                              v-model="model.anioUFC"
                              @change="getUFC()"
                              :options="yearsOptions"
                            />
                          </div>
                        </div>
                    </div>

                    </Form>

                  </div>

                  <div class="scrollable-content">

                    <h3 class="title-acceso-ganaderos mb-4">Gráfica de R. bacteriano por quincena</h3>

                    <template v-if="model.anioUFC == 0">
                      <GradientLineChart
                      id="ufc"
                        title="Recuento Bacteriano"
                        :enlarge="true"
                        @open-modal="openModal"
                        :chartDataToChar="true"
                        description=""
                        :chart="{
                          labels: this.labels,
                          suggestedMin: 9,
                          suggestedMax: 15,
                          yAxisTitle: 'UFC / ML',
                          datasets: dataPointsSolidosBacteriano
                        }"
                      />
                    </template>


                    <template v-else>
                      <GradientLineChart
                        id="ufc"
                        title="Recuento Bacteriano"
                        :enlarge="true"
                        @open-modal="openModal"
                        :chartDataToChar="true"
                        description=""
                        :chart="{
                          labels: this.labels,
                          suggestedMin: 9,
                          suggestedMax: 15,
                          yAxisTitle: 'UFC / ML',
                          datasets: [
                            {
                              legendTitle: this.model.anioUFC,
                              data: dataPointsSolidosUNOBacteriano,
                              borderColor: '#F78D2C',
                            },
                          ]
                        }"
                      />
                    </template>


                    
                    <h3 class="title-acceso-ganaderos mt-5 mb-4">Detalle de R. bacteriano por quincena</h3>

                    <template v-if="model.anioUFC == 0">
                      <div class="b-table blue acceso-ganaderos-acopio" v-if="yearsOptions.length > 0">

                        <div class="t-header">
                          <div class="t-row">
                            <div class="t-cell">quincena</div>
                            <div class="t-cell">{{ yearsOptions[3].id }}</div>
                            <div class="t-cell">{{ yearsOptions[2].id }}</div>
                            <div class="t-cell">{{ yearsOptions[1].id }}</div>
                            <div class="t-cell"></div>
                          </div>
                        </div>

                        <div class="t-body">

                          <div
                            class="t-row"
                            v-for="i, index in multidimensionalTablePromedioBacteriano"
                            :key="index"
                          >
                            <div class="t-cell"><span class="mobile-cell-title">quincena</span> {{ i.half+"Q " + $filters.getMonthAbr(i.month)  }} </div>
                            <div class="t-cell"><span class="mobile-cell-title">{{ yearsOptions[3].id }}</span>{{ $filters.getMonth(i.col1) ? $filters.toChar(i.col1) : '-' }}</div>
                            <div class="t-cell"><span class="mobile-cell-title">{{ yearsOptions[2].id }}</span>{{ $filters.getMonth(i.col2) ? $filters.toChar(i.col2) : '-' }}</div>
                            <div class="t-cell"><span class="mobile-cell-title">{{ yearsOptions[1].id }}</span>{{ $filters.getMonth(i.col3) ? $filters.toChar(i.col3) : '-' }}</div>
                            <div class="t-cell action">
                            </div>
                          </div>

                        </div>

                        <div class="t-body" v-if="multidimensionalTablePromedioBacteriano.length == 0">
                          <div class="t-body no-records">
                            <p>No existe data para esta fecha</p>
                          </div>
                        </div>


                      </div>
                    </template>

                    <template v-else>
                      <div class="row one-mobile-table mb-5">

                        <div class="col-md-4">

                            <div class="b-table blue mt-5 lista-calidad">

                            <div class="t-header">
                                <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">UFC / ML</div>
                                </div>
                            </div>

                            <div class="t-body">
                                <div
                                class="t-row"
                                v-for="record in dataUFCOne"
                                :key="record.id"
                                >
                                <div class="t-cell"><span class="mobile-cell-title">quincena</span>{{ this.$filters.getPeriod(record.quincena, record.mes) }}</div>
                                <div class="t-cell"><span class="mobile-cell-title">ufc / ml</span>{{ record.valor ? $filters.toChar(record.valor) : '-' }}</div>
                                </div>
                            </div>

                            </div>

                        </div>

                        <div class="col-md-4">

                            <div class="b-table blue mt-5 lista-calidad">

                            <div class="t-header">
                                <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">UFC / ML</div>
                                </div>
                            </div>

                            <div class="t-body">
                                <div
                                class="t-row"
                                v-for="record in dataUFCTwo"
                                :key="record.id"
                                >
                                <div class="t-cell"><span class="mobile-cell-title">quincena</span>{{ this.$filters.getPeriod(record.quincena, record.mes) }}</div>
                                <div class="t-cell"><span class="mobile-cell-title">ufc / ml</span>{{ record.valor ? $filters.toChar(record.valor) : '-' }}</div>
                                </div>
                            </div>

                            </div>

                        </div>

                        <div class="col-md-4">

                            <div class="b-table blue mt-5 lista-calidad">

                            <div class="t-header">
                                <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">UFC / ML</div>
                                </div>
                            </div>

                            <div class="t-body">
                                <div
                                class="t-row"
                                v-for="record in dataUFCThree"
                                :key="record.id"
                                >
                                <div class="t-cell"><span class="mobile-cell-title">quincena</span>{{ this.$filters.getPeriod(record.quincena, record.mes) }}</div>
                                <div class="t-cell"><span class="mobile-cell-title">ufc / ml</span>{{ record.valor ? $filters.toChar(record.valor) : '-' }}</div>
                                </div>
                            </div>

                            </div>

                        </div>

                      </div>
                    </template>

                    <div class="form-actions mb-5">

                    <Button
                        class="mt-3 primary-button shadow-none"
                        size="lg"
                        type="button"
                        @click="downloadUFCExcel()"
                        >Exportar</Button
                    >

                    </div>
                  </div>

                </div>

              </div>

              <div class="tab-pane" id="third-tab-pane" role="tabpanel" aria-labelledby="third-tab" tabindex="0">
  
                <div class="tab-body w-fixed-s calidad-pagos">
                  
                  <div class="fixed-section">
                    <Form
                      :validation-schema="schema"
                      ref="veeForm"
                      :initial-values="formValuesSomaticas"
                      v-slot="{ meta }"
                    >
    
                      <div class="action-box justify-content-start">
                        <div class="grid grid-container-1">
                          <div class="grid-item-1">
                            <SelectButton
                              name="anio"
                              label="año"
                              v-model="model.anioSomaticas"
                              :options="yearsOptions"
                              @change="getSomaticas()"
                            />
                          </div>
                        </div>
                      </div>
    
                    </Form>
                  </div>
                  
                  <div class="scrollable-content">

                    
                    <h3 class="title-acceso-ganaderos mb-4">Gráfica de células somáticas por quincena</h3>

                    <template v-if="model.anioSomaticas == 0">
                      <GradientLineChart
                        id="celulas_somaticas"
                        title="Células Somáticas"
                        :chartDataToChar="true"
                        :enlarge="true"
                        @open-modal="openModal"
                        :chart="{
                          labels: this.labels,                          
                          suggestedMin: 9,
                          suggestedMax: 15,
                          yAxisTitle: 'C.S',
                          datasets: dataPointsSolidosSomaticas
                        }"
                      />
                    </template>

                    <template v-else>
                      <GradientLineChart
                        id="celulas_somaticas"
                        title="Células Somáticas"
                        :chartDataToChar="true"
                        :enlarge="true"
                        @open-modal="openModal"
                        :chart="{
                          labels: this.labels,
                          suggestedMin: 9,
                          suggestedMax: 15,
                          yAxisTitle: 'C.S',
                          datasets: [
                            {
                              legendTitle: this.model.anioSomaticas,
                              data: dataPointsSolidosUNOSomaticas,
                              borderColor: '#F78D2C',
                            }
                          ]
                        }"
                      />
                    </template>
    

                    <h3 class="title-acceso-ganaderos mt-5 mb-4">Detalle de células somáticas por quincena</h3>

                    <template v-if="model.anioSomaticas == 0">
                      <div class="b-table blue acceso-ganaderos-acopio" v-if="yearsOptions.length > 0">

                        <div class="t-header">
                          <div class="t-row">
                            <div class="t-cell">quincena</div>
                            <div class="t-cell">{{ yearsOptions[3].id }}</div>
                            <div class="t-cell">{{ yearsOptions[2].id }}</div>
                            <div class="t-cell">{{ yearsOptions[1].id }}</div>
                            <div class="t-cell"></div>
                          </div>
                        </div>

                        <div class="t-body">

                          <div
                            class="t-row"
                            v-for="i, index in multidimensionalTablePromedioSomaticas"
                            :key="index"
                          >
                            <div class="t-cell"><span class="mobile-cell-title">quincena</span> {{ i.half+"Q " + $filters.getMonthAbr(i.month)  }} </div>
                            <div class="t-cell"><span class="mobile-cell-title">{{ yearsOptions[3].id }}</span>{{ $filters.getMonth(i.col1) ? $filters.toChar(i.col1) : '-' }}</div>
                            <div class="t-cell"><span class="mobile-cell-title">{{ yearsOptions[2].id }}</span>{{ $filters.getMonth(i.col2) ? $filters.toChar(i.col2) : '-' }}</div>
                            <div class="t-cell"><span class="mobile-cell-title">{{ yearsOptions[1].id }}</span>{{ $filters.getMonth(i.col3) ? $filters.toChar(i.col3) : '-' }}</div>
                            <div class="t-cell action">
                            </div>
                          </div>

                        </div>

                        <div class="t-body" v-if="multidimensionalTablePromedioSomaticas.length == 0">
                          <div class="t-body no-records">
                            <p>No existe data para esta fecha</p>
                          </div>
                        </div>


                      </div>
                    </template>

                    <template v-else>
                      <div class="row one-mobile-table mb-5">
      
                        <div class="col-md-4">
      
                          <div class="b-table blue mt-5 lista-calidad">
      
                            <div class="t-header">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">c. sómaticas</div>
                              </div>
                            </div>
      
                            <div class="t-body">
                              <div
                                class="t-row"
                                v-for="record in dataCSOne"
                                :key="record.id"
                              >
                                <div class="t-cell"><span class="mobile-cell-title">quincena</span>{{ this.$filters.getPeriod(record.quincena, record.mes) }}</div>
                                <div class="t-cell"><span class="mobile-cell-title">c. sómaticas</span>{{ record.valor ? $filters.toChar(record.valor) : '-' }}</div>
                              </div>
                            </div>
      
                          </div>
      
                        </div>
      
                        <div class="col-md-4">
      
                          <div class="b-table blue mt-5 lista-calidad">
      
                            <div class="t-header">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">c. sómaticas</div>
                              </div>
                            </div>
      
                            <div class="t-body">
                              <div
                                class="t-row"
                                v-for="record in dataCSTwo"
                                :key="record.id"
                              >
                                <div class="t-cell"><span class="mobile-cell-title">quincena</span>{{ this.$filters.getPeriod(record.quincena, record.mes) }}</div>
                                <div class="t-cell"><span class="mobile-cell-title">c. sómaticas</span>{{ record.valor ? $filters.toChar(record.valor) : '-' }}</div>
                              </div>
                            </div>
      
                          </div>
      
                        </div>
      
                        <div class="col-md-4">
      
                          <div class="b-table blue mt-5 lista-calidad">
      
                            <div class="t-header">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">c. sómaticas</div>
                              </div>
                            </div>
      
                            <div class="t-body">
                              <div
                                class="t-row"
                                v-for="record in dataCSThree"
                                :key="record.id"
                              >
                                <div class="t-cell"><span class="mobile-cell-title">quincena</span>{{ this.$filters.getPeriod(record.quincena, record.mes) }}</div>
                                <div class="t-cell"><span class="mobile-cell-title">c. sómaticas</span>{{ record.valor ? $filters.toChar(record.valor) : '-' }}</div>
                              </div>
                            </div>
      
                          </div>
      
                        </div>
      
                      </div>
                    </template>
    
                    <div class="form-actions mb-5">
    
                      <Button
                        class="mt-3 primary-button shadow-none"
                        size="lg"
                        type="button"
                        @click="downloadSomaticasExcel()"
                        >Exportar</Button
                      >
    
                    </div>
                  </div>
  
                </div>
  
              </div>
                
            </div>  
  
            </div>
  
          </div>
        </div>
      </div>
  
      <transition name="fade">
        <CustomModal
          :show="dataModal.showModal"
          :isHeader="false"
          :isFooter="false"
          :width="1600"
          :title="dataModal.title"
          v-on:modal:close="hideModal()"
        >
          <template v-slot:body>


            <template v-if="model.anioSolidos == 0">

              <GradientLineChart
                id="solidos_totales_modal"
                title="Sólidos Totales"
                :yAxisTicksDecimals="true"
                description=""
                :enlarge="false"
                @open-modal="openModal"
                :chart="{
                  labels: this.labels,
                  yAxisTitle: '%',
                  suggestedMin: 11,
                  suggestedMax: 12,
                  datasets: dataPointsSolidos
                }"
              />

            </template>

            <template v-else>

              <GradientLineChart
                id="solidos_totales_modal"
                title="Sólidos Totales"
                :yAxisTicksDecimals="true"
                :enlarge="false"
                @open-modal="openModal"
                description=""
                :chart="{
                  labels: this.labels,
                  suggestedMin: 11,
                  suggestedMax: 12,
                  yAxisTitle: '%',
                  datasets: [
                    {
                      legendTitle: this.model.anioSolidos,
                      data: dataPointsSolidosUNO,
                      borderColor: '#F78D2C',
                    }
                  ]
                }"
                />

            </template>
  
            <div class="form-actions">
  
              <Button
                class="mt-3 ghost-button shadow-none"
                size="lg"
                type="button"
                @click="hideModal()"
                >Cerrar</Button
              >
  
            </div>
  
          </template>
  
        </CustomModal>
      </transition>
  
  
      <transition name="fade">
        <CustomModal
          :show="dataModal2.showModal"
          :isHeader="false"
          :isFooter="false"
          :width="1600"
          :title="dataModal2.title"
          v-on:modal:close="hideModal2()"
        >
          <template v-slot:body>


            <template v-if="model.anioUFC == 0">
              <GradientLineChart
              id="ufc_modal"
                title="Recuento Bacteriano"
                :enlarge="false"
                @open-modal="openModal"
                :chartDataToChar="true"
                description=""
                :chart="{
                  labels: this.labels,
                  suggestedMin: 9,
                  suggestedMax: 15,
                  yAxisTitle: 'UFC / ML',
                  datasets: dataPointsSolidosBacteriano
                }"
              />
            </template>


            <template v-else>
              <GradientLineChart
                id="ufc_modal"
                title="Recuento Bacteriano"
                :enlarge="false"
                @open-modal="openModal"
                :chartDataToChar="true"
                description=""
                :chart="{
                  labels: this.labels,
                  suggestedMin: 9,
                  suggestedMax: 15,
                  yAxisTitle: 'UFC / ML',
                  datasets: [
                    {
                      legendTitle: this.model.anioUFC,
                      data: dataPointsSolidosUNOBacteriano,
                      borderColor: '#F78D2C',
                    },
                  ]
                }"
              />
            </template>

  
            <div class="form-actions">
  
              <Button
                class="mt-3 ghost-button shadow-none"
                size="lg"
                type="button"
                @click="hideModal2()"
                >Cerrar</Button
              >
  
            </div>
  
          </template>
  
        </CustomModal>
      </transition>


      <transition name="fade">
        <CustomModal
          :show="dataModal3.showModal"
          :isHeader="false"
          :isFooter="false"
          :width="1600"
          :title="dataModal3.title"
          v-on:modal:close="hideModal3()"
        >
          <template v-slot:body>

            <template v-if="model.anioSomaticas == 0">
              <GradientLineChart
                id="celulas_somaticas_modal"
                title="Células Somáticas"
                :chartDataToChar="true"
                :enlarge="false"
                @open-modal="openModal"
                :chart="{
                  labels: this.labels,
                  suggestedMin: 9,
                  suggestedMax: 15,
                  yAxisTitle: 'C.S',
                  datasets: dataPointsSolidosSomaticas
                }"
              />
            </template>

            <template v-else>
              <GradientLineChart
                id="celulas_somaticas_modal"
                title="Células Somáticas"
                :chartDataToChar="true"
                :enlarge="false"
                @open-modal="openModal"
                :chart="{
                  labels: this.labels,
                  suggestedMin: 9,
                  suggestedMax: 15,
                  yAxisTitle: 'C.S',
                  datasets: [
                    {
                      legendTitle: this.model.anioSomaticas,
                      data: dataPointsSolidosUNOSomaticas,
                      borderColor: '#F78D2C',
                    }
                  ]
                }"
              />
            </template>

            <div class="form-actions">
  
              <Button
                class="mt-3 ghost-button shadow-none"
                size="lg"
                type="button"
                @click="hideModal3()"
                >Cerrar</Button
              >
  
            </div>
  
          </template>
  
        </CustomModal>
      </transition>
  
    </div>
  </template>
  
  <script>
const CACHE_NAME = "site-cache-v2";

  import { markRaw } from "vue";
  import { Form, Field } from "vee-validate";
  import { mapActions } from 'vuex';
  import GradientLineChart from "@/components/Charts/GradientLineChart.vue";
  import TextInput from "@/components/Inputs/TextInput";
  import FilterBox from "@/components/FilterBox";
  import DateInput from "@/components/Inputs/Date";
  import SelectButton from "@/components/Inputs/SelectButton";
  import Button from "@/components/Inputs/Button";
  import Pagination from '@/components/Pagination.vue';
  import CustomModal from "@/components/CustomModal.vue";
  import ConfirmationScreen from "@/components/ConfirmationScreen";
  import Tag from "@/components/Tags/Tag";
  import RowAccordion from "@/components/Accordion/RowAccordion";
  import RadioButton from "@/components/Inputs/RadioButtonOriginalType.vue";
  import Checkbox from "@/components/Inputs/Checkbox";
  import * as Yup from "yup";
  
  export default {
    components: {
        GradientLineChart,
        TextInput,
        FilterBox,
        SelectButton,
        Button,
        Pagination,
        CustomModal,
        ConfirmationScreen,
        RadioButton,
        Checkbox,
        Tag,
        RowAccordion,
        DateInput,
        Form,
        Field,
    },
    name: "Aprobacion de Ganadero",
    data() {
      const schema = markRaw(
        Yup.object().shape({
          fechaInicio: Yup.string().required(),
          fechaFin: Yup.string().required(),
          zona: Yup.string().nullable(),
          codInterno: Yup.string().nullable(),
        })
      );
      const formValues = {
        fechaInicio: '',
        fechaFin: '',
        zona: '',
        codInterno: '',
      };

      const formValuesSolidos = {
        fechaInicio: '',
        fechaFin: '',
        zona: '',
        codInterno: '',
      };

      const formValuesUFC = {
        fechaInicio: '',
        fechaFin: '',
        zona: '',
        codInterno: '',
      };

      const formValuesSomaticas = {
        fechaInicio: '',
        fechaFin: '',
        zona: '',
        codInterno: '',
      };
  
      return {
        schema,
        formValues,
        formValuesSolidos,
        formValuesUFC,
        formValuesSomaticas,

        labels: this.$filters.getPeriodsLabels(),

        codigoProveedor: '5000000',
        aniosOptions: this.$filters.getYears(2020),
        records: [],


        dataSTActual: [],
        dataSTAnterior: [],
        dataSTActualMontos: [],
        dataSTAnteriorMontos: [],
        dataSTOne: [],
        dataSTTwo: [],
        dataSTThree: [],


        dataUFCActual: [],
        dataUFCAnterior: [],
        dataUFCActualMontos: [],
        dataUFCAnteriorMontos: [],
        dataUFCOne: [],
        dataUFCTwo: [],
        dataUFCThree: [],


        dataCSActual: [],
        dataCSAnterior: [],
        dataCSActualMontos: [],
        dataCSAnteriorMontos: [],
        dataCSOne: [],
        dataCSTwo: [],
        dataCSThree: [],



        allYearsDatasets: [],
        dataPointsSolidos: [],
        dataPointsSolidosUNO: [],
        yearsOptions: [],
        yearsServer: [],
        multidimensionalTablePromedio: [],


        allYearsDatasetsBacteriano: [],
        dataPointsSolidosBacteriano: [],
        dataPointsSolidosUNOBacteriano: [],
        yearsOptionsBacteriano: [],
        yearsServerBacteriano: [],
        multidimensionalTablePromedioBacteriano: [],


        allYearsDatasetsSomaticas: [],
        dataPointsSolidosSomaticas: [],
        dataPointsSolidosUNOSomaticas: [],
        yearsOptionsSomaticas: [],
        yearsServerSomaticas: [],
        multidimensionalTablePromedioSomaticas: [],


        dataRaw: [],
        dataRawUFC: [],
        dataRawSomaticas: [],


        model: {
          anioSolidos: '',
          anioUFC: '',
          anioSomaticas: ''
        },
        dataModal: {
          showModal: false,
          title: "",
          active: "",
          request: "",
          response: "",
        },
        dataModal2: {
          showModal: false,
          title: "",
          active: "",
          request: "",
          response: "",
        },
        dataModal3: {
          showModal: false,
          title: "",
          active: "",
          request: "",
          response: "",
        },
      };
    },
    computed: {
  
    },
    watch: {
  
    },
    methods: {
      ...mapActions({
        getOptions: 'pagos/getOptions',
        getListSolidos: 'calidad/getListSolidos',
        getListUFC: 'calidad/getListUFC',
        getListSomaticas: 'calidad/getListSomaticas',
        downloadSolidos: 'calidad/downloadSolidos',
        downloadUFC: 'calidad/downloadUFC',
        downloadSomaticas: 'calidad/downloadSomaticas',
      }),
      openModal(id) {
  
        if (id == 'solidos_totales') {
          this.dataModal.showModal = true;
        } else if (id == 'celulas_somaticas') {
          this.dataModal3.showModal = true;
        } else if (id == 'ufc') {
          this.dataModal2.showModal = true;
        }
  
      },
      hideModal() {
        this.dataModal.showModal = false;
      },
      hideModal2() {
        this.dataModal2.showModal = false;
      },
      hideModal3() {
        this.dataModal3.showModal = false;
      },
      downloadSolidosExcel() {

        const data = {
          "years": this.model.anioSolidos == 0 ? this.yearsServer : this.model.anioSolidos
        }

        this.downloadSolidos(data).then((response) => {

          if (response.status == 200) {

            let type = response.headers['content-type'];

            const file = new Blob([response.data], {type: type});
            const fileURL = URL.createObjectURL(file);

            let todayDate = this.$filters.generateFileDate();

            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', `reporte-solidos-totales_${todayDate}.xlsx`);
            document.body.appendChild(link);
            link.click();

          }

        });

      },
      downloadUFCExcel() {

        const data = {
          "years": this.model.anioUFC == 0 ? this.yearsServer : this.model.anioUFC
        }

        this.downloadUFC(data).then((response) => {

          if (response.status == 200) {

            let type = response.headers['content-type'];

            const file = new Blob([response.data], {type: type});
            const fileURL = URL.createObjectURL(file);

            let todayDate = this.$filters.generateFileDate();

            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', `reporte-recuento-bacteriano_${todayDate}.xlsx`);
            document.body.appendChild(link);
            link.click();

          }

        });

      },
      downloadSomaticasExcel() {

        const data = {
          "years": this.model.anioSomaticas == 0 ? this.yearsServer : this.model.anioSomaticas
        }

        this.downloadSomaticas(data).then((response) => {

          if (response.status == 200) {

            let type = response.headers['content-type'];

            const file = new Blob([response.data], {type: type});
            const fileURL = URL.createObjectURL(file);

            let todayDate = this.$filters.generateFileDate();

            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', `reporte-celulas-somaticas_${todayDate}.xlsx`);
            document.body.appendChild(link);
            link.click();

          }

        });

      },




      createMultidimensionalTablePromedio(oData, prop, propQuincena) {
        // parse years data
        let yMax = new Date().getFullYear();
        let yMin = yMax - 2;

        let oYears = [];
        for (let y = yMin; y <= yMax; y++) {
            let dataYear = findByYear(y, oData);
            oYears[y] = dataYear;
        }
        //console.log('oYears', oYears);

        // make months data 
        let oMonth = [];

        for (let m = 1; m <= 12; m++) {
            oMonth.push({ 
                half: 1, month: m,
                col1: getByHalfMonth(1, m, oYears[yMin]), 
                col2: getByHalfMonth(1, m, oYears[yMin+1]),
                col3: getByHalfMonth(1, m, oYears[yMax]) 
            });
            oMonth.push({ 
                half: 2, month: m, 
                col1: getByHalfMonth(2, m, oYears[yMin]), 
                col2: getByHalfMonth(2, m, oYears[yMin+1]),
                col3: getByHalfMonth(2, m, oYears[yMax]) 
            });
        }

        function findByYear(year, data) {
            let result = [];
            for (var key in data) {
                if (data.hasOwnProperty(key) && data[key].year === year){
                    result = data[key].data;
                }
            }
            return result;
        }

        function getByHalfMonth(half, month, data) {
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    const item = data[i];
                    if (item.mes == month && item[propQuincena] == half) {
                        return item[prop];
                    }
                }
            }
            return '';
        }

        //console.log('oMonth', oMonth);
        return oMonth;

      },




      getDataByUserSelection() {

        let anio = this.model.anioSolidos;

        if (anio == 0) {

          this.multidimensionalTablePromedio = this.createMultidimensionalTablePromedio(this.dataRaw, 'valor', 'numeroQuincena');

          let anios = [...this.yearsOptions];

          let index = anios.findIndex(objeto => objeto.id === 0);
          if (index !== -1) {
            anios.splice(index, 1);
          }

          this.allYearsDatasets = [];

          anios.map((x, i)=> {
            
            let aYear = x.id;

            let arrYear1 = this.$filters.getSolidosTotalesByYear(this.dataRaw, aYear, 'valor', 'numeroQuincena');
            let dataValuesYear1 = this.$filters.generarSecuenciaQuincenas(arrYear1, aYear, 'valor', 'numeroQuincena');

            let points = dataValuesYear1.map(objeto => objeto.valor);

            let borderColor = '';

            if (i == 0) {
              borderColor = '#F78D2C';
            } else if (i == 1 ) {
              borderColor = '#1D4193';
            } else if (i == 2) {
              borderColor = '#07C25E';
            }

            this.allYearsDatasets.push({
              legendTitle: aYear,
              data: points,
              borderColor: borderColor,
            });

          })

          this.dataPointsSolidos = Object.values(this.allYearsDatasets);


        } else {

          let aYear = anio;

          let arrYear1 = this.$filters.getSolidosTotalesByYear(this.dataRaw, aYear, 'valor', 'numeroQuincena');
          let dataValuesYear1 = this.$filters.generarSecuenciaQuincenas(arrYear1, aYear, 'valor', 'numeroQuincena');
          let points = dataValuesYear1.map(objeto => objeto.valor);

          this.dataPointsSolidosUNO = points;
          let dataActual = arrYear1;
          this.dataSTActual = this.$filters.getDataByPeriodsV2(dataActual, aYear);

          const chunkSize = 8;
          for (let i = 0; i < this.dataSTActual.length; i += chunkSize) {

            if (i == 0) {
              const chunk = this.dataSTActual.slice(i, i + chunkSize);
              this.dataSTOne = chunk;
            }

            if (i == 8) {
              const chunk = this.dataSTActual.slice(i, i + chunkSize);
              this.dataSTTwo = chunk;
            }

            if (i == 16) {
              const chunk = this.dataSTActual.slice(i, i + chunkSize);
              this.dataSTThree = chunk;
            }
            
          }




          
        }

      },
      getSolidos() {

        // Intenta obtener los datos del caché
        caches.open(CACHE_NAME)
          .then((cache) => {
            const cacheUrl = `/offline/solidos`;

            cache.match(cacheUrl)
              .then((response) => {
                if (response) {
                  return response.json();
                }
              })
              .then((data) => {
                if (data) {

                  // Los datos están disponibles en caché
                  this.dataRaw = data;

                  this.getDataByUserSelection();

                } else {

                  

                }
              });
          });



          const data = {
            "years": this.yearsServer,
          }

          this.getListSolidos(data).then((response) => {

            if (response.status == 200) {

              caches.open(CACHE_NAME)
                .then((cache) => {
                  cache.put("/offline/solidos", new Response(JSON.stringify(response.data)));
                });
                
              let data = response.data;

              this.dataRaw = data;

              this.getDataByUserSelection();            

            }

          }).catch((err) => {

            //console.log(err.message);

          });


        

      },





      getDataByUserSelectionUFC() {

        let anio = this.model.anioUFC;

        if (anio == 0) {

          this.multidimensionalTablePromedioBacteriano = this.createMultidimensionalTablePromedio(this.dataRawUFC, 'valor', 'numeroQuincena');

          let anios = [...this.yearsOptions];

          let index = anios.findIndex(objeto => objeto.id === 0);
          if (index !== -1) {
            anios.splice(index, 1);
          }

          this.allYearsDatasetsBacteriano = [];

          anios.map((x, i)=> {
            
            let aYear = x.id;

            let arrYear1 = this.$filters.getSolidosTotalesByYear(this.dataRawUFC, aYear, 'valor', 'numeroQuincena');
            let dataValuesYear1 = this.$filters.generarSecuenciaQuincenas(arrYear1, aYear, 'valor', 'numeroQuincena');
//console.log('r bacteriano', aYear, dataValuesYear1);
            let points = dataValuesYear1.map(objeto => objeto.valor);

            let borderColor = '';

            if (i == 0) {
              borderColor = '#F78D2C';
            } else if (i == 1 ) {
              borderColor = '#1D4193';
            } else if (i == 2) {
              borderColor = '#07C25E';
            }

            this.allYearsDatasetsBacteriano.push({
              legendTitle: aYear,
              data: points,
              borderColor: borderColor,
            });

          })

          this.dataPointsSolidosBacteriano = Object.values(this.allYearsDatasetsBacteriano);


        } else {

          let aYear = anio;

          let arrYear1 = this.$filters.getSolidosTotalesByYear(this.dataRawUFC, aYear, 'valor', 'numeroQuincena');
          let dataValuesYear1 = this.$filters.generarSecuenciaQuincenas(arrYear1, aYear, 'valor', 'numeroQuincena');
          let points = dataValuesYear1.map(objeto => objeto.valor);

          this.dataPointsSolidosUNOBacteriano = points;

          let dataActual = arrYear1;
          this.dataUFCActual = this.$filters.getDataByPeriodsV2(dataActual, aYear);

          const chunkSize = 8;
          for (let i = 0; i < this.dataUFCActual.length; i += chunkSize) {

            if (i == 0) {
              const chunk = this.dataUFCActual.slice(i, i + chunkSize);
              this.dataUFCOne = chunk;
            }

            if (i == 8) {
              const chunk = this.dataUFCActual.slice(i, i + chunkSize);
              this.dataUFCTwo = chunk;
            }

            if (i == 16) {
              const chunk = this.dataUFCActual.slice(i, i + chunkSize);
              this.dataUFCThree = chunk;
            }
            
          }


          
        }

      },
      getUFC() {



        // Intenta obtener los datos del caché
        caches.open(CACHE_NAME)
          .then((cache) => {
            const cacheUrl = `/offline/somaticas`;

            cache.match(cacheUrl)
              .then((response) => {
                if (response) {
                  return response.json();
                }
              })
              .then((data) => {
                if (data) {

                  // Los datos están disponibles en caché
                  this.dataRawUFC = data;

                  this.getDataByUserSelectionUFC();

                } else {


                  

                }
              });
          });


        

          const data = {
            "years": this.yearsServer,
          }

          this.getListUFC(data).then((response) => {

            if (response.status == 200) {

              caches.open(CACHE_NAME)
                .then((cache) => {
                  cache.put("/offline/ufc", new Response(JSON.stringify(response.data)));
                });

              let data = response.data;

              this.dataRawUFC = data;

              this.getDataByUserSelectionUFC(); 

            }

          }).catch((err) => {

            //console.log(err.message);

          });



      },




      getDataByUserSelectionSomaticas() {

        let anio = this.model.anioSomaticas;

        if (anio == 0) {

          this.multidimensionalTablePromedioSomaticas = this.createMultidimensionalTablePromedio(this.dataRawSomaticas, 'valor', 'numeroQuincena');

          let anios = [...this.yearsOptions];

          let index = anios.findIndex(objeto => objeto.id === 0);
          if (index !== -1) {
            anios.splice(index, 1);
          }

          this.allYearsDatasetsSomaticas = [];

          anios.map((x, i)=> {
            
            let aYear = x.id;

            let arrYear1 = this.$filters.getSolidosTotalesByYear(this.dataRawSomaticas, aYear, 'valor', 'numeroQuincena');
            let dataValuesYear1 = this.$filters.generarSecuenciaQuincenas(arrYear1, aYear, 'valor', 'numeroQuincena');

            let points = dataValuesYear1.map(objeto => objeto.valor);

            let borderColor = '';

            if (i == 0) {
              borderColor = '#F78D2C';
            } else if (i == 1 ) {
              borderColor = '#1D4193';
            } else if (i == 2) {
              borderColor = '#07C25E';
            }

            this.allYearsDatasetsSomaticas.push({
              legendTitle: aYear,
              data: points,
              borderColor: borderColor,
            });

          })

          this.dataPointsSolidosSomaticas = Object.values(this.allYearsDatasetsSomaticas);


      } else {

          let aYear = anio;

          let arrYear1 = this.$filters.getSolidosTotalesByYear(this.dataRawSomaticas, aYear, 'valor', 'numeroQuincena');
          let dataValuesYear1 = this.$filters.generarSecuenciaQuincenas(arrYear1, aYear, 'valor', 'numeroQuincena');
          let points = dataValuesYear1.map(objeto => objeto.valor);

          this.dataPointsSolidosUNOSomaticas = points;
          let dataActual = arrYear1;

          this.dataCSActual = this.$filters.getDataByPeriodsV2(dataActual, aYear);

          const chunkSize = 8;
          for (let i = 0; i < this.dataCSActual.length; i += chunkSize) {

            if (i == 0) {
              const chunk = this.dataCSActual.slice(i, i + chunkSize);
              this.dataCSOne = chunk;
            }

            if (i == 8) {
              const chunk = this.dataCSActual.slice(i, i + chunkSize);
              this.dataCSTwo = chunk;
            }

            if (i == 16) {
              const chunk = this.dataCSActual.slice(i, i + chunkSize);
              this.dataCSThree = chunk;
            }
            
          }

          
        }

      },
      getSomaticas() {

        // Intenta obtener los datos del caché
        caches.open(CACHE_NAME)
          .then((cache) => {
            const cacheUrl = `/offline/somaticas`;

            cache.match(cacheUrl)
              .then((response) => {
                if (response) {
                  return response.json();
                }
              })
              .then((data) => {
                if (data) {

                  // Los datos están disponibles en caché
                  this.dataRawSomaticas = data;

                  this.getDataByUserSelectionSomaticas();

                } else {



                }
              });
          });



          const data = {
            "years": this.yearsServer,
          }

          this.getListSomaticas(data).then((response) => {

            if (response.status == 200) {

              caches.open(CACHE_NAME)
                .then((cache) => {
                  cache.put("/offline/somaticas", new Response(JSON.stringify(response.data)));
                });

              let data = response.data;

              this.dataRawSomaticas = data;

              this.getDataByUserSelectionSomaticas(); 

            }

          }).catch((err) => {

            //console.log(err.message);

          });


      },






    },
    async mounted() {
  
      let loader = this.$loading.show();
      
      const dateFiltersV4 = this.$filters.getYearsV4();

      this.yearsOptions = dateFiltersV4.yearsOptions;
      this.yearsServer = dateFiltersV4.yearsServer;


      this.formValuesSolidos.anio = this.yearsOptions[0].id;
      this.model.anioSolidos = this.yearsOptions[0].id;

      this.formValuesUFC.anio = this.yearsOptions[0].id;
      this.model.anioUFC = this.yearsOptions[0].id;

      this.formValuesSomaticas.anio = this.yearsOptions[0].id;
      this.model.anioSomaticas = this.yearsOptions[0].id;

      this.getSolidos();
      this.getSomaticas();
      this.getUFC();
  
      loader.hide();
  
    },
  };
  </script>
  