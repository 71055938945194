<template>
  <div class="m-container container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="c-card">

          <div class="card-header pb-0">
            <h1 class="mb-4">Seguimiento de ruta</h1>
            <div class="hr mb-0"></div>
          </div>

          <div class="card-body pt-0 pb-0">
            <Form
              :validation-schema="schema"
              ref="veeForm"
              :initial-values="formValues"
              v-slot="{ meta }"
              class="sticky-action-box mt-4 mb-4"
            >
              <div class="action-box align-items-center">
                <div class="grid grid-container-2">
                  <div class="grid-item-2">
                    <SelectButton
                      name="productoTransportado"
                      label="producto transportado"
                      v-model="model.productoTransportado"
                      :options="productoTransportadoOptions"
                      @change="getList()"
                    />
                  </div>
                  <div class="grid-item-2">

                    <DateInput
                      name="fechaInicio"
                      label="fecha"
                      v-model="model.fechaInicio"
                      :configProp="{
                        'maxDate': {
                          action: null,
                          days: 'today'
                        }
                      }"
                      @input="getList()"
                      placeholder="Seleccionar"
                    />

                  </div>
                  
                </div>
              </div>
            </Form>

            <!-- leche caliente -->
            <template v-if="this.model.productoTransportado == 162">
              <div class="n-table seguimiento-rutas-lc sticky-header">
                <div class="t-header">
                  <div class="t-row">
                    <div class="t-cell">
                      placa de vehículo
                    </div>
                    <div class="t-cell">
                      nombre de ruta
                    </div>
                    <div class="t-cell">
                      región
                    </div>
                    <div class="t-cell">
                      producto transportado
                    </div>
                  </div>
                </div>
                <template v-if="this.records.length > 0">
                  <div class="t-body">
                    <div class="t-row"
                      v-for="record in records"
                      :key="record.id">
                      <div class="t-cell">
                        {{ record.numeroPlaca }}
                      </div>
                      <div class="t-cell">
                        {{ record.nombreRuta !== '' ? record.nombreRuta : '-' }}
                      </div>
                      <div class="t-cell">
                        {{ record.nombreRegion !== '' ? record.nombreRegion : '-' }}
                      </div>
                      <div class="t-cell">
                        {{ record.productoTransportado }}
                      </div>
                      <div class="t-cell">
                        <Button
                          class="m-0 primary-button w-100"
                          size="sm"
                          @click="goToRuta(record)"
                          >Registrar
                        </Button>
                      </div>


                      <RowAccordion>
                        <template v-slot:title>
                            <ul>
                              <li class="cell">
                                <div class="key">{{ record.numeroPlaca }}</div>
                                <div class="value">{{ record.nombreRegion }}</div>
                              </li>
                            </ul>
                        </template>
                        <template v-slot:content>

                          <ul>
                            <li class="cell">
                              <div class="key"><span>placa del vehículo</span></div>
                              <div class="value"><span>{{ record.numeroPlaca }}</span></div>
                            </li>
                            <li class="cell">
                              <div class="key"><span>región</span></div>
                              <div class="value">{{ record.nombreRegion }}</div>
                            </li>
                            <li class="cell">
                              <div class="key"><span>ruta</span></div>
                              <div class="value">{{ record.nombreRuta }}</div>
                            </li>
                            <li class="cell">
                              <Button
                                class="m-0 primary-button shadow-none w-100"
                                size="md"
                                @click="goToRuta(record)"
                                >Ver más</Button
                              >
                            </li>
                          </ul>

                        </template>
                      </RowAccordion>


                    </div>
                  </div>
                </template>
                <template v-else-if="this.records.length == 0">
                  <div class="t-body no-records">
                    <p>No se encontraron registros</p>
                  </div>
                </template>
              </div>
            </template>

            <!-- leche fria -->
            <template v-if="this.model.productoTransportado == 161">
              <div class="n-table seguimiento-rutas-b sticky-header">
                <div class="t-header">
                  <div class="t-row">
                    <div class="t-cell">
                      placa de vehículo
                    </div>
                    <div class="t-cell">
                      nombre de ruta
                    </div>
                    <div class="t-cell">
                      región
                    </div>
                    <div class="t-cell">
                      producto<br>transportado
                    </div>
                    <div class="t-cell">
                      
                    </div>
                  </div>
                </div>
                <template v-if="this.records.length > 0">
                  <div class="t-body">
                    <div class="t-row"
                      v-for="record in records"
                      :key="record.id">

                      <div class="t-cell">
                        {{ record.numeroPlaca }}
                      </div>
                      <div class="t-cell">
                        {{ record.nombreRuta }}
                      </div>
                      <div class="t-cell">
                        {{ record.nombreRegion }}
                      </div>
                      <div class="t-cell">
                        {{ record.productoTransportado }}
                      </div>
                      <div class="t-cell">
                        <Button
                            class="m-0 primary-button w-100"
                            size="sm"
                            @click="goToRuta(record)"
                            >Registrar
                          </Button>
                      </div>

                      <RowAccordion>
                        <template v-slot:title>
                            <ul>
                              <li class="cell">
                                <div class="key">{{ record.numeroPlaca }}</div>
                                <div class="value">{{ record.nombreRegion }}</div>
                              </li>
                            </ul>
                        </template>
                        <template v-slot:content>

                          <ul>
                            <li class="cell">
                              <div class="key"><span>placa del vehículo</span></div>
                              <div class="value"><span>{{ record.numeroPlaca }}</span></div>
                            </li>
                            <li class="cell">
                              <div class="key"><span>región</span></div>
                              <div class="value">{{ record.nombreRegion }}</div>
                            </li>
                            <li class="cell">
                              <div class="key"><span>ruta</span></div>
                              <div class="value">{{ record.nombreRuta }}</div>
                            </li>
                            <li class="cell">
                              <Button
                                class="m-0 primary-button shadow-none w-100"
                                size="md"
                                @click="goToRuta(record)"
                                >Ver más</Button
                              >
                            </li>
                          </ul>

                        </template>
                      </RowAccordion>

                    </div>
                  </div>
                </template>
                <template v-else-if="this.records.length == 0">
                  <div class="t-body no-records">
                    <p>No se encontraron registros</p>
                  </div>
                </template>
              </div>
            </template>

            <!-- leche acopio -->
            <template v-if="this.model.productoTransportado == 163">
              <div class="n-table seguimiento-rutas-lc sticky-header">
                <div class="t-header">
                  <div class="t-row">
                    <div class="t-cell">
                      placa de vehículo
                    </div>
                    <div class="t-cell">
                      nombre de ruta
                    </div>
                    <div class="t-cell">
                      región
                    </div>
                    <div class="t-cell">
                      producto transportado
                    </div>
                  </div>
                </div>
                <template v-if="this.records.length > 0">
                  <div class="t-body">
                    <div class="t-row"
                      v-for="record in records"
                      :key="record.id">
                      <div class="t-cell">
                        {{ record.numeroPlaca }}
                      </div>
                      <div class="t-cell">
                        {{ record.nombreRuta }}
                      </div>
                      <div class="t-cell">
                        {{ record.nombreRegion }}
                      </div>
                      <div class="t-cell">
                        {{ record.productoTransportado }}
                      </div>
                      <div class="t-cell">
                        <Button
                          class="m-0 primary-button w-100"
                          size="sm"
                          @click="goToRuta(record)"
                          >Registrar
                        </Button>
                      </div>


                      <RowAccordion>
                        <template v-slot:title>
                            <ul>
                              <li class="cell">
                                <div class="key">{{ record.numeroPlaca }}</div>
                                <div class="value">{{ record.nombreRegion }}</div>
                              </li>
                            </ul>
                        </template>
                        <template v-slot:content>

                          <ul>
                            <li class="cell">
                              <div class="key"><span>placa del vehículo</span></div>
                              <div class="value"><span>{{ record.numeroPlaca }}</span></div>
                            </li>
                            <li class="cell">
                              <div class="key"><span>región</span></div>
                              <div class="value">{{ record.nombreRegion }}</div>
                            </li>
                            <li class="cell">
                              <div class="key"><span>ruta</span></div>
                              <div class="value">{{ record.nombreRuta }}</div>
                            </li>
                            <li class="cell">
                              <Button
                                class="m-0 primary-button shadow-none w-100"
                                size="md"
                                @click="goToRuta(record)"
                                >Ver más</Button
                              >
                            </li>
                          </ul>

                        </template>
                      </RowAccordion>


                    </div>
                  </div>
                </template>
                <template v-else-if="this.records.length == 0">
                  <div class="t-body no-records">
                    <p>No se encontraron registros</p>
                  </div>
                </template>
              </div>
            </template>


            <!-- leche procesada -->
            <template v-if="this.model.productoTransportado == 164">
              <div class="n-table seguimiento-rutas-lc sticky-header">
                <div class="t-header">
                  <div class="t-row">
                    <div class="t-cell">
                      placa de vehículo
                    </div>
                    <div class="t-cell">
                      nombre de ruta
                    </div>
                    <div class="t-cell">
                      región
                    </div>
                    <div class="t-cell">
                      producto transportado
                    </div>
                  </div>
                </div>
                <template v-if="this.records.length > 0">
                  <div class="t-body">
                    <div class="t-row"
                      v-for="record in records"
                      :key="record.id">
                      <div class="t-cell">
                        {{ record.numeroPlaca }}
                      </div>
                      <div class="t-cell">
                        {{ record.nombreRuta }}
                      </div>
                      <div class="t-cell">
                        {{ record.nombreRegion }}
                      </div>
                      <div class="t-cell">
                        {{ record.productoTransportado }}
                      </div>
                      <div class="t-cell">
                        <Button
                          class="m-0 primary-button w-100"
                          size="sm"
                          @click="goToRuta(record)"
                          >Registrar
                        </Button>
                      </div>


                      <RowAccordion>
                        <template v-slot:title>
                            <ul>
                              <li class="cell">
                                <div class="key">{{ record.numeroPlaca }}</div>
                                <div class="value">{{ record.nombreRegion }}</div>
                              </li>
                            </ul>
                        </template>
                        <template v-slot:content>

                          <ul>
                            <li class="cell">
                              <div class="key"><span>placa del vehículo</span></div>
                              <div class="value"><span>{{ record.numeroPlaca }}</span></div>
                            </li>
                            <li class="cell">
                              <div class="key"><span>región</span></div>
                              <div class="value">{{ record.nombreRegion }}</div>
                            </li>
                            <li class="cell">
                              <div class="key"><span>ruta</span></div>
                              <div class="value">{{ record.nombreRuta }}</div>
                            </li>
                            <li class="cell">
                              <Button
                                class="m-0 primary-button shadow-none w-100"
                                size="md"
                                @click="goToRuta(record)"
                                >Ver más</Button
                              >
                            </li>
                          </ul>

                        </template>
                      </RowAccordion>


                    </div>
                  </div>
                </template>
                <template v-else-if="this.records.length == 0">
                  <div class="t-body no-records">
                    <p>No se encontraron registros</p>
                  </div>
                </template>
              </div>
            </template>

            <div class="sticky-footer-box">
              <div class="standalone t-footer">
                <div>
                  <Pagination :source="pagination" @navigate="navigate"></Pagination>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { markRaw } from "vue";
  import { Form, Field } from "vee-validate";
  import { mapGetters, mapActions } from 'vuex';
  import TextInput from "@/components/Inputs/TextInput";
  import DateInput from "@/components/Inputs/Date";
  import SelectButton from "@/components/Inputs/SelectButton";
  import Button from "@/components/Inputs/Button";
  import CustomModal from "@/components/CustomModal.vue";
  import RowAccordion from "@/components/Accordion/RowAccordion";
  import Accordion from "@/components/Accordion/Accordion";
  import Pagination from '@/components/Pagination.vue';
  import { Util } from "@/util/util";
  import { format } from 'date-fns';
  import * as Yup from "yup";
  import { useOnline } from '@vueuse/core'
  import swal from "sweetalert2";
  
  export default {
    components: {
      TextInput,
      SelectButton,
      Button,
      CustomModal,
      RowAccordion,
      Accordion,
      Util,
      DateInput,
      Pagination,
      format,
      Form, 
      Field,
    },
    name: "Seguimiento de Ruta",
    data() {
      const schema = markRaw(Yup.object().shape({}));
      const formValues = {};
      return {
        onLine: true,
        showBackOnline: false,
        schema,
        formValues,
        noID: '',
        isSubmittingSearch: false,
        itsSubmitted: false,
        page: 1,
        records: [],
        pagination: {},
        periodoOptions: [],
        productoTransportadoOptions: [],
        model: {
          ruta: '',
          productoTransportado: '',
          fechaInicio: ''
        }
      };
    },
    computed: {
      ...mapGetters({
        user: 'auth/user',
        isConnected: 'connection/getConnectionStatus'
      }),
    },
    methods: {
      ...mapActions({
        obtenerTiposLeche: 'offline/obtenerTiposLeche',
        obtenerRutas: 'offline/obtenerRutas',
        getParams: "seguimiento/getParams",
        getLista: "seguimiento/getList",
        getDetalle: "seguimiento/getDetalle",
      }),
      navigate(page) {
  
        this.page = page;

        const data = {
          "pagina" : page,
          "idProductoTransportado": this.model.productoTransportado,
          "fecha": this.model.fechaInicio,
        }
  
        this.getLista(data).then((response) => {
        
          if (response.status == 200) {
  
            this.records = response.data.data;
  
            this.pagination = {
              pagina: response.data.pagina,
              paginas: response.data.paginas
            }
            
          }
  
        });

      },
      goToRuta(record) {
  
        let id = record.idRuta;

        if (this.model.productoTransportado == 162) { //LecheCaliente
          
          this.$router.push({
            
            name: "REGISTRO_LC",
    
            query:{
              prod: this.model.productoTransportado,
              fecha: this.model.fechaInicio,
              idRP: record.idRutaProgramacion
            },
    
            params: { id: id }
          })

        } else if (this.model.productoTransportado == 161) { //Leche Fría

          this.$router.push({
            
            name: "REGISTRO_LF",
    
            query:{
              prod: this.model.productoTransportado,
              fecha: this.model.fechaInicio,
              idRP: record.idRutaProgramacion
            },
    
            params: { id: id }
          })

        }  else if (this.model.productoTransportado == 163) { //Leche Acopio

          console.log('Leche Acopio');
          this.$router.push({
            
            name: "REGISTRO_LA",

            query:{
              prod: this.model.productoTransportado,
              fecha: this.model.fechaInicio,
              idRP: record.idRutaProgramacion
            },

            params: { id: id }
          })

        } else if (this.model.productoTransportado == 164) { //Leche Procesada

          this.$router.push({
            
            name: "REGISTRO_LP",
    
            query:{
              prod: this.model.productoTransportado,
              fecha: this.model.fechaInicio,
              idRP: record.idRutaProgramacion
            },
    
            params: { id: id }
          })

        }        
  
      },
      async getList() {

        const data = {
          "idProductoTransportado": this.model.productoTransportado,
          "fecha": this.model.fechaInicio,
        }

        console.log('navigator.onLine', navigator.onLine);

        if (navigator.onLine) {

          try {

            const response = await this.getLista(data);

            if (response.status === 200) {
              this.records = response.data.data;
              
              console.log('records', this.records);
              
              this.pagination = {
                pagina: response.data.pagina,
                paginas: response.data.paginas
              };
            }
          } catch (error) {}

        } else {

          try {

            const data = {
              "idProductoTransportado": this.model.productoTransportado,
              "fecha": this.model.fechaInicio,
            }

            this.records = await this.obtenerRutas(data);

            console.log('records', this.records);

          } catch (error) {

            console.log(error);

          }

        }

      },

    },
    async mounted() {

      let loader = this.$loading.show();

      this.productoTransportadoOptions = await this.obtenerTiposLeche();

      this.model.productoTransportado = this.productoTransportadoOptions.length ? this.productoTransportadoOptions[0].id : '';
      this.model.fechaInicio = format(new Date(), 'YYYY-MM-DD');
      this.formValues.fechaInicio = format(new Date(), 'YYYY-MM-DD');

      this.obtenerRutas();
      //this.cargarTransportistas(); //Solo al iniciar sesión

      loader.hide();
    }
  }
  </script>
  <style lang="scss" scoped>

    .c-card > .card-body {
      height: calc(100% - 150px);
    }

    .action-box .grid-container-2 {
      .grid-item-4 {
        grid-column: span 4;
      }
    }
  </style>