<template>
    <div class="m-container container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="c-card">
  
            <div class="card-header pb-0">
              <h1 class="mb-4">Registro de leche procesada</h1>
              <div class="hr mb-0"></div>
            </div>
  
            <div class="card-body pt-0 pb-0">
  
              <template v-if="!geolocationEnabled">
                
                <div>
                 <p>Por favor, habilite la geolocalización para continuar.</p>
                </div>
  
              </template>
  
              <template v-else>
  
                <ConfirmationScreen
                  class="pt-4"
                  :withHeader="false"
                >
                  <template v-slot:body>
  
                    <div class="field">
                        <div class="key">placa del vehículo</div>
                        <div class="value">{{ infoRuta.numeroPlaca }}</div>
                    </div>
                    <div class="field">
                        <div class="key">ruta</div>
                        <div class="value">{{ infoRuta.nombreRuta }}</div>
                    </div>
                    <div class="field">
                        <div class="key">región</div>
                        <div class="value">{{ infoRuta.nombreRegion }}</div>
                    </div>
  
                  </template>
                </ConfirmationScreen>
  
                <div class="add-incidencia">
                  <template v-if="infoRuta.horaLlegada">
                    <div class="inc-cta closed">
                      <span>Agregar incidencia</span><img class="ms-2" src="/assets/images/icons/incident.svg">
                    </div>
                  </template>
                  <template v-else>
                    <div class="inc-cta" @click="addIncidencia">
                      <span>Agregar incidencia</span><img class="ms-2" src="/assets/images/icons/incident.svg">
                    </div>
                  </template>
                  
                </div>
  
  
                <div class="hr mt-3 mb-3"></div>
  
  
                <div class="n-table sticky-header">
      

                    <div class="t-body">
                        <div
                          class="t-row"
                          v-for="record, index in this.listaRecojo"
                          :key="record.id"
                        >


                            <template v-if="index == 0">

                              <div class="show-desktop-only">

                                <h4 class="title-accesorios pt-4"><img v-if="record.horaLlegada !== ''" src="/assets/images/check-circle-fill.svg" class="me-1" alt="Home" width="20"/> Ruta de ida</h4>
                                <ul class="transportista-desktop-table pb-4">

                                  <li class="cell">
                                    <div class="key font-x2">
                                      <span>Inicio de carga</span>
                                      <span class="user-timedate" v-if="record.horaInicio !== ''" :class="{'disabled': record.horaInicio !== ''}">{{ $filters.showOnlyTime(record.horaInicio) }}</span>
                                      <span class="user-timedate" v-else>{{ formattedTime }}</span>
                                    </div>
                                    <div class="value">
                                      <Button
                                      :disabled="record.horaLlegada !== '' || record.horaInicio !== ''"
                                      class="m-0 info-button shadow-none w-100"
                                      size="md"
                                      type="button"
                                      @click="marcarInicioCarga(record.idRutaRecojo)"
                                      >
                                        <img class="me-2" src="/assets/images/icons/timer.svg" alt="Timer"/>
                                        <span>Marcar</span>
                                      </Button>
                                    </div>
                                  </li>

                                  <li class="cell">
                                    <div class="key font-x2">
                                      <span>Salida de planta</span>
                                      <span class="user-timedate" v-if="record.horaSalida !== ''" :class="{'disabled': record.horaSalida !== ''}">{{ $filters.showOnlyTime(record.horaSalida) }}</span>
                                      <span class="user-timedate" v-else>{{ formattedTime }}</span>
                                    </div>
                                    <div class="value">
                                        <Button
                                        :disabled="record.horaLlegada !== '' || record.horaSalida !== ''"
                                        class="m-0 info-button shadow-none w-100"
                                        size="md"
                                        type="button"
                                        @click="marcarSalidaPlanta(record.idRutaRecojo)"
                                        >
                                          <img class="me-2" src="/assets/images/icons/timer.svg" alt="Timer"/>
                                          <span>Marcar</span>
                                        </Button>
                                    </div>
                                  </li>

  
                                  <li class="cell">
                                    <div class="key font-x2">
                                      <span>Llegada a planta</span>
                                      <span class="user-timedate" v-if="record.horaLlegada !== ''" :class="{'disabled': record.horaLlegada !== ''}">{{ $filters.showOnlyTime(record.horaLlegada) }}</span>
                                      <span class="user-timedate" v-else>{{ formattedTime }}</span>
                                    </div>
                                    <div class="value">
                                        <Button
                                        :disabled="record.horaInicio === '' && record.horaSalida === '' || record.horaLlegada !== ''"
                                        class="m-0 info-button shadow-none w-100"
                                        size="md"
                                        type="button"
                                        @click="marcarLlegadaPlanta(record.idRutaRecojo, index)"
                                        >
                                          <img class="me-2" src="/assets/images/icons/timer.svg" alt="Timer"/>
                                          <span>Marcar</span>
                                        </Button>
                                    </div>
                                  </li>

                                </ul>

                              </div>

      
                              <RowAccordion :index="index" :openedIndex="record.isOpen">
                                <template v-slot:title>
                                    <ul>
                                      <li class="cell">
                                        <div class="key">
                                          <img v-if="record.horaLlegada !== ''" src="/assets/images/check-circle-fill.svg" class="me-1" alt="Home" width="20"/>
                                          <span>RUTA DE IDA</span>
                                        </div>
                                      </li>
                                    </ul>
                                </template>
                                <template v-slot:content>

                                    <div class="hr my-2"></div>
  
                                    <div class="cell">
                                        <div class="key font-x2">
                                          <span>Inicio de carga</span>
                                          <span class="user-timedate" v-if="record.horaInicio !== ''" :class="{'disabled': record.horaInicio !== ''}">{{ $filters.showOnlyTime(record.horaInicio) }}</span>
                                          <span class="user-timedate" v-else>{{ formattedTime }}</span>
                                        </div>
                                        <div class="value">
                                          <Button
                                          :disabled="record.horaLlegada !== '' || record.horaInicio !== ''"
                                          class="m-0 info-button shadow-none w-100"
                                          size="md"
                                          type="button"
                                          @click="marcarInicioCarga(record.idRutaRecojo)"
                                          >
                                            <img class="me-2" src="/assets/images/icons/timer.svg" alt="Timer"/>
                                            <span>Marcar</span>
                                          </Button>
                                        </div>
                                    </div>
  
                                    <div class="hr my-2"></div>

                                    <div class="cell">
                                        <div class="key font-x2">
                                          <span>Salida de planta</span>
                                          <span class="user-timedate" v-if="record.horaSalida !== ''" :class="{'disabled': record.horaSalida !== ''}">{{ $filters.showOnlyTime(record.horaSalida) }}</span>
                                          <span class="user-timedate" v-else>{{ formattedTime }}</span>
                                        </div>
                                        <div class="value">
                                            <Button
                                            :disabled="record.horaLlegada !== '' || record.horaSalida !== ''"
                                            class="m-0 info-button shadow-none w-100"
                                            size="md"
                                            type="button"
                                            @click="marcarSalidaPlanta(record.idRutaRecojo)"
                                            >
                                              <img class="me-2" src="/assets/images/icons/timer.svg" alt="Timer"/>
                                              <span>Marcar</span>
                                            </Button>
                                        </div>
                                    </div>

                                    <div class="hr my-2"></div>
  
                                    <div class="cell">
                                        <div class="key font-x2">
                                          <span>Llegada a planta</span>
                                          <span class="user-timedate" v-if="record.horaLlegada !== ''" :class="{'disabled': record.horaLlegada !== ''}">{{ $filters.showOnlyTime(record.horaLlegada) }}</span>
                                          <span class="user-timedate" v-else>{{ formattedTime }}</span>
                                        </div>
                                        <div class="value">
                                            <Button
                                            :disabled="record.horaInicio === '' && record.horaSalida === '' || record.horaLlegada !== ''"
                                            class="m-0 info-button shadow-none w-100"
                                            size="md"
                                            type="button"
                                            @click="marcarLlegadaPlanta(record.idRutaRecojo, index)"
                                            >
                                              <img class="me-2" src="/assets/images/icons/timer.svg" alt="Timer"/>
                                              <span>Marcar</span>
                                            </Button>
                                        </div>
                                    </div>
  
                                    
  
                                </template>
                              </RowAccordion>

                            </template>


                            <template v-if="index == 1">
                            
                              
                              <div class="show-desktop-only">

                                <h4 class="title-accesorios pt-4"><img v-if="record.horaLlegada !== ''" src="/assets/images/check-circle-fill.svg" class="me-1" alt="Home" width="20"/> Ruta de vuelta</h4>
                                <ul class="transportista-desktop-table pb-4">

                                  <li class="cell">
                                    <div class="key font-x2">
                                      <span>Inicio de carga</span>
                                      <span class="user-timedate" v-if="record.horaInicio !== ''" :class="{'disabled': record.horaInicio !== ''}">{{ $filters.showOnlyTime(record.horaInicio) }}</span>
                                      <span class="user-timedate" v-else>{{ formattedTime }}</span>
                                    </div>
                                    <div class="value">
                                      <Button
                                      :disabled="record.horaLlegada !== '' || record.horaInicio !== '' || isHoraLlegadaEmpty"
                                      class="m-0 info-button shadow-none w-100"
                                      size="md"
                                      type="button"
                                      @click="marcarInicioCarga(record.idRutaRecojo)"
                                      >
                                        <img class="me-2" src="/assets/images/icons/timer.svg" alt="Timer"/>
                                        <span>Marcar</span>
                                      </Button>
                                    </div>
                                  </li>

                                  <li class="cell">
                                    <div class="key font-x2">
                                      <span>Salida de planta</span>
                                      <span class="user-timedate" v-if="record.horaSalida !== ''" :class="{'disabled': record.horaSalida !== ''}">{{ $filters.showOnlyTime(record.horaSalida) }}</span>
                                      <span class="user-timedate" v-else>{{ formattedTime }}</span>
                                    </div>
                                    <div class="value">
                                        <Button
                                        :disabled="record.horaLlegada !== '' || record.horaSalida !== '' || isHoraLlegadaEmpty"
                                        class="m-0 info-button shadow-none w-100"
                                        size="md"
                                        type="button"
                                        @click="marcarSalidaPlanta(record.idRutaRecojo)"
                                        >
                                          <img class="me-2" src="/assets/images/icons/timer.svg" alt="Timer"/>
                                          <span>Marcar</span>
                                        </Button>
                                    </div>
                                  </li>


                                  <li class="cell">
                                    <div class="key font-x2">
                                      <span>Llegada a planta</span>
                                      <span class="user-timedate" v-if="record.horaLlegada !== ''" :class="{'disabled': record.horaLlegada !== ''}">{{ $filters.showOnlyTime(record.horaLlegada) }}</span>
                                      <span class="user-timedate" v-else>{{ formattedTime }}</span>
                                    </div>
                                    <div class="value">
                                        <Button                                        
                                        :disabled="record.horaInicio === '' && record.horaSalida === '' || record.horaLlegada !== ''"
                                        class="m-0 info-button shadow-none w-100"
                                        size="md"
                                        type="button"
                                        @click="marcarLlegadaPlanta(record.idRutaRecojo, index)"
                                        >
                                          <img class="me-2" src="/assets/images/icons/timer.svg" alt="Timer"/>
                                          <span>Marcar</span>
                                        </Button>
                                    </div>
                                  </li>

                                </ul>

                              </div>


                              <RowAccordion :index="index" :openedIndex="record.isOpen">
                                <template v-slot:title>
                                    <ul>
                                      <li class="cell">
                                        <div class="key">
                                          <img v-if="record.horaLlegada !== ''" src="/assets/images/check-circle-fill.svg" class="me-1" alt="Home" width="20"/>
                                          <span>RUTA DE VUELTA</span>
                                        </div>
                                      </li>
                                    </ul>
                                </template>
                                <template v-slot:content>

                                    <div class="hr my-2"></div>
  
                                    <div class="cell">
                                        <div class="key font-x2">
                                          <span>Inicio de carga</span>
                                          <span class="user-timedate" v-if="record.horaInicio !== ''" :class="{'disabled': record.horaInicio !== ''}">{{ $filters.showOnlyTime(record.horaInicio) }}</span>
                                          <span class="user-timedate" v-else>{{ formattedTime }}</span>
                                        </div>
                                        <div class="value">
                                          <Button
                                          :disabled="record.horaLlegada !== '' || record.horaInicio !== '' || isHoraLlegadaEmpty"
                                          class="m-0 info-button shadow-none w-100"
                                          size="md"
                                          type="button"
                                          @click="marcarInicioCarga(record.idRutaRecojo)"
                                          >
                                            <img class="me-2" src="/assets/images/icons/timer.svg" alt="Timer"/>
                                            <span>Marcar</span>
                                          </Button>
                                        </div>
                                    </div>
  
                                    <div class="hr my-2"></div>

                                    <div class="cell">
                                        <div class="key font-x2">
                                          <span>Salida de planta</span>
                                          <span class="user-timedate" v-if="record.horaSalida !== ''" :class="{'disabled': record.horaSalida !== ''}">{{ $filters.showOnlyTime(record.horaSalida) }}</span>
                                          <span class="user-timedate" v-else>{{ formattedTime }}</span>
                                        </div>
                                        <div class="value">
                                            <Button
                                            :disabled="record.horaLlegada !== '' || record.horaSalida !== '' || isHoraLlegadaEmpty"
                                            class="m-0 info-button shadow-none w-100"
                                            size="md"
                                            type="button"
                                            @click="marcarSalidaPlanta(record.idRutaRecojo)"
                                            >
                                              <img class="me-2" src="/assets/images/icons/timer.svg" alt="Timer"/>
                                              <span>Marcar</span>
                                            </Button>
                                        </div>
                                    </div>

                                    <div class="hr my-2"></div>
  
                                    <div class="cell">
                                        <div class="key font-x2">
                                          <span>Llegada a planta</span>
                                          <span class="user-timedate" v-if="record.horaLlegada !== ''" :class="{'disabled': record.horaLlegada !== ''}">{{ $filters.showOnlyTime(record.horaLlegada) }}</span>
                                          <span class="user-timedate" v-else>{{ formattedTime }}</span>
                                        </div>
                                        <div class="value">
                                            <Button
                                            :disabled="record.horaInicio === '' && record.horaSalida === '' || record.horaLlegada !== ''"
                                            class="m-0 info-button shadow-none w-100"
                                            size="md"
                                            type="button"
                                            @click="marcarLlegadaPlanta(record.idRutaRecojo, index)"
                                            >
                                              <img class="me-2" src="/assets/images/icons/timer.svg" alt="Timer"/>
                                              <span>Marcar</span>
                                            </Button>
                                        </div>
                                    </div>
  
                                    
  
                                </template>
                              </RowAccordion>
                            
                            </template>




                            
                        </div>
                    </div>
  
                </div>
    
  

  
              </template>
  
            </div>
  
          </div>
        </div>
      </div>
  
  

  
      <transition name="fade">
        <CustomModal
          :show="dataModalIncidencia.showModal"
          :isHeader="true"
          :isFooter="false"
          :width="450"
          :title="'Agregar incidencia'"
          :closeIcon="false"
          v-on:modal:close="hideModalIncidencia()"
        >
          <template v-slot:body>
  
            <Form
              @submit="submitIncidencia"
              :validation-schema="schemaIncidencia"
              :initial-values="formValuesIncidencia"
              ref="formDataIncidencia"
            >
  
              <div class="row">
  
                <div class="col-md-12">
  
                  <ul class="metadata-ev-inc">
                    <li>
                      <img src="/assets/images/icons/calendar.svg" alt="Ubicación" class="me-2">
                      <span>{{ date }}</span>
                    </li>
                    <li>
                      <img src="/assets/images/icons/time.svg" alt="Ubicación" class="me-2">
                      <span>{{ formattedTime }}</span>
                    </li>
                    <li>
                      <img src="/assets/images/icons/pin.svg" alt="Ubicación" class="me-2">
                      <span>{{ direccion }}</span>
                    </li>
                  </ul>
  
                  <Textarea
                    label="describir la incidencia"
                    name="incidencia"
                    class="message mt-4"
                  v-model="incidenciaDescripcion"
                  ></Textarea>
  
                  <div class="dropZoneEvidencias">
  
                    <label class="button-file-upload">
                      <input class="m-0 button camera-button shadow-none" type="file" @change.prevent="onFileChangeIncidencia" accept="image/*">
                        <img class="me-2"  src="/assets/images/icons/takePhoto.svg">
                        <span>Tomar foto</span>
                    </label>
  
                  </div>
  
                </div>
  
                <div class="col-md-12">
                  <p class="info-evidencia">Recuerda: Cada foto no debe pasar el tamaño de 300MB</p>
                </div>
  
                <div class="col-md-12">
  
                  <ul class="grid-item-image">
                    <li class="grid-item" v-for="(image, index) in imagesIncidencia" :key="index">
                      <div class="image">
                        <img :src="image.url" />
                      </div>
                      <div class="text-image">
                        <p class="m-0">{{ image.name }}</p>
                      </div>
                      <div class="delete-action">
                        <DeleteButton
                          :idx="index"
                          :deleteR="deleteImageIncidencia"
                        />
                      </div>
                    </li>                  
                  </ul>
  
                </div>
  
              </div>
  
              <div class="hr my-4"></div>
  
              <div class="form-actions">
                <Button
                  class="mt-3 ghost-button shadow-none"
                  size="lg"
                  type="button"
                  @click="hideModalIncidencia()"
                  >Cancelar</Button
                >
  
                <Button
                  class="mt-3 primary-button shadow-none"
                  size="lg"
                  type="submit"
                  >Guardar</Button
                >
              </div>
  
            </Form>
  
          </template>
  
        </CustomModal>
      </transition>
  
  
    </div>
  </template>
  
  <script>
  
  import { markRaw } from "vue";
  import { Form, Field } from "vee-validate";
  import {mapActions, mapGetters} from 'vuex';
  import TextInput from "@/components/Inputs/TextInput";
  import Textarea from "@/components/Inputs/Textarea";
  import Button from "@/components/Inputs/Button";
  import SelectButton from "@/components/Inputs/SelectButton";
  import Pagination from '@/components/Pagination.vue';
  import CustomModal from "@/components/CustomModal.vue";
  import RowAccordion from "@/components/Accordion/RowAccordion";
  import ConfirmationScreen from "@/components/ConfirmationScreen";
  import DeleteButton from '@/components/DeleteButton.vue'
  import { format } from "date-fns";
  import Tag from "@/components/Tags/Tag";
  import html2canvas from 'html2canvas'
  import * as Yup from "yup";
  
  export default {
    components: {
      TextInput,
      Textarea,
      Button,
      Pagination,
      CustomModal,
      RowAccordion,
      ConfirmationScreen,
      Tag,
      DeleteButton,
      html2canvas,
      Form,
      Field,
      SelectButton
    },
    name: "NegociacionDePrecio",
    data() {
      
      const schemaCantidadLeche = markRaw(
        Yup.object().shape({
  
        })
      );
      const formValuesCantidadLeche = {};
  
  
      const schemaEvidencia = markRaw(
        Yup.object().shape({
  
        })
      );
      const formValuesEvidencia = {};
  
      const schemaIncidencia = markRaw(
        Yup.object().shape({
  
        })
      );
      const formValuesIncidencia = {};
  
      
      return {
  
        google: null,
        googleMapsLoaded: false,
        latitude: '',
        longitude: '',
        direccion: '',
        date: format(new Date(), 'DD/MM/YYYY'),
  
        idTipoSeguimientoIncidenciaOLlegada: 1,
        idTipoSeguimiento: 2,
        idTipoRecorrido: 1,
  
        idRutaRecojoIncidencia: '',
        direccionIncidencia: '',
        descripcionIncidencia: '',
        isSubmittingModalIncidencia: false,
  
        filesImagesIncidencia: [],
        imagesIncidencia: [],
  
        isSubmittingModal: false,
  
        geolocationEnabled: false,
  
  
        step2FinalCarga: false,


        step1InicioCarga: true,
        step2SalidaPlanta: false,
        step3LlegadaPlanta: false,

        
        incidenciaDescripcion: '',


        loadKg: false,
  
        latitudInicioCarga: '',
        longitudInicioCarga: '',
  
        latitudFinCarga: '',
        longitudFinCarga: '',
  
  
        idRutaRecojo: '',
  
        idRutaRecojoEvidencia: '',
        latitudEvidencia: '',
        longitudEvidencia: '',
        fechaRegistroEvidencia: '',
        direccionEvidencia: '',
        descripcionEvidencia: '',
        filesImages: [],
        images: [],
  
        time: new Date(),
  
  
        schemaCantidadLeche,
        formValuesCantidadLeche,
  
        schemaEvidencia,
        formValuesEvidencia,
  
        schemaIncidencia,
        formValuesIncidencia,
  
  
        imageUrl: null,
  
  
  
        page: 1,
        ganaderosActivos: 0,
        ganaderosInactivos: 0,
        ganaderoCodigo: '',
        roleAllowed: true,
        pagination: {},
        records: [],
  
        tableClassName: 'lista-registro-leche-caliente',
        infoRuta: {},
        listaRecojo: [],
        model: {
          cantidadLeche: 0
        },
        virtual: {
          cantidadLeche: 0,
          time1: {
            id: '',
            name: ''
          },
          time2: {
            id: '',
            name: ''
          }
        },
        dataModal: {
          showModal: false,
          title: "",
          active: "",
          request: "",
          response: "",
        },
        dataModalEvidencia: {
          showModal: false,
          title: "",
          active: "",
          request: "",
          response: "",
        },
        dataModalIncidencia: {
          showModal: false,
          title: "",
          active: "",
          request: "",
          response: "",
        },
        virtualIndex: ''
      };
    },
    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),
  
      formattedTime() {
        return format(this.time, 'h:mm a');
      },
      isHoraLlegadaEmpty() {
        return this.listaRecojo[0].horaLlegada === '';
      }
    },
    watch: {
  
    },
    methods: {
      ...mapActions({
        getDetalle: 'seguimiento/getDetalle',
        postSeguimiento: 'seguimiento/postSeguimiento',
        postEvidencia: 'seguimiento/postEvidencia',
        postIncidencia: 'seguimiento/postIncidencia',

        guardarCoordenadas: 'offline/guardarCoordenadas',
        getDetalleOffline: 'offline/getDetalleOffline',
        guardarSeguimientoOffline: 'offline/guardarSeguimientoOffline',
        obtenerSeguimientoOffline: 'offline/obtenerSeguimientoOffline',
        getOfflineTransportista: 'mantenimiento/getOfflineTransportista',
        getOfflineTrackingByAction: 'offline/getOfflineTrackingByAction',
        saveOfflineTracking: 'offline/saveOfflineTracking',
        mixOffline: 'offline/mixOffline',
        guardarRutas: 'offline/guardarRutas',
        updateOfflineTracking: 'offline/updateOfflineTracking',
        deleteOfflineTracking: 'offline/deleteOfflineTracking',
        eliminarTodasEvidenciaOffline: 'offline/eliminarTodasEvidenciaOffline',
        guardarEvidenciaOffline: 'offline/guardarEvidenciaOffline',
        obtenerEvidenciaOffline: 'offline/obtenerEvidenciaOffline',
        deleteOfflineEvidencia: 'offline/deleteOfflineEvidencia',
        guardarIncidenciaOffline: 'offline/guardarIncidenciaOffline',
      obtenerIncidenciaOffline: 'offline/obtenerIncidenciaOffline'
      }),
      closeAccordion() {
        const index = this.virtualIndex;
        this.listaRecojo[index].isOpen = index;
      },
      setVirtualIndex(index) {
        this.virtualIndex = index;
      },
      goToDetalle(id) {
        this.$router.push({ name: "Registro Maestro Ganadero", params: { id: id } })
      },
      hideModal() {
        this.dataModal.showModal = false;
      },
      async submitForm(values, { resetForm }) {
  
        this.isSubmittingModal = true;
  
        const data = {
          codigoProveedor: this.ganaderoCodigo,
          estado: values.estado
        }      
  
        await this.updateState(data).then((response) => {
  
          if (response.status == 200) {
  
            this.updateList();
            this.hideModal();
  
          }
  
        }).catch((err)=>{
  
          this.hideModal();
          this.$filters.onErrorBase(err);
  
        });
  
        this.isSubmittingModal = false;
  
        resetForm();
  
      },
      updateCantidadLeche(idRutaRecojo) {
  
        this.idRutaRecojo = idRutaRecojo;
        this.formValuesCantidadLeche.cantidadLeche = this.model.cantidadLeche;
        this.virtual.cantidadLeche = this.model.cantidadLeche;
        this.dataModal.showModal = true;
      },
      async marcarInicioCarga(idRutaRecojo) {
  
        const obj = {
          message: `Estas marcando la hora de inicio de carga<br><br><span class="user-time-modal">${this.formattedTime}<span>`,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          withCallback: true
        }
  
        this.$filters.showWarningMessage(obj, async (confirmed) => {
            
          if (confirmed) {


            const data = {
              "idRutaReferencia": idRutaRecojo,
              "idTipoSeguimiento": this.idTipoSeguimiento,
              "idTipoRecorrido": this.idTipoRecorrido,
              "fechaRegistro": this.$route.query.fecha,
              "horaInicio": this.formattedTime + " - " + format(new Date(), 'DD/MM/YYYY'),
              "latitudInicio": this.latitude,
              "longitudInicio": this.longitude,
              "idRutaProgramacion": this.$route.query.idRP,

              //IDB
              "idRuta": this.$route.params.id,
              "idProductoTransportado": this.$route.query.prod,
              "codigoTransportista": this.user.usuario
            }
  

            if (navigator.onLine) {

              try {
                const response = await this.postSeguimiento(data);
                if (response.status == 200) {

                  this.getList();
                  
                  await this.actualizarOffline();
                }
              } catch (error) {}

            } else {

              // Manejar el offline aquí
              localStorage.setItem('pendingRequests', 1);

              try {

                await this.saveOfflineTracking({action: 'OFFLINE_TRACK_PR', idRutaRecojo: idRutaRecojo, needToSend: true});

                await this.guardarSeguimientoOffline(data);

                this.getList();

              } catch (error) {

                console.log(error);

              }

            }
            
  
          }
            
        });
  
      },
      async marcarSalidaPlanta(idRutaRecojo) {
  
        const obj = {
          message: `Estas marcando la hora de salida a planta<br><br><span class="user-time-modal">${this.formattedTime}<span>`,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          withCallback: true
        }
  
        this.$filters.showWarningMessage(obj, async (confirmed) => {
            
          if (confirmed) {
  
            
            const data = {
              "idRutaReferencia": idRutaRecojo,
              "idTipoSeguimiento": this.idTipoSeguimiento,
              "idTipoRecorrido": this.idTipoRecorrido,
              "fechaRegistro": this.$route.query.fecha,
              "horaSalida": this.formattedTime + " - " + format(new Date(), 'DD/MM/YYYY'),
              "latitudFin": this.latitude,
              "longitudFin": this.longitude,
              "cantidadkilos": this.model.cantidadLeche,
              "idRutaProgramacion": this.$route.query.idRP,

              //IDB
              "idRuta": this.$route.params.id,
              "idProductoTransportado": this.$route.query.prod,
              "codigoTransportista": this.user.usuario
            }

            
            if (navigator.onLine) {

              try {
                const response = await this.postSeguimiento(data);
                if (response.status == 200) {

                  this.getList();
                  
                  await this.actualizarOffline();
                }
              } catch (error) {}

            } else {

              // Manejar el offline aquí
              localStorage.setItem('pendingRequests', 1);

              try {

                await this.saveOfflineTracking({action: 'OFFLINE_TRACK_PR', idRutaRecojo: idRutaRecojo, needToSend: true});

                await this.guardarSeguimientoOffline(data);

                this.getList();

              } catch (error) {

                console.log(error);

              }

            }

  
          }
            
        });
  
      },
      marcarLlegadaPlanta(idRutaRecojo, index) {

        if (!navigator.onLine) {
            const obj = {
            message: `Por favor habilita los datos antes de marcar llegada a planta`,
            confirmButtonText: 'Aceptar',
            withCallback: false
          }

          this.$filters.showWarningMessage(obj);
          return;
        }

        this.setVirtualIndex(index);
  
        const obj = {
          message: `Estas marcando la hora de llegada a planta<br><br><span class="user-time-modal">${this.formattedTime}<span>`,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          withCallback: true
        }

        this.$filters.showWarningMessage(obj, (confirmed) => {
            
          if (confirmed) {

            //Obtener coordenadas
            navigator.geolocation.getCurrentPosition((position) => {

              const data = {
                "idRutaReferencia": idRutaRecojo,
                "idTipoSeguimiento": this.idTipoSeguimiento,
                "idTipoRecorrido": this.idTipoRecorrido,
                "fechaRegistro": this.$route.query.fecha,
                "horaLlegada": this.formattedTime + " - " + format(new Date(), 'DD/MM/YYYY'),
                "latitudFin": position.coords.latitude,
                "longitudFin": position.coords.longitude,
                "cantidadkilos": this.model.cantidadLeche,
                "idRutaProgramacion": this.$route.query.idRP
              }

              this.postSeguimiento(data).then((response) => {

                if (response.status == 200) {

                  this.closeAccordion();
                  this.getList();

                }

              }).catch((err)=>{

                this.$filters.onErrorBase(err);

              });

            });

          }
            
        });

      },
        
  
  
  
  
  
  
      showModalEvidencia() {
        this.dataModalEvidencia.showModal = true;
      },
      hideModalEvidencia() {
        this.dataModalEvidencia.showModal = false;
        this.images = [];
        this.$refs.formDataEvidencia.resetForm();
      },
      addEvidencia(idRutaRecojo) {
        this.idRutaRecojoEvidencia = idRutaRecojo;
        this.showModalEvidencia();
      },
      onFileChange(event) {
        if (event.target.files && event.target.files[0]) {
          const file = event.target.files[0];
          const reader = new FileReader();
          reader.onload = (e) => {
            const imageUrl = e.target.result;
            const imageName = file.name;
            this.images.push({ url: imageUrl, name: imageName });
            this.filesImages.push(file);
          };
          reader.readAsDataURL(file);
        }
      },
      deleteImage(index) {
        this.images.splice(index, 1);
        this.filesImages.splice(index, 1);
  
        console.log('this.images', this.images);
      },
      submitEvidencia() {
  
        if (this.images.length == 0) {
          this.hideModalEvidencia();
          this.$filters.showErrorMessage('Ingrese imágenes para enviar la evidencia');
          return;
        }
  
        this.isSubmittingModal = true;
  
        navigator.geolocation.getCurrentPosition((position) => {
  
          var formData = new FormData();
          formData.append('idRutaReferencia', this.idRutaRecojoEvidencia);
          formData.append('idTipoSeguimiento', 2);
          formData.append('fechaRegistro', `${this.$route.query.fecha}`); //format(new Date(), 'YYYY-MM-DD')
          formData.append('hora', this.formattedTime);
  
          formData.append('latitud', position.coords.latitude); //Latitud
          formData.append('longitud', position.coords.longitude); //Longitud
  
          formData.append('direccion', this.direccionEvidencia ? this.direccionEvidencia : "-");
          formData.append('descripcion', this.descripcionEvidencia ? this.descripcionEvidencia : "-");
          formData.append('idRutaProgramacion', this.$route.query.idRP);
          
          
          this.filesImages.map((x)=> {
            formData.append('files', x);
          });
  
          console.log('this.filesImages', this.filesImages);
  
          this.postEvidencia(formData).then((response) => {
  
            if (response.status == 200) {
              
              this.isSubmittingModal = false;
  
              this.hideModalEvidencia();
  
              const obj = {
                message: 'Se agregaron las evidencias con éxito',
                confirmButtonText: 'Cerrar',
                withCallback: false
              }
  
              this.$filters.showSuccessMessage(obj);
            }
  
          }).catch((err) => {
  
            this.isSubmittingModal = false;
  
            this.hideModalEvidencia();
            this.$filters.onErrorBase(err);
  
          });
  
  
  
        });
  
      },
  
  
  
  
      showModalIncidencia() {
        this.dataModalIncidencia.showModal = true;
      },
      hideModalIncidencia() {
        this.dataModalIncidencia.showModal = false;
        this.images = [];
        this.clearIncidencias();
      },
      addIncidencia() {
        
        this.idRutaRecojoIncidencia = 1;
        this.showModalIncidencia();
  
      },
      onFileChangeIncidencia(event) {
        if (event.target.files && event.target.files[0]) {
          const file = event.target.files[0];
          const reader = new FileReader();
          reader.onload = (e) => {
            const imageUrl = e.target.result;
            const imageName = file.name;
            this.imagesIncidencia.push({ url: imageUrl, name: imageName });
            
            this.filesImagesIncidencia.push(file);
          };
          reader.readAsDataURL(file);
        }
      },
      deleteImageIncidencia(index) {
        this.imagesIncidencia.splice(index, 1);
        this.filesImagesIncidencia.splice(index, 1);
      },
      clearIncidencias() {
        this.incidenciaDescripcion = '';
        this.imagesIncidencia = [];
      },
      async submitIncidencia(values) {


      //////////////////////////////////////////////////////

      if (this.imagesIncidencia.length == 0) {
        this.hideModalEvidencia();
        this.$filters.showErrorMessage('Ingrese imágenes para enviar la incidencia');
        return;
      }

      this.isSubmittingModal = true;

      this.descripcionIncidencia = values.incidencia;

      var formData = new FormData();
      formData.append('idRutaReferencia', this.$route.params.id);
      formData.append('idTipoSeguimiento', this.idTipoSeguimientoIncidenciaOLlegada);
      formData.append('fechaRegistro', `${this.$route.query.fecha}`); //format(new Date(), 'YYYY-MM-DD')
      formData.append('hora', this.formattedTime);

      formData.append('latitud', this.latitude); //Latitud
      formData.append('longitud', this.longitude); //Longitud

      formData.append('direccion', this.direccionIncidencia ? this.direccionIncidencia : "-");
      formData.append('descripcion', this.descripcionIncidencia ? this.descripcionIncidencia : "-");
      formData.append('idRutaProgramacion', this.$route.query.idRP);
      
      this.filesImagesIncidencia.map((x)=> {
        formData.append('files', x);
      });


      if (navigator.onLine) {

        const response = await this.postIncidencia(formData);
        if (response.status == 200) {

          this.hideModalIncidencia();

          const obj = {
            message: 'Se agregaron las incidencias con éxito',
            confirmButtonText: 'Cerrar',
            withCallback: false
          }

          this.$filters.showSuccessMessage(obj);

          this.$refs.formDataIncidencia.resetForm();

        }

      } else {


        // Manejar el offline aquí
        localStorage.setItem('pendingRequests', 3);
        
        try {

          console.log('offline enviar ruta recojo', this.$route.params.id);
          const data = {
            idRutaReferencia: this.$route.params.id,
            idTipoSeguimiento: this.idTipoSeguimientoIncidenciaOLlegada,
            fechaRegistro: `${this.$route.query.fecha}`,
            hora: this.formattedTime,

            latitud: this.latitude,
            longitud: this.longitude,

            direccion: this.direccionIncidencia ? this.direccionIncidencia : "-",
            descripcion: this.descripcionIncidencia ? this.descripcionIncidencia : "-",
            idRutaProgramacion: this.$route.query.idRP,
            files: this.filesImagesIncidencia
          }
          
          await this.saveOfflineTracking({action: 'INCIDENCIA_PR', idRutaRecojo: this.$route.params.id, needToSend: true});

          console.log('guardar incidencia Offline', data);
          await this.guardarIncidenciaOffline(data);

          this.hideModalIncidencia();

          const obj = {
            message: 'Se agregaron las incidencias con éxito',
            confirmButtonText: 'Cerrar',
            withCallback: false
          }

          this.$filters.showSuccessMessage(obj);

          this.$refs.formDataIncidencia.resetForm();


          this.isSubmittingModal = false;
          
          this.clearIncidencias();

          /*setTimeout(() => {
            location.reload();
          }, 1000);*/

        } catch (error) {

          console.log(error);

        }


      }
      //////////////////////////////////////////////////////

    },
      checkLlegadaPlanta() {
  

        if (!navigator.onLine) {
            const obj = {
            message: `Por favor habilita los datos antes de marcar llegada a planta`,
            confirmButtonText: 'Aceptar',
            withCallback: false
          }

          this.$filters.showWarningMessage(obj);
          return;
        }

  
        const obj = {
          message: `Estas marcando tu llegada a planta<br><br><span class="user-time-modal">${this.formattedTime}<span>`,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          withCallback: true
        }
  
        this.$filters.showWarningMessage(obj, (confirmed) => {
            
          if (confirmed) {
  
            //Obtener coordenadas
            navigator.geolocation.getCurrentPosition((position) => {
  
              const data = {
                "idRutaReferencia": this.$route.params.id,
                "idTipoSeguimiento": this.idTipoSeguimientoIncidenciaOLlegada,
                "idTipoRecorrido": this.idTipoRecorrido,
                "fechaRegistro": this.$route.query.fecha,
                "horaLlegada": this.formattedTime + " - " + format(new Date(), 'DD/MM/YYYY'),
                "latitudLlegada": position.coords.latitude,
                "longitudLlegada": position.coords.longitude,
                "idRutaProgramacion": this.$route.query.idRP
              }
  
              this.postSeguimiento(data).then((response) => {
  
                if (response.status == 200) {
  
                  this.step1InicioCarga = false;
                  this.step2FinalCarga = false;
  
                  this.getList();
  
                }
  
              }).catch((err)=>{
  
                this.$filters.onErrorBase(err);
  
              });
  
            });
  
          }
            
        });
  
      },
      enableGeoLocation() {
        if (navigator.geolocation) {
  
          navigator.geolocation.getCurrentPosition((position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
  
            this.geolocationEnabled = true;
  
            // haz algo con la latitud y longitud
            this.latitude = latitude;
            this.longitude = longitude;
  
          }, () => {
            console.log("No se pudo obtener la ubicación");
          });
  
          } else {
  
          console.log("La geolocalización no está disponible");
  
        }
      },



      getGeoLocation() {
        const API_KEY_GOOGLE_MAPS = 'AIzaSyDrHYuv4x2ZC0nDA39DMo4i7VsXX5wzFuo';

        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY_GOOGLE_MAPS}&libraries=places`;
        script.defer = true;
        script.async = true;

        let scriptLoaded = false;

        script.onload = () => {
          scriptLoaded = true;
          const coordenadas = {
            lat: this.latitude, //37.7749
            lng: this.longitude //-122.4194
          };

          try {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: coordenadas }, (results, status) => {
              if (status === "OK") {
                if (results[0]) {
                  console.log(results[0].formatted_address);
                  this.direccion = results[0].formatted_address;
                } else {
                  console.log("No se encontraron resultados");
                  this.direccion = 'Ubicación no disponible';
                }
              } else {
                console.log("Falló la búsqueda con el siguiente estado: " + status);
              }
            });
  
          } catch (error) {
            console.log("Error offline: " + error);
          }

        };

        script.onerror = () => {
          console.error("Error al cargar la biblioteca de Google Maps");
        };

        // verifica si el script de Google Maps se ha cargado después de 5 segundos
        setTimeout(() => {
          if (!scriptLoaded) {
            console.error("No se pudo cargar la biblioteca de Google Maps");
          }
        }, 5000);

        document.head.appendChild(script);
      },



      async getList() {

        var data = {
          "idRuta": this.$route.params.id,
          "idProductoTransportado": this.$route.query.prod,
          "fecha": this.$route.query.fecha,
          "idRutaProgramacion": this.$route.query.idRP
        }

        if (navigator.onLine) {
          const response = await this.getDetalle(data);
          
          if (response.status == 200) {

            if (response.data.infoRuta === undefined) {
              this.infoRuta = {};
            } else {
              this.infoRuta = response.data.infoRuta;
            }          

            console.log('this.infoRuta', this.infoRuta);

            this.listaRecojo = response.data.listaRecojo.map(recojo => ({
              ...recojo,
              existeHoraLlegada: recojo.horaLlegada !== '',
              isOpen: true
            }));

            this.listaRecojo = [...this.listaRecojo].splice(0, 2);

          }
        } else {

          try {

            const data = {
              "idRuta": this.$route.params.id,
              "idProductoTransportado": this.$route.query.prod,
              "fecha": this.$route.query.fecha,
              "idRutaProgramacion": this.$route.query.idRP
            }

            const response = await this.getDetalleOffline(data);
          
            console.log('info ruta offline', response.data.infoRuta);
            this.infoRuta = response.data.infoRuta;


            this.listaRecojo = response.data.listaRecojo.map(recojo => ({
              ...recojo,
              existeHoraLlegada: recojo.horaLlegada !== '',
              isOpen: true
            }));

            this.listaRecojo = [...this.listaRecojo].splice(0, 2);

          } catch (error) {

            console.log(error);

          }

        }

      },

      verifyOffline() {
        let offline = localStorage.getItem('offline');

        if (!navigator.onLine) {
          if (!offline) {

            const obj = {
              message: `Sin conexión.<br> Puedes seguir trabajando en modo offline`,
              confirmButtonText: 'Ok',
              cancelButtonText: false,
              withCallback: false
            }

            this.$filters.showWarningMessage(obj);
            
            localStorage.setItem('offline', '1');
            
            setTimeout(() => {
              location.reload();
            }, 2000);
            
          } else {
            console.log('Estás offline');
          }
        } else {
          localStorage.removeItem('offline');
        }
      },


      async actualizarOffline() {

        await this.getList();

        const data = {
          "idRutaProgramacion": this.$route.query.idRP,
          "idRuta": this.$route.params.id,
          "idProductoTransportado": this.$route.query.prod,
          "codigoTransportista": this.user.usuario,
          "listaRecojo": this.listaRecojo
        }

        this.mixOffline(data);

      },

      async sendOffline() {

        try {

          const needed = await this.getOfflineTrackingByAction({ action: 'OFFLINE_TRACK_PR' });

          if (!needed) {
            return;
          }

          if (needed.needToSend) {
            
            let loaderOffline = this.$loading.show();

            const data = {
              "idRutaProgramacion": this.$route.query.idRP,
              "idRuta": this.$route.params.id,
              "idProductoTransportado": this.$route.query.prod,
              "codigoTransportista": this.user.usuario
            }

            const list = await this.obtenerSeguimientoOffline(data);

            console.log('list offline to send', list);

            list.detalle.map(async(x) => {

              let latitudInicio = this.latitude;
              let longitudInicio = this.longitude;

              let latitudFin = this.latitude;
              let longitudFin = this.longitude;

              let latitudSalida = this.latitude;
              let longitudSalida = this.longitude;

              let latitudLlegada = this.latitude;
              let longitudLlegada = this.longitude;

              const payload = {
                "idRutaReferencia": x.idRutaRecojo,
                "idTipoSeguimiento": this.idTipoSeguimiento,
                "idTipoRecorrido": this.idTipoRecorrido,
                "fechaRegistro": this.$route.query.fecha,
                
                "horaInicio": x.horaInicio ? x.horaInicio : "",
                "latitudInicio": latitudInicio,
                "longitudInicio": longitudInicio,

                "horaFin": x.horaFin ? x.horaFin : "",
                "latitudFin": latitudFin,
                "longitudFin": longitudFin,

                "cantidadKilos": x.cantidadKilos ? x.cantidadKilos : 0,
                "temperaturaRecojo": x.temperaturaRecojo ? x.temperaturaRecojo : 0,

                "horaSalida": x.horaSalida ? x.horaSalida : "",
                "latitudSalida": latitudSalida,
                "longitudSalida": longitudSalida,

                "horaLlegada": x.horaLlegada ? x.horaLlegada : "",
                "latitudLlegada": latitudLlegada,
                "longitudLlegada": longitudLlegada,

                "idRutaProgramacion": this.$route.query.idRP,

                //IDB
                "idRuta": this.$route.params.id,
                "idProductoTransportado": this.$route.query.prod,
                "codigoTransportista": this.user.usuario
              }

              try {
                const response = await this.postSeguimiento(payload);
                if (response.status == 200) {

                  await this.deleteOfflineTracking({action: 'OFFLINE_TRACK_PR', idRutaRecojo: x.idRutaRecojo, needToSend: false});
                    
                  const needed = await this.getOfflineTrackingByAction({ action: 'INCIDENCIA_PR' });
                  
                  if (!needed) {
                    
                    loaderOffline.hide();

                    setTimeout(() => {
                      location.reload();
                    }, 100);

                  }

                  
                }
              } catch (err) {
                //
                console.log(err);
              }


            });

          }

        } catch (error) {

          console.log(error);

        }

      },

      async sendRequests() {

        //Enviar Offline
        await this.sendOffline();

        //Enviar Incidencia
        await this.sendIncidencias();

        localStorage.setItem('pendingRequests', 0);

      },

      async sendIncidencias() {

      if (this.incidenciasEnviadas) {
        return; // Evita el envío si las evidencias ya se enviaron
      }

      try {

        const needed = await this.getOfflineTrackingByAction({ action: 'INCIDENCIA_PR' });

        //console.log('needed evidencias', needed);
        if (!needed) {
          return;
        }
      
        const list = await this.obtenerIncidenciaOffline();

        console.log('incidencias', list);
        if (list.length > 0) {

          for (const x of list) {

            console.log('xxxxx ', x);

            const formData = new FormData();
            formData.append('idRutaReferencia', x.idRutaReferencia);
            formData.append('idTipoSeguimiento', x.idTipoSeguimiento);
            formData.append('fechaRegistro', x.fechaRegistro);
            formData.append('hora', x.hora);

            formData.append('latitud', x.latitud);
            formData.append('longitud', x.longitud);

            formData.append('direccion', x.direccion);
            formData.append('descripcion', x.descripcion);
            formData.append('idRutaProgramacion', x.idRutaProgramacion);

            for (const file of x.files) {
              formData.append('files', file.data);
            }

            try {
              const response = await this.postIncidencia(formData);

              if (response.status == 200) {            
                console.log([{action: 'INCIDENCIA_PR', idRutaRecojo: this.$route.params.id}]);
                await this.updateOfflineTracking({action: 'INCIDENCIA_PR', idRutaRecojo: this.$route.params.id, needToSend: false});
                await this.deleteOfflineTracking({action: 'INCIDENCIA_PR', idRutaRecojo: this.$route.params.id, needToSend: false});
                await this.deleteOfflineEvidencia({action: 'INCIDENCIA_PR', idRutaReferencia: this.$route.params.id, needToSend: false});
              }

            } catch (err) {
              //
              console.log(err);
            }


          }

          this.incidenciasEnviadas = true;

          setTimeout(() => {
            location.reload();
          }, 100);
          

        }
        

      } catch (error) {

        console.log(error);

      }

    },

    },
    created() {

      setInterval(() => {
        this.time = new Date();
      }, 1000);

      setInterval(async() => {
        
        this.verifyOffline();

        if (navigator.onLine) {

          // Verificar si hay solicitudes pendientes en el almacenamiento local
          const pendingRequests = JSON.parse(localStorage.getItem('pendingRequests') || 0);
          //console.log('verificar pendientes', pendingRequests > 0);
          if (pendingRequests > 0) {
            console.log('Hay requests por enviar');
            this.enableGeoLocation();
            await this.sendRequests();
            await this.actualizarOffline();

          } else {

            //await this.actualizarOffline();

          }
        }

      }, 5000);

    },
    async mounted() {

      this.google = window.google;
    
      //Habilitar geolocalización
      this.enableGeoLocation();

      //Actualizar Offline
      await this.actualizarOffline();

      //Obtener dirección
      if (!this.googleMapsLoaded) {
        this.googleMapsLoaded = true;
        this.getGeoLocation();
      }

      let loader = this.$loading.show();

      await this.getList();

      if (navigator.onLine) {
        this.sendRequests();
      }

      this.isLoading = false;
      loader.hide();
  
    },
  };
  </script>
  <style lang="scss">



 .transportista-desktop-table {
  display: flex;
  width: 100%;
  gap: 45px;

  .cell {
    width: 100%;
  }
 }








  
  .user-time-modal {
    color: #F78D2C;
  }
  
  .dropZoneEvidencias {
    border: 1px dashed #B8B8B8;
    border-radius: 10px;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
    background-color: #fff;
    transition: 0.3s ease all;
    padding: 15px;
  }
  
  .camera-button,
  .adjunt-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .camera-button {
    background: #F7F8F2;
    border-radius: 5.22091px;
    border: 1px solid #02a54f !important;
  }
  .adjunt-button {
    background: #ECF7EA;
    border-radius: 5.22091px;
    border: 1px solid #02a54f !important;
  }
  
  
  .button-file-upload input[type="file"] {
      display: none;
  }
  
  .button-file-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    letter-spacing: -0.025rem;
    text-transform: none;
    line-height: 18px;
    width: 100%;
    font-weight: 500;
    background: #ECF7EA;
    border-radius: 5.22091px;
    border: 1px solid #02a54f !important;
    -webkit-appearance: button;
    padding: 0.875rem 4rem !important;
    font-size: 0.875rem;
    color: black;
  }
  
  
  .info-evidencia {
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    color: #6E6E6E;
    margin: 15px 0;
    font-family: "Poppins", sans-serif;
  }
  
  #canvas {
    max-width: 100%;
    height: auto;
  }
  
  #preview {
    max-width: 100%;
    height: 450px;
    object-fit: cover;
    width: 100%;
  }
  
  #video {
    position: absolute;
    top: -9999px;
    left: -9999px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  
  .grid-item-image {
    .grid-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
  
      &:not(:last-child) {
        margin-bottom: 15px;
      }
  
      .image {
        width: 40%;
        display: flex;
        justify-content: flex-start;
        img {
          width: 100%;
          height: 120px;
          object-fit: cover;
          border: 1px solid;
        }
      }
  
      .text-image {
        justify-content: flex-start;
        display: flex;
        width: 50%;
        word-break: break-word;
        p {
          font-size: 12px;
        }
      }
  
      .delete-action {
        display: flex;
        justify-content: flex-end;
        width: 10%;
      }
    }
  }
  
  .metadata-ev-inc {
    
    li {
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;
      span {
        font-size: 13px;
      }
    }
  }
  </style>