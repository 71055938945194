<template>
  <div class="m-container container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="c-card">
          <div class="card-header pb-0">
            <h1 class="mb-4">Pagos</h1>
            <div class="hr mb-0"></div>
          </div>

          <div class="card-body no-scroll pt-4">
            <ul class="tabs-selector nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="first-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#first-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="first-tab-pane"
                  aria-selected="true"
                >
                  Precio Unitario
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="second-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#second-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="second-tab-pane"
                  aria-selected="false"
                >
                  Monto Facturado
                </button>
              </li>
            </ul>

            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane show active"
                id="first-tab-pane"
                role="tabpanel"
                aria-labelledby="first-tab"
                tabindex="0"
              >
                <div class="tab-body w-fixed-s calidad-pagos">
                  <div class="fixed-section">
                    <Form
                      :validation-schema="schema"
                      ref="veeForm"
                      :initial-values="formValuesPrecioUnitario"
                      v-slot="{ meta }"
                    >
                      <div class="action-box justify-content-start">
                        <div class="grid grid-container-1">
                          <div class="grid-item-1">
                            <SelectButton
                              name="anio"
                              label="año"
                              v-model="model.anioPrecioUnitario"
                              @change="getDataByUserSelection()"
                              :options="yearsOptions"
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>

                  <div class="scrollable-content">
                    <div class="col-12">
                      <h3 class="title-acceso-ganaderos mb-4">
                        Gráfica de Precio unitario por quincena
                      </h3>

                      <template v-if="model.anioPrecioUnitario == 0">
                        <GradientLineChart
                          id="precio_unitario_quincena"
                          :enlarge="true"
                          @open-modal="openModal"
                          title="Precio Unitario por Quincena"
                          :decimals="5"
                          :yAxisTicksDecimals="true"
                          :dataSymbolPosition="'start'"
                          :chart="{
                            labels: this.labels,
                            yAxisTitle: 'S/',
                            suggestedMin: 1.1,
                            suggestedMax: 1.4,
                            datasets: dataPointsSolidos,
                          }"
                        />
                      </template>

                      <template v-else>
                        <GradientLineChart
                          id="precio_unitario_quincena"
                          :enlarge="true"
                          @open-modal="openModal"
                          title="Precio Unitario por Quincena"
                          :decimals="5"
                          :yAxisTicksDecimals="true"
                          :dataSymbolPosition="'start'"
                          :chart="{
                            labels: this.labels,
                            yAxisTitle: 'S/',
                            suggestedMin: 1.1,
                            suggestedMax: 1.4,
                            datasets: [
                              {
                                legendTitle: this.model.anioPrecioUnitario,
                                data: this.dataPointsSolidosUNO,
                                borderColor: '#F78D2C',
                              },
                            ],
                          }"
                        />
                      </template>
                    </div>

                    <template v-if="model.anioPrecioUnitario == 0">
                      <h3 class="title-acceso-ganaderos mb-4">
                        Detalle de Precio unitario por quincena
                      </h3>

                      <div class="b-table blue mt-5 acceso-ganaderos-acopio">
                        <div class="t-header">
                          <tr class="t-row">
                            <th class="t-cell">QUINCENA</th>
                            <th class="t-cell" v-for="year in getYears2()" :key="year">
                              {{ year.year }}
                            </th>
                          </tr>
                        </div>
                        <div class="t-body">
                          <tr v-for="quin in getQuincenas2()" :key="quin" class="t-row">
                            <td class="t-cell">{{ quin }}</td>
                            <td v-for="year in getYears2()" :key="year" class="t-cell">
                              <span v-if="getData2(year, quin)">{{
                                $filters.roundToFive(getData2(year, quin))
                              }}</span>
                              <span v-else> - </span>
                            </td>
                          </tr>
                        </div>

                        <div
                          class="t-body"
                          v-if="multidimensionalTablePromedio.length == 0"
                        >
                          <div class="t-body no-records">
                            <p>No existe data para esta fecha</p>
                          </div>
                        </div>
                      </div>
                    </template>

                    <template v-else>
                      <div class="row one-mobile-table mb-5">
                        <div class="col-md-4">
                          <div class="b-table blue mt-5 acceso-ganaderos-pagos-pu">
                            <div class="t-header">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">precio unit</div>
                              </div>
                            </div>

                            <div class="t-body">
                              <div class="t-row" v-for="record in dataPUOne">
                                <div class="t-cell">
                                  <span class="mobile-cell-title">quincena</span
                                  >{{
                                    this.$filters.getPeriod(record.quincena, record.mes)
                                  }}
                                </div>
                                <div class="t-cell">
                                  <span class="mobile-cell-title">precio unit</span
                                  >{{
                                    record.monto
                                      ? $filters.roundToFive(record.monto)
                                      : "x"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="b-table blue mt-5 acceso-ganaderos-pagos-pu">
                            <div class="t-header">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">precio unit</div>
                              </div>
                            </div>

                            <div class="t-body">
                              <div class="t-row" v-for="record in dataPUTwo">
                                <div class="t-cell">
                                  <span class="mobile-cell-title">quincena</span
                                  >{{
                                    this.$filters.getPeriod(record.quincena, record.mes)
                                  }}
                                </div>
                                <div class="t-cell">
                                  <span class="mobile-cell-title">precio unit</span
                                  >{{
                                    record.monto
                                      ? $filters.roundToFive(record.monto)
                                      : "-"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="b-table blue mt-5 acceso-ganaderos-pagos-pu">
                            <div class="t-header">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">precio unit</div>
                              </div>
                            </div>

                            <div class="t-body">
                              <div class="t-row" v-for="record in dataPUThird">
                                <div class="t-cell">
                                  <span class="mobile-cell-title">quincena</span
                                  >{{
                                    this.$filters.getPeriod(record.quincena, record.mes)
                                  }}
                                </div>
                                <div class="t-cell">
                                  <span class="mobile-cell-title">precio unit</span
                                  >{{
                                    record.monto
                                      ? $filters.roundToFive(record.monto)
                                      : "-"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>

                    <div class="form-actions mb-5">
                      <Button
                        class="mt-3 primary-button shadow-none"
                        size="lg"
                        type="button"
                        @click="downloadReportePrecioUnitario()"
                        >Exportar</Button
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane"
                id="second-tab-pane"
                role="tabpanel"
                aria-labelledby="second-tab"
                tabindex="0"
              >
                <div class="tab-body w-fixed-s calidad-pagos">
                  <div class="fixed-section">
                    <Form
                      :validation-schema="schema"
                      ref="veeForm"
                      :initial-values="formValuesMontoFacturado"
                      v-slot="{ meta }"
                    >
                      <div class="action-box justify-content-start">
                        <div class="grid grid-container-1">
                          <div class="grid-item-1">
                            <SelectButton
                              name="anio"
                              label="año"
                              v-model="model.anioMontoFacturado"
                              @change="getMontoFacturado()"
                              :options="yearsOptions"
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>

                  <div class="scrollable-content">
                    <template v-if="model.anioMontoFacturado == 0">
                      <h3 class="title-acceso-ganaderos mb-4">
                        Gráfica de Monto facturado por quincena
                      </h3>

                      <GradientLineChart
                        id="monto_facturado_quincena"
                        :enlarge="true"
                        @open-modal="openModal"
                        title="Monto Facturado por Quincena"
                        :dataSymbolPosition="'start'"
                        infoText="Observación: Sin impuestos, ni descuentos."
                        description=""
                        :chart="{
                          labels: this.labels,
                          suggestedMin: 0,
                          datasets: dataPointsMF,
                        }"
                      />
                    </template>

                    <template v-else>
                      <GradientLineChart
                        id="monto_facturado_quincena"
                        :enlarge="true"
                        @open-modal="openModal"
                        title="Monto Facturado por Quincena"
                        :dataSymbolPosition="'start'"
                        infoText="Observación: Sin impuestos, ni descuentos."
                        :chart="{
                          labels: this.labels,
                          suggestedMin: 0,
                          datasets: [
                            {
                              legendTitle: this.model.anioMontoFacturado,
                              data: this.dataMFActualMontos,
                              borderColor: '#F78D2C',
                            },
                          ],
                        }"
                      />
                    </template>

                    <div class="row mb-5">
                      <div class="col-md-12">
                        <h3 class="title-acceso-ganaderos mt-5 mb-4">
                          Detalle de Monto facturado por quincena
                        </h3>

                        <template v-if="model.anioMontoFacturado == 0">
                          <div class="b-table blue acceso-ganaderos-acopio">
                            <div class="t-header" v-if="yearsOptions.length > 0">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">
                                  monto facturado {{ yearsOptions[3].id }}
                                </div>
                                <div class="t-cell">
                                  monto facturado {{ yearsOptions[2].id }}
                                </div>
                                <div class="t-cell">
                                  monto facturado {{ yearsOptions[1].id }}
                                </div>
                                <div class="t-cell"></div>
                              </div>
                            </div>

                            <div class="t-body">
                              <div
                                class="t-row"
                                v-for="(i, index) in multidimensionalTableFacturado"
                                :key="index"
                              >
                                <div class="t-cell">
                                  <span class="mobile-cell-title">quincena</span>
                                  {{ i.half + "Q " + $filters.getMonthAbr(i.month) }}
                                </div>
                                <div class="t-cell">
                                  <span class="mobile-cell-title">total 2021</span>

                                  <template v-if="$filters.getMonth(i.col1)">
                                    <span
                                      @click="
                                        downloadPDF(yearsOptions[3].id, i.month, i.half)
                                      "
                                      class="download-link"
                                      >{{
                                        $filters.getMonth(i.col1)
                                          ? $filters.roundToTwo(i.col1)
                                          : "-"
                                      }}</span
                                    >
                                  </template>

                                  <template v-else>
                                    <span>-</span>
                                  </template>
                                </div>
                                <div class="t-cell">
                                  <span class="mobile-cell-title">total 2022</span>

                                  <template v-if="$filters.getMonth(i.col2)">
                                    <span
                                      @click="
                                        downloadPDF(yearsOptions[2].id, i.month, i.half)
                                      "
                                      class="download-link"
                                      >{{
                                        $filters.getMonth(i.col2)
                                          ? $filters.roundToTwo(i.col2)
                                          : "-"
                                      }}</span
                                    >
                                  </template>

                                  <template v-else>
                                    <span>-</span>
                                  </template>
                                </div>
                                <div class="t-cell">
                                  <span class="mobile-cell-title">total 2023</span>

                                  <template v-if="$filters.getMonth(i.col3)">
                                    <span
                                      @click="
                                        downloadPDF(yearsOptions[1].id, i.month, i.half)
                                      "
                                      class="download-link"
                                      >{{ $filters.roundToTwo(i.col3) }}</span
                                    >
                                  </template>

                                  <template v-else>
                                    <span>-</span>
                                  </template>
                                </div>
                                <div class="t-cell action"></div>
                              </div>
                            </div>

                            <div
                              class="t-body"
                              v-if="multidimensionalTableFacturado.length == 0"
                            >
                              <div class="t-body no-records">
                                <p>No existe data para esta fecha</p>
                              </div>
                            </div>
                          </div>
                        </template>

                        <template v-else>
                          <div class="b-table blue acceso-ganaderos-pagos-mf">
                            <div class="t-header">
                              <div class="t-row">
                                <div class="t-cell">quincena</div>
                                <div class="t-cell">monto facturado</div>
                                <div class="t-cell"></div>
                              </div>
                            </div>

                            <div class="t-body">
                              <div class="t-row" v-for="record in dataMFActual">
                                <div class="t-cell">
                                  <span class="mobile-cell-title">quincena</span
                                  >{{
                                    this.$filters.getPeriod(record.quincena, record.mes)
                                  }}
                                </div>
                                <div class="t-cell">
                                  <span class="mobile-cell-title">monto facturado</span
                                  >{{
                                    record.monto ? $filters.roundToTwo(record.monto) : "-"
                                  }}
                                </div>
                                <div class="t-cell action">
                                  <Button
                                    class="m-0 primary-button"
                                    size="sm"
                                    :disabled="!record.monto"
                                    @click="
                                      downloadPDF(
                                        record.year,
                                        record.mes,
                                        record.quincena
                                      )
                                    "
                                  >
                                    <span>Descargar pdf</span>
                                  </Button>
                                </div>
                              </div>
                            </div>

                            <div class="t-body" v-if="dataMFActual.length == 0">
                              <div class="t-body no-records">
                                <p>No existe data para esta fecha</p>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>

                    <div class="form-actions mb-5">
                      <Button
                        class="mt-3 primary-button shadow-none"
                        size="lg"
                        type="button"
                        @click="downloadReporteMontosFacturados()"
                        >Exportar</Button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="fade">
      <CustomModal
        :show="dataModal.showModal"
        :isHeader="false"
        :isFooter="false"
        :width="1600"
        :title="dataModal.title"
        v-on:modal:close="hideModal()"
      >
        <template v-slot:body>
          <template v-if="model.anioPrecioUnitario == 0">
            <GradientLineChart
              id="precio_unitario_quincena_modal"
              :enlarge="false"
              @open-modal="openModal"
              title="Precio Unitario por Quincena"
              :decimals="5"
              :yAxisTicksDecimals="true"
              :dataSymbolPosition="'start'"
              :chart="{
                labels: this.labels,
                yAxisTitle: 'S/',
                suggestedMin: 9,
                suggestedMax: 1,
                datasets: dataPointsSolidos,
              }"
            />
          </template>

          <template v-else>
            <GradientLineChart
              id="precio_unitario_quincena_modal"
              :enlarge="false"
              @open-modal="openModal"
              title="Precio Unitario por Quincena"
              :decimals="5"
              :yAxisTicksDecimals="true"
              :dataSymbolPosition="'start'"
              :chart="{
                labels: this.labels,
                yAxisTitle: 'S/',
                suggestedMin: 9,
                suggestedMax: 15,
                datasets: [
                  {
                    legendTitle: this.model.anioPrecioUnitario,
                    data: this.dataPointsSolidosUNO,
                    borderColor: '#F78D2C',
                  },
                ],
              }"
            />
          </template>

          <div class="form-actions">
            <Button
              class="mt-3 ghost-button shadow-none"
              size="lg"
              type="button"
              @click="hideModal()"
              >Cerrar</Button
            >
          </div>
        </template>
      </CustomModal>
    </transition>

    <transition name="fade">
      <CustomModal
        :show="dataModal2.showModal"
        :isHeader="false"
        :isFooter="false"
        :width="1600"
        :title="dataModal2.title"
        v-on:modal:close="hideModal()"
      >
        <template v-slot:body>
          <template v-if="model.anioMontoFacturado == 0">
            <h3 class="title-acceso-ganaderos mb-4">
              Gráfica de Monto facturado por quincena
            </h3>

            <GradientLineChart
              id="monto_facturado_quincena_modal"
              :enlarge="false"
              @open-modal="openModal"
              title="Monto Facturado por Quincena"
              :dataSymbolPosition="'start'"
              infoText="Observación: Sin impuestos, ni descuentos."
              description=""
              :chart="{
                labels: this.labels,
                suggestedMin: 0,
                datasets: dataPointsMF,
              }"
            />
          </template>

          <template v-else>
            <GradientLineChart
              id="monto_facturado_quincena_modal"
              :enlarge="false"
              @open-modal="openModal"
              title="Monto Facturado por Quincena"
              :dataSymbolPosition="'start'"
              infoText="Observación: Sin impuestos, ni descuentos."
              :chart="{
                labels: this.labels,
                suggestedMin: 0,
                datasets: [
                  {
                    legendTitle: this.model.anioMontoFacturado,
                    data: this.dataMFActualMontos,
                    borderColor: '#F78D2C',
                  },
                ],
              }"
            />
          </template>

          <div class="form-actions">
            <Button
              class="mt-3 ghost-button shadow-none"
              size="lg"
              type="button"
              @click="hideModal2()"
              >Cerrar</Button
            >
          </div>
        </template>
      </CustomModal>
    </transition>
  </div>
</template>

<script>
const CACHE_NAME = "site-cache-v2";

import { markRaw } from "vue";
import { Form, Field } from "vee-validate";
import { mapGetters, mapActions } from "vuex";
import GradientLineChart from "@/components/Charts/GradientLineChart.vue";
import TextInput from "@/components/Inputs/TextInput";
import FilterBox from "@/components/FilterBox";
import DateInput from "@/components/Inputs/Date";
import SelectButton from "@/components/Inputs/SelectButton";
import Button from "@/components/Inputs/Button";
import Pagination from "@/components/Pagination.vue";
import CustomModal from "@/components/CustomModal.vue";
import ConfirmationScreen from "@/components/ConfirmationScreen";
import Tag from "@/components/Tags/Tag";
import RowAccordion from "@/components/Accordion/RowAccordion";
import RadioButton from "@/components/Inputs/RadioButtonOriginalType.vue";
import Checkbox from "@/components/Inputs/Checkbox";
import * as Yup from "yup";

export default {
  components: {
    GradientLineChart,
    TextInput,
    FilterBox,
    SelectButton,
    Button,
    Pagination,
    CustomModal,
    ConfirmationScreen,
    RadioButton,
    Checkbox,
    Tag,
    RowAccordion,
    DateInput,
    Form,
    Field,
  },
  name: "Aprobacion de Ganadero",
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  data() {
    const schema = markRaw(
      Yup.object().shape({
        fechaInicio: Yup.string().required(),
        fechaFin: Yup.string().required(),
        zona: Yup.string().nullable(),
        codInterno: Yup.string().nullable(),
      })
    );
    const formValuesPrecioUnitario = {
      precioUnitario: "",
      fechaFin: "",
      zona: "",
      codInterno: "",
    };

    const formValuesMontoFacturado = {
      precioUnitario: "",
      fechaFin: "",
      zona: "",
      codInterno: "",
    };

    return {
      schema,
      formValuesPrecioUnitario,
      formValuesMontoFacturado,
      codigoProveedor: "010000",
      isDownloadAvailable: false,
      aniosOptions: this.$filters.getYears(2020),
      records: [],
      labels: this.$filters.getPeriodsLabels(),
      estadisticas: [
        { quincena: "1 Ene", solidos: "11.23" },
        { quincena: "1 Feb", solidos: "1,24" },
        { quincena: "1 Mar", solidos: "1,22" },
        { quincena: "1 Abr", solidos: "1,25" },
        { quincena: "1 May", solidos: "1,22" },
        { quincena: "1 Jun", solidos: "11.4" },
        { quincena: "1 Jul", solidos: "1,12" },
      ],
      dataPUActual: [],
      dataPUActualMontos: [],
      dataPUAnterior: [],
      dataPUAnteriorMontos: [],

      dataMFActual: [],
      dataMFAnterior: [],
      dataMFActualMontos: [],
      dataMFAnteriorMontos: [],

      dataPUOne: [],
      dataPUTwo: [],
      dataPUThird: [],

      dataMFOne: [],
      dataMFTwo: [],
      dataMFThird: [],

      dataRaw: [],
      dataRawMF: [],

      multidimensionalTablePromedio: [],
      multidimensionalTableFacturado: [],
      yearsOptions: [],
      allYearsDatasets: [],
      dataPointsSolidos: [],
      dataPointsSolidosUNO: [],

      dataPointsMontoFacturado: [],

      dataPointsMF: [],
      allYearsDatasets2: [],

      model: {
        anioPrecioUnitario: "",
        anioMontoFacturado: "",
      },
      dataModal: {
        showModal: false,
        title: "",
        active: "",
        request: "",
        response: "",
      },
      dataModal2: {
        showModal: false,
        title: "",
        active: "",
        request: "",
        response: "",
      },

      getYears2() {
        return this.dataRaw.map((item) => item).sort((a, b) => a - b);
      },
      getQuincenas2() {
        const quincenasOrdenadas = Array.from(
          new Set(
            this.dataRaw.flatMap((item) =>
              item.data.map((quin) => `${quin.quincena}Q ${this.mesANumero2(quin.mes)}`)
            )
          )
        ).sort((a, b) => {
          const [aNum, aMes] = a.split("Q ");
          const [bNum, bMes] = b.split("Q ");
          const aDate = new Date(2020, this.numeroAMes(aMes) - 1, aNum * 15);
          const bDate = new Date(2020, this.numeroAMes(bMes) - 1, bNum * 15);
          return aDate - bDate;
        });

        return quincenasOrdenadas;
      },
      mesANumero2(mes) {
        const meses = {
          1: "Ene",
          2: "Feb",
          3: "Mar",
          4: "Abr",
          5: "May",
          6: "Jun",
          7: "Jul",
          8: "Ago",
          9: "Sep",
          10: "Oct",
          11: "Nov",
          12: "Dic",
        };
        return meses[mes];
      },
      numeroAMes(num) {
        const meses = {
          Ene: 1,
          Feb: 2,
          Mar: 3,
          Abr: 4,
          May: 5,
          Jun: 6,
          Jul: 7,
          Ago: 8,
          Sep: 9,
          Oct: 10,
          Nov: 11,
          Dic: 12,
        };
        return meses[num];
      },

      getData2(year, quin) {
        let yearData = year.data.find(
          (item) =>
            item.year === year.year &&
            item.quincena + "Q " + this.mesANumero2(item.mes) === quin
        );

        if (yearData) {
          return yearData.monto;
        } else {
          return null; // Devuelve null en lugar de '-'
        }
      },

      // getData2(year, quin) {
      //   let yearData = year.data.find(
      //     (item) =>
      //       item.year === year.year &&
      //       item.quincena + "Q " + this.mesANumero2(item.mes) === quin
      //   );

      //   if (yearData) {
      //     if (yearData) {
      //       return yearData.monto;
      //     } else {
      //       return "-";
      //     }
      //   }
      //   return '-';
      // },
      formatNumber(number) {
        return new Intl.NumberFormat("es-MX", {
          style: "decimal",
          minimumFractionDigits: 0,
        }).format(number);
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions({
      getOptions: "pagos/getOptions",
      getListPagoUnitario: "accesoGanaderos/getListPagoUnitario",
      getListMontosFacturados: "accesoGanaderos/getListMontosFacturados",
      downloadPagoUnitario: "accesoGanaderos/downloadPagoUnitario",
      downloadMontosFacturados: "accesoGanaderos/downloadMontosFacturados",
      downloadLiquidacionPDF: "maestroGanadero/downloadLiquidacionPDF",

      postInsertarUsuario: "accesoGanaderos/postInsertarUsuario",
    }),
    openModal(id) {
      if (id == "precio_unitario_quincena") {
        this.dataModal.showModal = true;
      } else if (id == "monto_facturado_quincena") {
        this.dataModal2.showModal = true;
      }
    },
    hideModal() {
      this.dataModal.showModal = false;
    },
    hideModal2() {
      this.dataModal2.showModal = false;
    },
    downloadReportePrecioUnitario() {
      const data = {
        //"years": this.model.anioPrecioUnitario,
        years:
          this.model.anioPrecioUnitario == 0
            ? this.yearsServer
            : this.model.anioPrecioUnitario,
        //"codigoProveedor": this.codigoProveedor
      };

      this.downloadPagoUnitario(data)
        .then((response) => {
          if (response.status == 200) {
            let type = response.headers["content-type"];

            const file = new Blob([response.data], { type: type });
            const fileURL = URL.createObjectURL(file);

            let todayDate = this.$filters.generateFileDate();

            const link = document.createElement("a");
            link.href = fileURL;
            link.setAttribute("download", `reporte-precio-unitario_${todayDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
          } else if (response.status == 400) {
          }
        })
        .catch((e) => {});
    },

    downloadReporteMontosFacturados() {
      const data = {
        years:
          this.model.anioMontoFacturado == 0
            ? this.yearsServer
            : this.model.anioMontoFacturado,
        //"codigoProveedor": this.codigoProveedor
      };

      this.downloadMontosFacturados(data)
        .then((response) => {
          if (response.status == 200) {
            let type = response.headers["content-type"];

            const file = new Blob([response.data], { type: type });
            const fileURL = URL.createObjectURL(file);

            let todayDate = this.$filters.generateFileDate();

            const link = document.createElement("a");
            link.href = fileURL;
            link.setAttribute("download", `reporte-montos-facturados_${todayDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
          } else if (response.status == 400) {
          }
        })
        .catch((e) => {});
    },
    getDataByPeriods(arr, year) {
      let periods = [
        { mes: 1, quincena: 1 },
        { mes: 1, quincena: 2 },
        { mes: 2, quincena: 1 },
        { mes: 2, quincena: 2 },
        { mes: 3, quincena: 1 },
        { mes: 3, quincena: 2 },
        { mes: 4, quincena: 1 },
        { mes: 4, quincena: 2 },
        { mes: 5, quincena: 1 },
        { mes: 5, quincena: 2 },
        { mes: 6, quincena: 1 },
        { mes: 6, quincena: 2 },
        { mes: 7, quincena: 1 },
        { mes: 7, quincena: 2 },
        { mes: 8, quincena: 1 },
        { mes: 8, quincena: 2 },
        { mes: 9, quincena: 1 },
        { mes: 9, quincena: 2 },
        { mes: 10, quincena: 1 },
        { mes: 10, quincena: 2 },
        { mes: 11, quincena: 1 },
        { mes: 11, quincena: 2 },
        { mes: 12, quincena: 1 },
        { mes: 12, quincena: 2 },
      ];

      let newArr = [];

      if (arr.length == 0) {
        periods.map((item) => {
          newArr.push({
            year: year,
            mes: item.mes,
            quincena: item.quincena,
            monto: null,
          });
        });
      } else {
        periods.map((item) => {
          let itemFound = arr.find(
            (i) => i.mes == item.mes && i.quincena == item.quincena
          );

          if (!itemFound) {
            newArr.push({
              year: year,
              mes: item.mes,
              quincena: item.quincena,
              monto: null,
            });
          } else {
            newArr.push(itemFound);
          }
        });
      }

      return newArr;
    },
    getAmountsByPeriods(arr, year) {
      let periods = [
        { mes: 1, quincena: 1 },
        { mes: 1, quincena: 2 },
        { mes: 2, quincena: 1 },
        { mes: 2, quincena: 2 },
        { mes: 3, quincena: 1 },
        { mes: 3, quincena: 2 },
        { mes: 4, quincena: 1 },
        { mes: 4, quincena: 2 },
        { mes: 5, quincena: 1 },
        { mes: 5, quincena: 2 },
        { mes: 6, quincena: 1 },
        { mes: 6, quincena: 2 },
        { mes: 7, quincena: 1 },
        { mes: 7, quincena: 2 },
        { mes: 8, quincena: 1 },
        { mes: 8, quincena: 2 },
        { mes: 9, quincena: 1 },
        { mes: 9, quincena: 2 },
        { mes: 10, quincena: 1 },
        { mes: 10, quincena: 2 },
        { mes: 11, quincena: 1 },
        { mes: 11, quincena: 2 },
        { mes: 12, quincena: 1 },
        { mes: 12, quincena: 2 },
      ];

      let newArr = [];

      if (arr.length == 0) {
        periods.map((item) => {
          newArr.push({
            year: year,
            mes: item.mes,
            quincena: item.quincena,
            monto: null,
          });
        });
      } else {
        periods.map((item) => {
          let itemFound = arr.find(
            (i) => i.mes == item.mes && i.quincena == item.quincena
          );

          if (!itemFound) {
            newArr.push({
              year: year,
              mes: item.mes,
              quincena: item.quincena,
              monto: null,
            });
          } else {
            newArr.push(itemFound);
          }
        });
      }

      let montos = [];

      montos = newArr.map((x) => {
        let m;

        if (x.monto) {
          m = this.$filters.twoDecimals(x.monto);
        } else {
          m = null;
        }

        return m;
      });

      return montos;
    },
    downloadPDF(year, mes, quincena) {
      const data = {
        codigoProveedor: this.codigoProveedor,
        year: year,
        mes: mes,
        numeroQuincena: quincena,
      };

      this.downloadLiquidacionPDF(data)
        .then((response) => {
          if (response.status == 200) {
            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);

            let todayDate = this.$filters.generateFileDate();
            //let proveedorName = this.$filters.makeSlug(this.confirmation.proveedor.nombreProveedor);

            const link = document.createElement("a");
            link.href = fileURL;
            link.setAttribute("download", `liquidacion-pagos_${todayDate}.pdf`);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((err) => {
          //this.$filters.onErrorBase(err);
          this.$filters.showErrorMessage(
            "No se encontró información con los datos ingresados"
          );
        });
    },

    createMultidimensionalTablePromedio(oData) {
      // parse years data
      let yMax = new Date().getFullYear();
      let yMin = yMax - 2;

      let oYears = [];
      for (let y = yMin; y <= yMax; y++) {
        let dataYear = findByYear(y, oData);
        oYears[y] = dataYear;
      }
      //console.log('oYears', oYears);

      // make months data
      let oMonth = [];

      for (let m = 1; m <= 12; m++) {
        oMonth.push({
          half: 1,
          month: m,
          col1: getByHalfMonth(1, m, oYears[yMin]),
          col2: getByHalfMonth(1, m, oYears[yMin + 1]),
          col3: getByHalfMonth(1, m, oYears[yMax]),
        });
        oMonth.push({
          half: 2,
          month: m,
          col1: getByHalfMonth(2, m, oYears[yMin]),
          col2: getByHalfMonth(2, m, oYears[yMin + 1]),
          col3: getByHalfMonth(2, m, oYears[yMax]),
        });
      }

      function findByYear(year, data) {
        let result = [];
        for (var key in data) {
          if (data.hasOwnProperty(key) && data[key].year === year) {
            result = data[key].data;
          }
        }
        return result;
      }

      function getByHalfMonth(half, month, data) {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            if (item.mes == month && item.quincena == half) {
              return item.monto;
            }
          }
        }
        return "";
      }

      //console.log('oMonth', oMonth);
      this.multidimensionalTablePromedio = oMonth;
    },

    createMultidimensionalTableFacturado(oData) {
      // parse years data
      let yMax = new Date().getFullYear();
      let yMin = yMax - 2;

      let oYears = [];
      for (let y = yMin; y <= yMax; y++) {
        let dataYear = findByYear(y, oData);
        oYears[y] = dataYear;
      }
      //console.log('oYears', oYears);

      // make months data
      let oMonth = [];

      for (let m = 1; m <= 12; m++) {
        oMonth.push({
          half: 1,
          month: m,
          col1: getByHalfMonth(1, m, oYears[yMin]),
          col2: getByHalfMonth(1, m, oYears[yMin + 1]),
          col3: getByHalfMonth(1, m, oYears[yMax]),
        });
        oMonth.push({
          half: 2,
          month: m,
          col1: getByHalfMonth(2, m, oYears[yMin]),
          col2: getByHalfMonth(2, m, oYears[yMin + 1]),
          col3: getByHalfMonth(2, m, oYears[yMax]),
        });
      }

      function findByYear(year, data) {
        let result = [];
        for (var key in data) {
          if (data.hasOwnProperty(key) && data[key].year === year) {
            result = data[key].data;
          }
        }
        return result;
      }

      function getByHalfMonth(half, month, data) {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            if (item.mes == month && item.quincena == half) {
              return item.monto;
            }
          }
        }
        return "";
      }

      //console.log('oMonth', oMonth);
      this.multidimensionalTableFacturado = oMonth;
    },

    getDataByUserSelectionMontoFacturado() {
      let anio = this.model.anioMontoFacturado;

      if (anio == 0) {
        this.createMultidimensionalTableFacturado(this.dataRawMF);

        let anios = [...this.yearsOptions];

        let index = anios.findIndex((objeto) => objeto.id === 0);
        if (index !== -1) {
          anios.splice(index, 1);
        }

        this.allYearsDatasets2 = [];

        anios.map((x, i) => {
          let aYear = x.id;

          let arrYear1 = this.$filters.getSolidosTotalesByYear(
            this.dataRawMF,
            aYear,
            "monto",
            "quincena"
          );
          let dataValuesYear1 = this.$filters.generarSecuenciaQuincenas(
            arrYear1,
            aYear,
            "monto",
            "quincena"
          );

          let points = dataValuesYear1.map((objeto) => objeto.monto);

          let borderColor = "";

          if (i == 0) {
            borderColor = "#F78D2C";
          } else if (i == 1) {
            borderColor = "#1D4193";
          } else if (i == 2) {
            borderColor = "#07C25E";
          }

          this.allYearsDatasets2.push({
            legendTitle: aYear,
            data: points,
            borderColor: borderColor,
          });
        });

        this.dataPointsMF = Object.values(this.allYearsDatasets2);

        //console.log('allYearsDatasets', this.dataPointsMF);
      } else {
        let aYear = anio;

        let arrYear1 = this.$filters.getSolidosTotalesByYear(
          this.dataRawMF,
          aYear,
          "monto",
          "quincena"
        );
        let dataValuesYear1 = this.$filters.generarSecuenciaQuincenas(
          arrYear1,
          aYear,
          "monto",
          "quincena"
        );
        let points = dataValuesYear1.map((objeto) => objeto.monto);

        this.dataMFActualMontos = points;

        let dataActual = dataValuesYear1;
        this.dataMFActual = this.$filters.getDataByPeriodsPU(dataActual, aYear);
      }
    },

    getMontoFacturado() {
      caches.open(CACHE_NAME).then((cache) => {
        const cacheUrl = "/offline/montofacturado";

        cache
          .match(cacheUrl)
          .then((response) => {
            if (response) {
              return response.json();
            }
          })
          .then((data) => {
            if (data) {
              // Los datos están disponibles en caché
              this.dataRawMF = data;

              this.getDataByUserSelectionMontoFacturado();
            }
          });
      });

      const data = {
        years: this.yearsServer,
      };

      this.getListMontosFacturados(data)
        .then((response) => {
          if (response.status == 200) {
            caches.open(CACHE_NAME).then((cache) => {
              cache.put(
                "/offline/montofacturado",
                new Response(JSON.stringify(response.data))
              );
            });

            let data = response.data;

            this.dataRawMF = data;

            this.getDataByUserSelectionMontoFacturado();
          }
        })
        .catch((err) => {
          //console.log(err.message);
        });
    },

    getDataByUserSelection() {
      let anio = this.model.anioPrecioUnitario;

      if (anio == 0) {
        this.createMultidimensionalTablePromedio(this.dataRaw);

        let anios = [...this.yearsOptions];

        let index = anios.findIndex((objeto) => objeto.id === 0);
        if (index !== -1) {
          anios.splice(index, 1);
        }

        this.allYearsDatasets = [];

        anios.map((x, i) => {
          let aYear = x.id;

          let arrYear1 = this.$filters.getSolidosTotalesByYear(
            this.dataRaw,
            aYear,
            "monto",
            "quincena"
          );
          let dataValuesYear1 = this.$filters.generarSecuenciaQuincenas(
            arrYear1,
            aYear,
            "monto",
            "quincena"
          );

          let points = dataValuesYear1.map((objeto) => objeto.monto);

          let borderColor = "";

          if (i == 0) {
            borderColor = "#F78D2C";
          } else if (i == 1) {
            borderColor = "#1D4193";
          } else if (i == 2) {
            borderColor = "#07C25E";
          }

          this.allYearsDatasets.push({
            legendTitle: aYear,
            data: points,
            borderColor: borderColor,
          });
        });

        this.dataPointsSolidos = Object.values(this.allYearsDatasets);
      } else {
        let aYear = anio;

        let arrYear1 = this.$filters.getSolidosTotalesByYear(
          this.dataRaw,
          aYear,
          "monto",
          "quincena"
        );
        let dataValuesYear1 = this.$filters.generarSecuenciaQuincenas(
          arrYear1,
          aYear,
          "monto",
          "quincena"
        );
        let points = dataValuesYear1.map((objeto) => objeto.monto);

        console.log("dataValuesYear1", dataValuesYear1);

        this.dataPointsSolidosUNO = points;

        let dataActual = dataValuesYear1;
        this.dataPUActual = this.$filters.getDataByPeriodsPU(dataActual, aYear);

        const chunkSize = 8;
        for (let i = 0; i < this.dataPUActual.length; i += chunkSize) {
          if (i == 0) {
            const chunk = this.dataPUActual.slice(i, i + chunkSize);
            this.dataPUOne = chunk;
          }

          if (i == 8) {
            const chunk = this.dataPUActual.slice(i, i + chunkSize);
            this.dataPUTwo = chunk;
          }

          if (i == 16) {
            const chunk = this.dataPUActual.slice(i, i + chunkSize);
            this.dataPUThird = chunk;
          }
        }
      }
    },

    getPrecioUnitario() {
      if (!navigator.onLine) {
        caches.open(CACHE_NAME).then((cache) => {
          const cacheUrl = "/offline/pagounitario";

          cache
            .match(cacheUrl)
            .then((response) => {
              if (response) {
                return response.json();
              }
            })
            .then((data) => {
              if (data) {
                // Los datos están disponibles en caché
                this.dataRaw = data;

                this.getDataByUserSelection();
              }
            });
        });
      } else {
        const data = {
          years: this.yearsServer,
        };

        this.getListPagoUnitario(data)
          .then((response) => {
            if (response.status == 200) {
              caches.open(CACHE_NAME).then((cache) => {
                cache.put(
                  "/offline/pagounitario",
                  new Response(JSON.stringify(response.data))
                );
              });

              let data = response.data;

              this.dataRaw = data;
              this.getDataByUserSelection();
            }
          })
          .catch((err) => {
            //console.log(err.message);
          });
      }
    },
  },
  async mounted() {
    let loader = this.$loading.show();

    const dateFiltersV4 = this.$filters.getYearsV4();

    this.yearsOptions = dateFiltersV4.yearsOptions;
    this.yearsServer = dateFiltersV4.yearsServer;

    this.formValuesPrecioUnitario.anio = this.yearsOptions[0].id;
    this.model.anioPrecioUnitario = this.yearsOptions[0].id;

    this.formValuesMontoFacturado.anio = this.yearsOptions[0].id;
    this.model.anioMontoFacturado = this.yearsOptions[0].id;

    this.getPrecioUnitario();
    this.getMontoFacturado();

    loader.hide();
  },
};
</script>
