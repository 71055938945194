import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import AccesoGanaderos from '../services/AccesoGanaderos';
export default {
    namespaced: true,
    state:{},
    getters:{},
    mutations:{},
    methods:{ },
    actions:{

      async getListUsuarios({state,commit}, data) {
        return await AccesoGanaderos.getListUsuarios(axios,data);
      },
      async postInsertarUsuario({state,commit}, data) {
        return await AccesoGanaderos.postInsertarUsuario(axios,data);
      },
      async updateUsuario({state,commit}, data) {
        return await AccesoGanaderos.updateUsuario(axios,data);
      },
      async unlockUsuario({state,commit}, data) {
        return await AccesoGanaderos.unlockUsuario(axios,data);
      },
      async generatePassword({state,commit}, data) {
        return await AccesoGanaderos.generatePassword(axios,data);
      },


      async getListPagoUnitario({state,commit}, data) {
        return await AccesoGanaderos.getListPagoUnitario(axios,data);
      },
      async downloadPagoUnitario({state,commit}, data) {
        return await AccesoGanaderos.downloadPagoUnitario(axios,data);
      },
      async getListMontosFacturados({state,commit}, data) {
        return await AccesoGanaderos.getListMontosFacturados(axios,data);
      },
      async downloadMontosFacturados({state,commit}, data) {
        return await AccesoGanaderos.downloadMontosFacturados(axios,data);
      },


    }
}
