<template>
  <ol v-if="source.paginas > 1 && source.paginas < 8" class="pagination d-flex justify-content-end align-items-center mt-4 mb-0">
    <li>
      <img @click="navigate($event, 1)" src="/assets/images/icons/doubleCareLeft.svg" alt="">
    </li>
    <li :class="{ disabled: source.pagina == 1 }">
      <img @click="navigate($event, source.pagina-1)" src="/assets/images/icons/arrowLeft.svg" alt="">
    </li>

    <li v-for="(page) in pages" v-bind:key="page">
      <a :class="{ selected: source.pagina == page }" href="#" @click="navigate($event, page)">{{ page }}</a>
    </li>

    <li :class="{ disabled: source.pagina == source.paginas }">
      <img @click="navigate($event, source.pagina+1)" src="/assets/images/icons/arrowRight.svg" alt="">
    </li>
    <li>
      <img @click="navigate($event, source.paginas)" src="/assets/images/icons/doubleCareRight.svg" alt="">
    </li>
  </ol>

  <ol v-else-if="source.paginas >= 8" class="pagination d-flex justify-content-end align-items-center mt-4 mb-0">
    <li>
      <img @click="navigate($event, 1)" src="/assets/images/icons/doubleCareLeft.svg" alt="">
    </li>
    <li :class="{ disabled: source.pagina == 1 }">
      <img @click="navigate($event, source.pagina-1)" src="/assets/images/icons/arrowLeft.svg" alt="">
    </li>

    <li v-for="(item) in pagesX.list" v-if="!pagesX.isComplete" v-show="item.show">
      <a v-if="item.disable != 'disabled'" href="#" @click="navigate($event, item.content)"
      :class="{ selected: selected == item.content, 'text-decoration-none': item.content === '...' }">
        {{ item.content }}
      </a>
      <a v-else :class="{ selected: selected == item.content, 'text-decoration-none': item.content === '...' }">{{ item.content }}</a>
    </li>


    <li :class="{ disabled: source.pagina == source.paginas }">
      <img @click="navigate($event, source.pagina+1)" src="/assets/images/icons/arrowRight.svg" alt="">
    </li>
    <li>
      <img @click="navigate($event, source.paginas)" src="/assets/images/icons/doubleCareRight.svg" alt="">
    </li>
  </ol>
</template>

<script>
import { range } from 'lodash'
export default {
  props: {
    source: {
      type: [Object, Array, Boolean],
      default: false,
    },
    tableClassName: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {
      pages: [],
      pagesX: {},
      selected: 1,
      pageRange: 3,
      activeBGColor: 'red',
      customActiveBGColor: 'green'
    }
  },
  watch: {
    source() {

      this.pages = range(1, this.source.paginas + 1);

      if(this.source.paginas >= 8) {

        this.pagesX = {
          list: [],
          isCompleted: false
        };

        let items = [];

        for (let i = 0; i < this.source.paginas; i++) {
            let page = {
                content: i + 1,
                disable : '',
                show : false
            }

            items[i] = page;
        }

        let page1 = {
            content: "...",
            disable : 'disabled',
            show : false
        }

        let page2 = {
            content: "...",
            disable : 'disabled',
            show : false
        }

        items.splice(1, 0, page1);
        items.splice(items.length-1, 0, page2);

        for (let i = 0; i < items.length; i++) {
            if(i == 0 || i == items.length-1)
                items[i].show = true;

            if(this.selected <= this.pageRange)
            {
                if(this.selected == this.pageRange)
                {
                    if(i >= 2 && i <= this.pageRange+1)
                        items[i].show = true;
                }
                else {
                    if(i >= 2 && i <= this.pageRange)
                        items[i].show = true;
                }

                items[items.length-2].show = true;
            }
            else if (this.selected > this.pageRange) {
                if(i >= this.selected-1 && (this.selected + 2) < items.length-2 && i <= this.selected+1)
                {
                    items[1].show = true;
                    items[items.length-2].show = true;
                    items[i].show = true;
                }
                else if (i >= items.length - 2 - this.pageRange &&(this.selected + 2) >= items.length-2)
                {
                    items[1].show = true;
                    items[items.length-2].show = false;
                    items[i].show = true;
                }
            }

        }

        this.pagesX.list = items;
        this.pagesX.isCompleted = true;

      }

    }
  },
  computed: {

  },
  methods: {
    navigate(ev, page) {
      ev.preventDefault();
      this.selected = page;
      this.$emit('navigate', page);

      if (this.tableClassName) {
        this.$filters.scrollToTop(`.${this.tableClassName}`);
      }
    }
  },
  mounted() {

    this.pages = range(1, this.source.paginas + 1);

    if(this.source.paginas >= 8) {

      this.pagesX = {
        list: [],
        isCompleted: false
      };


      let items = [];
      items.isComplete = true;

      for (let i = 0; i < this.source.paginas; i++) {
          let page = {
              content: i + 1,
              disable : '',
              show : false
          }

          items[i] = page;
      }

      let page1 = {
          content: "...",
          disable : 'disabled',
          show : false
      }

      let page2 = {
          content: "...",
          disable : 'disabled',
          show : false
      }

      items.splice(1, 0, page1);
      items.splice(items.length-1, 0, page2);

      for (let i = 0; i < items.length; i++) {
          if(i == 0 || i == items.length-1)
              items[i].show = true;

          if(this.selected <= this.pageRange)
          {
              if(this.selected == this.pageRange)
              {
                  if(i >= 2 && i <= this.pageRange+1)
                      items[i].show = true;
              }
              else {
                  if(i >= 2 && i <= this.pageRange)
                      items[i].show = true;
              }

              items[items.length-2].show = true;
          }
          else if (this.selected > this.pageRange) {
              if(i >= this.selected-1 && (this.selected + 2) < items.length-2 && i <= this.selected+1)
              {
                  items[1].show = true;
                  items[items.length-2].show = true;
                  items[i].show = true;
              }
              else if (i >= items.length - 2 - this.pageRange &&(this.selected + 2) >= items.length-2)
              {
                  items[1].show = true;
                  items[items.length-2].show = false;
                  items[i].show = true;
              }
          }

      }

      this.pagesX.list = items;
      this.pagesX.isCompleted = true;

    }

  }
}
</script>

<style>
.disabled {
  pointer-events: none;
}
</style>
