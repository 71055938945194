import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import Filters from './filters/global-filters';
import Format from './filters/global-format';
import Roles from './filters/roles-access';
import VueSnip from 'vue-snip';
import {store} from "@/store/index";
import ArgonDashboard from "./core";
import "element-plus/lib/theme-chalk/index.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import { useI18n, createI18n } from "vue-i18n";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueSplide from '@splidejs/vue-splide';
import OfflinePlugin from 'v-offline';

//import '@splidejs/vue-splide/dist/css/splide.min.css';
import { VueReCaptcha } from "vue-recaptcha-v3";

import './registerServiceWorker'








/*import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";*/



const options = { containerClassName: "ct-notification" };


const userString = localStorage.getItem("userinfo");

if (userString !== 'undefined') {
  
  const dataInfo = {
    token: localStorage.getItem("token"),
    expiration: localStorage.getItem("maxAge"),
    user: JSON.parse(userString),
  };

  store.dispatch("auth/validToken", dataInfo);

} else {

  store.dispatch('auth/logout');

}




const numberFormats = {
  "en-US": {
    currency: {
      style: "currency",
      currency: "USD"
    }
  },
  "es-PE": {
    currency: {
      style: "currency",
      currency: "PEN",
      currencyDisplay: "symbol"
    }
  }
}

const i18n = createI18n({
  numberFormats
})

const loadingProps = {
  color: "#02A54F",
  loader: "dots",
  canCancel: false,
  width: 110,
  height: 110,
  opacity: 1,
}


const appInstance = createApp(App);
appInstance.use(VueReCaptcha, { siteKey: "6LeuKkQjAAAAAC2C-YkVFOOzaPBdZlTzgkmba8Qr" });
appInstance.use(OfflinePlugin);
appInstance.use(Filters);
appInstance.use(Format);
appInstance.use(Roles);
appInstance.use(router);
appInstance.use(store);
appInstance.use(VueTilt);
appInstance.use(VueSnip);
appInstance.use(VueSplide);
appInstance.use(VueSweetalert2);
appInstance.use(ArgonDashboard);
appInstance.use(i18n);
appInstance.use(VueLoading, loadingProps);
appInstance.mount("#app");



if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/sw.js")
    .then(function(registration) {
      console.log("Service worker registered successfully");
    })
    .catch(function(error) {
      console.error("Service worker registration failed:", error);
    });
}