import FormDataEncoder from 'form-data-encoder';

const DB_NAME = 'my-db';
const DB_VERSION = 1;
const TIPOS_LECHE_STORE_NAME = 'tipos_leche';
const TRANSPORTISTAS_STORE_NAME = 'rutas';
const COORDENADAS_STORE_NAME = 'coordenadas';
const EVIDENCIAS_STORE_NAME = 'evidencias';
const INCIDENCIAS_STORE_NAME = 'incidencias';
const OFFLINE_TRACKING_STORE_NAME = 'offline_tracking';

const state = {
  tipos: [],
  rutas: [],
  coordenadas: []
};

const getters = {
  getRutas: state => state.rutas
};

const mutations = {
  setTipos(state, tipos) {
      state.tipos = tipos;
  },
  setRutas(state, rutas) {
      state.rutas = rutas;
  },
  setCoordenadas(state, coordenadas) {
    state.coordenadas = coordenadas;
  },
  setDetalle(state, detalle) {
    state.detalle = detalle;
  }
};

const actions = {



  async mixOffline({ commit }, data) {
    try {

      const db = await openDatabase(DB_NAME, DB_VERSION);
      const tx = db.transaction([TRANSPORTISTAS_STORE_NAME], 'readwrite');
      const store = tx.objectStore(TRANSPORTISTAS_STORE_NAME);
      const index = store.index('idRuta_idRutaProgramacion_idProductoTransportado_codigoTransportista');

      const idRuta = parseInt(data.idRuta);
      const idRutaProgramacion = parseInt(data.idRutaProgramacion);
      const idProductoTransportado = parseInt(data.idProductoTransportado);
      const codigoTransportista = data.codigoTransportista;

      //console.log([idRuta, idRutaProgramacion, idProductoTransportado, codigoTransportista]);

      const searchObj = IDBKeyRange.only([idRuta, idRutaProgramacion, idProductoTransportado, codigoTransportista]);

      return new Promise((resolve, reject) => {
        const request = index.openCursor(searchObj);
        request.onsuccess = (event) => {

          const cursor = event.target.result;

          if (cursor) {

            // Found
            const registro = cursor.value;
            
            registro.detalle = [];

            data.listaRecojo.map((x) => {
              const obj = {...x};
              registro.detalle.push(obj);
            })

            cursor.update(registro);
            resolve(registro);

          }
  
        };
        request.onerror = () => {
          reject(request.error);
        };
      });


    } catch (error) {
      //console.log('Error al encontrar el obj: ', error);
      location.reload();
    }
  
  },


  async saveOfflineTracking({ commit }, data) {
    try {
      const db = await openDatabase(DB_NAME, DB_VERSION);
      const tx = db.transaction([OFFLINE_TRACKING_STORE_NAME], 'readwrite');
      const store = tx.objectStore(OFFLINE_TRACKING_STORE_NAME);

      const item = { action: data.action, idRutaRecojo: data.idRutaRecojo, needToSend: data.needToSend };
      
      console.log('actualizado correctamente');
      await store.put(item);

      return item;
    } catch (error) {
      console.log('Error al guardar en IndexedDB: ', error);
      throw error;
    }
  },


  async updateOfflineTracking({ commit }, data) {
    try {
      const db = await openDatabase(DB_NAME, DB_VERSION);
      const tx = db.transaction([OFFLINE_TRACKING_STORE_NAME], 'readwrite');
      const store = tx.objectStore(OFFLINE_TRACKING_STORE_NAME);

      const index = store.index('action_idRutaRecojo');
      const searchObj = IDBKeyRange.only([data.action, data.idRutaRecojo]);

      console.log('update offline tracking', [data.action, data.idRutaRecojo]);

      return new Promise((resolve, reject) => {
        const request = index.openCursor(searchObj);
        request.onsuccess = (event) => {

          const cursor = event.target.result;

          if (cursor) {
            // Found
            const item = cursor.value;
            
            item.needToSend = data.needToSend;
            cursor.update(item);
    
            //return item;
            
            resolve(item);
          } else {
            // Si no se encuentra el registro, lanza una excepción
            //throw new Error('Registro no encontrado');
            resolve({});

          }


        };
        request.onerror = () => {
          reject(request.error);
        };
      });


    } catch (error) {
      console.log('Error al guardar en IndexedDB: ', error);
      throw error;
    }
  },

  async deleteOfflineEvidencia({ commit }, data) {
    try {
      const db = await openDatabase(DB_NAME, DB_VERSION);
      const tx = db.transaction([EVIDENCIAS_STORE_NAME], 'readwrite');
      const store = tx.objectStore(EVIDENCIAS_STORE_NAME);

      const index = store.index('idRutaReferencia');
      const searchObj = IDBKeyRange.only(data.idRutaReferencia);

      console.log('delete offline evidencia', [data.action, data.idRutaRecojo]);

      return new Promise((resolve, reject) => {
        const request = index.openCursor(searchObj);
        request.onsuccess = (event) => {

          const cursor = event.target.result;

          if (cursor) {
            // Found
            cursor.delete();
            resolve(true);
          } else {
            // Si no se encuentra el registro, lanza una excepción
            //throw new Error('Registro no encontrado');
            resolve({});
          }
        };
        request.onerror = () => {
          reject(request.error);
        };
      });
    } catch (error) {
      console.log('Error al eliminar evidencia: ', error);
      throw error;
    }

  },

  async deleteOfflineTracking({ commit }, data) {
    try {
      const db = await openDatabase(DB_NAME, DB_VERSION);
      const tx = db.transaction([OFFLINE_TRACKING_STORE_NAME], 'readwrite');
      const store = tx.objectStore(OFFLINE_TRACKING_STORE_NAME);

      const index = store.index('action_idRutaRecojo');
      const searchObj = IDBKeyRange.only([data.action, data.idRutaRecojo]);

      console.log('delete offline tracking', [data.action, data.idRutaRecojo]);

      return new Promise((resolve, reject) => {
        const request = index.openCursor(searchObj);
        request.onsuccess = (event) => {

          const cursor = event.target.result;

          if (cursor) {
            // Found
            cursor.delete();
            resolve(true);
          } else {
            // Si no se encuentra el registro, lanza una excepción
            //throw new Error('Registro no encontrado');
            resolve({});
          }
        };
        request.onerror = () => {
          reject(request.error);
        };
      });
    } catch (error) {
      console.log('Error al eliminar en IndexedDB: ', error);
      throw error;
    }

  },

  async eliminarTodasEvidenciaOffline() {

    try {
      const db = await openDatabase(DB_NAME, DB_VERSION);
      const tx = db.transaction([EVIDENCIAS_STORE_NAME], 'readwrite');
      const store = tx.objectStore(EVIDENCIAS_STORE_NAME);
      const request = store.clear();
  
      return new Promise((resolve, reject) => {
        request.onsuccess = () => {
          console.log('Todas las evidencias han sido eliminadas');
          resolve();
        };
        request.onerror = () => {
          console.log('Error al eliminar las evidencias');
          reject();
        };
      });
    } catch (error) {
      console.log(error);
    }

  },

  async getOfflineTrackingByAction({ commit }, data) {

    const db = await openDatabase(DB_NAME, DB_VERSION);
    const tx = db.transaction([OFFLINE_TRACKING_STORE_NAME], 'readonly');
    const store = tx.objectStore(OFFLINE_TRACKING_STORE_NAME);

    const index = store.index('action');
    const searchObj = IDBKeyRange.only(data.action);
  
    return new Promise((resolve, reject) => {

      const request = index.openCursor(searchObj);
      request.onsuccess = (event) => {

        const cursor = event.target.result;

        if (cursor) {
          
          resolve(cursor.value);

        } else {
          // Si no se encuentra el registro, lanza una excepción
          resolve(null);
        }


      };
      request.onerror = () => {
        reject(request.error);
      };

    });
  },



  async getOfflineEvidenciaTracking({ commit }, data) {

    try {
      const db = await openDatabase(DB_NAME, DB_VERSION);
      const tx = db.transaction([EVIDENCIAS_STORE_NAME], 'readwrite');
      const store = tx.objectStore(EVIDENCIAS_STORE_NAME);
      const request = store.getAll();
  
      return new Promise((resolve, reject) => {
        request.onsuccess = () => {
          console.log('request', request);
          const evidencias = request.result;
          //commit('setEvidencias', evidencias);
          resolve(evidencias);
        };
        request.onerror = () => {
          resolve([]);
        };
      });
    } catch (error) {
      console.log(error);
    }

  },
  
  

  async guardarIncidenciaOffline({ commit }, incidencia) {
    try {
      const db = await openDatabase(DB_NAME, DB_VERSION);
      const tx = db.transaction(INCIDENCIAS_STORE_NAME, 'readwrite');
      const store = tx.objectStore(INCIDENCIAS_STORE_NAME);
  
      const files = incidencia.files.map(file => {
        return {
          name: file.name,
          type: file.type,
          size: file.size,
          data: new Blob([file], { type: file.type })
        };
      });
  
      const incidenciaData = {
        idRutaReferencia: incidencia.idRutaReferencia,
        idTipoSeguimiento: incidencia.idTipoSeguimiento,
        idRutaProgramacion: incidencia.idRutaProgramacion,
        fechaRegistro: incidencia.fechaRegistro,
        hora: incidencia.hora,
        latitud: incidencia.latitud,
        longitud: incidencia.longitud,
        direccion: incidencia.direccion || '-',
        descripcion: incidencia.descripcion || '-',
        files: files
      };
  
      const request = store.put(incidenciaData);
  
      await new Promise((resolve, reject) => {
        request.onsuccess = function () {
          resolve();
        };
  
        request.onerror = function () {
          reject(new Error('Error al guardar la incidencia en la base de datos'));
        };
      });
    } catch (error) {
      console.log(error);
    }
  },



  async guardarEvidenciaOffline({ commit }, evidencia) {
    try {
      const db = await openDatabase(DB_NAME, DB_VERSION);
      const tx = db.transaction(EVIDENCIAS_STORE_NAME, 'readwrite');
      const store = tx.objectStore(EVIDENCIAS_STORE_NAME);
  
      const files = evidencia.files.map(file => {
        return {
          name: file.name,
          type: file.type,
          size: file.size,
          data: new Blob([file], { type: file.type })
        };
      });
  
      const evidenciaData = {
        idRutaReferencia: evidencia.idRutaReferencia,
        idTipoSeguimiento: evidencia.idTipoSeguimiento,
        idRutaProgramacion: evidencia.idRutaProgramacion,
        fechaRegistro: evidencia.fechaRegistro,
        hora: evidencia.hora,
        latitud: evidencia.latitud,
        longitud: evidencia.longitud,
        direccion: evidencia.direccion || '-',
        descripcion: evidencia.descripcion || '-',
        files: files
      };
  
      const request = store.put(evidenciaData);
  
      await new Promise((resolve, reject) => {
        request.onsuccess = function () {
          resolve();
        };
  
        request.onerror = function () {
          reject(new Error('Error al guardar la evidencia en la base de datos'));
        };
      });
    } catch (error) {
      console.log(error);
    }
  },
  
  
  



  //Guardar y actualizar IndexedDB
  async guardarSeguimientoOffline({ commit }, data) {
    try {

      const db = await openDatabase(DB_NAME, DB_VERSION);
      const tx = db.transaction([TRANSPORTISTAS_STORE_NAME], 'readwrite');
      const store = tx.objectStore(TRANSPORTISTAS_STORE_NAME);
      const index = store.index('idRuta_idRutaProgramacion_idProductoTransportado_codigoTransportista');

      const idRuta = parseInt(data.idRuta);
      const idRutaProgramacion = parseInt(data.idRutaProgramacion);
      const idProductoTransportado = parseInt(data.idProductoTransportado);
      const codigoTransportista = data.codigoTransportista;
      const idRutaRecojo = data.idRutaReferencia;

      console.log([idRuta, idRutaProgramacion, idProductoTransportado, codigoTransportista]);

      const searchObj = IDBKeyRange.only([idRuta, idRutaProgramacion, idProductoTransportado, codigoTransportista]);

      return new Promise((resolve, reject) => {
        const request = index.openCursor(searchObj);
        request.onsuccess = (event) => {

          const cursor = event.target.result;
          if (cursor) {

            // Found
            const registro = cursor.value;

            registro.detalle.map((x) => {
              
              if (x.idRutaRecojo == idRutaRecojo) {

                console.log('x obj', x);
                console.log('data obj', data);
                
                x.nombreZona = data.nombreZona ? data.nombreZona : x.nombreZona;
                x.destino = data.destino ? data.destino : x.destino;
                x.horaInicio = data.horaInicio ? data.horaInicio : x.horaInicio;
                x.horaFin = data.horaFin ? data.horaFin : x.horaFin;

                x.horaSalida = data.horaSalida ? data.horaSalida : x.horaSalida;
                x.horaLlegada = data.horaLlegada ? data.horaLlegada : x.horaLlegada;

                x.cantidadKilos = data.cantidadKilos ? data.cantidadKilos : x.cantidadKilos;
                x.temperaturaRecojo = data.temperaturaRecojo ? data.temperaturaRecojo : x.temperaturaRecojo;
              }

            })

            cursor.update(registro);
            resolve(registro);

          } else {

            /*const nuevoRegistro = {
              idRuta: idRuta,
              idRutaProgramacion: idRutaProgramacion,
              idProductoTransportado: idProductoTransportado,
              codigoTransportista: codigoTransportista,
              horaInicio: data.horaInicio
            };*/
            //store.add(nuevoRegistro);
            //resolve(nuevoRegistro);

          }
        };
        request.onerror = () => {
          reject(request.error);
        };
      });


    } catch (error) {
      console.log('Error al encontrar el obj: ', error);
    }
  
  },

  async obtenerIncidenciaOffline({ commit }) {
    try {
      const db = await openDatabase(DB_NAME, DB_VERSION);
      const tx = db.transaction([INCIDENCIAS_STORE_NAME], 'readwrite');
      const store = tx.objectStore(INCIDENCIAS_STORE_NAME);
      const request = store.getAll();
  
      return new Promise((resolve, reject) => {
        request.onsuccess = () => {
          console.log('request', request);
          const incidencias = request.result;
          //commit('incidencias', incidencias);
          resolve(incidencias);
        };
        request.onerror = () => {
          resolve([]);
        };
      });
    } catch (error) {
      console.log(error);
    }
},

  async obtenerEvidenciaOffline({ commit }) {
      try {
        const db = await openDatabase(DB_NAME, DB_VERSION);
        const tx = db.transaction([EVIDENCIAS_STORE_NAME], 'readwrite');
        const store = tx.objectStore(EVIDENCIAS_STORE_NAME);
        const request = store.getAll();
    
        return new Promise((resolve, reject) => {
          request.onsuccess = () => {
            console.log('request', request);
            const evidencias = request.result;
            //commit('setEvidencias', evidencias);
            resolve(evidencias);
          };
          request.onerror = () => {
            resolve([]);
          };
        });
      } catch (error) {
        console.log(error);
      }
  },


  async obtenerSeguimientoOffline({ commit }, data) {
    try {

      const db = await openDatabase(DB_NAME, DB_VERSION);
      const tx = db.transaction([TRANSPORTISTAS_STORE_NAME], 'readwrite');
      const store = tx.objectStore(TRANSPORTISTAS_STORE_NAME);
      const index = store.index('idRuta_idRutaProgramacion_idProductoTransportado_codigoTransportista');

      const idRuta = parseInt(data.idRuta);
      const idRutaProgramacion = parseInt(data.idRutaProgramacion);
      const idProductoTransportado = parseInt(data.idProductoTransportado);
      const codigoTransportista = data.codigoTransportista;
      const idRutaRecojo = data.idRutaReferencia;

      console.log([idRuta, idRutaProgramacion, idProductoTransportado, codigoTransportista]);

      const searchObj = IDBKeyRange.only([idRuta, idRutaProgramacion, idProductoTransportado, codigoTransportista]);

      return new Promise((resolve, reject) => {
        const request = index.openCursor(searchObj);
        request.onsuccess = (event) => {

          const cursor = event.target.result;
          if (cursor) {

            // Found
            const registro = cursor.value;

            resolve(registro);

          }

        };
        request.onerror = () => {
          reject(request.error);
        };
      });


    } catch (error) {
      console.log(error);
    }
  },


  async guardarCoordenadas({ commit }, coordenadas) {
    try {
      const db = await openDatabase(DB_NAME, DB_VERSION);
      const tx = db.transaction([COORDENADAS_STORE_NAME], 'readwrite');
      const store = tx.objectStore(COORDENADAS_STORE_NAME);

      //console.log('data coordenadas', coordenadas);

      // Guardar la latitud
      const objetoLatitud = {
        id: 'lat',
        nombre: 'latitude',
        valor: coordenadas.latitude
      };
      store.put(objetoLatitud);

      // Guardar la longitud
      const objetoLongitud = {
        id: 'lng',
        nombre: 'longitude',
        valor: coordenadas.longitude
      };
      store.put(objetoLongitud);
  
      await tx.complete;
      console.log('Coordenadas guardadas exitosamente!');
    } catch (error) {
      console.log('Error al guardar las coordenadas: ', error);
    }
  },
  

  async guardarTiposLeche({ commit }, tipos) {
      try {
      const db = await openDatabase(DB_NAME, DB_VERSION);
      const tx = db.transaction([TIPOS_LECHE_STORE_NAME], 'readwrite');
      const store = tx.objectStore(TIPOS_LECHE_STORE_NAME);

      //console.log('tipos', tipos);

      tipos.forEach(tipo => {
          
          store.put(tipo);

      });

      await tx.complete;

      // Actualizar el state
      commit('setTipos', tipos);

      } catch (error) {
          console.log(error);
      }
  },

  async obtenerTiposLeche({ commit }) {
      try {
        const db = await openDatabase(DB_NAME, DB_VERSION);
        const tx = db.transaction([TIPOS_LECHE_STORE_NAME]);
        const store = tx.objectStore(TIPOS_LECHE_STORE_NAME);
        const request = store.getAll();
    
        return new Promise((resolve, reject) => {
          request.onsuccess = () => {
            const tipos = request.result;
            commit('setTipos', tipos);
            resolve(tipos);
          };
          request.onerror = () => {
            reject(request.error);
          };
        });
      } catch (error) {
        console.log(error);
      }
  },

  async guardarRutas({ commit }, rutas) {
      try {
          const db = await openDatabase(DB_NAME, DB_VERSION);
          const tx = db.transaction([TRANSPORTISTAS_STORE_NAME], 'readwrite');
          const store = tx.objectStore(TRANSPORTISTAS_STORE_NAME);

          // Eliminar todas las rutas existentes en el almacén
          store.clear();

          //console.log('transp', rutas);

          rutas.forEach(ruta => {
              
              store.put(ruta);

          });

          await tx.complete;

          // Actualizar el state
          commit('setRutas', rutas);

      } catch (error) {
          console.log(error);
      }
  },


  async obtenerRutas({ commit }, data) {
    try {
      const db = await openDatabase(DB_NAME, DB_VERSION);
      const tx = db.transaction([TRANSPORTISTAS_STORE_NAME]);
      const store = tx.objectStore(TRANSPORTISTAS_STORE_NAME);
      const request = store.getAll();
  
      return new Promise((resolve, reject) => {
        request.onsuccess = () => {
          const rutasTemp = request.result;

          let rutas = [];

          if (data) {
            rutas = rutasTemp.filter((x) => x.idProductoTransportado == data.idProductoTransportado);
          } else {
            rutas = rutasTemp;
          }

          commit('setRutas', rutas);
          resolve(rutas);
        };
        request.onerror = () => {
          reject(request.error);
        };
      });
    } catch (error) {
      console.log(error);
    }
  },

  async getDetalleOffline({ commit }, data) {
    try {

      const db = await openDatabase(DB_NAME, DB_VERSION);
      const tx = db.transaction([TRANSPORTISTAS_STORE_NAME]);
      const store = tx.objectStore(TRANSPORTISTAS_STORE_NAME);
      const request = store.getAll();

      return new Promise((resolve, reject) => {
        request.onsuccess = () => {
          const rutasTemp = request.result;

          let detalle = {};

          if (data) {

            let originalObj = rutasTemp.find((ruta) => {
              return ruta.idRuta == data.idRuta && ruta.idProductoTransportado == data.idProductoTransportado && ruta.idRutaProgramacion == data.idRutaProgramacion;
            });

            detalle = {
              data: {
                infoRuta: {
                  idRuta: originalObj.idRuta,
                  idRutaProgramacion: 0,
                  numeroPlaca: originalObj.numeroPlaca,
                  nombreRuta: originalObj.nombreRuta,
                  nombreRegion: originalObj.nombreRegion,
                  horaLlegada: originalObj.horaLlegada ? originalObj.horaLlegada : '',
                },
                listaRecojo: originalObj.detalle.map((detalleItem) => ({
                  
                  nombreZona: detalleItem.nombreZona ? detalleItem.nombreZona : '',
                  destino: detalleItem.destino ? detalleItem.destino : '',

                  idRutaRecojo: detalleItem.idRutaRecojo ? detalleItem.idRutaRecojo : '',
                  codigoProveedor: detalleItem.codigoProveedor ? detalleItem.codigoProveedor : '',
                  nombreProveedor: detalleItem.nombreProveedor ? detalleItem.nombreProveedor : '',
                  horaOrdeno: detalleItem.horaOrdeno ? detalleItem.horaOrdeno : '',
                  horaOrdenoDiaAnterior: detalleItem.horaOrdenoDiaAnterior ? detalleItem.horaOrdenoDiaAnterior : '',
                  cantidadKilos: detalleItem.cantidadKilos ? detalleItem.cantidadKilos : '',
                  temperaturaRecojo: detalleItem.temperaturaRecojo ? detalleItem.temperaturaRecojo : '',
                  horaInicio: detalleItem.horaInicio ? detalleItem.horaInicio : '',
                  latitudInicio: detalleItem.latitudInicio ? detalleItem.latitudInicio : '',
                  longitudInicio: detalleItem.longitudInicio ? detalleItem.longitudInicio : '',
                  horaFin: detalleItem.horaFin ? detalleItem.horaFin : '',
                  latitudFin: detalleItem.latitudFin ? detalleItem.latitudFin : '',
                  longitudFin: detalleItem.longitudFin ? detalleItem.longitudFin : '',


                  horaSalida: detalleItem.horaSalida ? detalleItem.horaSalida : '',
                  latitudSalida: detalleItem.latitudSalida ? detalleItem.latitudSalida : '',
                  longitudSalida: detalleItem.longitudSalida ? detalleItem.longitudSalida : '',

                  horaLlegada: detalleItem.horaLlegada ? detalleItem.horaLlegada : '',
                  latitudLlegada: detalleItem.latitudLlegada ? detalleItem.latitudLlegada : '',
                  longitudLlegada: detalleItem.longitudLlegada ? detalleItem.longitudLlegada : '',

                  horaEvidencia: detalleItem.horaEvidencia ? detalleItem.horaEvidencia : '',
                  latitudEvidencia: detalleItem.latitudEvidencia ? detalleItem.latitudEvidencia : '',
                  longitudEvidencia: detalleItem.longitudEvidencia ? detalleItem.longitudEvidencia : '',
                  horaLlegadaPlanta: detalleItem.nulhoraLlegadaPlanta ? detalleItem.horaLlegadaPlanta : '',
                  latitudLlegadaPlanta: detalleItem.latitudLlegadaPlanta ? detalleItem.latitudLlegadaPlanta : '',
                  longitudLlegadaPlanta: detalleItem.longitudLlegadaPlanta ? detalleItem.longitudLlegadaPlanta : '',
                })),
              }
            };
            
          }

          commit('setDetalle', detalle);
          resolve(detalle);
          
        };
        request.onerror = () => {
          reject(request.error);
        };
      });

    } catch (error) {
      console.log(error);
    }
  }
  

};

async function openDatabase(dbName, dbVersion) {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(dbName, dbVersion);

    request.onsuccess = event => {
      const db = event.target.result;
      resolve(db);
    };

    request.onerror = event => {
      reject(event.target.error);
    };

    request.onupgradeneeded = event => {
      const db = event.target.result;

      db.createObjectStore(TIPOS_LECHE_STORE_NAME, { keyPath: 'id' });
    
      db.createObjectStore(COORDENADAS_STORE_NAME, { keyPath: 'id' });

      const evidenciasStore = db.createObjectStore(EVIDENCIAS_STORE_NAME, { keyPath: 'id', autoIncrement: true });
      evidenciasStore.createIndex('idRutaReferencia', 'idRutaReferencia', { unique: false }); 

      const incidenciasStore = db.createObjectStore(INCIDENCIAS_STORE_NAME, { keyPath: 'id', autoIncrement: true });
      incidenciasStore.createIndex('idRutaReferencia', 'idRutaReferencia', { unique: false }); 

      const transportistasStore = db.createObjectStore(TRANSPORTISTAS_STORE_NAME, { keyPath: 'id' });  
      transportistasStore.createIndex('idRuta_idRutaProgramacion_idProductoTransportado_codigoTransportista', ['idRuta', 'idRutaProgramacion', 'idProductoTransportado', 'codigoTransportista'], { unique: false });


      const trackingStore = db.createObjectStore(OFFLINE_TRACKING_STORE_NAME, { keyPath: 'id', autoIncrement: true });
      trackingStore.createIndex('action_idRutaRecojo', ['action', 'idRutaRecojo'], { unique: true });
      trackingStore.createIndex('action', 'action', { unique: false });

      // Definir los campos de la tabla
      /*coordenadasStore.createIndex('latitude', 'latitude', { unique: false });
      coordenadasStore.createIndex('longitude', 'longitude', { unique: false });
      coordenadasStore.createIndex('valor', 'valor', { unique: false });*/
    };
  });
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
