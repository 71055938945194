import GlobalComponents from "@/plugins/globalComponents";
import GlobalDirectives from "@/plugins/globalDirectives";

import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";

import "./assets/js/nav-pills.js";
import "./assets/scss/main.scss";
import SidebarPlugin from "@/components/SidebarPlugin";

import "bootstrap/dist/js/bootstrap.min.js";

export default {
  install(app) {
    app.use(SidebarPlugin);
    app.use(GlobalComponents);
    app.use(GlobalDirectives);
  },
};
