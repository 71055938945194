import { mapActions, mapGetters } from 'vuex';
import NotificacionesService from '../services/NotificacionesService';
import axios from 'axios';
export default {
    namespaced: true,
    state:{},
    getters:{},
    mutations:{},
    methods:{ },
    actions:{
      async notificacionResumen({state,commit}, data) {
        return await NotificacionesService.notificacionResumen(axios,data);
      },
      async notificacionLista({state,commit}, data) {
        return await NotificacionesService.notificacionLista(axios,data);
      },
      async notificacionResumenGanadero({state,commit}, data) {
        return await NotificacionesService.notificacionResumenGanadero(axios,data);
      },
      async notificacionListaGanadero({state,commit}, data) {
        return await NotificacionesService.notificacionListaGanadero(axios,data);
      },

    }
}
