<template>
  <div class="m-container container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="c-card">

          <div class="card-header pb-0">
            <h1 class="mb-4">Reporte de acopio diario</h1>
            <div class="hr mb-0"></div>
          </div>

          <div class="card-body pt-4">
            

            <div class="row mb-5">

              <div class="col-12">
                  <div class="overview-info style-2 without-borders">
                      <div>
                          <span class="label">total de kg</span><span class="amount">{{ $filters.toChar(totalAmount) }}</span>
                      </div>
                  </div>
              </div>

              <div class="col-12 col-md-3 order-2 order-md-1">
                  
                  <div class="b-table blue acceso-ganaderos-detalle-acopio">

                      <div class="t-header">
                      <div class="t-row">
                          <div class="t-cell">fecha</div>
                          <div class="t-cell">kg</div>
                      </div>
                      </div>

                      <div class="t-body">
                      <div
                          class="t-row"
                          v-for="record in records"
                      >
                          <div class="t-cell"><span class="mobile-cell-title">fecha</span>{{ record.fecha }}</div>
                          <div class="t-cell"><span class="mobile-cell-title">kg</span>{{ $filters.toChar(record.total) }}</div>
                      </div>
                      </div>

                  </div>

              </div>
              
              <div class="col-12 col-md-9 order-1 order-md-2 mb-5 mb-md-0">
                  <BarChart
                      id="prom_diario"
                      :title="chartTitle"
                      :chartDataToChar="true"
                      :chart="{
                        labels: labelsChart,
                        yAxisTitle: 'KG',
                        xAxisTitle: `${this.month} ${this.year}`,
                        datasets: [
                          {
                            label: '',
                            data: dataset,
                            borderColor: '#C1D42F',
                          }
                        ]
                      }"
                  />
              </div>

            </div>  


              <div class="form-actions mb-5">
                  
                  <Button
                      class="mt-3 ghost-button shadow-none"
                      size="lg"
                      type="button"
                      @click="goBack()"
                      >Volver</Button
                  >

              </div>


          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { markRaw } from "vue";
import { Form, Field } from "vee-validate";
import { mapActions } from 'vuex';
import BarChart from "@/components/Charts/BarChart.vue";
import TextInput from "@/components/Inputs/TextInput";
import FilterBox from "@/components/FilterBox";
import DateInput from "@/components/Inputs/Date";
import SelectButton from "@/components/Inputs/SelectButton";
import Button from "@/components/Inputs/Button";
import Pagination from '@/components/Pagination.vue';
import CustomModal from "@/components/CustomModal.vue";
import ConfirmationScreen from "@/components/ConfirmationScreen";
import Tag from "@/components/Tags/Tag";
import RowAccordion from "@/components/Accordion/RowAccordion";
import RadioButton from "@/components/Inputs/RadioButtonOriginalType.vue";
import Checkbox from "@/components/Inputs/Checkbox";
import * as Yup from "yup";

export default {
  components: {
      BarChart,
      TextInput,
      FilterBox,
      SelectButton,
      Button,
      Pagination,
      CustomModal,
      ConfirmationScreen,
      RadioButton,
      Checkbox,
      Tag,
      RowAccordion,
      DateInput,
      Form,
      Field,
  },
  name: "Aprobacion de Ganadero",
  data() {
    const schema = markRaw(
      Yup.object().shape({
        fechaInicio: Yup.string().required(),
        fechaFin: Yup.string().required(),
        zona: Yup.string().nullable(),
        codInterno: Yup.string().nullable(),
      })
    );
    const formValues = {
      fechaInicio: '',
      fechaFin: '',
      zona: '',
      codInterno: '',
    };

    return {
      schema,
      formValues,
      chartTitle: 'Acopio diario - 1era Quincena de Enero',
      totalAmount: 0,
      month: '',
      year: '',
      aniosOptions: this.$filters.getYears(2020),
      labelsChart: [],
      dataset: [],
      records: [],
      estadisticas: [
          { fecha: '1 Ene', cantidad: '11' },
          { fecha: '1 Feb', cantidad: '12' },
          { fecha: '1 Mar', cantidad: '8' },
          { fecha: '1 Abr', cantidad: '13' },
          { fecha: '1 May', cantidad: '4' },
          { fecha: '1 Jun', cantidad: '7' },
          { fecha: '1 Jul', cantidad: '10' },
          { fecha: '1 Ago', cantidad: '15' },
          { fecha: '1 Sep', cantidad: '5' },
          { fecha: '1 Oct', cantidad: '9' },
          { fecha: '1 Nov', cantidad: '12' },
          { fecha: '1 Dic', cantidad: '11' },
      ],
      model: {
        anio: '',
      },
    };
  },
  computed: {

  },
  watch: {

  },
  methods: {
    ...mapActions({
      getAcopioDiario: 'acopio/getAcopioDiario',
      downloadAcopio: 'acopio/downloadAcopio',
    }),
    async getReporte() {

      this.totalAmount = 0;

      const data = {
        "id": this.$route.params.id
      }

      await this.getAcopioDiario(data).then((response) => {

        if (response.status == 200) {

          this.records = response.data;

          this.records.map((x) => this.totalAmount += x.total);

          this.labelsChart = this.records.map((x) => x.fecha.split('/')[0]);
          this.dataset = this.records.map((x) => this.$filters.twoDecimals(x.total));

        }

      }).catch((err) => {

        //console.log(err.message);

      });

    },
    getChartTitle() {

      const reportDate = this.$route.params.id.split(".");
      var period = '1era';

      if (reportDate[2] == 2) {
        period = '2da';
      }

      var month = this.$filters.getMonth(reportDate[1]);

      this.year = reportDate[0];
      this.month = month;

      this.chartTitle = `Acopio diario - ${period} Quincena de ${month}`;
      
    },
    goBack() {
      this.$router.push({ name: "Acopio" });
    }
  },
  async mounted() {

    let loader = this.$loading.show();

    this.getChartTitle();

    await this.getReporte();

    loader.hide();

  },
};
</script>
