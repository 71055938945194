<template>
    <main class="mt-0 main-content">
      <section>
        <div class="page-login">
          <div class="container-fluid ps-0 pe-0">
            <div class="row g-0">
  
              <div class="col-md-6 billboard-wrapper">
  
                <div class="billboard">
  
                  <div class="top-box">
                    <h1>
                      <span class="bold">Bienvenidos</span><br>
                      <span>a la plataforma</span><br>
                      <span class="bold">de Ganaderos</span>
                    </h1>
                  </div>
  
                  <transition-group tag="div" class="img-slider"
                    name="fade"
                    @enter="startTransition"
                    @before-enter="startTransition"
                    @after-enter="endTransition"
                    @before-leave="startTransition"
                    @after-leave="endTransition">
                    <template v-for="number in [currentImg]" v-bind:key="number" >
  
                      <img :src="images[Math.abs(currentImg) % images.length]"/>
  
                    </template>
                  </transition-group>
  
                  <div class="bottom-box">
                    <img class="bottom-shape" src="assets/images/login/shapes/bottom-shape.png">
                  </div>
  
  
                </div>
  
              </div>

              <div class="col-md-6 d-flex">
  
                <img class="logo position-absolute" src="assets/images/logo-sm.svg">
  
                <div class="login-box card card-plain">
                  <div class="pb-0 header card-header">
                    <h4 class="font-weight-bolder">¿Olvidaste tu contraseña?</h4>
                  </div>
                  <div class="card-body">

                    <Form
                      @submit="onSubmit"
                      :validation-schema="schemaResetPassword"
                      :initial-values="formValuesResetPassword"
                      ref="veeForm"
                      v-slot="{ meta }"
                    >

                        <div v-show="(resetPassStep == 1)">

                            <p class="reset-password-hint">Escriba su código y le enviaremos un SMS al celular registrado con las instrucciones para restablecer su contraseña.</p>

                            <div class="text-input-mb-28px">
                                <TextInput
                                    name="codigo"
                                    type="text"
                                    label="código"
                                    placeholder="Escribe aquí tu código"
                                    v-model="model.codigo"
                                    :errorCustom="errorMessage"
                                />
                            </div>
        
                            <div v-if="enableSMS" class="text-input-mb-16px">
                                <TextInput
                                    name="sms"
                                    type="text"
                                    label="código del sms"
                                    placeholder="Escribe aquí tu código"
                                    v-model="model.sms"
                                    :errorCustom="errorMessageSMS"
                                />
                            </div>
                            
                            <div class="text-center">
                                <p class="reset-password-hint recaptcha"><span>Este sitio está protegido por reCAPTCHA. Aplican la <a href="https://policies.google.com/privacy" target="_blank" class="link">Política de Privacidad</a> de Google y los <a href="https://policies.google.com/terms" target="_blank" class="link">Términos del Servicio</a>.</span></p>

                                <button 
                                    @click="recaptcha"
                                    class="button success button-lg mt-3 primary-button shadow-none w-100"
                                    type="button"
                                    :class="{ 'loading-btn': isSubmitting }"
                                    :disabled="isSubmitting"
                                >
                                    <span class="btn-text">Enviar</span>
                                </button>
                            </div>

                        </div>

                        <div v-show="(resetPassStep == 2)">

                            <p class="reset-password-hint">Escriba su código y le enviaremos un SMS al celular registrado con las instrucciones para restablecer su contraseña.</p>

                            <div class="text-input-mb-28px">
                                <TextInput
                                    name="codigo"
                                    type="text"
                                    label="código"
                                    placeholder="Escribe aquí tu código"
                                    v-model="model.codigo"
                                    :errorCustom="errorMessage"
                                />
                            </div>
        
                            <div class="text-input-mb-16px">
                                <TextInput
                                    name="sms"
                                    type="text"
                                    label="código del sms"
                                    placeholder="Escribe aquí tu código"
                                    v-model="model.sms"
                                    :errorCustom="errorMessageSMS"
                                />
                            </div>

                            <div class="text-center">

                                <div class="sms-hint gap-0">
                                    <ul>
                                        <li>El código fue enviado a el número que termina en * * * * {{ phone }}</li>
                                        <li>Recuerde que el tiempo de espera es de {{ waitSeconds }}</li>
                                    </ul>
                                </div>

                                <p class="reset-password-hint recaptcha"><span>Este sitio está protegido por reCAPTCHA. Aplican la <a href="https://policies.google.com/privacy" target="_blank" class="link">Política de Privacidad</a> de Google y los <a href="https://policies.google.com/terms" target="_blank" class="link">Términos del Servicio</a>.</span></p>

                                <Button
                                    class="mt-3 primary-button"
                                    full-width
                                    size="lg"
                                    :class="{ 'loading-btn': isSubmitting }"
                                    :disabled="isSubmitting"
                                >
                                    <span class="btn-text">Continuar</span>
                                </Button>

                            </div>

                            <div class="text-center mt-3">

                                <span @click="sendAgainSMS()" class="text-success cursor-pointer" style="text-decoration: underline;font-size: 13px;font-family: &quot;Poppins&quot;;">Volver a enviar código</span>

                            </div>

                        </div>

                        <template v-if="(resetPassStep == 3)">

                            <p class="reset-password-hint">Le pediremos esta contraseña siempre que inicie sesión</p>

                            <div class="text-input-mb-28px">
                                <TextInput
                                    name="password"
                                    type="password"
                                    label="nueva contraseña"
                                    v-model="model.password"
                                    placeholder="Escribe aquí tu código"
                                />
                            </div>
        
                            <div class="text-input-mb-16px">
                                <TextInput
                                    name="passwordConfirmation"
                                    type="password"
                                    label="confirmar nueva contraseña"
                                    v-model="model.passwordConfirmation"
                                    placeholder="Escribe aquí tu código"
                                />
                            </div>

                            <div class="sms-hint">
                                <h4 class="title">Consejos para una contraseña segura:</h4>
                                <ul class="order-list">
                                    <li>Utilice al menos 8 caracteres, es mejor si es una combinación de cifras y letras.</li>
                                    <li>No utilice la misma contraseña que has utilizado con nosotros antes.</li>
                                </ul>
                            </div>
        
                            <div class="text-center">
                                <Button
                                    class="mt-3 primary-button"
                                    full-width
                                    size="lg"
                                    :class="{ 'loading-btn': isSubmitting }"
                                    :disabled="isSubmitting"
                                >
                                <span class="btn-text">Guardar</span>
                                </Button>
                            </div>
                      
                        </template>
  
                    </Form>
                  </div>
  
                </div>
              </div>
  
            </div>
          </div>
        </div>
      </section>
  
  
    </main>
  </template>

<script>

const body = document.getElementsByTagName("body")[0];

import { markRaw } from "vue";
import { Form, Field } from "vee-validate";
import TextInput from "@/components/Inputs/TextInput";
import Checkbox from "@/components/Inputs/Checkbox";
import Button from "@/components/Inputs/Button";
import SelectButton from "@/components/Inputs/SelectButton";
import CustomModal from "@/components/CustomModal.vue";
import { useReCaptcha } from 'vue-recaptcha-v3';
import {mapActions, mapGetters} from 'vuex';
import * as Yup from "yup";
import swal from "sweetalert2";

Yup.setLocale({
mixed: {
    required: 'Este campo es requerido'
},
string: {
    email: 'Este valor no es un e-mail válido',
    min: 'Valor muy corto (mínimo ${min} caracteres)',
    max: 'Valor muy largo (máximo ${max} caracteres)'
}
});

export default {
components: {
    TextInput,
    Checkbox,
    Button,
    CustomModal,
    Form,
    Field,
    SelectButton,
    useReCaptcha
},
data() {

    const schema = markRaw(
        Yup.object().shape({
            email: Yup.string()/*.email()*/.required(),
            password: Yup.string().min(6).required()
        })
    );
    const formValues = {
        email: "",
        password: "",
    };


    const schemaResetPassword = markRaw(
        Yup.object().shape({
            codigo: Yup.string()/*.email()*/.required(),
            sms: Yup.string().required(),
            password: Yup.string().min(8).required(),
            passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
        })
    );
    const formValuesResetPassword = {
        codigo: "",
        password: "",
        passwordConfirmation: "",
    };

    return {
        schema,
        formValues,
        schemaResetPassword,
        formValuesResetPassword,
        isSubmitting: false,
        
        enableSMS: false,
        resetPassStep: 1,
        codigoGanadero: null,
        sms: null,
        phone: 'xx',
        waitSeconds: 60,
        isSubmittingModal: false,

        errorMessage: '',
        errorMessageSMS: '',

        model: {
            rememberMe: true,
            islogged : false,
            messageLogin: '',
            codigo: '',
            sms: '',
            password: '',
            passwordConfirmation: '',
        },
        images: [
            "/assets/images/login/slider/1.jpg",
            "/assets/images/login/slider/2.jpg",
            "/assets/images/login/slider/3.jpg",
            //"/assets/images/login/slider/4.jpg",
            //"/assets/images/login/slider/5.jpg",
            //"/assets/images/login/slider/6.jpg",
            "/assets/images/login/slider/8.jpg",
            "/assets/images/login/slider/9.jpg",
            "/assets/images/login/slider/7.jpg",
        ],
        currentImg: 0,

        timer: 0,
        currentIndex: 0,
        dataModal: {
            showModal: false,
            title: "",
            active: "",
            request: "",
            response: "",
        },
    };
},
computed:{
    ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user',
    }),
},
methods: {
    ...mapActions({
    signIn: 'auth/signIn',
    resetPassword: 'auth/resetPassword',
    validateSMS: 'auth/validateSMS',
    changePassword: 'auth/changePassword',
    }),
    startTransition(el) {

    },

    endTransition(el) {

    },
    startSlide: function() {
    //this.timer = setInterval(this.next, 5000);

    setInterval(() => {
        this.currentImg = this.currentImg + 1;
    }, 10000);
    },
    next: function() {
        this.currentIndex += 1;
    },
    prev: function() {
        this.currentIndex -= 1;
    },
    showResetPassword() {
    
        this.errorMessage = '';
        this.errorMessageSMS = '';
        this.resetPassStep = 1;
        this.codigoGanadero = null;
        this.sms = null;

        this.dataModal.showModal = true;

    },
    async sendAgainSMS() {

        let data = {
            "username" : this.model.codigo,
        }

        await this.resetPassword(data).then((response) => {

            if (response.status == 200) {

                this.phone = response.data.telefono;
                this.waitSeconds = this.$filters.calcTimeFromSeconds(response.data.segundos);
                this.codigoGanadero = this.model.codigo;
                this.enableSMS = true;

            }

        }).catch((err) => {
            
            this.isSubmitting = false;

            if (typeof err.response.data.error == 'object') {
                
                let errorMessage = '';

                Object.values(err.response.data.errors).map((key, value)=> {
                    errorMessage = key[0];
                });

                this.errorMessage = errorMessage;

            } else if (typeof err.response.data.error == 'string') {

                this.errorMessage = err.response.data.error;

            }

        });

    },
    async onSubmit(values) {

        this.isSubmitting = true;

        this.errorMessage = '';
        this.errorMessageSMS = '';

        this.codigo = '';
        this.sms = '';
        this.password = '';
        this.passwordConfirmation = '';


        if (this.resetPassStep == 1 && !this.enableSMS) {
            
            let data = {
                "username" : this.model.codigo,
            }

            await this.resetPassword(data).then(async (response) => {

                if (response.status == 200) {

                    this.phone = response.data.telefono;
                    this.waitSeconds = this.$filters.calcTimeFromSeconds(response.data.segundos);
                    this.codigoGanadero = this.model.codigo;

                    this.enableSMS = true;
                    this.resetPassStep = 2;
                }

            }).catch((err) => {
                
                this.isSubmitting = false;

                this.$filters.onErrorBase(err);

                if (typeof err.response.data.error == 'object') {
                    
                    let errorMessage = '';

                    Object.values(err.response.data.errors).map((key, value)=> {
                        errorMessage = key[0];
                    });

                    this.errorMessage = errorMessage;

                } else if (typeof err.response.data.error == 'string') {

                    this.errorMessage = err.response.data.error;

                }

            });

        } else if (this.resetPassStep == 2) {

            let data = {
                "username" : this.codigoGanadero,
                "codigoSms" : this.model.sms
            }

            await this.validateSMS(data).then(async (response) => {

                if (response.status == 200) {

                    this.sms = this.model.sms;
                    this.resetPassStep = 3;

                }

            }).catch((err)=>{

                this.isSubmitting = false;

                this.$filters.onErrorBase(err);

                if (typeof err.response.data.error == 'object') {

                    let errorMessageSMS = '';

                    Object.values(err.response.data.errors).map((key, value)=> {
                        errorMessageSMS = key[0];
                    });

                    this.errorMessageSMS = errorMessageSMS;

                } else if (typeof err.response.data.error == 'string') {

                    this.errorMessageSMS = err.response.data.error;

                }

            });

        } else if (this.resetPassStep == 3) {

            let data = {
                "username" : this.codigoGanadero,
                "userpass" : this.model.password,
                "userpassConfirm" : this.model.passwordConfirmation,
                "codigoSms" : this.model.sms
            }
            
            await this.changePassword(data).then(async (response) => {

                if (response.status == 200) {

                    this.resetPassStep = 1;
                    this.codigoGanadero = null;
                    this.sms = null;

                    this.$refs.veeForm.resetForm();

                    const obj = {
                        message: 'Se actualizó clave con éxito',
                        confirmButtonText: 'Cerrar',
                        withCallback: false
                    }

                    this.$filters.showSuccessMessage(obj);

                    setTimeout(() => {
                        this.$router.push({ name: "Login" });
                    },150);

                }

            }).catch((err)=>{

                this.isSubmitting = false;

                this.$filters.onErrorBase(err);

                /*if (typeof err.response.data.error == 'object') {

                    let errorMessage = '';

                    Object.values(err.response.data.errors).map((key, value)=> {
                        errorMessage = key[0];
                    });

                    this.errorMessage = errorMessage;

                } else if (typeof err.response.data.error == 'string') {

                    this.errorMessage = err.response.data.error;

                }*/

            });

        }

        this.isSubmitting = false;

    },
    async recaptcha() {

        this.isSubmitting = true;

        this.errorMessage = '';

        if (this.model.codigo == '') {
            this.errorMessage = 'Este campo es requerido';
            this.isSubmitting = false;
            return;
        }

        await this.$recaptchaLoaded();

        const token = await this.$recaptcha('login');

        if (token) {
            this.onSubmit();
        } else {
            this.errorMessage = 'Error al validar código';
        }

        this.isSubmitting = true;

    }
},
mounted() {
    this.startSlide();
},
created() {
    if (this.authenticated) {
    this.$router.push({
        name: 'Home',
    })
    }

    if (localStorage.username && localStorage.checkbox !== "") {
    this.formValues.email = localStorage.getItem("username");
    this.model.rememberMe = (localStorage.getItem("rememberMe") === 'true');
    } else {
    this.formValues.email = '';
    this.model.rememberMe = false;
    }
}
};
</script>

<style>
.swal2-popup {
background: #F7F8F2;
/*box-shadow: 9px 9px 20px rgba(0, 101, 55, 0.4);*/
border-radius: 10px;
}
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
    background: rgb(0 0 0 / 75%);
}



.fade-enter-active,
.fade-leave-active {
transition: all 0.6s ease;
overflow: hidden;
visibility: visible;
position: absolute;
width:100%;
opacity: 1;
}

.fade-enter,
.fade-leave-to {
visibility: hidden;
width:100%;
opacity: 0;
}




.slide-leave-active,
.slide-enter-active {
transition: 1s;
}
.slide-enter {
transform: translate(100%, 0);
}
.slide-leave-to {
transform: translate(-100%, 0);
}







.img-slider{
overflow: hidden;
position: relative;
height: 100vh;
width: 100%;

/*width: 100%;
height: 100vh;
object-fit: cover;*/
}

.img-slider img {
width: 100%;
height: 100%;
object-fit: cover;
/*position: absolute;
top: 0;
left: 0;
bottom: 0;
right:0;*/
}
</style>
