<template>
  <div
    v-show="type !== 'hidden'"
    class="TextInput"
    :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <label v-if="label" :for="name">{{ label }}</label>

    <div class="position-relative">
      <template v-if="type === 'password'">
        <input
          :name="name"
          :id="name"
          :type="showPassword ? 'text' : 'password'"
          :value="inputValue"
          :placeholder="placeholder"
          @input="onInput($event)"
          @blur="handleBlur"
        />
      </template>

      <template v-else>

        <template v-if="showCurrency">
          <div class="text-input-group">
            <span class="input-group-text">S/.</span>
            <input
              :name="name"
              :id="name"
              :type="type"
              :value="inputValue"
              :placeholder="placeholder"
              @input="onInput($event)"
              @blur="handleBlur"
              :readonly="readonly"
            />
          </div>
        </template>

        <template v-else>
          <input
            :name="name"
            :id="name"
            :type="type"
            :value="inputValue"
            :placeholder="placeholder"
            @input="onInput($event)"
            @blur="handleBlur"
            :maxlength="maximumLength ? maximumLength : ''"
            :readonly="readonly"
          />
        </template>

      </template>

      <template v-if="type == 'password'">
        <span class="icon-password-eye" @click="toggleShow">
          <img v-show="showPassword" src="assets/images/icons/eyeClosed.svg" alt="Eye Closed">
          <img v-show="!showPassword" src="assets/images/icons/eyeOpen.svg" alt="Eye Open">
        </span>
      </template>
    </div>

    <template v-if="search">
      <span class="icon-search">
        <img src="/assets/images/icons/search.svg" alt="Search Icon">
      </span>
    </template>

    <p class="help-message text-gray" v-show="helpMessage && !errorMessage">
      {{ helpMessage }}
    </p>

    <p class="help-message" v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>

<script>
import { toRef } from "vue";
import { useField } from "vee-validate";

export default {
  data() {
    return {
      showPassword: false
    };
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      description: "Input value",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    successMessage: {
      type: String,
      default: "",
    },
    helpMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    search: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    validateOnMount: {
      type: Boolean,
      default: false,
    },
    showCurrency: {
      type: Boolean,
      default: false,
    },
    maximumLength: {
      type: [Boolean, Number, String],
      default: false,
    },
  },
  setup(props, {emit}) {
    // use `toRef` to create reactive references to `name` prop which is passed to `useField`
    // this is important because vee-validte needs to know if the field name changes
    // https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
    const name = toRef(props, "name");

    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, undefined, {
      validateOnMount: props.validateOnMount,
      initialValue: props.value,
    });

    const onInput = (event) => {
      handleChange(event, true)
      emit('update:modelValue', event.target.value)
    }


    if (props.readonly) {
      meta.valid = true;
    }

    if (props.optional) {
      meta.valid = true;
    }

    return {
      onInput,
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals/_variables.scss";
@import "../../assets/scss/globals/_mixins.scss";

.TextInput {
  position: relative;
  /*margin-bottom: calc(1em * 1.5);*/
  width: 100%;

  @media (max-width: $tablet_m) {
    //margin-bottom: calc(1em * 1.5);
  }
}

label {
  display: block;
  margin-bottom: 4px;
  width: 100%;
  font-family: $font-family-poppins;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #6E6E6E;

  @media (max-width: 1750px) {
    font-size: 10px;
  }

  @media (min-width: $tablet_m) and (max-width:1366px) {
    height: 24px;
    //margin-bottom: 8px;
    margin-bottom: 4px;
    display: flex;
    //align-items: center;
    align-items: flex-end;
  }
}

input {
  border: 1px solid #6E6E6E;
  //border-radius: 10px;
  border-radius: 8px;
  outline: none;
  background-color: $white;
  width: 100%;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
  background-color 0.3s ease-in-out;
  font-family: $font-family-poppins;
  font-weight: 400;
  line-height: 16px;
  color: #33352A;

  /*padding: 16px 20px;
  font-size: 13px;*/
  padding: 14px 11px;
  font-size: 11px;

  @media (max-width: $mobile_l) {
    padding: 14px 6px;
    font-size: 13px;
  }

  @media (min-width: 1440px) {
    //padding: 21px 20px;
    padding: 18px 20px;
    font-size: 14px;
  }
}

::placeholder {
  color: #B8B8B8;
}

/*input:focus {
  border-color: var(--primary-color);
}*/

.help-message {
  position: absolute;
  bottom: calc(-1.5 * 1em);
  left: 0;
  margin: 0;
  font-family: $font-family-poppins;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  white-space: nowrap !important;

  @media (max-width: $tablet_m) {
    white-space: nowrap !important;
  }

  &.text-gray {
    color: #B8B8B8;
  }

}



.icon-password-eye {
  position: absolute;
  right: 16px;
  //top: 38px;
  top: 50%;
  transform: translateY(-50%);
}

.search-box {
  input {
    border: 1px solid #7D8067;
    border-radius: 8px;
    //padding: 15px 20px;
  }

  .icon-search {
    position: absolute;
    right: 10px;
    top: 45%;
    transform: translateY(-45%);
    background-color: white;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.icon-search {
  position: absolute;
  top: 26px;
  right: 13px;
}

input:read-only {
  background: #efefef;
  border-color: #efefef;
}

.text-input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.text-input-group > input {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}


.text-input-group .input-group-text {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group-text {
  color: black;
  border: 1px solid #6E6E6E !important;
}

/*.TextInput.has-error input {
  background-color: var(--error-bg-color);
  color: var(--error-color);
}*/

.TextInput.has-error input {
  border-color: $error !important;
  //border-right: 1px solid $error !important;
}

.TextInput.has-error .help-message {
  color: $error;
  border-color: $error;
}

/*.TextInput.success input {
  background-color: var(--success-bg-color);
  color: $success;
}*/

/*.TextInput.success input {
  border-color: $success;
}

.TextInput.success .help-message {
  color: $success;
  border-color: $success;
}*/
</style>