<template>
  <div class="overview">
    <div v-if="withHeader" class="registro-exito" style="background-color: #F7F8F2;border-radius: 10px; padding: 10px 10px 10px 20px;width: 100%;">
      <div class="d-flex align-items-center">
        <img src="/assets/images/check-circle-fill.svg" class="me-1" alt="Home" width="20" style="width: 40px;margin-left: 6px;"/>
        <div class="preregistroexito">
          <h3>{{ title }}</h3>
          <h4>{{ subtitle }}</h4>
        </div>
      </div>

    </div>

    <div class="body" :class="{ 'two-col': twoColumns }">
      <slot name="body">
      </slot>
    </div>
    <!--<div class="field"
      v-for="(field, index) in fields"
      :key="index">
        <div class="key" v-html="field.key"></div>
        <div class="value">
          {{ field.value }}
        </div>
    </div>-->
  </div>
</template>

<script>
export default {
  name: 'ConfirmationScreen',
  data() {
    return {

    }
  },
  props: {
    title: {
      type: String,
      default: "Ganadero",
    },
    subtitle: {
      type: String,
      default: "PRE - REGISTRADO CON ÉXITO",
    },
    fields: {
      type: Array,
      description: "",
    },
    withHeader: {
      type: Boolean,
      default: true,
    },
    twoColumns: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.two-col {
  display: grid;

  @media (min-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>

