import { mapActions, mapGetters } from 'vuex';
import PagosService from '../services/PagosService';
import axios from 'axios';
export default {
  namespaced: true,
  state:{},
  getters:{},
  mutations:{},
  methods:{ },
  actions:{
    async getResultadoCalidad({state,commit}, data) {
      return await PagosService.getResultadoCalidad(axios,data);
    },
    async updateResultadoCalidad({state,commit}, data) {
      return await PagosService.updateResultadoCalidad(axios,data);
    },
    async downloadReporteResultadoCalidad({state,commit}, data) {
      return await PagosService.downloadReporteResultadoCalidad(axios,data);
    },
    async getTablas({state,commit}, data) {
      return await PagosService.getTablas(axios,data);
    },
    async getOptions({state,commit}, data) {
      return await PagosService.getOptions(axios,data);
    },
    async getDetalleTabla({state,commit}, data) {
      return await PagosService.getDetalleTabla(axios,data);
    },
    async postEscala({state,commit}, data) {
      return await PagosService.postEscala(axios,data);
    },
    async getEscala({state,commit}, data) {
      return await PagosService.getEscala(axios,data);
    },
    async updateEscala({state,commit}, data) {
      return await PagosService.updateEscala(axios,data);
    },
    async updateEstadoTabla({state,commit}, data) {
      return await PagosService.updateEstadoTabla(axios,data);
    },
    async getValorBase({state,commit}, data) {
      return await PagosService.getValorBase(axios,data);
    },
    async getProveedores({state,commit}, data) {
      return await PagosService.getProveedores(axios,data);
    },
    async postInsertarProveedor({state,commit}, data) {
      return await PagosService.postInsertarProveedor(axios,data);
    },
    async deleteProveedor({state,commit}, data) {
      return await PagosService.deleteProveedor(axios,data);
    },
    async postImportarProveedor({state,commit}, data) {
      return await PagosService.postImportarProveedor(axios,data);
    },

    async postGenerarPlanilla({state,commit}, data) {
      return await PagosService.postGenerarPlanilla(axios,data);
    },

    async getPreciosUnitarios({state,commit}, data) {
      return await PagosService.getPreciosUnitarios(axios,data);
    },
    async getDetallePreciosUnitarios({state,commit}, data) {
      return await PagosService.getDetallePreciosUnitarios(axios,data);
    },
    async downloadReportePreciosUnitarios({state,commit}, data) {
      return await PagosService.downloadReportePreciosUnitarios(axios,data);
    },

    async getReportePago({state,commit}, data) {
      return await PagosService.getReportePago(axios,data);
    },
    async getTotalReportePago({state,commit}, data) {
      return await PagosService.getTotalReportePago(axios,data);
    },
    async getDetalleReportePago({state,commit}, data) {
      return await PagosService.getDetalleReportePago(axios,data);
    },
    async downloadReporteReportePago({state,commit}, data) {
      return await PagosService.downloadReporteReportePago(axios,data);
    },

    async getEstadosCuadro({state,commit}, data) {
      return await PagosService.getEstadosCuadro(axios,data);
    },
    async getCuadros({state,commit}, data) {
      return await PagosService.getCuadros(axios,data);
    },
    async postGenerarCuadro({state,commit}, data) {
      return await PagosService.postGenerarCuadro(axios,data);
    },
    async postActualizarCuadro({state,commit}, data) {
      return await PagosService.postActualizarCuadro(axios,data);
    },

    async getRegionesReportePlanilla({state,commit}, data) {
      return await PagosService.getRegionesReportePlanilla(axios,data);
    },
    async getReportePlanilla({state,commit}, data) {
      return await PagosService.getReportePlanilla(axios,data);
    },
    async downloadReportePlanilla({state,commit}, data) {
      return await PagosService.downloadReportePlanilla(axios,data);
    },




    async postGenerarLiquidacion({state,commit}, data) {
      return await PagosService.postGenerarLiquidacion(axios,data);
    },
    async postValidarLiquidacionZip({state,commit}, data) {
      return await PagosService.postValidarLiquidacionZip(axios,data);
    },
    async downloadLiquidacionZip({state,commit}, data) {
      return await PagosService.downloadLiquidacionZip(axios,data);
    },


    async postImportarComprobantes({state,commit}, data) {
      return await PagosService.postImportarComprobantes(axios,data);
    },
    async getListarComprobantes({state,commit}, data) {
      return await PagosService.getListarComprobantes(axios,data);
    },
    async getDetalleComprobante({state,commit}, data) {
      return await PagosService.getDetalleComprobante(axios,data);
    },
    async viewComprobanteIFrame({state,commit}, data) {
      return await PagosService.viewComprobanteIFrame(axios,data);
    },
    async downloadComprobante({state,commit}, data) {
      return await PagosService.downloadComprobante(axios,data);
    },


    async getFacturasReporteSAP({state,commit}, data) {
      return await PagosService.getFacturasReporteSAP(axios,data);
    },
    async downloadFacturasSAP({state,commit}, data) {
      return await PagosService.downloadFacturasSAP(axios,data);
    },
    async getDescuentosSAP({state,commit}, data) {
      return await PagosService.getDescuentosSAP(axios,data);
    },
    async postCargarDescuentosSAPExcel({state,commit}, data) {
      return await PagosService.postCargarDescuentosSAPExcel(axios,data);
    },

  }
}
