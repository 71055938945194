<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb breadcrumb-links">

      <li class="breadcrumb-item">
        <!--<router-link to="/">-->
          <img @click="$router.go(-1)" src="/assets/images/icons/breadcrumbBack.svg" alt="Ir atrás" class="cursor-pointer" width="33">
        <!--</router-link>-->
      </li>

      <li class="breadcrumb-item"
          v-for="(route, index) in routes"
          :key="route.name"
          :class="{ 'active': (index === $route.matched.length - 1 || onlyRoute) }"
        >
          <router-link
            :to="{ name: route.name, query: { ...$route.query } }"
            v-if="index < $route.matched.length - 1"
          >
            {{ route.meta.title || route.name }}
          </router-link>
          <span v-else-if="route.meta.show !== false">
              {{ route.meta.title || route.name }}
          </span>
      </li>

    </ol>
  </nav>
</template>

<script>
export default {
  name: "route-breadcrumb",
  data() {
    return {
      routes: [],
      onlyRoute: false
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    }
  },
  methods: {
    getBreadName(route) {
      return route.name;
    },
    prevRoutePath() {
      //@click="$router.go(-1)"
      //hasHistory() ? $router.go(-1) : $router.push('/')
      //$router.back()
      //return this.prevRoute ? this.prevRoute.path : '/';
      //return hasHistory() ? $router.go(-1) : $router.push('/');
    },
    getBreadcrumb() {

      this.onlyRoute = false;

      let breadcrumb = this.$route.matched.slice();
      let occurrence = breadcrumb[0].redirect;
      let len = breadcrumb.filter((x) => (x.path == occurrence || x.redirect == occurrence));

      if (len.length >= 2) {
        
        this.routes = [];
        this.routes.push(breadcrumb[0]);
        this.onlyRoute = true;

      } else {

        this.routes = this.$route.matched.slice();

      }

    }
  },
  mounted() {
    this.getBreadcrumb();
  }
}
</script>

<style lang="scss">
.breadcrumb-links {

  .breadcrumb-item {
    display: inline-table;
  }

  .breadcrumb-item a,
  .breadcrumb-item span {
    font-size: 12px;
    line-height: 18px;
    color: #a5a3a3;
    font-weight: 500;

  }

  .breadcrumb-item.active a,
  .breadcrumb-item.active span {
    color: #33352A !important;
    font-weight: 600 !important;
    opacity: 1 !important;
  }

}
</style>
