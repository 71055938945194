import setting from './setting';

export default {

  getListSolicitudes: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/prestamo/lista`, data, {headers:setting.http.headers()});
  },

  getOptions: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/prestamo/parametros`, data, {headers:setting.http.headers()});
  },

  getGanadero: ($axios, id)=>{
    return $axios.get(`${setting.http.base_url}/prestamo/ganadero/${id}`, {headers:setting.http.headers()});
  },

  postPrestamo: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/prestamo/insertar`, data, {headers:setting.http.headers()});
  },

  getPrestamo: ($axios, id)=>{
    return $axios.get(`${setting.http.base_url}/prestamo/detalle/${id}`, {headers:setting.http.headers()});
  },

  updatePrestamo: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/prestamo/actualizar`, data, {headers:setting.http.headers()});
  },

  updatePrestamoEstado: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/prestamo/actualizar/estado`, data, {headers:setting.http.headers()});
  },

  getListReporte: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/prestamo/reporte`, data, {headers:setting.http.headers()});
  },

  getDetalleReporte: ($axios, id)=>{
    return $axios.get(`${setting.http.base_url}/prestamo/reporte/detalle/${id}`, {headers:setting.http.headers()});
  }

}
