/* eslint-disable prettier/prettier */
<template>
  <span
    class="tag"
    :class="getClasses(color)"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: "Tag",
  props: {
    color: {
      type: String
    },
  },
  methods: {
    getClasses: (color) => {
      let colorValue;

      colorValue = `${color}`;

      return `${colorValue}`;
    },
  },
};
</script>
