<template>

  <base-nav
    container-classes="m-container overflow-visible overflow-md-hidden"
    class="navbar-main navbar-expand-lg shadow-none"
    :position="'relative'"
    @change="toggleSidebar"
  >

        <nav class="m-breadcrumb" aria-label="breadcrumb">

          <route-bread-crumb></route-bread-crumb>

          <!--<ol class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb me-sm-6">
            <li class="text-sm breadcrumb-item">
              <img src="/assets/images/icons/home.svg" class="me-1" alt="Home" width="20">
              <a href="#">Home</a>
            </li>
            <li class="text-sm breadcrumb-item active" aria-current="page">
              {{ currentPage }}
            </li>
          </ol>-->

        </nav>

        <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4 me-sm-4 justify-content-end gap-4" id="navbar">
          <div class="d-flex box_notification" style="position: relative;">
            <div class="box_icon_alert cursor-pointer" @click="activeNotifications">
              <img class="box_icon_alert__img" src="/assets/images/icon_alert.svg" alt="Notificacion">
              <div class="box_icon_alert__text">{{ notifications.countTotal }}</div>
            </div>
            <div class="box_notification__list" v-if="notifications.active">
              <h4 class="box_notification__title">Notificaciones</h4>
              <ul class="box_notification__list__tab">
                <li class="box_notification__list__tab__item active" v-if="notifications.tabActive == 1" @click="btnTab($event, 1)">Nuevo <span>({{ notifications.countNews }})</span></li>
                <li class="box_notification__list__tab__item" :class="notifications.tabActive == 2 && 'active'" @click="btnTab($event, 2)">Todas <span>({{ notifications.countTotal }})</span></li>
              </ul>
              <div class="box_notification__list__content" v-if="notifications.tabActive == 1">
                <template v-if="notifications.listNew.length">
                  <template v-for="item in notifications.listNew" :key="item.detalle">
                    <div class="box_notification__list__content__item observer">
                      <div class="box_notification__list__content__item__icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M13.5 0C10.83 0 8.21987 0.791761 5.99981 2.27516C3.77974 3.75856 2.04942 5.86697 1.02763 8.33377C0.00584933 10.8006 -0.261496 13.515 0.259405 16.1337C0.780305 18.7525 2.06606 21.1579 3.95406 23.0459C5.84207 24.9339 8.24754 26.2197 10.8663 26.7406C13.485 27.2615 16.1994 26.9942 18.6662 25.9724C21.133 24.9506 23.2414 23.2203 24.7248 21.0002C26.2082 18.7801 27 16.17 27 13.5C27 11.7272 26.6508 9.97167 25.9724 8.33377C25.2939 6.69588 24.2995 5.20765 23.0459 3.95406C21.7924 2.70047 20.3041 1.70606 18.6662 1.02763C17.0283 0.349188 15.2728 0 13.5 0ZM13.5 24.3C11.364 24.3 9.2759 23.6666 7.49985 22.4799C5.7238 21.2931 4.33953 19.6064 3.52211 17.633C2.70468 15.6595 2.4908 13.488 2.90753 11.393C3.32425 9.29803 4.35284 7.37365 5.86325 5.86325C7.37366 4.35284 9.29803 3.32424 11.393 2.90752C13.488 2.4908 15.6595 2.70467 17.633 3.5221C19.6064 4.33953 21.2932 5.72379 22.4799 7.49984C23.6666 9.27589 24.3 11.364 24.3 13.5C24.3 16.3643 23.1621 19.1114 21.1368 21.1367C19.1114 23.1621 16.3643 24.3 13.5 24.3Z"
                            fill="#337AD0" />
                          <path
                            d="M13.4999 9.45C14.2455 9.45 14.8499 8.84558 14.8499 8.1C14.8499 7.35442 14.2455 6.75 13.4999 6.75C12.7543 6.75 12.1499 7.35442 12.1499 8.1C12.1499 8.84558 12.7543 9.45 13.4999 9.45Z"
                            fill="#337AD0" />
                          <path
                            d="M13.4999 10.8C13.1419 10.8 12.7985 10.9422 12.5453 11.1954C12.2921 11.4486 12.1499 11.7919 12.1499 12.15V18.9C12.1499 19.258 12.2921 19.6014 12.5453 19.8546C12.7985 20.1078 13.1419 20.25 13.4999 20.25C13.8579 20.25 14.2013 20.1078 14.4545 19.8546C14.7077 19.6014 14.8499 19.258 14.8499 18.9V12.15C14.8499 11.7919 14.7077 11.4486 14.4545 11.1954C14.2013 10.9422 13.8579 10.8 13.4999 10.8Z"
                            fill="#337AD0" />
                        </svg>
                      </div>
                      <div class="box_notification__list__content__item__body">
                        <div class="time_after">{{item.tiempo}}</div>
                        <h5 class="title">{{item.detalle}}</h5>
                        <div class="subject">{{item.motivo}}</div>
                        <time class="time">{{item.fecha}}</time>
                        <a class="enlarge" href="./devoluciones" v-if="item.parametro">
                          <span class="text">Ver detalle</span>
                        </a>
                      </div>
                    </div>
                  </template>
                </template>
              </div>
              <div class="box_notification__list__content" v-if="notifications.tabActive == 2">
                <template v-if="notifications.listAll.length">
                  <template v-for="item in notifications.listAll" :key="item.detalle">
                    <div class="box_notification__list__content__item info">
                      <div class="box_notification__list__content__item__icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M13.5 0C10.83 0 8.21987 0.791761 5.99981 2.27516C3.77974 3.75856 2.04942 5.86697 1.02763 8.33377C0.00584933 10.8006 -0.261496 13.515 0.259405 16.1337C0.780305 18.7525 2.06606 21.1579 3.95406 23.0459C5.84207 24.9339 8.24754 26.2197 10.8663 26.7406C13.485 27.2615 16.1994 26.9942 18.6662 25.9724C21.133 24.9506 23.2414 23.2203 24.7248 21.0002C26.2082 18.7801 27 16.17 27 13.5C27 11.7272 26.6508 9.97167 25.9724 8.33377C25.2939 6.69588 24.2995 5.20765 23.0459 3.95406C21.7924 2.70047 20.3041 1.70606 18.6662 1.02763C17.0283 0.349188 15.2728 0 13.5 0ZM13.5 24.3C11.364 24.3 9.2759 23.6666 7.49985 22.4799C5.7238 21.2931 4.33953 19.6064 3.52211 17.633C2.70468 15.6595 2.4908 13.488 2.90753 11.393C3.32425 9.29803 4.35284 7.37365 5.86325 5.86325C7.37366 4.35284 9.29803 3.32424 11.393 2.90752C13.488 2.4908 15.6595 2.70467 17.633 3.5221C19.6064 4.33953 21.2932 5.72379 22.4799 7.49984C23.6666 9.27589 24.3 11.364 24.3 13.5C24.3 16.3643 23.1621 19.1114 21.1368 21.1367C19.1114 23.1621 16.3643 24.3 13.5 24.3Z"
                            fill="#337AD0" />
                          <path
                            d="M13.4999 9.45C14.2455 9.45 14.8499 8.84558 14.8499 8.1C14.8499 7.35442 14.2455 6.75 13.4999 6.75C12.7543 6.75 12.1499 7.35442 12.1499 8.1C12.1499 8.84558 12.7543 9.45 13.4999 9.45Z"
                            fill="#337AD0" />
                          <path
                            d="M13.4999 10.8C13.1419 10.8 12.7985 10.9422 12.5453 11.1954C12.2921 11.4486 12.1499 11.7919 12.1499 12.15V18.9C12.1499 19.258 12.2921 19.6014 12.5453 19.8546C12.7985 20.1078 13.1419 20.25 13.4999 20.25C13.8579 20.25 14.2013 20.1078 14.4545 19.8546C14.7077 19.6014 14.8499 19.258 14.8499 18.9V12.15C14.8499 11.7919 14.7077 11.4486 14.4545 11.1954C14.2013 10.9422 13.8579 10.8 13.4999 10.8Z"
                            fill="#337AD0" />
                        </svg>
                      </div>
                      <div class="box_notification__list__content__item__body">
                        <div class="time_after">{{item.tiempo}}</div>
                        <h5 class="title">{{item.detalle}}</h5>
                        <div class="subject">{{item.motivo}}</div>
                        <time class="time">{{item.fecha}}</time>
                        <a class="enlarge" href="./devoluciones" v-if="item.parametro">
                          <span class="text">Ver detalle</span>
                        </a>
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <ul class="navbar-nav justify-content-end">
            <base-dropdown
              class="nav-item"
              tag="li"
              title-tag="a"
              title-classes="nav-link p-0"
            >
              <template v-slot:title-container>
                <a href="#" class="pe-sm-2 p-md-0" @click.prevent>
                  <div class="user-info">
                    <span class="me-2">{{ userName }}</span>
                    <img class="user me-3" src="/assets/images/user-profile.jpg" width="32" alt="User" style="border-radius: 50%;border: 1px solid #000000;">
                  </div>
                </a>
              </template>
              <a class="dropdown-item" @click="logout">
                <i class="ni ni-button-power me-2"></i>
                <span>Cerrar Sesión</span>
              </a>
            </base-dropdown>
          </ul>

      </div>

  </base-nav>
</template>
<script>
import BaseNav from "@/components/Navbar/BaseNav";
import {mapGetters, mapActions} from 'vuex';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Util } from "@/util/util";

export default {
  components: {
    BaseNav,
    RouteBreadCrumb,
    Util
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
    currentPage: {
      type: String
    }
  },
  computed: {
    userName() {
      return Util.getUserName();
    },
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    })
  },
  data() {
    return {
      showMenu: false,
      timer: null,
      searchModalVisible: false,
      searchQuery: "",
      notifications: {
        active: false,
        tabActive: 1,
        countNews: 0,
        countTotal: 0,
        listNew: [],
        listAll: []
      }
    };
  },
  methods: {
    ...mapActions({
      notificacionResumen: "notificacion/notificacionResumen",
      notificacionLista: "notificacion/notificacionLista",
      notificacionResumenGanadero: "notificacion/notificacionResumenGanadero",
      notificacionListaGanadero: "notificacion/notificacionListaGanadero",
    }),
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout(evn){
      localStorage.removeItem('token');
      localStorage.removeItem('userinfo');
      localStorage.removeItem('maxAge');
      setTimeout(() => {
        location.reload();
      },200)
    },
    activeNotifications() {
      this.notifications.active = !this.notifications.active
      document.querySelector(".viewport_layout").classList.toggle("active")
    },
    btnTab(event, index) {
      const allBtnTabs = document.querySelectorAll(".box_notification__list__tab__item")
      allBtnTabs.forEach(item => {
        item.classList.remove("active")
      });
      event.target.classList.add("active");
      this.notifications.tabActive = index
    },
    async getAllNotifications() {

      if (this.user.tipoUsuario == 170) {

        try {

          await this.notificacionResumen().then((response) => {
            if (response.status == 200) {
              this.notifications.countTotal = response.data.todos
              this.notifications.countNews = response.data.nuevo
              this.notifications.tabActive = (response.data.nuevo > 0) ? 1 : 2;
              console.log((response.data.nuevo > 0) ? 1 : 2)
            }
          }).catch((err) => {

          });
          let dataNew = {
              "nuevo": true
          }
          await this.notificacionLista(dataNew).then((response) => {
            if (response.status == 200) {
              this.notifications.listNew = response.data
            }
          }).catch((err) => {

          });
          let dataAll = {
              "nuevo": false
          }
          await this.notificacionLista(dataAll).then((response) => {
            if (response.status == 200) {
              this.notifications.listAll = response.data
            }
          }).catch((err) => {

          });

        } catch(err) {

        }

        



      } else {


        await this.notificacionResumenGanadero().then((response) => {
          if (response.status == 200) {
            this.notifications.countTotal = response.data.todos
            this.notifications.countNews = response.data.nuevo
            this.notifications.tabActive = (response.data.nuevo > 0) ? 1 : 2;
            console.log((response.data.nuevo > 0) ? 1 : 2)
          }
        })
        let dataNew = {
            "nuevo": true
        }
        await this.notificacionListaGanadero(dataNew).then((response) => {
          if (response.status == 200) {
            this.notifications.listNew = response.data
          }
        });
        let dataAll = {
            "nuevo": false
        }
        await this.notificacionListaGanadero(dataAll).then((response) => {
          if (response.status == 200) {
            this.notifications.listAll = response.data
          }
        });



      }
      
    }
  },
  async mounted() {

    let loader = this.$loading.show();

    try {  
      await this.getAllNotifications();
      this.timer = setInterval(() => {
        this.getAllNotifications();
      }, 120000);
      loader.hide();    
    } catch (error) {
      console.log('err', error);
      loader.hide();
    }
  
  },
  beforeDestroy() {
    // Limpia el timer usando el ID de intervalo almacenado en la variable de instancia
    clearInterval(this.timer);
  }
};
</script>

<style>
.user-info {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #33352A;
}
.box_notification__title {
  font-size: 17px;
}
</style>
