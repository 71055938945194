<template>
  <label class="Checkbox" :class="{ 'no-label': !label }">
    <input
      type="checkbox"
      :class="{ 'checked': modelValue }"
      :name="name"
      checked="checked"
      :disabled="disabled"
      v-model="model">
    <span class="checkmark"></span>
    <span v-show="label" class="text">{{ label }}</span>
  </label>
</template>

<script>
export default {
  components: {

  },
  name: "base-checkbox",
  emits: ["update:modelValue"],
  model: {
    prop: "modelValue",
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [Array, Boolean, Number],
      description: "Whether checkbox is checked",
    },
    disabled: {
      type: Boolean,
      description: "Whether checkbox is disabled",
    },
    inline: {
      type: Boolean,
      description: "Whether checkbox is inline",
    },
    inputClasses: {
      type: [String, Object, Array],
      description: "Checkbox input classes",
    },
    type: {
      type: String,
      description: "Checkbox type (e.g info, danger etc)",
    }
  },
  data() {
    return {
      cbId: "",
      touched: false,
    };
  },
  computed: {
    model: {
      get() {
        this.touched = this.modelValue ? true : false;

        return this.modelValue;
      },
      set(check) {
        check = this.modelValue ? false : true;

        if (!this.touched) {
          this.touched = true;
        }

        this.$emit("update:modelValue", check);
      },
    },
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`;
      }
      return "";
    },
  },
  created() {
    this.cbId = Math.random().toString(16).slice(2);
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals/_mixins.scss";
@import "../../assets/scss/globals/_variables.scss";
.Checkbox {

  display: block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  font-size: 11px;
  user-select: none;
  margin: 0;

  &:hover {

    background-color: initial;

    input[disabled] + .checkmark {
      background-color: #efefef;
      border-color: #efefef;

      &:hover {
        background-color: #efefef;
        border-color: #efefef;
      }

    }

  }

  input[disabled] + .checkmark {
    background-color: #efefef;
    border-color: #efefef;

    &:hover {
      background-color: #efefef;
      border-color: #efefef;
    }

  }

  &:hover input ~ .checkmark {
    background-color: #ffffff;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input.checked ~ .checkmark {
    background-color: $green;
    border: 1px solid $green;
    border-radius: 4px;
  }

  input.checked ~ .checkmark:after {
    display: block;
  }

  .text {
    font-family: 'Poppins';
    font-weight: 400;

    font-size: 11px;
    line-height: 23px;

    margin-left: 5px;

    @include responsive-min-width($desktop_s) {
      font-size: 12px;
      line-height: 22px;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    //top: 3px;
    left: 0;
    /*height: 16px;
    width: 16px;*/
    height: 23px;
    width: 23px;
    background-color: #ffffff;
    border: 1px solid $green;
    border-radius: 4px;

    &:after {
      content: "";
      position: absolute;
      display: none;

      /*left: 6px;
      top: 3px;
      width: 3px;
      height: 8px;*/
      left: 9px;
      top: 3px;
      width: 4px;
      height: 13px;
      border: solid #ffffff;
      border-width: 0 1px 1px 0;
      transform: rotate(45deg);

      @include responsive-min-width($desktop_s) {
        left: 8px;
        top: 4px;
        width: 4px;
        height: 11px;
      }

    }

    @include responsive-min-width($desktop_s) {
      top: 0;
      height: 21px;
      width: 21px;
    }
  }

  &.no-label {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .checkmark {

      position: relative;
      
    }

  }

  &.start-pos {
    padding: 0;
    justify-content: start;
  }

}
</style>

<style lang="scss">
.checkbox-mobile {
  padding: 1px !important;
  margin-top: 6px !important;
  //margin-bottom: 12px !important;

  .checkmark {
    width: 30px;
    height: 30px;

    &:after {
      width: 7px !important;
      height: 16px !important;
      left: 11px !important;
    }
  }
}
</style>
