const state = {
  isConnected: true,
};

const getters = {
  getConnectionStatus: state => state.isConnected
};

const mutations = {
  SET_CONNECTIVITY_STATUS(state, status) {
    state.isConnected = status;
  },
};

const actions = {
  setConnectivityStatus({ commit }, status) {
    commit('SET_CONNECTIVITY_STATUS', status);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
