<template>
  <div class="landing-bg h-100 bg-gradient-primary position-fixed w-100" style="display: none;"></div>

  <!--<div class="min-height-300 position-absolute w-100 bg-success"></div>-->

  <aside class="overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs fixed-start bg-white" id="sidenav-main">

    <side-bar class="icons_laive">

      <template v-slot:links>

        <template v-if="user.tipoUsuario !== 170">

        <sidebar-item :role="{ id: ['*'] }" :link="{ name: 'Acopio', path: '/acopio'}"></sidebar-item>
        <sidebar-item :role="{ id: [TIPO_LECHE_CALIENTE] }" :link="{ name: 'Calidad de leche', path: '/calidad/lc'}"></sidebar-item>
        <sidebar-item :role="{ id: [TIPO_LECHE_FRIA] }" :link="{ name: 'Calidad de leche', path: '/calidad/lf'}"></sidebar-item>
        <sidebar-item :role="{ id: ['*'] }" :link="{ name: 'Pagos', path: '/pagos'}"></sidebar-item>
        <sidebar-item :role="{ id: ['*'] }" :link="{ name: 'Créditos / Descuentos', path: '/creditos-descuentos'}"></sidebar-item>
        <sidebar-item :role="{ id: ['*'] }" :link="{ name: 'Devoluciones', path: '/devoluciones'}"></sidebar-item>

        </template>

        <template v-else>
          <sidebar-item :role="{ id: ['*'] }" :link="{ name: 'Seguimiento de rutas', path: '/seguimiento-rutas'}"></sidebar-item>
        </template>

      </template>

      <template v-slot:links-after></template>

    </side-bar>

  </aside>

  <main class="main-content position-relative border-radius-lg" @click="hideSidebar">
    <dashboard-navbar class="dashboard-navbar" :currentPage="routeName" :type="$route.meta.navbarType"></dashboard-navbar>

    <div class="viewport_layout height-100-per">
      <router-view></router-view>
      <VOffline @detected-condition="handleConnectivityChange" />
    </div>

    <content-footer v-if="!$route.meta.hideFooter"></content-footer>
  </main>

</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import Constants from '@/constants';
import { mapActions, mapGetters } from 'vuex';
import { VOffline } from 'v-offline';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}
function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    VOffline
  },
  data() {
    return {
      isLoaded: false,
      TIPO_LECHE_CALIENTE: Constants.TIPO_LECHE_CALIENTE,
      TIPO_LECHE_FRIA: Constants.TIPO_LECHE_FRIA,
      onLine: navigator.onLine,
      showBackOnline: false
    }
  },
  computed:{
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      isConnected: 'connection/getConnectionStatus'
    }),
    routeName() {
      if ( this.$route.path == '/dashboard') {
        return 'Logs de Ganaderos'
      }
    }
  },
  watch: {
    $route() {
      this.resizeCardContent();
    },
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
        }, 1000)
      }
    }
  },
  methods: {
    ...mapActions({
      setConnectivityStatus: 'connection/setConnectivityStatus',
      getParams: "seguimiento/getParams",
    }),
    updateOnlineStatus(e) {
      const { type } = e
      this.onLine = type === 'online'
    },
    handleConnectivityChange(status) {
      //console.log('status online', status);
      
      //this.setConnectivityStatus(status);
    },
    initScrollbar() {
      initScrollbar("sidenav");
    },
    hideSidebar(e) {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar(e) {
      this.$sidebar.isMinimized = true;
      if (window.innerWidth < 1440) {
        document.getElementById("admin_sidebar").scroll(0,0);
        this.$sidebar.isMinimized = false;
      }
      this.$sidebar.toggleMinimize();
    },
    resizeCardContent() {
      //console.log('RESIZE CONTENT');

      /*const $scrollableContent = document.querySelector('.scrollable-content');
      const hasScrollableContent = $scrollableContent !== null;

      if (hasScrollableContent) {

        if (window.innerHeight >= 1080) {
          document.querySelector('.scrollable-content').style.height = '64vh';
          console.log('1080');
        }

        if (window.innerHeight >= 1024 && window.innerHeight < 1080) {
          document.querySelector('.scrollable-content').style.height = '65vh';
          console.log('1024');
        }

        if (window.innerHeight >= 768 && window.innerHeight < 1024) {
          document.querySelector('.scrollable-content').style.height = '60vh';
          console.log('768');
        }

      }*/

    },
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  },
  mounted() {

    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)

    this.initScrollbar();

    this.$nextTick(function () {

      window.addEventListener("resize", this.resizeCardContent());

      let toDelete = [];

      let parentSection = document.querySelectorAll(".menu-list-laive");

      parentSection.forEach((section, index) => {

        let list = section.querySelectorAll(".section-list-laive:not(:empty)");
        let listCount = list.length;

        if (listCount == 0) {
          toDelete.push({
            'sectionIndex': index
          });
        }

      });

      toDelete.forEach((item) => {

        parentSection[item.sectionIndex].parentNode.parentNode.remove();

      });

    })

  },
  created() {
    window.addEventListener("resize", this.minimizeSidebar);
    window.addEventListener("resize", this.hideSidebar);

    window.addEventListener("resize", this.resizeCardContent());

    setInterval(() => {

      console.log('is online?', this.onLine);

    }, 1500);

  },
  destroyed() {
    window.removeEventListener("resize", this.hideSidebar);

    window.addEventListener("resize", this.resizeCardContent());
  }
};
</script>

<style>
.height-100-per {
  height: 100% !important;
}
.main-content {
  max-height: 100% !important;
  height: 100% !important;
}
</style>
