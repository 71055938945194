<template>
  <div
    class="TextInput Date"
    :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <label :for="name">{{ label }}</label>

    <div class="position-relative">
      <flatPickr
        :name="name"
        :id="name"
        :type="type"
        :value="inputValue"
        :modelValue="inputValue"
        :config="config"
        :placeholder="'Seleccionar'"
        :class="{ 'noedit': !!noedit }"
        @input="onInput($event)"
        @blur="handleBlur"
      />

      <span class="icon-calendar">
        <img src="/assets/images/icons/calendarInput.svg" alt="Calendar Icon">
      </span>
    </div>

    <p class="help-message" v-show="errorMessage || meta.valid">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import 'flatpickr/dist/flatpickr.min.css';
import flatPickr from "vue-flatpickr-component/dist/vue-flatpickr.min.js";
import { Spanish } from "flatpickr/dist/l10n/es.js"

export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      description: "Input value",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    noedit: {
      type: Boolean,
      default: false,
    },
    configProp: {
      type: [Array, Object],
      required: false,
    },
    onlyPeriods: {
      type: Boolean,
      default: false,
    },
    configPeriods: {
      type: [Array, Object],
      required: false,
    },
  },
  data () {
    return {
      config: {
        static: true,
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        altInputClass: 'fp-input',
        locale: Spanish,
        minDate: '',
        maxDate: '',
        disableMobile: "true"
      },
    }
  },
  computed: {
    computedConfig() {

      let minDate = '';
      let maxDate = '';

      if (this.configProp && this.configProp.hasOwnProperty('minDate')) {
        if (this.configProp.minDate.action == 'decrease') {

          minDate = new Date().fp_incr('-' + this.configProp.minDate.days);

          } else if (this.configProp.minDate.action == 'increase') {

          minDate = new Date().fp_incr(this.configProp.minDate.days);

          } else if (this.configProp.minDate.action == null) {

          minDate = this.configProp.minDate.days;

          }
      }

      if (this.configProp && this.configProp.hasOwnProperty('maxDate')) {

        if (this.configProp.maxDate.action == 'decrease') {

          maxDate = new Date().fp_incr('-' + this.configProp.maxDate.days);

        } else if (this.configProp.maxDate.action == 'increase') {

          maxDate = new Date().fp_incr(this.configProp.maxDate.days);

        } else if (this.configProp.maxDate.action == null) {

          maxDate = this.configProp.maxDate.days;

        }

      }

      return {
        "minDate": minDate,
        "maxDate": maxDate
      };

    },
    computedConfigPeriods() {

      if (this.configPeriods && this.configPeriods.hasOwnProperty('minMonth') && this.configPeriods.hasOwnProperty('maxMonth')) {

        let maxMonth = '';
        let minMonth = '';

        if (this.configPeriods.maxMonth == 'this') {
          maxMonth = new Date().getMonth() + 1;
        } else {
          maxMonth = this.configPeriods.maxMonth;
        }

        minMonth = maxMonth + this.configPeriods.minMonth;

        return {
          "minMonth": minMonth,
          "maxMonth": maxMonth
        };
      
      }

      return {
        "minMonth": '',
        "maxMonth": ''
      };

    }
  },
  created() {

    if (this.onlyPeriods) {

      let minMonth = parseInt(this.computedConfigPeriods.minMonth, 10);
      let maxMonth = this.computedConfigPeriods.maxMonth;
      
      let thisYear = new Date().getFullYear();

      var periods = [];

      for (let index = minMonth; index <= maxMonth; index++) {

        let iFormat = this.twoDigits(index);
        periods.push(`${thisYear}-${iFormat}-01`);
        periods.push(`${thisYear}-${iFormat}-15`);
        
      }

      this.config.enable = periods;

    } else {

      this.config.minDate = this.computedConfig.minDate;
      this.config.maxDate = this.computedConfig.maxDate;

    }

    //console.log('config', this.config);

  },
  components: {
    flatPickr,
  },
  methods: {
    twoDigits(number) {
      if (number < 10) {
        return String(number).padStart(2, '0');
      } else {
        return number;
      }
    }
  },
  setup(props, {emit}) {
    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });

    const onInput = (event) => {
      handleChange(event, true)
      emit('update:modelValue', event.target.value)
    }

    return {
      onInput,
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/globals/_variables.scss";
@import "../../assets/scss/globals/_mixins.scss";

.TextInput.Date {
  position: relative;
  /*margin-bottom: calc(1em * 1.5);*/
  width: 100%;

  @media (max-width: $tablet_m) {
    //margin-bottom: calc(1em * 1.5);
  }
}

.TextInput.Date label {
  display: block;
  margin-bottom: 4px;
  width: 100%;
  font-family: $font-family-poppins;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #6E6E6E;

  @media (max-width: 1750px) {
    font-size: 10px;
  }

  @media (min-width: $tablet_m) and (max-width:1366px) {
    height: 24px;
    //margin-bottom: 8px;
    margin-bottom: 4px;
    display: flex;
    //align-items: center;
    align-items: flex-end;
  }
}

.fp-input,
.flatpickr-mobile {
  border: 1px solid;
  border-color: #6E6E6E !important;
  //border-radius: 10px;
  border-radius: 8px;
  outline: none;
  background-color: $white !important;
  width: 100%;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
  background-color 0.3s ease-in-out;
  font-family: $font-family-poppins;
  font-weight: 400;
  line-height: 16px;
  color: #33352A;

  /*padding: 16px 20px;
  font-size: 13px;*/
  padding: 14px 11px;
  font-size: 11px;

  @media (max-width: $mobile_l) {
    font-size: 13px;
  }

  @media (min-width: 1440px) {
    //padding: 21px 20px;
    padding: 18px 20px;
    font-size: 14px;
  }

  &[type="date"]::-webkit-inner-spin-button,
  &[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
}

::placeholder {
  color: #B8B8B8;
}

/*input:focus {
  border-color: var(--primary-color);
}*/

.help-message {
  position: absolute;
  bottom: calc(-1.5 * 1em);
  left: 0;
  margin: 0;
  font-family: $font-family-poppins;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  white-space: nowrap !important;

  @media (max-width: $tablet_m) {
    white-space: nowrap !important;
  }

}



.icon-calendar {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.search-box {
  input {
    border-radius: 5px;
    //padding: 15px 20px;
  }

  .icon-search {
    position: absolute;
    right: 22px;
    //top: 10px;
    top: 49%;
    transform: translateY(-49%);

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.TextInput.Date .noedit + .fp-input {
  background: #efefef !important;
  border-color: #efefef !important;
  pointer-events: none;
}

.text-input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.text-input-group > input {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}


.text-input-group .input-group-text {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group-text {
  color: black;
  border: 1px solid #6E6E6E !important;
}

/*.TextInput.has-error input {
  background-color: var(--error-bg-color);
  color: var(--error-color);
}*/

.TextInput.Date.has-error input {
  border-color: $error !important;
  //border-right: 1px solid $error !important;
}

.TextInput.Date.has-error .help-message {
  color: $error;
  border-color: $error;
}

/*.TextInput.success input {
  background-color: var(--success-bg-color);
  color: $success;
}*/

/*.TextInput.success input {
  border-color: $success;
}

.TextInput.success .help-message {
  color: $success;
  border-color: $success;
}*/


.noedit.flatpickr-input.flatpickr-mobile {
  background: #efefef !important;
  border-color: #efefef !important;
  pointer-events: none;
}
</style>

<style>
.flatpickr-wrapper {
  display: flex !important;
}
.flatpickr-calendar.open {
  margin-left: 0 !important;
}
.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.today {
  color: #393939 !important;
  border: none;
  background: none !important;
  border-radius: 0;
}
.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.selected {
  background: #02A54F !important;
  color: white !important;
  border-radius: 0;
}
.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day:not(.selected):hover,
.flatpickr-calendar .flatpickr-day:not(.selected):focus {
  background: #02A54F !important;
  color: white !important;
  border: none;
  border-radius: 0;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #02A54F !important;
  font-size: 15px;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #7D8067 !important;
}
.flatpickr-current-month input.cur-year {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #7D8067 !important;
}

.flatpickr-day.prevMonthDay {
  color: rgb(57 57 57 / 65%) !important;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.flatpickr-disabled {
  color: rgba(57,57,57,0.1) !important;
}

.flatpickr-calendar.rightMost.open {
  right: 0;
}
</style>
