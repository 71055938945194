<template>
  <div class="chart">

    <div class="card chart-card z-index-2">
      <div class="pb-0 card-header mb-0">
        
        <div class="d-flex align-items-center justify-content-between">
          <h6 class="title">{{ title }}</h6>
          <div class="enlarge" v-if="enlarge" @click="openModal()">
            <span class="text">Ampliar</span>
            <img class="image" src="/assets/images/icons/magnifyingGlass.svg" alt="Agregar Accesorio" />
          </div>
        </div>
        
        <p v-if="description" class="text-sm" v-html="description" />
      </div>
      <div class="p-3 card-body">
        <div class="y-axis-title">
          <span class="symbol">{{ chart.yAxisTitle ? chart.yAxisTitle : 'S/' }}</span>
        </div>

        <div class="chart">
          <canvas :id="id" class="chart-canvas" :height="height" style="min-height: 350px; height: 400px; max-height: 400px!important;"></canvas>
        </div>
      </div>
    </div>

    <div v-if="infoText || legend" class="d-flex align-items-start mt-3" :class="[{ 'justify-content-between': infoText }, { 'justify-content-end': !infoText }]">
      <div class="chart-disclaimer" v-if="infoText">
        <p class="m-0">{{ infoText }}</p>
      </div>
      <ul class="legend" v-if="legend">
        <li v-for="dataset in chart.datasets">
          <div class="square" :style="{ backgroundColor: dataset.borderColor }"></div>
          <span class="text">{{ dataset.legendTitle }}</span>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
import Chart from "chart.js/auto";
import Numeral from 'numeral';

export default {
  name: "GradientLineChart",
  props: {
    id: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: "300",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    enlarge: {
      type: Boolean,
      default: false
    },
    infoText: {
      type: [Boolean, String],
      default: false
    },
    legend: {
      type: [Boolean, String],
      default: true
    },
    chartDataToChar: {
      type: Boolean,
      default: false
    },
    decimals: {
      type: [Boolean, Number],
      default: false
    },
    dataSymbolPosition: {
      type: [Boolean, String],
      default: false
    },
    yAxisTicksDecimals: {
      type: Boolean,
      default: false
    },
    chart: {
      type: Object,
      required: true,
      labels: Array,
      datasets: {
        type: Array,
        label: String,
        data: Array,
      },
    },
  },
  data() {

    const margin = 100;

    const minData = Math.min(...this.chart.datasets.flatMap(dataset => dataset.data));
    const maxData = Math.max(...this.chart.datasets.flatMap(dataset => dataset.data));

    return {
      yAxisTitle: this.chart.yAxisTitle || 'S/',
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
            /*position: 'bottom',
            labels: {
              usePointStyle: false,
              padding: 40,
              font: {
                size: 13,
                family: "Poppins",
                style: "normal",
                lineHeight: 2,
              },
            },*/
          },
          tooltip: {
            callbacks: {

              label: (tooltipItem, data) =>  {

                if (this.dataSymbolPosition == 'start') {
                  if (this.chartDataToChar) {
                    return ' ' + this.yAxisTitle + ' ' + Numeral(tooltipItem.raw).format('0,0');  
                  } else {
                    if (this.decimals == 5) {
                      return ' ' + this.yAxisTitle + ' ' + Numeral(tooltipItem.raw).format('0,0.00000');
                    } else {
                      return ' ' + this.yAxisTitle + ' ' + Numeral(tooltipItem.raw).format('0,0.00');
                    }                    
                  }
                } else {
                  if (this.chartDataToChar) {
                    return ' ' + Numeral(tooltipItem.raw).format('0,0') + ' ' + this.yAxisTitle;
                  } else {
                    return ' ' + Numeral(tooltipItem.raw).format('0,0.00') + ' ' + this.yAxisTitle;
                  }
                }
                
              }
              
            }
          }
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            suggestedMin: minData - margin,
            suggestedMax: maxData + margin,
            min: this.chart.min !== undefined ? this.chart.min - margin : undefined,
            max: this.chart.max !== undefined ? this.chart.max + margin : undefined,
            title: {
              display: false,
              text: this.chart.yAxisTitle || 'S/',
              color: '#02A54F',
              font: {
                size: 14,
                family: "Poppins",
                style: "normal",
              },
              align: 'center',
            },
            grid: {
              drawBorder: true,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#6E6E6E",
              font: {
                size: 11,
                family: "Poppins",
                style: "normal",
                lineHeight: 2,
              },
              callback: (value) =>  {
                if (this.yAxisTicksDecimals) {
                  return Numeral(value).format('0,0.00');
                } else {
                  return Numeral(value).format('0,0');
                }
                
              }
            },
          },
          x: {
            title: {
              display: true,
              text: '',
              color: '#02A54F',
              font: {
                size: 14,
                family: "Poppins",
                style: "normal",
              },
              align: 'center',
            },
            grid: {
              drawBorder: true,
              display: true,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#6E6E6E",
              padding: 20,
              font: {
                size: 11,
                family: "Poppins",
                style: "normal",
                lineHeight: 2,
              },
              minRotation: 45,
            },
          },
          
        }
      }
    }
  },
  watch: {
    chart() {
      this.setCharts();
    }
  },
  methods: {
    openModal() {
      this.$emit('open-modal', this.id);
    },
    setCharts() {
      var gradientLineChart = document.getElementById(this.id).getContext("2d");

      //var gradientStroke1 = gradientLineChart.createLinearGradient(0, 230, 0, 50);
      var gradientStroke1 = gradientLineChart.createLinearGradient(0, 0, 0, 0);
      var gradientStroke2 = gradientLineChart.createLinearGradient(0, 0, 0, 0);
      var gradientStroke3 = gradientLineChart.createLinearGradient(0, 0, 0, 0);

      let chartStatus = Chart.getChart(this.id);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      if (this.chart.datasets.length == 3) {

        new Chart(gradientLineChart, {
          type: "line",
          data: {
            labels: this.chart.labels,
            datasets: [
              {
                label: this.chart.datasets[0].label,
                tension: 0,
                borderColor: this.chart.datasets[0].borderColor,
                backgroundColor: gradientStroke1,
                // eslint-disable-next-line no-dupe-keys
                borderWidth: 3,
                fill: true,
                data: this.chart.datasets[0].data,
                pointRadius: 1,
                pointHoverRadius: 1,
                maxBarThickness: 6,
              },
              {
                label: this.chart.datasets[1].label,
                tension: 0,
                borderColor: this.chart.datasets[1].borderColor,
                // eslint-disable-next-line no-dupe-keys
                borderWidth: 3,
                backgroundColor: gradientStroke2,
                fill: true,
                data: this.chart.datasets[1].data,
                pointRadius: 1,
                pointHoverRadius: 1,
                maxBarThickness: 6,
              },
              {
                label: this.chart.datasets[2].label,
                tension: 0,
                borderColor: this.chart.datasets[2].borderColor,
                // eslint-disable-next-line no-dupe-keys
                borderWidth: 3,
                backgroundColor: gradientStroke3,
                fill: true,
                data: this.chart.datasets[2].data,
                pointRadius: 1,
                pointHoverRadius: 1,
                maxBarThickness: 6,
              },
            ],
          },
          options: this.options
        });
      } else if (this.chart.datasets.length == 2) {
        new Chart(gradientLineChart, {
          type: "line",
          data: {
            labels: this.chart.labels,
            datasets: [
              {
                label: this.chart.datasets[0].label,
                tension: 0,
                borderColor: this.chart.datasets[0].borderColor,
                backgroundColor: gradientStroke1,
                // eslint-disable-next-line no-dupe-keys
                borderWidth: 3,
                fill: true,
                data: this.chart.datasets[0].data,
                pointRadius: 1,
                pointHoverRadius: 1,
                maxBarThickness: 6,
              },
              {
                label: this.chart.datasets[1].label,
                tension: 0,
                borderColor: this.chart.datasets[1].borderColor,
                // eslint-disable-next-line no-dupe-keys
                borderWidth: 3,
                backgroundColor: gradientStroke2,
                fill: true,
                data: this.chart.datasets[1].data,
                pointRadius: 1,
                pointHoverRadius: 1,
                maxBarThickness: 6,
              },
            ],
          },
          options: this.options
        });
      } else if (this.chart.datasets.length == 1) {
        new Chart(gradientLineChart, {
          type: "line",
          data: {
            labels: this.chart.labels,
            datasets: [
              {
                label: this.chart.datasets[0].label,
                tension: 0,
                borderWidth: 0,
                pointRadius: 1,
                pointHoverRadius: 1,
                borderColor: this.chart.datasets[0].borderColor,
                backgroundColor: gradientStroke1,
                // eslint-disable-next-line no-dupe-keys
                borderWidth: 3,
                fill: true,
                data: this.chart.datasets[0].data,
                maxBarThickness: 6,
              },
            ],
          },
          options: this.options,
        });
      }
    }
  },
  mounted() {
    this.setCharts();
  },
};
</script>
