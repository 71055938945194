<template>
  <div v-show="show" class="filter-box">

    <template v-if="mode && mode == 'select'">

      <template v-if="hasCustomId">
        <select v-model="model.selectBox" class="select">
          <option value="" disabled>Selecciona aquí</option>
          <option v-for="item in options"
            :key="item[hasCustomId]"
            :value="item[hasCustomId]">
            {{item.nombre}}
          </option>
        </select>
      </template>

      <template v-else>
        <select v-model="model.selectBox" class="select">
          <option value="" disabled>Selecciona aquí</option>
          <option v-for="item in options"
            :key="item.id"
            :value="item.id">
            {{item.nombre}}
          </option>
        </select>
      </template>

    </template>

    <template v-else>
      <div class="order-filters">
        <p>Ordenar<br> alfabéticamente</p>

        <div>
          <img src="/assets/images/icons/AZ.svg" @click="orderFilters.asc = !orderFilters.asc; orderFilters.desc = false" :class="{selected: (orderFilters.asc)}" class="order-filter me-2" alt="Ordenar ASC" width="29">
          <img src="/assets/images/icons/ZA.svg" @click="orderFilters.desc = !orderFilters.desc; orderFilters.asc = false" :class="{selected: (orderFilters.desc)}" class="order-filter" alt="Ordenar DESC" width="29">
        </div>
      </div>

      <hr class="hr my-2">

      <input
        type="text"
        placeholder="Busca aquí"
        v-model="model.userInput"
        class="search-box mb-3"
      />
    </template>

    <div class="d-flex" style="gap: 14px;">
      <Button
        class="primary-button shadow-none"
        size="sm"
        @click="search()"
        >Aplicar</Button
      >

      <Button
        class="danger-button shadow-none"
        size="sm"
        @click="clear()"
        >Borrar</Button
      >
    </div>

  </div>
</template>

<script>
import TextInput from "@/components/Inputs/TextInput";
import Button from "@/components/Inputs/Button.vue";
import FilterBox from "@/components/FilterBox";

export default {
  components: {
    TextInput,
    Button,
    FilterBox
  },
  data() {
    return {
      model: {
        userInput: '',
        selectBox : '',
      },
      orderFilters: {
        asc: false,
        desc: false
      }
    };
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    show: {
      type: [String, Boolean],
      default: "",
    },
    clearInput: {
      type: [String, Boolean],
      default: "",
    },
    mode: {
      type: [String, Boolean],
      default: false,
    },
    options: {
      type: [Array],
      default: [],
    },
    hasCustomId: {
      type: String,
      default: "",
    },
  },
  watch: {
    clearInput(val) {
      if (val) {

        if (mode == 'select') {
          this.model.selectBox = '';
        } else {
          this.model.userInput = '';
        }

      }
    },
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
    hide() {
      //this.show = false;
      this.$emit('close', this.name);

    },
    search() {

      if (this.mode == 'select') {

        this.$emit('search', {
          'name': this.name,
          'word': this.model.selectBox,
          'order': this.orderFilters.asc ? 'asc' : this.orderFilters.desc ? 'desc' : false
        });

      } else {
  
        this.$emit('search', {
          'name': this.name,
          'word': this.model.userInput,
          'order': this.orderFilters.asc ? 'asc' : this.orderFilters.desc ? 'desc' : false
        });

      }


      this.$emit('close', this.name);
    },
    clear() {

      if (this.mode == 'select') {

        this.model.selectBox = '';
        this.orderFilters.asc = false,
        this.orderFilters.desc = false,

        this.$emit('clear', this.name);

      } else {

        this.model.userInput = '';
        this.orderFilters.asc = false,
        this.orderFilters.desc = false,

        this.$emit('clear', this.name);

      }

      this.$emit('close', this.name);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals/_mixins.scss";
@import "../assets/scss/globals/_variables.scss";
.filter-box {
  position: absolute;
  background-color: white;
  max-width: 270px;
  display: flex;
  flex-direction: column;
  z-index: 99;
  box-shadow: 9px 9px 20px rgb(0 101 55 / 40%) !important;
  border-radius: 10px !important;
  top: 50px;//145px;
  padding: 20px;
  border: 1px solid #e5e5e5;

  @include responsive-max-width($tablet_m) {
    /*left: 50%;
    transform: translateX(-50%);*/
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  &:before {
    font-family: "FontAwesome";
    content: "\f0d8";
    position: absolute;
    right: auto;
    font-size: 22px;
    color: #fff;
    transition: top 0.35s ease;
    top: -11px;

    @include responsive-max-width($tablet_m) {
      left: 50% !important;
      transform: translateX(-50%);
    }
  }


  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #7D8067;
    text-align: left;
    margin-bottom: 5px;
  }

  input {
    border: 1px solid #6E6E6E;
    border-radius: 5px;
    padding: 21px 20px;
    outline: none;
    background-color: $white;
    width: 100%;
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;

    font-family: $font-family-poppins;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #33352A;

    @media (min-width: 1440px) {
      padding: 18px 20px;
      font-size: 14px;
    }

  }

  .order-filters {
    display: flex;
    padding: 21px 0px 0px;
    width: 100%;
    justify-content: space-between;
    .order-filter {
      cursor: pointer;
      &.selected {
        border: 1px solid #ff3143;
        border-radius: 4px;
      }
    }
  }

  .hr {
    width: 100%;
    opacity: 0.5;
    border: 1px solid #DCDECB;
  }

  .button {
    font-weight: 400;
    padding: 0.5rem 2rem!important;
  }
}


.select {
  margin-bottom: 16px;
  border: 1px solid #7D8067;
  //border-radius: 10px;
  border-radius: 8px;
  //padding: 14px 11px;
  font-size: 11px;
  width: 100%;

  padding: 13px 34px 13px 20px;

  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;

  background: url(~images/icons/caretDown.svg) no-repeat;
  background-position: right 6px center;
  background-size: 24px;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  outline: 0;

  @media (max-width: $mobile_l) {
    font-size: 13px;
  }

  @media (min-width: 1440px) {
    /*padding: 14.82px 20px;
    font-size: 14px;
    background-position: right 8px center;
    background-size: 30px;*/

    padding: 14.82px 34px 14.82px 20px;
    font-size: 14px;
    background-position: right 8px center;
    background-size: 27px;
  }

  /*@media (min-width: $tablet_m) {
    max-width: 168px;
  }*/

  @media (max-width: $tablet_m) {
    padding-right: 40px;
    background-position: right 8px center;
  }
}
</style>
