import setting from './setting';

export default {

    getParams: ($axios) => {
        return $axios.get(`${setting.http.base_url}/ruta/programacion/parametros`, { headers: setting.http.headers() });
    },

    getList: ($axios, data)=>{
        return $axios.post(`${setting.http.base_url}/portal/transportista/ruta/seguimiento/lista`, data, {headers:setting.http.headers()});
    },

    getDetalle: ($axios, data)=>{
        return $axios.post(`${setting.http.base_url}/portal/transportista/ruta/seguimiento/detalle`, data, {headers:setting.http.headers()});
    },

    postSeguimiento: ($axios, data)=>{
        return $axios.post(`${setting.http.base_url}/portal/transportista/ruta/seguimiento/registrar`, data, {headers:setting.http.headers()});
    },

    postEvidencia: ($axios, data)=>{
        return $axios.post(`${setting.http.base_url}/portal/transportista/ruta/seguimiento/evidencia/registrar`, data,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "multipart/form-data",
            },
        }
    )},

    postIncidencia: ($axios, data)=>{
        return $axios.post(`${setting.http.base_url}/portal/transportista/ruta/seguimiento/evidencia/registrar`, data,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "multipart/form-data",
            },
        }
    )},

}
