<template>
  <div class="accordion confirmation-field" :class="{'is-active': isOpen}">
    <div class="accordion-item-title header">
      <button
        @click="toggleAccordion()"
        :aria-expanded="isOpen"
        :aria-controls="`collapse${uid}`"
        class="accordion-item-trigger d-flex justify-content-between"
        :class="getClass(backgroundColor)"
      >
        <h4 class="title">
          <slot name="title" />
        </h4>

        <img v-if="isEdit" @click="performAction($event)" :class="getEditIconClass(backgroundColor)" :src="getEditIcon(backgroundColor)" alt="Editar Datos">
        
        <div class="arrow-trigger">
          <span class="accordion-item-trigger-icon"></span>
        </div>

      </button>
    </div>

    <transition
        name="accordion-item"
        @enter="startTransition"
        @before-enter="startTransition"
        @after-enter="endTransition"
        @before-leave="startTransition"
        @after-leave="endTransition">
      <div v-show="isOpen" :id="`collapse${uid}`" class="accordion-item-details">
        <div class="accordion-item-details-inner body">
          <slot name="content" />
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: true,
    };
  },
  props: {
    backgroundColor: {
      type: String
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    showOnEdit: '',
  },
  created() {
    this.uid = Math.random().toString(16).slice(2);
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    performAction(event) {
      event.stopPropagation();
      this.$emit('performAction', this.showOnEdit);
    },
    startTransition(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    endTransition(el) {
      el.style.height = ''
    },
    getClass: (color) => {

      if (color !== undefined) {

        let backgroundColor;

        backgroundColor = `${color}`;

        return `background-${backgroundColor}`;

      }

    },
    getEditIcon(color) {
      
      return color == 'gray' ? '/assets/images/icons/edit.svg' : '/assets/images/icons/edit-white.svg';

    },
    getEditIconClass(color) {

      return color == 'gray' ? 'edit-btn color-white' : 'edit-btn';

    }
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
@import "../../assets/scss/globals/_variables.scss";
@import "../../assets/scss/globals/_mixins.scss";

.accordion-item-trigger {
  width: 100%;
  text-align: left;

  background-color: #02A54F;
  border-radius: 10px;

  padding: 11.5px 20px;

  color: #ffffff;

  .title {
    color: #ffffff !important;
  }

  &.background-gray {
    
    background-color: transparent;
    border: none;

    .title {
      color: #02A54F !important;
    }

    .accordion-item-trigger-icon {
      border-right: 2px solid #02A54F;
      border-bottom: 2px solid #02A54F;
    }

  }

  .edit-btn {
    //border-right: 2px solid white;

    margin-right: 71px;
    padding-left: 80px;
    padding-right: 31px;
    padding-top: 12px;
    position: absolute;
    top: 0;
    padding-bottom: 13px;
    right: 0;

    &.color-white {
      border-right: 2px solid #02A54F;
    }
  }

  .edit-btn + .arrow-trigger {
    border-left: 2px solid white;
    padding-left: 50px;
    width: 20px;
    height: 20px;
  }

}

.accordion-item-title {
  position: relative;

   h4 {
    font-size: 1.25rem;
    margin-bottom: 0;
    //padding-right: 1.25rem;
  }
}
.accordion-item-trigger-icon {
  $size: 14.67px;
  display: block;
  position: absolute;
  top: 3px; right: 1.25rem; bottom: 0;
  margin: auto;
  width: $size;
  height: $size;
  border-right: 2px solid #ffffff;//2px solid #02A54F;
  border-bottom: 2px solid #ffffff;//2px solid #02A54F;
  transform: translateY(math.div(-$size, 4)) rotate(45deg);
  transition: transform 0.2s ease;

  .is-active & {
    transform: translateY(math.div($size, 4)) rotate(225deg);
  }
}

/*.accordion-item-details {
  overflow: hidden;
  background-color: whitesmoke;
}*/

.accordion-item-enter-active, .accordion-item-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}
.accordion-item-enter, .accordion-item-leave-to {
  height: 0 !important;
  opacity: 0;
}

.confirmation-field {
  //margin-bottom: 26.5px;
  margin-bottom: 40.5px;

  .header {
    //padding: 11.5px 20px;
    background: rgba(247, 247, 247, 0.5);
    border: 1px solid #DCDECB;
    border-radius: 10px;

    .title {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #02A54F;
      margin-bottom: 0;
      border: 0;

      @include responsive-min-width($desktop_s) {
        font-size: 16.5px;
      }
    }
  }

}
</style>
