class Util {

  static getUser() {
    return JSON.parse(localStorage.getItem("userinfo")).usuario;
  }

  static getUserName() {
    return JSON.parse(localStorage.getItem("userinfo")).nombreCompleto;
  }

  static getTipo() {
    let tipo = JSON.parse(localStorage.getItem("userinfo")).tipoLeche;
    tipo = (tipo == 4) ? 'Fria' : 'Caliente';
    return tipo;
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  static formatDate(date) {
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }

  static formatDateReturn(input) {
    return input.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$2-$1");
  }

  static getTipoRegistro(no) {
    let documento;

    switch (parseInt(no)) {
      case 1:
        documento = "DNI";
        break;
      case 2:
        documento = "RUC";
    }

    return documento;
  }

  static getTipoRegistroString(str) {
    let documento;

    switch (str) {
      case "DNI":
        documento = 1;
        break;
      case "RUC":
        documento = 2;
    }

    return documento;
  }

  static getTipoLeche(no) {
    let leche;

    switch (parseInt(no)) {
      case 3:
        leche = "Caliente";
        break;
      case 4:
        leche = "Fría";
    }

    return leche;
  }

  static getTipoLecheString(str) {
    let leche;

    switch (str) {
      case "Caliente":
        leche = 3;
        break;
      case "Fría":
        leche = 4;
    }

    return leche;
  }

  static getTipoOrdeno(no) {
    let leche;

    switch (parseInt(no)) {
      case 32:
        leche = "Mecánico";
        break;
      case 33:
        leche = "Manual";
    }

    return leche;
  }

  static getTipoProveedor(no) {
    let proveedor;

    switch (parseInt(no)) {
      case 5:
        proveedor = "directo";
        break;
      case 6:
        proveedor = "arrendatario";
        break;
      case 7:
        proveedor = "acopiador";
        break;
      case 8:
        proveedor = "asociacion";
    }

    return proveedor;
  }

  static getTipoProveedorString(str) {
    let proveedor;

    switch (str) {
      case "Directo":
        proveedor = 5;
        break;
      case "Arrendatario":
        proveedor = 6;
        break;
      case "Acopiador":
        proveedor = 7;
        break;
      case "Asociación":
        proveedor = 8;
    }

    return proveedor;
  }
}
export { Util }
