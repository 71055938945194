<template>
    <img 
      @click="deleteR(idx)" 
      alt="Eliminar"
      class="cursor-pointer"
      src="/assets/images/icons/trash-red.svg" 
    >
  </template>
  
  <script>
  export default {
    props: {
      idx: Number,
      deleteR: Function
    }
  }
  </script>