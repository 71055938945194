import { mapActions, mapGetters } from 'vuex';
import CalidadService from '../services/CalidadService';
import axios from 'axios';
export default {
    namespaced: true,
    state:{},
    getters:{},
    mutations:{},
    methods:{ },
    actions:{

      async getOptions({state,commit}, data) {
        return await CalidadService.getOptions(axios,data);
      },
      async getListSolidos({state,commit}, data) {
        return await CalidadService.getListSolidos(axios,data);
      },
      async getListUFC({state,commit}, data) {
        return await CalidadService.getListUFC(axios,data);
      },
      async getListTRAM({state,commit}, data) {
        return await CalidadService.getListTRAM(axios,data);
      },
      async getListSomaticas({state,commit}, data) {
        return await CalidadService.getListSomaticas(axios,data);
      },
      async downloadSolidos({state,commit}, data) {
        return await CalidadService.downloadSolidos(axios,data);
      },
      async downloadUFC({state,commit}, data) {
        return await CalidadService.downloadUFC(axios,data);
      },
      async downloadSomaticas({state,commit}, data) {
        return await CalidadService.downloadSomaticas(axios,data);
      },
      async downloadTRAM({state,commit}, data) {
        return await CalidadService.downloadTRAM(axios,data);
      },
      async devolucionLecheList({state,commit}, data) {
        return await CalidadService.devolucionLecheList(axios,data);
      },
      async devolucionLechePDF({state,commit}, data) {
        return await CalidadService.devolucionLechePDF(axios,data);
      },

    }
}
