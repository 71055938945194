<template>
  <div
    v-show="type !== 'hidden'"
    class="TextArea"
    :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <label v-if="label" :for="name">{{ label }}</label>

    <div class="position-relative">
      <textarea
      :id="name"
      :name="name"
      :placeholder="placeholder"
      :readonly="readonly"
      :value="modelValue"
      @input="onInput($event)"
      @blur="handleBlur"
      rows="4"
      />

    </div>

    <p class="help-message" v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>


<script>
import { toRef } from 'vue';
import { useField } from 'vee-validate';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    successMessage: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 4,
    },
  },
  setup(props, { emit }) {
    const name = toRef(props, 'name');

    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, undefined, {
      validateOnMount: true,
      initialValue: props.modelValue,
    });

    const onInput = (event) => {
      handleChange(event, true);
      emit('update:modelValue', event.target.value);
    };

    return {
      onInput,
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>

  
<style lang="scss" scoped>
@import "../../assets/scss/globals/_variables.scss";
@import "../../assets/scss/globals/_mixins.scss";


.TextArea {
    position: relative;
    /*margin-bottom: calc(1em * 1.5);*/
    width: 100%;

    @media (max-width: $tablet_m) {
        //margin-bottom: calc(1em * 1.5);
    }

    label {
        display: block;
        margin-bottom: 4px;
        width: 100%;
        font-family: $font-family-poppins;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #6E6E6E;

        @media (max-width: 1750px) {
            font-size: 10px;
        }

        @media (min-width: $tablet_m) and (max-width:1366px) {
            height: 24px;
            //margin-bottom: 8px;
            margin-bottom: 4px;
            display: flex;
            //align-items: center;
            align-items: flex-end;
        }
    }

    .position-relative {

        textarea {
            border: 1px solid #6E6E6E;
            border-radius: 8px;
            outline: none;
            background-color: #FFFFFF;
            width: 100%;
            transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out;
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            line-height: 16px;
            color: #33352A;
            padding: 18px 20px;
            font-size: 14px;
        }

    }
}


.help-message {
    position: absolute;
    bottom: calc(-1.5 * 1em);
    left: 0;
    margin: 0;
    font-family: $font-family-poppins;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    white-space: nowrap !important;

    @media (max-width: $tablet_m) {
        white-space: nowrap !important;
    }
}


input:read-only {
    background: #efefef;
    border-color: #efefef;
}


.TextArea.has-error input {
    border-color: $error !important;
    //border-right: 1px solid $error !important;
}

.TextArea.has-error .help-message {
    color: $error;
    border-color: $error;
}

/*.TextArea.success input {
    background-color: var(--success-bg-color);
    color: $success;
}*/

/*.TextArea.success input {
    border-color: $success;
}

.TextArea.success .help-message {
    color: $success;
    border-color: $success;
}*/
</style>