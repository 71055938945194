import setting from './setting';

export default {

  getResultadoCalidad: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/acopio/resumen/calidad`, data, {headers:setting.http.headers()});
  },

  updateResultadoCalidad: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/acopio/resumen/calidad/actualizar`, data, {headers:setting.http.headers()});
  },

  downloadReporteResultadoCalidad: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/acopio/resumen/calidad/generar/excel`, data, {headers:setting.http.headers(), observe: 'response', responseType: 'arraybuffer'});
  },




  getTablas: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/tablas`, data, {headers:setting.http.headers()});
  },

  getOptions: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/tablas/parametros`, data, {headers:setting.http.headers()});
  },

  getDetalleTabla: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/tablas/detalle`, data, {headers:setting.http.headers()});
  },

  postEscala: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/tablas/escalas/insertar`, data, {headers:setting.http.headers()});
  },

  getEscala: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/tablas/escalas/detalle`, data, {headers:setting.http.headers()});
  },

  updateEscala: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/tablas/escalas/actualizar`, data, {headers:setting.http.headers()});
  },

  updateEstadoTabla: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/tablas/detalle/actualizar`, data, {headers:setting.http.headers()});
  },

  getValorBase: ($axios, id)=>{
    return $axios.get(`${setting.http.base_url}/pago/tablas/detalle/${id}`, {headers:setting.http.headers()});
  },

  getProveedores: ($axios, id)=>{
    return $axios.get(`${setting.http.base_url}/pago/tablas/escalas/listar/proveedor/${id}`, {headers:setting.http.headers()});
  },

  postInsertarProveedor: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/tablas/escalas/insertar/proveedor`, data, {headers:setting.http.headers()});
  },

  deleteProveedor: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/tablas/escalas/actualizar/proveedor`, data, {headers:setting.http.headers()});
  },

  postImportarProveedor: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/tablas/escalas/importar/proveedor`, data, {headers:setting.http.headers()});
  },

  postGenerarPlanilla: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/planilla/generar`, data, {headers:setting.http.headers()});
  },

  getPreciosUnitarios: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/reporte/precios-unitarios`, data, {headers:setting.http.headers()});
  },
  getDetallePreciosUnitarios: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/reporte/precios-unitarios/detalle`, data, {headers:setting.http.headers()});
  },
  downloadReportePreciosUnitarios: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/reporte/precios-unitarios/generar/excel`, data, {headers:setting.http.headers(), observe: 'response', responseType: 'arraybuffer'});
  },

  getReportePago: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/reporte/pago`, data, {headers:setting.http.headers()});
  },
  getTotalReportePago: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/reporte/pago/total`, data, {headers:setting.http.headers()});
  },
  getDetalleReportePago: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/reporte/pago/detalle`, data, {headers:setting.http.headers()});
  },
  downloadReporteReportePago: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/reporte/pago/generar/excel`, data, {headers:setting.http.headers(), observe: 'response', responseType: 'arraybuffer'});
  },

  getEstadosCuadro: ($axios, data)=>{
    return $axios.get(`${setting.http.base_url}/pago/cuadro/estados`, {headers:setting.http.headers()});
  },
  getCuadros: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/cuadro/listar`, data, {headers:setting.http.headers()});
  },
  postGenerarCuadro: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/cuadro/generar`, data, {headers:setting.http.headers()});
  },
  postActualizarCuadro: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/cuadro/actualizar`, data, {headers:setting.http.headers()});
  },

  getRegionesReportePlanilla: ($axios, data)=>{
    return $axios.get(`${setting.http.base_url}/pago/resumen/regiones`, {headers:setting.http.headers()});
  },
  getReportePlanilla: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/resumen/consultar`, data, {headers:setting.http.headers()});
  },
  downloadReportePlanilla: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/resumen/exportar`, data, {headers:setting.http.headers(), observe: 'response', responseType: 'arraybuffer'});
  },


  postGenerarLiquidacion: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/liquidaciones/generar`, data, {headers:setting.http.headers()});
  },
  postValidarLiquidacionZip: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/liquidaciones/generar/validar`, data, {headers:setting.http.headers()});
  },
  downloadLiquidacionZip: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/liquidaciones/descargar/zip`, data, {headers:setting.http.headers(), observe: 'response', responseType: 'arraybuffer'});
  },

  postImportarComprobantes: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/comprobante/importar`, data, {headers:setting.http.headers()});
  },
  getListarComprobantes: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/comprobante/lista`, data, {headers:setting.http.headers()});
  },
  getDetalleComprobante: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/comprobante/detalle`, data, {headers:setting.http.headers()});
  },
  viewComprobanteIFrame: ($axios, file)=>{
    return $axios.get(`${setting.http.base_url}/pago/comprobante/detalle/archivo/${file}`, {headers:setting.http.headers(), responseType: 'blob'});
  },
  downloadComprobante: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/comprobante/excel`, data, {headers:setting.http.headers(), observe: 'response', responseType: 'arraybuffer'});
  },


  getFacturasReporteSAP: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/reporte/carga/facturas`, data, {headers:setting.http.headers()});
  },
  downloadFacturasSAP: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/reporte/carga/facturas/excel`, data, {headers:setting.http.headers(), observe: 'response', responseType: 'arraybuffer'});
  },
  getDescuentosSAP: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/pago/reporte/carga/descuentos`, data, {headers:setting.http.headers()});
  },
  postCargarDescuentosSAPExcel: ($axios, data)=>{
    //return $axios.post(`${setting.http.base_url}/pago/carga/descuentos/excel`, data, {headers:setting.http.headers()});
    return $axios.post(`${setting.http.base_url}/pago/reporte/carga/descuentos/excel`, data, {headers:setting.http.headers(), observe: 'response', responseType: 'arraybuffer'});
  },


}
