import { createStore } from 'vuex';
import axios from 'axios';
import auth from './auth';
import accesoGanaderos from './accesoGanaderos';
import acopio from './acopio';
import calidad from './calidad';
import maestroGanadero from './maestroGanadero';
import pagos from './pagos';
import prestamos from './prestamos';
import dashboard from './dashboard';
import seguimiento from './seguimiento';
import mantenimiento from "./mantenimiento";
import notificacion from "./notificacion";
import offline from "./offline";
import connection from "./connection";
//import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import bootstrap from 'bootstrap/dist/js/bootstrap.min';

const axiosP = store => {
    store.$axios = axios;
}
export const store = createStore({
  state: {
    bootstrap
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    accesoGanaderos,
    acopio,
    calidad,
    maestroGanadero,
    pagos,
    prestamos,
    dashboard,
    seguimiento,
    mantenimiento,
    notificacion,
    offline,
    connection
  },
  plugins:[axiosP]
})
