import { mapActions, mapGetters } from "vuex";
import MantenimientoService from "../services/MantenimientoService";
import axios from "axios";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  methods: {},
  actions: {
    async getQuincenas({ state, commit }, data) {
      return await MantenimientoService.getQuincenas(axios, data);
    },
    async getOfflineTransportista({ state, commit }, data) {
      return await MantenimientoService.getOfflineTransportista(axios, data);
    },
  },
};
