export default {
    tokenData() {
        return {
            token: localStorage.getItem("token"),
            expiration: localStorage.getItem("maxAge"),
            user: JSON.parse(localStorage.getItem("userinfo")),
            roles: JSON.parse(localStorage.getItem("userinfo")).roles,
        }
    },
    http: {
        base_url: process.env.VUE_APP_API_URL ,
        headers: () => {
            return {
                "Authorization": `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json"
            }
        }
    }

}
