import setting from './setting';

export default {

  getOptions: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/acopio/parametros`, data, {headers:setting.http.headers()});
  },
  getListSolidos: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/portal/ganadero/lista/solidos/quincena`, data, {headers:setting.http.headers()});
  },
  getListUFC: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/portal/ganadero/lista/ufc/quincena`, data, {headers:setting.http.headers()});
  },
  getListSomaticas: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/portal/ganadero/lista/somatica/quincena`, data, {headers:setting.http.headers()});
  },
  getListTRAM: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/portal/ganadero/lista/tram/quincena`, data, {headers:setting.http.headers()});
  },
  downloadSolidos: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/portal/ganadero/lista/solidos/quincena/exportar`, data, {headers:setting.http.headers(), observe: 'response', responseType: 'arraybuffer'});
  },
  downloadUFC: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/portal/ganadero/lista/ufc/quincena/exportar`, data, {headers:setting.http.headers(), observe: 'response', responseType: 'arraybuffer'});
  },
  downloadSomaticas: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/portal/ganadero/lista/somatica/quincena/exportar`, data, {headers:setting.http.headers(), observe: 'response', responseType: 'arraybuffer'});
  },
  downloadTRAM: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/portal/ganadero/lista/tram/quincena/exportar`, data, {headers:setting.http.headers(), observe: 'response', responseType: 'arraybuffer'});
  },
  devolucionLecheList: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/portal/ganadero/devolucion/leche`, data, {headers:setting.http.headers()});
  },
  devolucionLechePDF: ($axios, id)=>{
    return $axios.get(`${setting.http.base_url}/portal/ganadero/devolucion/leche/generar/pdf/${id}`, {headers:setting.http.headers(), responseType: 'blob'});
  },

}
