<template>
  <div class="m-container container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="c-card">
          <div class="card-header pb-0">
            <h1 class="mb-4">Créditos / Descuentos</h1>
            <div class="hr mb-0"></div>
          </div>

          <div class="card-body no-scroll pt-4">
            <ul class="tabs-selector nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="first-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#first-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="first-tab-pane"
                  aria-selected="true"
                >
                  Créditos
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="second-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#second-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="second-tab-pane"
                  aria-selected="false"
                >
                  Convenios
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="third-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#third-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="third-tab-pane"
                  aria-selected="false"
                >
                  Casas Agropecuarias
                </button>
              </li>
            </ul>

            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane show active"
                id="first-tab-pane"
                role="tabpanel"
                aria-labelledby="first-tab"
                tabindex="0"
              >
                <div class="tab-body w-fixed-s">
                  <template v-if="!modeCreditosDetalle">
                    <div class="fixed-section">
                      <Form
                        @submit="onSubmitPrestamos"
                        :validation-schema="schemaPrestamos"
                        ref="veeForm"
                        :initial-values="formValuesPrestamos"
                        v-slot="{ meta }"
                      >
                        <div class="action-box justify-content-start">
                          <div class="grid grid-container-1">
                            <div class="grid-item-1">
                              <SelectButton
                                name="estado"
                                label="estado"
                                placeholder="Selecciona"
                                v-model="model.estadoPrestamos"
                                :options="estadosPrestamos"
                              />
                            </div>
                            <div class="grid-item-action">
                              <Button
                                class="primary-button shadow-none mb-0"
                                size="lg"
                                type="submit"
                                :class="{
                                  'loading-btn': isSubmittingPrestamos,
                                }"
                                :disabled="isSubmittingPrestamos"
                                ><span class="btn-text">Consultar</span></Button
                              >
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>

                    <div class="scrollable-content-xl">
                      <template
                        v-if="recordsPrestamos && recordsPrestamos.length > 0"
                      >
                        <div class="overview-info mt-0 sticky-overview-total">
                          <span class="label">MONTO DE SALDO DE DEUDA</span
                          ><span class="amount">{{
                            $filters.formatCurrency(totalPrestamos)
                          }}</span>
                        </div>

                        <div class="n-table mt-4 lista-creditos sticky-header">
                          <div class="t-header">
                            <div class="t-row">
                              <div class="t-cell">
                                <span
                                  >fecha de<br />
                                  solicitud</span
                                >
                              </div>
                              <div class="t-cell">
                                <span
                                  >fecha de<br />
                                  desembolso</span
                                >
                              </div>
                              <div class="t-cell">
                                <span
                                  >tipo de<br />
                                  solicitud</span
                                >
                              </div>
                              <div class="t-cell">
                                <span>monto<br />solicitado (s/)</span>
                              </div>
                              <div class="t-cell">
                                <span>saldo<br />préstamos (s/)</span>
                              </div>
                              <div class="t-cell">
                                <span
                                  >tasa de<br />
                                  interés</span
                                >
                              </div>
                              <div class="t-cell">
                                <span>estado</span>
                              </div>
                              <div class="t-cell justify-content-center">
                                <span></span>
                              </div>
                            </div>
                          </div>

                          <div class="t-body">
                            <div
                              class="t-row"
                              v-for="record in this.recordsPrestamos"
                            >
                              <div class="t-cell">
                                {{ record.fechaSolicitud }}
                              </div>
                              <div class="t-cell">
                                {{ record.fechaDesembolso }}
                              </div>
                              <div class="t-cell">
                                {{ record.tipoSolicitud }}
                              </div>
                              <div class="t-cell">
                                {{
                                  $filters.roundToTwo(record.montoSolicitado)
                                }}
                              </div>
                              <div class="t-cell">
                                {{ $filters.roundToTwo(record.saldoPrestamo) }}
                              </div>
                              <div class="t-cell">
                                {{ $filters.twoDecimals(record.tasaInteres) }}
                              </div>
                              <div class="t-cell">
                                <Tag
                                  color="green"
                                  v-if="record.estado == 'Aprobado'"
                                  >{{ record.estado }}</Tag
                                >
                                <Tag
                                  color="danger"
                                  v-if="record.estado == 'Rechazado'"
                                  >{{ record.estado }}</Tag
                                >
                                <Tag
                                  color="danger"
                                  v-if="record.estado == 'Reprogramado'"
                                >
                                  {{ record.estado }}</Tag
                                >
                                <Tag
                                  color="info"
                                  v-if="record.estado == 'Pendiente'"
                                  >{{ record.estado }}</Tag
                                >
                                <Tag
                                  color="green"
                                  v-if="record.estado == 'Pagado'"
                                  >{{ record.estado }}</Tag
                                >
                                <Tag
                                  color="green"
                                  v-if="record.estado == 'Cancelado'"
                                  >{{ record.estado }}</Tag
                                >
                                <Tag
                                  color="green"
                                  v-if="record.estado == 'Anulado'"
                                  >{{ record.estado }}</Tag
                                >
                                <Tag
                                  color="green"
                                  v-if="record.estado == 'En Revisión'"
                                  >{{ record.estado }}</Tag
                                >
                              </div>
                              <div class="t-cell justify-content-center">
                                <Button
                                  class="m-0 primary-button"
                                  size="sm"
                                  @click="goToDetallePrestamos(record.id)"
                                  >Ver más</Button
                                >
                              </div>

                              <RowAccordion :isTempHeader="true">
                                <template v-slot:title>
                                  <ul>
                                    <li class="cell temp">
                                      <Tag color="green">{{
                                        record.estado
                                      }}</Tag>
                                    </li>
                                    <li class="cell temp">
                                      <div class="key">
                                        saldo préstamos (s/)
                                      </div>
                                      <div class="value">
                                        {{
                                          $filters.roundToTwo(
                                            record.saldoPrestamo
                                          )
                                        }}
                                      </div>
                                    </li>
                                  </ul>
                                </template>
                                <template v-slot:content>
                                  <ul>
                                    <li class="cell">
                                      <div class="key">fecha de solicitud</div>
                                      <div class="value">
                                        {{ record.fechaSolicitud }}
                                      </div>
                                    </li>
                                    <li class="cell">
                                      <div class="key">fecha de desembolso</div>
                                      <div class="value">
                                        {{ record.fechaDesembolso }}
                                      </div>
                                    </li>
                                    <li class="cell">
                                      <div class="key">tipo de solicitud</div>
                                      <div class="value">
                                        {{ record.tipoSolicitud }}
                                      </div>
                                    </li>
                                    <li class="cell">
                                      <div class="key">
                                        monto solicitado (s/)
                                      </div>
                                      <div class="value">
                                        {{
                                          $filters.roundToTwo(
                                            record.montoSolicitado
                                          )
                                        }}
                                      </div>
                                    </li>
                                    <li class="cell">
                                      <div class="key">
                                        saldo préstamos (s/)
                                      </div>
                                      <div class="value">
                                        {{
                                          $filters.roundToTwo(
                                            record.saldoPrestamo
                                          )
                                        }}
                                      </div>
                                    </li>
                                    <li class="cell">
                                      <div class="key">tasa de interés</div>
                                      <div class="value">
                                        {{
                                          $filters.twoDecimals(
                                            record.tasaInteres
                                          )
                                        }}
                                      </div>
                                    </li>
                                    <li class="cell">
                                      <div class="key">estado</div>
                                      <div class="value">
                                        <Tag color="green">{{
                                          record.estado
                                        }}</Tag>
                                      </div>
                                    </li>
                                    <li class="cell">
                                      <Button
                                        class="m-0 primary-button shadow-none w-100"
                                        size="md"
                                        @click="goToDetallePrestamos(record.id)"
                                        >Ver más</Button
                                      >
                                    </li>
                                  </ul>
                                </template>
                              </RowAccordion>
                            </div>
                          </div>

                          <div class="t-footer">
                            <div>
                              <Pagination
                                :source="paginationPrestamos"
                                @navigate="navigatePrestamos"
                              ></Pagination>
                            </div>
                          </div>
                        </div>

                        <div class="download-section">
                          <div class="action" @click="downloadExcelPrestamos()">
                            <a class="title">Descargar reporte</a>
                            <img
                              class="ms-2"
                              src="/assets/images/icons/download.svg"
                            />
                          </div>
                        </div>
                      </template>

                      <div
                        v-else-if="recordsPrestamos.length == 0"
                        class="t-body no-records"
                      >
                        <p>No se encontraron registros</p>
                      </div>
                    </div>
                  </template>

                  <template v-else>
                    <div class="scrollable-content-xl detail-view">
                      <div class="mt-5">
                        <h4 class="title-accesorios mb-4">
                          Detalle de préstamos
                        </h4>
                      </div>

                      <div
                        v-if="confirmation.nombreContrato"
                        class="download-section without-borders d-flex justify-content-start d-none d-md-block mb-3"
                      >
                        <div
                          @click="downloadContrato()"
                          class="d-flex justify-content-start align-items-center cursor-pointer"
                        >
                          <a class="enlace">Descargar contrato</a>
                          <img
                            class="ms-2"
                            src="/assets/images/icons/download.svg"
                          />
                        </div>
                      </div>

                      <Accordion :backgroundColor="'green'">
                        <template v-slot:title
                          >Detalle de la solicitud</template
                        >
                        <template v-slot:content>
                          <div class="field">
                            <div class="key">estado</div>
                            <div class="value">{{ confirmation.estado }}</div>
                          </div>
                          <div class="field">
                            <div class="key">monto solicitado (s/)</div>
                            <div class="value">
                              {{
                                $filters.roundToTwo(
                                  confirmation.montoSolicitado
                                )
                              }}
                            </div>
                          </div>
                          <div class="field">
                            <div class="key">% tasa de interés / año</div>
                            <!-- TEA (%) -->
                            <div class="value">
                              {{ confirmation.tasaInteres }}
                            </div>
                          </div>
                        </template>
                      </Accordion>

                      <Accordion :backgroundColor="'green'">
                        <template v-slot:title> Datos de préstamo </template>
                        <template v-slot:content>
                          <div class="field">
                            <div class="key">n.&deg; de cuotas</div>
                            <div class="value">
                              {{ confirmation.numeroCuotas }}
                            </div>
                          </div>
                          <div class="field">
                            <div class="key">cuotas canceladas</div>
                            <div class="value">
                              {{ confirmation.cuotasCanceladas }} de
                              {{ confirmation.numeroCuotas }}
                            </div>
                          </div>
                          <div class="field">
                            <div class="key">
                              total de cuotas canceladas (S/)
                            </div>
                            <div class="value">
                              {{
                                $filters.roundToTwo(confirmation.totalCuotas)
                              }}
                            </div>
                          </div>
                          <div class="field">
                            <div class="key">saldo de préstamo (S/)</div>
                            <div class="value">
                              {{
                                $filters.roundToTwo(confirmation.saldoPrestamo)
                              }}
                            </div>
                          </div>
                          <div class="field">
                            <div class="key">valor cuota (S/)</div>
                            <!-- PROYECCIÓN DE DESCUENTO QUINCENAL (S/) -->
                            <div class="value">
                              {{
                                $filters.roundToTwo(
                                  confirmation.proyeccionDescuento
                                )
                              }}
                            </div>
                          </div>
                          <div class="field">
                            <div class="key">motivo</div>
                            <div class="value">{{ confirmation.motivo }}</div>
                          </div>

                          <!--<div class="open-field">
                                            <div v-if="confirmation.nombreContrato" class="download-section without-borders d-flex justify-content-end d-none d-md-block mt-4">
                                                <div @click="downloadContrato()" class="d-flex justify-content-end align-items-center cursor-pointer">
                                                    <a class="enlace">Descargar contrato</a>
                                                    <img class="ms-2" src="/assets/images/icons/download.svg">
                                                </div>
                                            </div>
                                        </div>-->
                        </template>
                      </Accordion>

                      <Accordion :backgroundColor="'green'" v-if="cuotas">
                        <template v-slot:title> Detalle de cuotas </template>
                        <template v-slot:content>
                          <div class="open-field">
                            <div
                              class="b-table scrollable mt-4 prestamos-tabla-maestro"
                            >
                              <div class="t-header">
                                <div class="t-row">
                                  <div class="t-cell">
                                    <span>fecha de pago</span>
                                  </div>
                                  <div class="t-cell">
                                    <span>valor cuota (s/)</span>
                                  </div>
                                  <div class="t-cell">
                                    <span>intereses (s/)</span>
                                  </div>
                                  <div class="t-cell">
                                    <span
                                      >monto total a descontar (s/)<br />(valor
                                      cuota + intereses)</span
                                    >
                                  </div>
                                  <div class="t-cell">
                                    <span>estado</span>
                                  </div>
                                </div>
                              </div>

                              <div class="t-body">
                                <div
                                  class="t-row"
                                  v-for="(record, index) in cuotas"
                                  :key="record.id"
                                >
                                  <div class="t-cell">
                                    {{ record.fechaPago }}
                                  </div>
                                  <div class="t-cell">
                                    {{ $filters.roundToTwo(record.montoCuota) }}
                                  </div>
                                  <div class="t-cell">
                                    {{ $filters.roundToTwo(record.interes) }}
                                  </div>
                                  <div class="t-cell">
                                    {{
                                      $filters.roundToTwo(record.montoDescontar)
                                    }}
                                  </div>
                                  <div class="t-cell">
                                    <Tag
                                      v-if="
                                        record.estado == 'Pendiente de pago'
                                      "
                                      color="orange"
                                      >{{ record.estado }}</Tag
                                    >
                                    <Tag
                                      v-if="record.estado == 'En Revisión'"
                                      color="info"
                                      >{{ record.estado }}</Tag
                                    >
                                    <Tag
                                      v-if="record.estado == 'Aprobado'"
                                      color="green"
                                      >{{ record.estado }}</Tag
                                    >
                                    <Tag
                                      v-if="record.estado == 'Rechazado'"
                                      color="danger"
                                      >{{ record.estado }}</Tag
                                    >
                                    <Tag
                                      v-if="record.estado == 'Anulado'"
                                      color="gray"
                                    >
                                      {{ record.estado }}</Tag
                                    >
                                    <Tag
                                      v-if="record.estado == 'Cancelada'"
                                      color="danger"
                                      >{{ record.estado }}</Tag
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </Accordion>

                      <Accordion
                        :backgroundColor="'green'"
                        v-if="prestamoReprogramado"
                      >
                        <template v-slot:title>
                          Referencia de préstamos anteriores
                        </template>
                        <template v-slot:content>
                          <div class="open-field">
                            <div
                              class="b-table scrollable mt-4 prestamos-tabla-maestro"
                            >
                              <div class="t-header">
                                <div class="t-row">
                                  <div class="t-cell">
                                    <span>tipo de<br />solicitud</span>
                                  </div>
                                  <div class="t-cell">
                                    <span>fecha de<br />desembolso</span>
                                  </div>
                                  <div class="t-cell">
                                    <span>fecha de<br />refinanciamiento</span>
                                  </div>
                                  <div class="t-cell">
                                    <span>monto<br />solicitado (s/)</span>
                                  </div>
                                  <div class="t-cell">
                                    <span>monto<br />refinanciado (s/)</span>
                                  </div>
                                </div>
                              </div>

                              <div class="t-body">
                                <div
                                  class="t-row"
                                  v-for="(record, index) in cronograma"
                                  :key="record.id"
                                >
                                  <div class="t-cell">
                                    {{ record.tipoSolicitud }}
                                  </div>
                                  <div class="t-cell">
                                    {{
                                      $filters.roundToTwo(
                                        record.fechaDesembolso
                                      )
                                    }}
                                  </div>
                                  <div class="t-cell">
                                    {{
                                      $filters.roundToTwo(
                                        record.montoRefinanciado
                                      )
                                    }}
                                  </div>
                                  <div class="t-cell">
                                    {{
                                      $filters.roundToTwo(
                                        record.montoSolicitado
                                      )
                                    }}
                                  </div>
                                  <div class="t-cell">
                                    {{
                                      $filters.roundToTwo(
                                        record.montoRefinanciado
                                      )
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </Accordion>

                      <div class="form-actions">
                        <Button
                          class="mt-3 primary-button shadow-none"
                          size="lg"
                          type="button"
                          @click="goBackPrestamos()"
                          >Regresar</Button
                        >
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <div
                class="tab-pane"
                id="second-tab-pane"
                role="tabpanel"
                aria-labelledby="second-tab"
                tabindex="0"
              >
                <div class="tab-body w-fixed-s">
                  <template v-if="!modeConvenioDetalle">
                    <div class="fixed-section">
                      <Form
                        @submit="onSubmitConvenios"
                        :validation-schema="schemaConvenios"
                        ref="veeForm"
                        :initial-values="formValues"
                        v-slot="{ meta }"
                      >
                        <div class="action-box justify-content-start">
                          <div class="grid grid-container-1">
                            <div class="grid-item-1">
                              <SelectButton
                                name="estadoConvenios"
                                label="estado"
                                v-model="model.estadoConvenios"
                                :options="estadosDescuentos"
                              />
                            </div>
                            <div class="grid-item-action">
                              <Button
                                class="primary-button shadow-none mb-0"
                                size="lg"
                                type="submit"
                                :class="{
                                  'loading-btn': isSubmittingConvenios,
                                }"
                                :disabled="isSubmittingConvenios"
                                ><span class="btn-text">Consultar</span></Button
                              >
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>

                    <div class="scrollable-content-xl">
                      <template
                        v-if="recordsConvenios && recordsConvenios.length > 0"
                      >
                        <div class="overview-info mt-0 sticky-overview-total">
                          <span class="label">MONTO DE SALDO DE DEUDA</span
                          ><span class="amount">{{
                            $filters.formatCurrency(totalConvenios)
                          }}</span>
                        </div>

                        <div class="n-table lista-convenios sticky-header">
                          <div class="t-header">
                            <div class="t-row">
                              <div class="t-cell">
                                <span>quincena</span>
                              </div>
                              <div class="t-cell">
                                <span>convenio</span>
                              </div>
                              <div class="t-cell">
                                <span>monto<br />total (s/)</span>
                              </div>
                              <div class="t-cell">
                                <span>monto<br />pagado (s/)</span>
                              </div>
                              <div class="t-cell">
                                <span>saldo (s/)</span>
                              </div>
                              <div class="t-cell">
                                <span>n.&deg; de<br />cuotas</span>
                              </div>
                              <div class="t-cell">
                                <span>estado</span>
                              </div>
                              <div class="t-cell justify-content-center">
                                <span></span>
                              </div>
                            </div>
                          </div>

                          <div class="t-body">
                            <div
                              class="t-row"
                              v-for="record in this.recordsConvenios"
                            >
                              <div class="t-cell">
                                {{ record.fechaDescuento }}
                              </div>
                              <div class="t-cell">
                                {{
                                  record.empresaConvenio
                                    ? record.empresaConvenio
                                    : "-"
                                }}
                              </div>
                              <div class="t-cell">
                                {{ $filters.roundToTwo(record.montoTotal) }}
                              </div>
                              <div class="t-cell">
                                {{ $filters.roundToTwo(record.montoPagado) }}
                              </div>
                              <div class="t-cell">
                                {{ $filters.roundToTwo(record.montoSaldo) }}
                              </div>
                              <div class="t-cell">
                                {{ record.numeroCuota }}
                              </div>
                              <div class="t-cell">
                                <Tag
                                  v-if="record.estado == 'Pendiente'"
                                  color="orange"
                                  >{{ record.estado }}</Tag
                                >
                                <Tag
                                  v-if="record.estado == 'Pagado'"
                                  color="green"
                                  >{{ record.estado }}</Tag
                                >
                              </div>
                              <div class="t-cell justify-content-center">
                                <Button
                                  class="m-0 primary-button"
                                  size="sm"
                                  @click="goToDetalleConvenios(record.id)"
                                  >Ver más</Button
                                >
                              </div>

                              <RowAccordion>
                                <template v-slot:title>
                                  <ul>
                                    <li class="cell temp">
                                      <div class="key">
                                        nombre de la empresa
                                      </div>
                                      <div class="value">
                                        {{ record.empresaConvenio }}
                                      </div>
                                    </li>
                                    <li class="cell temp">
                                      <div class="key">total (s/)</div>
                                      <div class="value">
                                        {{
                                          $filters.roundToTwo(record.montoTotal)
                                        }}
                                      </div>
                                    </li>
                                  </ul>
                                </template>
                                <template v-slot:content>
                                  <ul>
                                    <li class="cell">
                                      <div class="key">
                                        nombre de la empresa
                                      </div>
                                      <div class="value">
                                        {{ record.empresaConvenio }}
                                      </div>
                                    </li>
                                    <li class="cell">
                                      <div class="key">producto</div>
                                      <div class="value">
                                        {{
                                          record.producto
                                            ? record.producto
                                            : "-"
                                        }}
                                      </div>
                                    </li>
                                    <li class="cell">
                                      <div class="key">precio unit</div>
                                      <div class="value">
                                        {{ record.precioUnit }}
                                      </div>
                                    </li>
                                    <li class="cell">
                                      <div class="key">cantidad</div>
                                      <div class="value">
                                        {{ record.cantidad }}
                                      </div>
                                    </li>
                                    <li class="cell">
                                      <div class="key">total (s/)</div>
                                      <div class="value">
                                        {{
                                          $filters.roundToTwo(record.montoTotal)
                                        }}
                                      </div>
                                    </li>
                                    <li class="cell">
                                      <div class="key">cuotas</div>
                                      <div class="value">
                                        {{ record.numeroCuota }}
                                      </div>
                                    </li>
                                    <li class="cell">
                                      <div class="key">valor cuota</div>
                                      <div class="value">
                                        {{ record.valorCuota }}
                                      </div>
                                    </li>
                                    <li class="cell">
                                      <div class="key">estado</div>
                                      <div class="value">
                                        <Tag
                                          v-if="record.estado == 'Pendiente'"
                                          color="orange"
                                          >{{ record.estado }}</Tag
                                        >
                                        <Tag
                                          v-if="record.estado == 'Pagado'"
                                          color="green"
                                          >{{ record.estado }}</Tag
                                        >
                                      </div>
                                    </li>
                                    <li class="cell">
                                      <Button
                                        class="m-0 primary-button shadow-none w-100"
                                        size="md"
                                        @click="goToDetalleConvenios(record.id)"
                                        >Ver más</Button
                                      >
                                    </li>
                                  </ul>
                                </template>
                              </RowAccordion>
                            </div>
                          </div>

                          <div class="t-footer">
                            <div>
                              <Pagination
                                :source="paginationConvenios"
                                @navigate="navigateConvenios"
                              ></Pagination>
                            </div>
                          </div>
                        </div>

                        <div class="download-section">
                          <div class="action" @click="downloadExcelConvenios()">
                            <a class="title">Descargar reporte</a>
                            <img
                              class="ms-2"
                              src="/assets/images/icons/download.svg"
                            />
                          </div>
                        </div>
                      </template>

                      <div
                        v-else-if="
                          recordsConvenios.length == 0 && firstSubmitConvenios
                        "
                        class="t-body no-records"
                      >
                        <p>No se encontraron registros</p>
                      </div>
                    </div>
                  </template>

                  <template v-else>
                    <div class="scrollable-content-xl detail-view pt-5">
                      <h4 class="title-accesorios mt-5 mb-5">
                        Detalle Convenios
                      </h4>

                      <ConfirmationScreen :withHeader="false" class="mb-5">
                        <template v-slot:body>
                          <div class="field">
                            <div class="key">fecha de solicitud</div>
                            <div class="value">
                              {{ convenioDetalle.fechaDescuento }}
                            </div>
                          </div>
                          <div class="field">
                            <div class="key">convenio</div>
                            <div class="value">
                              {{ convenioDetalle.empresaConvenio }}
                            </div>
                          </div>
                          <div class="field">
                            <div class="key">monto total</div>
                            <div class="value">
                              {{
                                $filters.formatCurrency(
                                  convenioDetalle.montoTotal
                                )
                              }}
                            </div>
                          </div>
                          <div class="field">
                            <div class="key">monto pagado</div>
                            <div class="value">
                              {{
                                $filters.formatCurrency(
                                  convenioDetalle.montoPagado
                                )
                              }}
                            </div>
                          </div>
                          <div class="field">
                            <div class="key">saldo</div>
                            <div class="value">
                              {{
                                $filters.formatCurrency(
                                  convenioDetalle.montoSaldo
                                )
                              }}
                            </div>
                          </div>
                          <div class="field">
                            <div class="key">n.&deg; de cuotas</div>
                            <div class="value">
                              {{ convenioDetalle.numeroCuota }}
                            </div>
                          </div>
                          <div class="field">
                            <div class="key">estado</div>
                            <div class="value">
                              {{ convenioDetalle.estado }}
                            </div>
                          </div>
                          <div class="field">
                            <div class="key">n.&deg; de autorización</div>
                            <div class="value">
                              {{
                                numeroAutorizacionConvenios
                                  ? numeroAutorizacionConvenios
                                  : "-"
                              }}
                            </div>
                          </div>
                        </template>
                      </ConfirmationScreen>

                      <!--<div class="download-section">
                                        <div class="action" @click="downloadAutorizacionConvenio()">
                                            <a class="title">Descargar autorización</a>
                                            <img class="ms-2" src="/assets/images/icons/download.svg">
                                        </div>
                                    </div>-->

                      <template
                        v-if="cuotasConvenios && cuotasConvenios.length > 0"
                      >
                        <div
                          class="n-table mt-4 lista-detalle-convenios scrollable-table"
                        >
                          <div class="t-header">
                            <div class="t-row">
                              <div class="t-cell">
                                <span>año</span>
                              </div>
                              <div class="t-cell">
                                <span>mes</span>
                              </div>
                              <div class="t-cell">
                                <span>quincena</span>
                              </div>
                              <div class="t-cell">
                                <span>monto (s/)</span>
                              </div>
                              <div class="t-cell">
                                <span>estado</span>
                              </div>
                            </div>
                          </div>

                          <div class="t-body">
                            <div
                              class="t-row"
                              v-for="record in cuotasConvenios"
                              :key="record.id"
                            >
                              <div class="t-cell">
                                {{ record.year }}
                              </div>
                              <div class="t-cell">
                                {{ record.mes }}
                              </div>
                              <div class="t-cell">
                                {{ record.numeroQUincena }}
                              </div>
                              <div class="t-cell">
                                {{ $filters.roundToTwo(record.monto) }}
                              </div>
                              <div class="t-cell">
                                <Tag
                                  v-if="
                                    record.estado.toLowerCase() == 'pendiente'
                                  "
                                  color="orange"
                                  >{{ record.estado }}</Tag
                                >
                                <Tag
                                  v-if="record.estado.toLowerCase() == 'pagada'"
                                  color="green"
                                  >{{ record.estado }}</Tag
                                >
                              </div>

                              <RowAccordion>
                                <template v-slot:title>
                                  <ul>
                                    <li class="cell">
                                      <div class="key">fecha</div>
                                      <div class="value">
                                        {{ record.year }} / {{ record.mes }}
                                      </div>
                                    </li>
                                    <li class="cell">
                                      <div class="key">periodo</div>
                                      <div class="value">
                                        {{ record.numeroQUincena }}
                                      </div>
                                    </li>
                                    <li class="cell temp">
                                      <Tag
                                        v-if="
                                          record.estado.toLowerCase() ==
                                          'pendiente'
                                        "
                                        color="orange"
                                        >{{ record.estado }}</Tag
                                      >
                                      <Tag
                                        v-if="
                                          record.estado.toLowerCase() ==
                                          'pagada'
                                        "
                                        color="green"
                                        >{{ record.estado }}</Tag
                                      >
                                    </li>
                                  </ul>
                                </template>
                                <template v-slot:content>
                                  <div class="cell">
                                    <div class="key">monto</div>
                                    <div class="value">
                                      {{ $filters.roundToTwo(record.monto) }}
                                    </div>
                                  </div>
                                  <div class="cell">
                                    <Tag
                                      v-if="
                                        record.estado.toLowerCase() ==
                                        'pendiente'
                                      "
                                      color="orange"
                                      >{{ record.estado }}</Tag
                                    >
                                    <Tag
                                      v-if="
                                        record.estado.toLowerCase() == 'pagada'
                                      "
                                      color="green"
                                      >{{ record.estado }}</Tag
                                    >
                                  </div>
                                </template>
                              </RowAccordion>
                            </div>
                          </div>

                          <!--<div class="t-footer">
                                            <div>
                                                <Pagination :source="detallePaginationConvenios" @navigate="detalleNavigateConvenios"></Pagination>
                                            </div>
                                            </div>-->
                        </div>
                      </template>

                      <div
                        v-else-if="cuotasConvenios.length == 0"
                        class="t-body no-records"
                      >
                        <p>No se encontraron registros</p>
                      </div>

                      <div class="form-actions">
                        <Button
                          class="mt-3 primary-button shadow-none"
                          size="lg"
                          type="button"
                          @click="goBackConvenios"
                          >Regresar</Button
                        >
                        <!-- Regresar a convenios -->
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <div
                class="tab-pane"
                id="third-tab-pane"
                role="tabpanel"
                aria-labelledby="third-tab"
                tabindex="0"
              >
                <div class="tab-body w-fixed-s">
                  <div class="fixed-section">
                    <!--<h4 class="title-accesorios mb-4">Casas agropecuarias</h4>-->

                    <Form
                      @submit="onSubmitCasasAgropecuarias"
                      :validation-schema="schemaCasasAgropecuarias"
                      ref="veeForm"
                      :initial-values="formValuesCasasAgropecuarias"
                      v-slot="{ meta }"
                    >
                      <div class="action-box justify-content-start">
                        <div class="grid grid-container-1">
                          <div class="grid-item-1">
                            <SelectButton
                              name="anio"
                              label="año"
                              v-model="model.anioCasasAgropecuarias"
                              :options="aniosCasasAgropecuarias"
                            />
                          </div>
                          <div class="grid-item-action">
                            <Button
                              class="primary-button shadow-none mb-0"
                              size="lg"
                              type="submit"
                              :class="{
                                'loading-btn': isSubmittingCasasAgropecuarias,
                              }"
                              :disabled="isSubmittingCasasAgropecuarias"
                              ><span class="btn-text">Consultar</span></Button
                            >
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>

                  <div class="scrollable-content-xl">
                    <template
                      v-if="
                        recordsCasasAgropecuarias &&
                        recordsCasasAgropecuarias.length > 0
                      "
                    >
                      <div class="overview-info mt-0 sticky-overview-total">
                        <span class="label">monto total</span
                        ><span class="amount">{{
                          $filters.formatCurrency(totalCasasAgropecuarias)
                        }}</span>
                      </div>

                      <div
                        class="n-table lista-casas-agropecuarias sticky-header"
                      >
                        <div class="t-header">
                          <div class="t-row">
                            <div class="t-cell">
                              <span>año</span>
                            </div>
                            <div class="t-cell">
                              <span>mes</span>
                            </div>
                            <div class="t-cell">
                              <span>periodo</span>
                            </div>
                            <div class="t-cell">
                              <span>casa agropecuaria</span>
                            </div>
                            <div class="t-cell">
                              <span>monto total (s/)</span>
                            </div>
                          </div>
                        </div>

                        <div class="t-body">
                          <div
                            class="t-row"
                            v-for="record in this.recordsCasasAgropecuarias"
                          >
                            <div class="t-cell">
                              {{ record.year }}
                            </div>
                            <div class="t-cell">
                              {{ record.mes }}
                            </div>
                            <div class="t-cell">
                              {{ record.numeroQuincena }}
                            </div>
                            <div class="t-cell">
                              {{ record.nombreCasa }}
                            </div>
                            <div class="t-cell">
                              {{ $filters.roundToTwo(record.montoDescuento) }}
                            </div>

                            <RowAccordion>
                              <template v-slot:title>
                                <ul>
                                  <li class="cell">
                                    <div class="key">casa agropecuaria</div>
                                    <div class="value">
                                      {{ record.nombreCasa }}
                                    </div>
                                  </li>
                                  <li class="cell temp">
                                    <div class="key">monto total (s/)</div>
                                    <div class="value">
                                      {{
                                        $filters.roundToTwo(
                                          record.montoDescuento
                                        )
                                      }}
                                    </div>
                                  </li>
                                </ul>
                              </template>
                              <template v-slot:content>
                                <ul>
                                  <li class="cell">
                                    <div class="key">año</div>
                                    <div class="value">{{ record.year }}</div>
                                  </li>
                                  <li class="cell">
                                    <div class="key">mes</div>
                                    <div class="value">{{ record.mes }}</div>
                                  </li>
                                  <li class="cell">
                                    <div class="key">periodo</div>
                                    <div class="value">
                                      {{ record.numeroQuincena }}
                                    </div>
                                  </li>
                                  <li class="cell">
                                    <div class="key">monto total (s/)</div>
                                    <div class="value">
                                      {{
                                        $filters.roundToTwo(
                                          record.montoDescuento
                                        )
                                      }}
                                    </div>
                                  </li>
                                </ul>
                              </template>
                            </RowAccordion>
                          </div>
                        </div>

                        <div class="t-footer">
                          <div>
                            <Pagination
                              :source="paginationCasasAgropecuarias"
                              @navigate="navigateCasasAgropecuarias"
                            ></Pagination>
                          </div>
                        </div>
                      </div>

                      <div class="download-section">
                        <div
                          class="action"
                          @click="downloadExcelCasasAgropecuarias()"
                        >
                          <a class="title">Descargar reporte</a>
                          <img
                            class="ms-2"
                            src="/assets/images/icons/download.svg"
                          />
                        </div>
                      </div>
                    </template>

                    <div
                      v-else-if="
                        recordsCasasAgropecuarias.length == 0 &&
                        firstSubmitCasasAgropecuarias
                      "
                      class="t-body no-records"
                    >
                      <p>No se encontraron registros</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="fade">
      <CustomModal
        :show="dataModal.showModal"
        :isHeader="false"
        :isFooter="false"
        :width="1600"
        :title="dataModal.title"
        v-on:modal:close="hideModal()"
      >
        <template v-slot:body>
          <GradientLineChart
            class="mt-2 mb-4"
            id="precio_unitario_quincena_modal"
            title="sólidos por quincena"
            description=""
            :chart="{
              labels: [
                '1 Ene',
                '1 Feb',
                '1 Mar',
                '1 Abr',
                '1 May',
                '1 Jun',
                '1 Jul',
                '1 Ago',
                '1 Sep',
                '1 Oct',
                '1 Nov',
                '1 Dic',
              ],
              datasets: [
                {
                  label: 'Año actual',
                  data: [20, 11.5, 29, 9, 3.9, 10.6, 16.7, 21, 25, 4, 13.6, 5],
                  borderColor: '#F78D2C',
                },
                {
                  label: 'Año anterior',
                  data: [9, 5, 16, 20, 12.4, 21.9, 29.6, 25.4, 11, 4, 15, 22.1],
                  borderColor: '#1D4193',
                },
              ],
            }"
          />

          <div class="form-actions">
            <Button
              class="mt-3 ghost-button shadow-none"
              size="lg"
              type="button"
              @click="hideModal()"
              >Cerrar</Button
            >
          </div>
        </template>
      </CustomModal>
    </transition>

    <transition name="fade">
      <CustomModal
        :show="dataModal2.showModal"
        :isHeader="false"
        :isFooter="false"
        :width="1600"
        :title="dataModal2.title"
        v-on:modal:close="hideModal2()"
      >
        <template v-slot:body>
          <GradientLineChart
            class="mt-2 mb-4"
            id="monto_facturado_quincena_modal"
            title="células somáticas por quincena"
            description=""
            :chart="{
              labels: [
                '1 Ene',
                '1 Feb',
                '1 Mar',
                '1 Abr',
                '1 May',
                '1 Jun',
                '1 Jul',
                '1 Ago',
                '1 Sep',
                '1 Oct',
                '1 Nov',
                '1 Dic',
              ],
              datasets: [
                {
                  label: 'Año actual',
                  data: [20, 11.5, 29, 9, 3.9, 10.6, 16.7, 21, 25, 4, 13.6, 5],
                  borderColor: '#F78D2C',
                },
                {
                  label: 'Año anterior',
                  data: [9, 5, 16, 20, 12.4, 21.9, 29.6, 25.4, 11, 4, 15, 22.1],
                  borderColor: '#1D4193',
                },
              ],
            }"
          />

          <div class="form-actions">
            <Button
              class="mt-3 ghost-button shadow-none"
              size="lg"
              type="button"
              @click="hideModal2()"
              >Cerrar</Button
            >
          </div>
        </template>
      </CustomModal>
    </transition>

    <transition name="fade">
      <CustomModal
        :show="dataModal3.showModal"
        :isHeader="false"
        :isFooter="false"
        :width="1600"
        :title="dataModal3.title"
        v-on:modal:close="hideModal3()"
      >
        <template v-slot:body>
          <GradientLineChart
            class="mt-2 mb-4"
            id="ufc_modal"
            title="r. bacteriano por quincena"
            description=""
            :chart="{
              labels: [
                '1 Ene',
                '1 Feb',
                '1 Mar',
                '1 Abr',
                '1 May',
                '1 Jun',
                '1 Jul',
                '1 Ago',
                '1 Sep',
                '1 Oct',
                '1 Nov',
                '1 Dic',
              ],
              datasets: [
                {
                  label: 'Año actual',
                  data: [20, 11.5, 29, 9, 3.9, 10.6, 16.7, 21, 25, 4, 13.6, 5],
                  borderColor: '#F78D2C',
                },
                {
                  label: 'Año anterior',
                  data: [9, 5, 16, 20, 12.4, 21.9, 29.6, 25.4, 11, 4, 15, 22.1],
                  borderColor: '#1D4193',
                },
              ],
            }"
          />

          <div class="form-actions">
            <Button
              class="mt-3 ghost-button shadow-none"
              size="lg"
              type="button"
              @click="hideModal3()"
              >Cerrar</Button
            >
          </div>
        </template>
      </CustomModal>
    </transition>
  </div>
</template>

<script>
import { markRaw } from "vue";
import { Form, Field } from "vee-validate";
import { mapActions } from "vuex";
import GradientLineChart from "@/components/Charts/GradientLineChart.vue";
import TextInput from "@/components/Inputs/TextInput";
import FilterBox from "@/components/FilterBox";
import DateInput from "@/components/Inputs/Date";
import SelectButton from "@/components/Inputs/SelectButton";
import Button from "@/components/Inputs/Button";
import Pagination from "@/components/Pagination.vue";
import CustomModal from "@/components/CustomModal.vue";
import ConfirmationScreen from "@/components/ConfirmationScreen";
import Tag from "@/components/Tags/Tag";
import RowAccordion from "@/components/Accordion/RowAccordion";
import RadioButton from "@/components/Inputs/RadioButtonOriginalType.vue";
import Checkbox from "@/components/Inputs/Checkbox";
import Accordion from "@/components/Accordion/Accordion";
import * as Yup from "yup";

export default {
  components: {
    GradientLineChart,
    TextInput,
    FilterBox,
    SelectButton,
    Button,
    Pagination,
    CustomModal,
    ConfirmationScreen,
    RadioButton,
    Checkbox,
    Accordion,
    Tag,
    RowAccordion,
    DateInput,
    Form,
    Field,
  },
  name: "Aprobacion de Ganadero",
  data() {
    const schema = markRaw(
      Yup.object().shape({
        fechaInicio: Yup.string().required(),
        fechaFin: Yup.string().required(),
        zona: Yup.string().nullable(),
        codInterno: Yup.string().nullable(),
      })
    );
    const formValues = {
      fechaInicio: "",
      fechaFin: "",
      zona: "",
      codInterno: "",
    };

    const schemaPrestamos = markRaw(
      Yup.object().shape({
        estado: Yup.string().required(),
      })
    );

    const formValuesCasasConvenio = {};

    const schemaConvenios = markRaw(
      Yup.object().shape({
        estado: Yup.string().required(),
      })
    );

    const formValuesPrestamos = {};

    const schemaCasasAgropecuarias = markRaw(
      Yup.object().shape({
        estado: Yup.string().required(),
      })
    );

    const formValuesCasasAgropecuarias = {};

    return {
      schema,
      schemaPrestamos,
      schemaConvenios,
      schemaCasasAgropecuarias,
      formValues,
      formValuesPrestamos,
      formValuesCasasConvenio,
      formValuesCasasAgropecuarias,

      ganaderoId: "500000", //'',
      solicitudId: "",

      isDownloadAvailable: false,
      aniosOptions: this.$filters.getYears(2020),
      records: [],
      estadisticas: [
        { quincena: "1 Ene", solidos: "11.23" },
        { quincena: "1 Feb", solidos: "1,24" },
        { quincena: "1 Mar", solidos: "1,22" },
        { quincena: "1 Abr", solidos: "1,25" },
        { quincena: "1 May", solidos: "1,22" },
        { quincena: "1 Jun", solidos: "11.4" },
        { quincena: "1 Jul", solidos: "1,12" },
      ],

      /* Prestamos */
      recordsPrestamos: [],
      totalPrestamos: 0,
      estadosPrestamos: [],
      isSubmittingPrestamos: false,

      /* Convenios */
      recordsConvenios: [],
      totalConvenios: 0,
      estadosDescuentos: [],
      prestamoId: "",
      convenioId: "",
      modeCreditosDetalle: false,
      modeConvenioDetalle: false,
      cuotasConvenios: [],
      numeroAutorizacionConvenios: "",
      convenioDetalle: {},
      paginationConvenios: {
        pagina: 1,
        paginas: 1,
      },
      detallePaginationConvenios: {
        pagina: 1,
        paginas: 1,
      },
      isSubmittingConvenios: false,
      firstSubmitConvenios: false,

      /* Casas Agropecuarias */
      recordsCasasAgropecuarias: [],
      totalCasasAgropecuarias: 0,
      paginationCasasAgropecuarias: {
        pagina: 1,
        paginas: 1,
      },
      anioCasasAgropecuarias: "",
      aniosCasasAgropecuarias: this.$filters.getYears(2020),
      isSubmittingCasasAgropecuarias: false,
      firstSubmitCasasAgropecuarias: false,

      confirmation: {
        estado: "",
        autorizadoPor: "",
        proveedor: {
          codigoProveedor: "",
          nombreProveedor: "",
        },
        cuotasCanceladas: "",
        montoSolicitado: "",
        nombreContrato: "",
        numeroCuotas: "",
        proyeccionDescuento: "",
        saldoPrestamo: "",
        tasaInteres: "",
        cuotasQuincenales: "",
        motivo: "",
        proyeccionDescuento: "",
        totalCuotas: "",
      },
      prestamoReprogramado: false,
      cronograma: [],
      cuotas: [],

      model: {
        anio: "",
        estadoPrestamos: "",
        estadoConvenios: "",
      },
      dataModal: {
        showModal: false,
        title: "",
        active: "",
        request: "",
        response: "",
      },
      dataModal2: {
        showModal: false,
        title: "",
        active: "",
        request: "",
        response: "",
      },
      dataModal3: {
        showModal: false,
        title: "",
        active: "",
        request: "",
        response: "",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions({
      getOptionsPrestamo: "maestroGanadero/getOptionsPrestamo",
      getOptionsDescuento: "maestroGanadero/getOptionsDescuento",
      getListPrestamo: "maestroGanadero/getListPrestamo",
      downloadReportePrestamos: "maestroGanadero/downloadReportePrestamos",
      getDetallePrestamo: "maestroGanadero/getDetallePrestamo",
      downloadReporteSolicitud:
        "maestroGanadero/downloadReporteDetallePrestamo",
      downloadContratoPDF: "maestroGanadero/downloadContratoPDF",
      getListDescuento: "maestroGanadero/getListDescuento",
      getDetalleDescuento: "maestroGanadero/getDetalleDescuento",
      downloadReporteDescuento: "maestroGanadero/downloadReporteDescuento",
    }),
    openModal(id) {
      if (id == "solidos_totales") {
        this.dataModal.showModal = true;
      } else if (id == "celulas_somaticas") {
        this.dataModal2.showModal = true;
      } else if (id == "ufc") {
        this.dataModal3.showModal = true;
      }
    },
    hideModal() {
      this.dataModal.showModal = false;
    },
    hideModal2() {
      this.dataModal2.showModal = false;
    },
    hideModal3() {
      this.dataModal3.showModal = false;
    },
    async onSubmitPrestamos(values) {
      this.isSubmittingPrestamos = true;

      const data = {
        pagina: 1,
        //"codigoProveedor": this.ganaderoId,
        estado: values.estado,
      };

      await this.getListPrestamo(data)
        .then((response) => {
          if (response.status == 200) {
            this.recordsPrestamos = response.data.lista;

            this.totalPrestamos = response.data.montoSaldoDeuda;

            this.paginationPrestamos = {
              pagina: response.data.pagina,
              paginas: response.data.paginas,
            };

            //this.firstSubmitPrestamos = true;
            this.isSubmittingPrestamos = false;
          }
        })
        .catch((err) => {
          //console.log(err.message);

          this.recordsPrestamos = [];

          //this.firstSubmitPrestamos = true;
          this.isSubmittingPrestamos = false;
        });
    },
    downloadExcelPrestamos() {
      const data = {
        //"codigoProveedor": this.ganaderoId,
        estado: this.model.estadoPrestamos,
      };

      this.downloadReportePrestamos(data).then((response) => {
        if (response.status == 200) {
          let type = response.headers["content-type"];

          const file = new Blob([response.data], { type: type });
          const fileURL = URL.createObjectURL(file);

          let todayDate = this.$filters.generateFileDate();

          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute("download", `reporte-prestamos_${todayDate}.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    async goToDetallePrestamos(id) {
      this.prestamoId = id;

      await this.getDetallePrestamo(id)
        .then((response) => {
          if (response.status == 200) {
            this.solicitudId = response.data.id;
            this.prestamoReprogramado = response.data.prestamoReprogramado;

            this.confirmation.estado = response.data.estado;
            //this.confirmation.autorizadoPor = response.data.autorizadoPor;
            this.confirmation.proveedor.codigoProveedor =
              response.data.codigoProveedor;
            this.confirmation.proveedor.nombreProveedor =
              response.data.nombreProveedor;
            this.confirmation.cuotasCanceladas = response.data.cuotasCanceladas;
            this.confirmation.montoSolicitado = response.data.montoSolicitado;
            this.confirmation.nombreContrato = response.data.nombreContrato;
            this.confirmation.numeroCuotas = response.data.numeroCuotas;
            this.confirmation.proyeccionDescuento =
              response.data.proyeccionDescuento;
            this.confirmation.saldoPrestamo = response.data.saldoPrestamo;
            this.confirmation.tasaInteres = response.data.tasaInteres
              ? this.$filters.twoDecimals(response.data.tasaInteres)
              : "-";
            this.confirmation.cuotasQuincenales = response.data
              .cuotasQuincenales
              ? response.data.cuotasQuincenales
              : "-";
            this.confirmation.motivo = response.data.motivo;
            this.confirmation.proyeccionDescuento =
              response.data.proyeccionDescuento;

            this.confirmation.totalCuotas =
              response.data.montoSolicitado - response.data.saldoPrestamo;

            this.cuotas = response.data.cuotas;
            this.cronograma = response.data.prestamosAnteriores;

            this.modeCreditosDetalle = true;
          }
        })
        .catch((err) => {
          this.modeCreditosDetalle = false;
          //this.$filters.onErrorBase(err);
        });
    },
    goBackPrestamos() {
      this.modeCreditosDetalle = false;
    },
    downloadSolicitud() {
      const solicitudId = this.solicitudId;

      this.downloadReporteSolicitud(solicitudId)
        .then((response) => {
          if (response.status == 200) {
            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);

            let todayDate = this.$filters.generateFileDate();

            const link = document.createElement("a");
            link.href = fileURL;
            link.setAttribute("download", `solicitud_${todayDate}.pdf`);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((err) => {
          this.$filters.onErrorBase(err);
        });
    },
    downloadContrato() {
      let pdfFilename = this.confirmation.nombreContrato;

      this.downloadContratoPDF(pdfFilename)
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);

          let todayDate = this.$filters.generateFileDate();

          //let proveedorName = this.$filters.makeSlug(this.confirmation.nombreProveedor);

          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute("download", `contrato_${todayDate}.pdf`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.$filters.onErrorBase(err);
        });
    },
    async onSubmitConvenios(values) {
      this.isSubmittingConvenios = true;

      const data = {
        estado: values.estadoConvenios,
        tipo: 1,
      };

      this.estadoConvenios = values.estadoConvenios;

      await this.getListDescuento(data)
        .then((response) => {
          if (response.status == 200) {
            this.recordsConvenios = response.data.lista;

            this.totalConvenios = response.data.montoSaldoDeuda;

            this.paginationConvenios = {
              pagina: response.data.pagina,
              paginas: response.data.paginas,
            };

            this.firstSubmitConvenios = true;
            this.isSubmittingConvenios = false;
          }
        })
        .catch((err) => {
          this.records = [];

          this.firstSubmitConvenios = true;
          this.isSubmittingConvenios = false;
        });
    },
    downloadExcelConvenios() {
      const data = {
        //"codigoProveedor": this.ganaderoId,
        estado: this.model.estadoConvenios,
        tipo: 1,
      };

      this.downloadReporteDescuento(data).then((response) => {
        if (response.status == 200) {
          let type = response.headers["content-type"];

          const file = new Blob([response.data], { type: type });
          const fileURL = URL.createObjectURL(file);

          let todayDate = this.$filters.generateFileDate();

          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute("download", `reporte-convenios_${todayDate}.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    async goToDetalleConvenios(id) {
      this.convenioId = id;

      await this.getDetalleDescuento(id)
        .then((response) => {
          if (response.status == 200) {
            this.cuotasConvenios = response.data.cuotas;
            this.numeroAutorizacionConvenios = response.data.numeroAutorizacion;

            this.convenioDetalle.fechaDescuento = response.data.fechaDescuento;
            this.convenioDetalle.empresaConvenio =
              response.data.empresaConvenio;
            this.convenioDetalle.montoTotal = response.data.montoTotal;
            this.convenioDetalle.montoPagado = response.data.montoPagado;
            this.convenioDetalle.montoSaldo = response.data.montoSaldo;
            this.convenioDetalle.numeroCuota = response.data.numeroCuota;
            this.convenioDetalle.estado = response.data.estado;

            this.modeConvenioDetalle = true;
          }
        })
        .catch((err) => {
          this.modeConvenioDetalle = false;
          this.$filters.onErrorBase(err);
        });
    },
    goBackConvenios() {
      this.modeConvenioDetalle = false;
    },
    async onSubmitCasasAgropecuarias(values) {
      this.isSubmittingCasasAgropecuarias = true;

      const data = {
        year: values.anio,
        tipo: 2,
      };

      this.anioCasasAgropecuarias = values.anio;

      await this.getListDescuento(data)
        .then((response) => {
          if (response.status == 200) {
            this.recordsCasasAgropecuarias = response.data.lista;

            this.totalCasasAgropecuarias = response.data.montoSaldoDeuda;

            this.paginationCasasAgropecuarias = {
              pagina: response.data.pagina,
              paginas: response.data.paginas,
            };

            this.firstSubmitCasasAgropecuarias = true;
            this.isSubmittingCasasAgropecuarias = false;
          }
        })
        .catch((err) => {
          //console.log(err.message);

          this.records = [];

          this.firstSubmitCasasAgropecuarias = true;
          this.isSubmittingCasasAgropecuarias = false;
        });
    },
    downloadExcelCasasAgropecuarias() {
      const data = {
        //"codigoProveedor": this.codigoProveedor,
        year: this.model.anioCasasAgropecuarias,
        tipo: 2,
      };

      this.downloadReporteDescuento(data).then((response) => {
        if (response.status == 200) {
          let type = response.headers["content-type"];

          const file = new Blob([response.data], { type: type });
          const fileURL = URL.createObjectURL(file);

          let todayDate = this.$filters.generateFileDate();

          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute(
            "download",
            `reporte-casas-agropecuarias_${todayDate}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    navigatePrestamos() {},
    navigateConvenios() {},
    navigateCasasAgropecuarias() {},
  },
  async mounted() {
    let loader = this.$loading.show();

    await this.getOptionsPrestamo().then((response) => {
      if (response.status == 200) {
        this.estadosPrestamos = response.data;
        this.estadosPrestamos.unshift({ id: 0, nombre: "Todos" });
      }
    });

    await this.getOptionsDescuento().then((response) => {
      if (response.status == 200) {
        this.estadosDescuentos = response.data;
        this.estadosDescuentos.unshift({ id: 0, nombre: "Todos" });
      }
    });

    let dateFilters = this.$filters.getCurrentDatePeriodFilter();

    //Convenio
    this.formValues.estado = this.estadosDescuentos[0].id;
    this.model.estadoConvenios = this.estadosDescuentos[0].id;

    const dataConvenios = {
      estado: this.model.estadoConvenios,
      tipo: 1,
    };

    this.getListDescuento(dataConvenios)
      .then((response) => {
        if (response.status == 200) {
          this.recordsConvenios = response.data.lista;

          this.totalConvenios = response.data.montoSaldoDeuda;

          this.paginationConvenios = {
            pagina: response.data.pagina,
            paginas: response.data.paginas,
          };

          this.firstSubmitConvenios = true;
        }
      })
      .catch((err) => {
        this.records = [];
        this.firstSubmitConvenios = true;
      });

    //Casas agropecuarias
    this.formValuesCasasAgropecuarias.anio = dateFilters.anio;
    this.model.anioCasasAgropecuarias = dateFilters.anio;

    const dataCasasAgropecuarias = {
      year: this.model.anioCasasAgropecuarias,
      tipo: 2,
    };

    this.getListDescuento(dataCasasAgropecuarias)
      .then((response) => {
        if (response.status == 200) {
          this.recordsCasasAgropecuarias = response.data.lista;

          this.totalCasasAgropecuarias = response.data.montoSaldoDeuda;

          this.paginationCasasAgropecuarias = {
            pagina: response.data.pagina,
            paginas: response.data.paginas,
          };

          this.firstSubmitCasasAgropecuarias = true;
        }
      })
      .catch((err) => {
        this.records = [];

        this.firstSubmitCasasAgropecuarias = true;
      });

    //Créditos
    this.formValuesPrestamos.estado = this.estadosPrestamos[0].id;
    this.model.estadoPrestamos = this.estadosPrestamos[0].id;

    const data = {
      pagina: 1,
      estado: this.model.estadoPrestamos,
    };

    await this.getListPrestamo(data)
      .then((response) => {
        if (response.status == 200) {
          this.recordsPrestamos = response.data.lista;

          this.totalPrestamos = response.data.montoSaldoDeuda;

          this.paginationPrestamos = {
            pagina: response.data.pagina,
            paginas: response.data.paginas,
          };
        }
      })
      .catch((err) => {
        this.recordsPrestamos = [];

        this.isSubmittingPrestamos = false;
      });

    loader.hide();
  },
};
</script>
