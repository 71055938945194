import setting from './setting';

export default {

  getAcopioQuincena: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/portal/ganadero/lista/acopio/quincena`, data, {headers:setting.http.headers()});
  },
  getAcopioDiario: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/portal/ganadero/lista/acopio/diario`, data, {headers:setting.http.headers()});
  },
  downloadAcopio: ($axios, data)=>{
    return $axios.post(`${setting.http.base_url}/portal/ganadero/lista/acopio/quincena/exportar`, data, {headers:setting.http.headers(), observe: 'response', responseType: 'arraybuffer'});
  },
  
}
