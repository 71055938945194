<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-login">
        <div class="container-fluid ps-0 pe-0">
          <div class="row g-0">

            <div class="col-md-6 billboard-wrapper">

              <div class="billboard">

                <div class="top-box">
                  <h1>
                    <span class="bold">Bienvenidos</span><br>
                    <span>a la plataforma</span><br>
                    <span class="bold">de Ganaderos</span>
                  </h1>
                </div>

                <transition-group tag="div" class="img-slider"
                  name="fade"
                  @enter="startTransition"
                  @before-enter="startTransition"
                  @after-enter="endTransition"
                  @before-leave="startTransition"
                  @after-leave="endTransition">
                  <template v-for="number in [currentImg]" v-bind:key="number" >

                    <img :src="images[Math.abs(currentImg) % images.length]"/>

                  </template>
                </transition-group>

                <div class="bottom-box">
                  <img class="bottom-shape" src="assets/images/login/shapes/bottom-shape.png">
                </div>


              </div>

              <!--<div class="billboard">
                <div class="image"></div>
                <h4 class="title">
                    Bienvenido a la plataforma de Ganaderos
                </h4>
              </div>-->

            </div>
            <div class="col-md-6 d-flex">

              <img class="logo position-absolute" src="assets/images/logo-sm.svg">

              <div class="login-box card card-plain">
                <div class="pb-0 header card-header">
                  <h4 class="font-weight-bolder">Inicia sesión</h4>
                </div>
                <div class="card-body">
                  <Form
                    @submit="onSubmit"
                    :validation-schema="schema"
                    @invalid-submit="onInvalidSubmit"
                    :initial-values="formValues"
                    ref="veeForm"
                    v-slot="{ meta }"
                  >
                    <div class="text-input-mb-28px">
                      <TextInput
                        name="email"
                        type="email"
                        label="código de usuario"
                        :maximumLength="8"
                        placeholder="Escribe aquí tu código"
                      />
                    </div>

                    <div class="text-input-mb-16px">
                      <TextInput
                        name="password"
                        type="password"
                        label="Contraseña"
                        placeholder="Escribe aquí tu contraseña"
                      />
                    </div>

                    <div class="float-end mt-2">
                      <Checkbox
                        id="rememberMe"
                        name="rememberMe"
                        label="Recordarme"
                        v-model="model.rememberMe"
                      />
                    </div>

                    <div class="text-center">
                      <Button
                        class="mt-3 primary-button"
                        full-width
                        size="lg"
                        :class="{ 'loading-btn': isSubmitting }"
                        :disabled="isSubmitting"
                        >
                        <span class="btn-text">Ingresar</span>
                      </Button>
                    </div>

                    <div class="text-center mt-3">

                      <span @click="showResetPassword()" class="text-success cursor-pointer" style="text-decoration: underline;font-size: 13px;font-family: &quot;Poppins&quot;;">¿Olvidaste tu contraseña?</span>

                    </div>

                  </Form>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </section>


    <transition name="fade">
      <CustomModal
        :show="dataModal.showModal"
        :isHeader="false"
        :isFooter="false"
        :width="450"
        :title="dataModal.title"
        :closeIcon="false"
        v-on:modal:close="hideModal()"
      >
        <template v-slot:body>

          <Form
            @submit="submitFormResetPassword"
            :validation-schema="schemaResetPassword"
            :initial-values="formValuesResetPassword"
            ref="formDataResetPassword"
          >

            <template v-if="resetPassStep == 1">

              <h3 style="font-weight: 600;font-size: 20px;line-height: 24px;color: black;margin-bottom: 31px;margin-top: 0;text-align: center;">¿Olvidaste tu contraseña?</h3>
            
              <p style="font-size: 13px;color:#6E6E6E;">Ingrese su código para verificar.</p>

              <div class="row mb-3">
                <div class="col-md-12">
                  <TextInput
                    name="codigo"
                    type="text"
                    label="código"
                    placeholder="Escribe aquí tu código"
                    v-model="model.codigo"
                    :errorCustom="errorMessage"
                  />
                </div>
              </div>


              <div class="form-actions">
                <Button
                  class="mt-3 ghost-button shadow-none"
                  size="lg"
                  type="button"
                  @click="hideModal()"
                  >Cancelar</Button
                >

                <!--<Button
                  class="mt-3 primary-button shadow-none"
                  size="lg"
                  type="submit"
                  :class="{ 'loading-btn': isSubmittingModal }"
                  :disabled="isSubmittingModal"
                  ><span class="btn-text">Enviar</span></Button
                >-->

                <button @click="recaptcha" class="button success button-lg mt-3 primary-button shadow-none" type="button">Enviar</button>
              </div>

            </template>

            <template v-else-if="resetPassStep == 2">

              <h3 style="font-weight: 600;font-size: 20px;line-height: 24px;color: black;margin-bottom: 31px;margin-top: 0;text-align: center;">Comprobar código</h3>
            
              <p style="font-size: 13px;color:#6E6E6E;">Insertar el código sms</p>

              <div class="row mb-3">
                <div class="col-md-12">
                  <TextInput
                    name="sms"
                    type="text"
                    label="código del sms"
                    placeholder="Escribe aquí tu código"
                    v-model="model.sms"
                    :errorCustom="errorMessage"
                  />
                </div>
              </div>

              <div class="form-actions">
                <Button
                  class="mt-3 ghost-button shadow-none"
                  size="lg"
                  type="button"
                  @click="hideModal()"
                  >Cancelar</Button
                >

                <Button
                  class="mt-3 primary-button shadow-none"
                  size="lg"
                  type="submit"
                  :class="{ 'loading-btn': isSubmittingModal }"
                  :disabled="isSubmittingModal"
                  ><span class="btn-text">Enviar</span></Button
                >
              </div>

            </template>

            <template v-else-if="resetPassStep == 3">

              <h3 style="font-weight: 600;font-size: 20px;line-height: 24px;color: black;margin-bottom: 31px;margin-top: 0;text-align: center;">Coloque su nueva contraseña</h3>

              <div class="row mb-3">
                <div class="col-md-12">
                  <TextInput
                    name="password"
                    type="password"
                    label="nueva contraseña"
                    v-model="model.password"
                    placeholder="Escribe aquí tu código"
                  />
                </div>

                <div class="col-md-12 mt-4">
                  <TextInput
                    name="passwordConfirmation"
                    type="password"
                    label="confirmar nueva contraseña"
                    v-model="model.passwordConfirmation"
                    placeholder="Escribe aquí tu código"
                  />
                </div>
              </div>


              <div class="form-actions">
                <Button
                  class="mt-3 ghost-button shadow-none"
                  size="lg"
                  type="button"
                  @click="hideModal()"
                  >Cancelar</Button
                >

                <Button
                  class="mt-3 primary-button shadow-none"
                  size="lg"
                  type="submit"
                  :class="{ 'loading-btn': isSubmittingModal }"
                  :disabled="isSubmittingModal"
                  ><span class="btn-text">Enviar</span></Button
                >
              </div>

            </template>

            <!--<div class="hr my-4"></div>-->

          </Form>

        </template>

      </CustomModal>
    </transition>

  </main>
</template>

<script>

const body = document.getElementsByTagName("body")[0];

import { markRaw } from "vue";
import { Form, Field } from "vee-validate";
import TextInput from "@/components/Inputs/TextInput";
import Checkbox from "@/components/Inputs/Checkbox";
import Button from "@/components/Inputs/Button";
import SelectButton from "@/components/Inputs/SelectButton";
import CustomModal from "@/components/CustomModal.vue";
import { useReCaptcha } from 'vue-recaptcha-v3';
import {mapActions, mapGetters} from 'vuex';
import * as Yup from "yup";
import swal from "sweetalert2";

Yup.setLocale({
  mixed: {
    required: 'Este campo es requerido'
  },
  string: {
    email: 'Este valor no es un e-mail válido',
    min: 'Valor muy corto (mínimo ${min} caracteres)',
    max: 'Valor muy largo (máximo ${max} caracteres)'
  }
});

export default {
  components: {
    TextInput,
    Checkbox,
    Button,
    CustomModal,
    Form,
    Field,
    SelectButton,
    useReCaptcha
  },
  data() {

    const schema = markRaw(
      Yup.object().shape({
        email: Yup.string()/*.email()*/.required(),
        password: Yup.string().min(6).required()
      })
    );
    const formValues = {
      email: "",
      password: "",
    };


    const schemaResetPassword = markRaw(
      Yup.object().shape({
        codigo: Yup.string()/*.email()*/.required(),
        password: Yup.string().min(6).required(),
        passwordConfirmation: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
      })
    );

    const formValuesResetPassword = {
      codigo: "",
      password: "",
      passwordConfirmation: "",
    };

    return {
      schema,
      formValues,
      schemaResetPassword,
      formValuesResetPassword,
      isSubmitting: false,

      resetPassStep: 1,
      codigoGanadero: null,
      sms: null,
      isSubmittingModal: false,

      errorMessage: '',

      model: {
        rememberMe: true,
        islogged : false,
        messageLogin: '',
        codigoGanadero: '',
        codigo: '',
        sms: '',
        password: '',
        passwordConfirmation: '',
      },
      images: [
        "/assets/images/login/slider/1.jpg",
        "/assets/images/login/slider/2.jpg",
        "/assets/images/login/slider/3.jpg",
        //"/assets/images/login/slider/4.jpg",
        //"/assets/images/login/slider/5.jpg",
        //"/assets/images/login/slider/6.jpg",
        "/assets/images/login/slider/8.jpg",
        "/assets/images/login/slider/9.jpg",
        "/assets/images/login/slider/7.jpg",
      ],
      currentImg: 0,

      timer: 0,
      currentIndex: 0,
      dataModal: {
        showModal: false,
        title: "",
        active: "",
        request: "",
        response: "",
      },
    };
  },
  computed:{
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    /*currentImg: function() {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    }*/
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn',
      resetPassword: 'auth/resetPassword',
      changePassword: 'auth/changePassword',
      getOfflineTransportista: 'mantenimiento/getOfflineTransportista',
      getParams: "seguimiento/getParams",
      guardarTiposLeche: 'offline/guardarTiposLeche',
      guardarRutas: 'offline/guardarRutas',
    }),
    startTransition(el) {

    },

    endTransition(el) {

    },
    startSlide: function() {
      //this.timer = setInterval(this.next, 5000);
      this.images.forEach((img) => {
        const image = new Image();
        image.src = img;
      });

      setInterval(() => {
        this.currentImg = this.currentImg + 1;
      }, 10000);
    },
    next: function() {
      this.currentIndex += 1;
    },
    prev: function() {
      this.currentIndex -= 1;
    },
    cargarTiposLeche() {
      this.getParams().then((response) => {
        if (response.status == 200) {

          this.guardarTiposLeche(response.data.productosTransportados);

        }
      });
    },
    cargarTransportistas() {
      this.getOfflineTransportista()
        .then((response) => {
          if (response.status === 200) {
            const rutasConId = response.data.map((ruta, index) => ({
              ...ruta,
              id: index + 1
            }));
            this.guardarRutas(rutasConId);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showResetPassword() {
      
      /*this.errorMessage = '';
      this.resetPassStep = 1;
      this.codigoGanadero = null;
      this.sms = null;

      this.dataModal.showModal = true;*/

      this.$router.push({ name: "Resetear contraseña" });

    },
    hideModal() {
      this.dataModal.showModal = false;
      this.$refs.formDataResetPassword.resetForm();
    },
    async submitFormResetPassword(values) {

      this.isSubmittingModal = true;

      this.errorMessage = '';

      this.codigo = '';
      this.sms = '';
      this.password = '';
      this.passwordConfirmation = '';


      if (this.resetPassStep == 1) {
        
        let data = {
          "username" : this.model.codigo,
        }

        await this.resetPassword(data).then((response) => {

          if (response.status == 200) {

            this.codigoGanadero = this.model.codigo;
            
            this.resetPassStep = 2;

          }

        }).catch((err) => {
          
          if (typeof err.response.data.error == 'object') {
            
            let errorMessage = '';

            Object.values(err.response.data.errors).map((key, value)=> {
              errorMessage = key[0];
            });

            this.errorMessage = errorMessage;

          } else if (typeof err.response.data.error == 'string') {

            this.errorMessage = err.response.data.error;

          }

        });

      } else if (this.resetPassStep == 2) {

        let data = {
          "username" : this.codigoGanadero,
          "codigoSms" : this.model.sms
        }

        await this.resetPassword(data).then((response) => {

          if (response.status == 200) {

            this.sms = this.model.sms;
            this.resetPassStep = 3;

          }

        }).catch((err)=>{

          if (typeof err.response.data.error == 'object') {

            let errorMessage = '';

            Object.values(err.response.data.errors).map((key, value)=> {
              errorMessage = key[0];
            });

            this.errorMessage = errorMessage;

          } else if (typeof err.response.data.error == 'string') {

            this.errorMessage = err.response.data.error;

          }

        });

      } else if (this.resetPassStep == 3) {

        let data = {
          "username" : this.codigoGanadero,
          "userpass" : this.model.password,
          "userpassConfirm" : this.model.passwordConfirmation,
          "codigoSms" : this.sms
        }
        
        await this.resetPassword(data).then((response) => {

          if (response.status == 200) {

            this.resetPassStep = 1;
            this.codigoGanadero = null;
            this.sms = null;

            this.$refs.formDataResetPassword.resetForm();

            this.hideModal();


            const obj = {
              message: 'Se actualizó clave con éxito',
              confirmButtonText: 'Cerrar',
              withCallback: false
            }

            this.$filters.showSuccessMessage(obj);

          }

        }).catch((err)=>{

          if (typeof err.response.data.error == 'object') {

            let errorMessage = '';

            Object.values(err.response.data.errors).map((key, value)=> {
              errorMessage = key[0];
            });

            this.errorMessage = errorMessage;

          } else if (typeof err.response.data.error == 'string') {

            this.errorMessage = err.response.data.error;

          }

        });

      }

      this.isSubmittingModal = false;

    },
    async onSubmit(values) {

      this.isSubmitting = true;

      const data = {
        username: values.email,
        userpass: values.password
      };


      this.isRememberMe(values.email);

      this.signIn(data)
        .then(async (response) => {

          if (response.status == 200) {

            if (response.data.usuario.tipoUsuario !== 24) { //response.data.usuario.tipoUsuario  == 24

              this.isSubmitting = false;

              this.cargarTiposLeche();

              this.getOfflineTransportista()
                .then((response) => {
                  if (response.status === 200) {

                    const rutasConId = response.data.map((ruta, index) => ({
                      ...ruta,
                      id: index + 1
                    }));

                    this.guardarRutas(rutasConId);

                    this.$router.push({
                      name: "Home",
                    });

                  }
                })
                .catch((error) => {
                  console.error(error);
                });
              

            } else {

              this.isSubmitting = false;

              this.$router.push({
                name: "Home",
              });

            }

          } else if (response.status == 400) {

            this.isSubmitting = false;

            this.model.messageLogin = response.message;

            this.$filters.showErrorMessage(`<div>
              <h3 style="font-weight: 600;font-size: 20px;line-height: 24px;color: black;margin-bottom: 21px;margin-top: 21px;">Error</h3>
              <p style="font-weight: 400;font-size: 14px;line-height: 16px;max-width: 391px;margin: auto;">${response.message}</p>
              </div>`);

          }

        })

    },
    onInvalidSubmit() {
      //
    },
    isRememberMe(email) {
      if (this.model.rememberMe && email !== "") {
        localStorage.setItem('username', email);
        localStorage.setItem('rememberMe', this.model.rememberMe);
      } else {
        localStorage.removeItem('username');
        localStorage.removeItem('rememberMe');
      }
    },
    async recaptcha() {

      this.errorMessage = '';

      if (this.model.codigo == '') {
        this.errorMessage = 'Este campo es requerido';
        return;
      }

      await this.$recaptchaLoaded()

      const token = await this.$recaptcha('login');

      if (token) {
        this.submitFormResetPassword();
      } else {
        this.errorMessage = 'Error al validar código';
      }       

    }
  },
  mounted() {
    //this.cargarTransportistas();
    this.startSlide();
  },
  created() {
    if (this.authenticated) {
      this.$router.push({
        name: 'Home',
      })
    }

    if (localStorage.username && localStorage.checkbox !== "") {
      this.formValues.email = localStorage.getItem("username");
      this.model.rememberMe = (localStorage.getItem("rememberMe") === 'true');
    } else {
      this.formValues.email = '';
      this.model.rememberMe = false;
    }
  }
};
</script>

<style>
.swal2-popup {
  background: #F7F8F2;
  /*box-shadow: 9px 9px 20px rgba(0, 101, 55, 0.4);*/
  border-radius: 10px;
}
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
    background: rgb(0 0 0 / 75%);
}



.fade-enter-active,
.fade-leave-active {
  transition: all 0.6s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width:100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width:100%;
  opacity: 0;
}




.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}







.img-slider{
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100%;

  /*width: 100%;
  height: 100vh;
  object-fit: cover;*/
}

.img-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /*position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right:0;*/
}
</style>
