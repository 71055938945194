<template>
  <div class="row-accordion" :class="{'is-active': isOpen}">
    <div class="accordion-item-title header" :class="{'is-temp': isTempHeader}">
      <button
        @click="toggleAccordion()"
        :aria-expanded="isOpen"
        :aria-controls="`collapse${uid}`"
        class="accordion-item-trigger"
      >
        <slot name="title" />
        <span class="accordion-item-trigger-icon"></span>
      </button>
    </div>

    <transition
        name="accordion-item"
        @enter="startTransition"
        @before-enter="startTransition"
        @after-enter="endTransition"
        @before-leave="startTransition"
        @after-leave="endTransition">
      <div v-show="isOpen" :id="`collapse${uid}`" class="accordion-item-details">
        <div class="accordion-item-details-inner body">
          <slot name="content" />
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  props: {
    isTempHeader: {
      type: Boolean,
      default: false,
    },
    index: '',       // index of this component
    openedIndex: ''
  },
  created() {
    this.uid = Math.random().toString(16).slice(2);
  },
  watch: {
    openedIndex(newVal) {
      this.isOpen = false
    }
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    startTransition(el) {
      el.style.height = el.scrollHeight + 'px'
    },

    endTransition(el) {
      el.style.height = ''
    }
  },
};
</script>

<style lang="scss">
@use "sass:math";
@import "../../assets/scss/globals/_variables.scss";
@import "../../assets/scss/globals/_mixins.scss";
.accordion-item-title {
  position: relative;
}
.accordion-item-trigger-icon {
  $size: 14.67px;
  display: block;
  position: absolute;
  /*top: 3px;
  margin: auto;*/
  right: 1.15rem;
  bottom: 0;
  width: $size;
  height: $size;
  border-right: 2px solid #02A54F;
  border-bottom: 2px solid #02A54F;
  transform: translateY(math.div(-$size, 4)) rotate(45deg);
  transition: transform 0.2s ease;

  top: 15px;
  margin-bottom: auto;

  .is-active & {
    transform: translateY(math.div($size, 4)) rotate(225deg);
  }

  @include responsive-max-width($tablet_m) {
    width: 12.67px;
    height: 12.67px;
  }
}

.accordion-item-enter-active, .accordion-item-leave-active {
  will-change: height, opacity;
  transition: height 0.5s ease, opacity 0.5s ease, padding 0.5s ease;
  //transition: height 9s ease, opacity 9s ease;
  overflow: hidden;
}
.accordion-item-enter, .accordion-item-leave-to {
  height: 0 !important;
  opacity: 0;
}


.row-accordion {

  background: rgba(247, 247, 247, 0.5);
  border: 1px solid #DCDECB;
  border-radius: 10px;

  &.is-active {

    .header {
      
      &.is-temp {
        
        padding: 0 !important;

      }

      ul {

        .cell {

          &.temp {

            height: 0 !important;
            opacity: 0;
            padding: 0 !important;

            .tag {

              display: none;

            }

          }

        }

      }

    }

  }

  /*.header {

  }*/

  .accordion-item-title {
    button {

      .cell {
        
        padding-bottom: 10px;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }

      }

    }

  }

  .header {
    padding: 10px 11px;
    //padding-bottom: 0;

    .accordion-item-trigger {
      width: 100%;
      text-align: left;
      background-color: transparent;
      border: none;
      padding: 0;
    }

  }

  .body {

    width: 100%;
    text-align: left;
    background-color: transparent;
    border: none;
    padding: 10px 11px;
    padding-top: 0;

    .cell:not(:last-child) {
      padding-bottom: 10px;
    }

  }

  .cell {

    font-family: "Poppins";
    font-style: normal;
    font-size: 14px;

    &.temp {
      //padding-top: 10px;
      will-change: height, opacity;
      transition: height 0.3s ease, opacity 0.3s ease;
    }

    .key {
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      color: #33352A;
      text-transform: uppercase;
      margin-bottom: 4px;
      max-width: 90%;
    }

    .value {
      font-weight: 400;
      font-size: 13px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: #6E6E6E;
    }

  }
}
</style>
