import axios from "axios";
import AuthService from "../services/AuthService";
export default {
  namespaced: true,
  state: {
    isLogged: false,
    token: null,
    user: null,
    expiration: null,
  },
  getters: {
    authenticated(state) {
      return state.isLogged;
    },
    user(state) {
      return state.user;
    },
  },
  mutations: {
    SET_LOGGED(state, data) {
      if (data) {
        state.isLogged = true;
        state.token = data.token;
        state.expiration = new Date(data.expiration).getTime();
      }
    },
    SET_USER(state, data) {
      state.user = data;
    },
  },
  actions: {
    async signIn({ commit, state }, credentials) {
      let dataResp = "";
      await axios
        .post(
          process.env.VUE_APP_API_URL + "/login",
          JSON.stringify(credentials),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          dataResp = {
            status: response.status,
            data: response.data,
          };
          console.log(dataResp.data);
          commit("SET_LOGGED", response.data);
          commit("SET_USER", response.data.usuario);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem(
            "maxAge",
            new Date(response.data.expiration).getTime()
          );
          localStorage.setItem(
            "userinfo",
            JSON.stringify(response.data.usuario)
          );
        })
        .catch(function (error) {
          dataResp = {
            status: error.response.status,
            message: error.response.data.error,
          };
          commit("SET_LOGGED", null);
          commit("SET_USER", null);
          state.isLogged = false;
          localStorage.removeItem("token");
          localStorage.removeItem("userinfo");
        });

      return dataResp;
    },
    async resetPassword({ state, commit }, data) {
      return await AuthService.resetPassword(axios, data);
    },
    async validateSMS({ state, commit }, data) {
      return await AuthService.validateSMS(axios, data);
    },
    async changePassword({ state, commit }, data) {
      return await AuthService.changePassword(axios, data);
    },
    async validToken({ commit, state }, data) {
      const maxAge =
        new Date().getTime() <= Number(data.expiration) ? true : false;

      if (data.token && data.user && maxAge) {
        commit("SET_LOGGED", data);
        commit("SET_USER", data.user);
      } else {
        commit("SET_LOGGED", null);
        commit("SET_USER", null);
        localStorage.removeItem("token");
        localStorage.removeItem("userinfo");
        localStorage.removeItem("maxAge");
        state.isLogged = false;
      }
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("userinfo");
      localStorage.removeItem("maxAge");
    },
  },
};
