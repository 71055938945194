<template>
  <div
    class='select-button'
    :class="{ 'has-error': !!errorMessage, 'success': meta.valid }">
      <label :for="name">{{ label }}</label>
  
      <template v-if="hasCustomId">
        <select
          class="select"
          :value="modelValue"
          @change="onChange($event)"
          :disabled="disabledSelect">
          <option value="" disabled>{{ placeholder ? placeholder : 'Selecciona aquí' }}</option>
          <option v-for="item in options"
            :key="item[hasCustomId]"
            :value="item[hasCustomId]"
            :selected="item[hasCustomId] === inputValue">
            {{ hasCustomName ? item[hasCustomName] : item.nombre}}
          </option>
        </select>
      </template>
  
      <template v-else>
        <select
          class="select"
          :value="modelValue"
          @change="onChange($event)"
          :disabled="disabledSelect">
          <option value="" disabled>{{ placeholder ? placeholder : 'Selecciona aquí' }}</option>
          <option v-for="item in options"
            :key="item.id"
            :value="item.id"
            :selected="item.id === inputValue">
            {{item.nombre}}
          </option>
        </select>
      </template>
  
      <p class="help-message" v-show="errorMessage || meta.valid">
        {{ errorMessage || successMessage }}
      </p>
  </div>
  </template>
  
  <script>
  import { useField } from "vee-validate";
  
  export default {
    props: {
      modelValue: {
        type: [String, Number],
        description: "",
        default: "",
      },
      value: {
        type: String,
        default: "",
      },
      name: {
        type: String,
        description: "Select label",
      },
      label: {
        type: String,
        required: false,
      },
      successMessage: {
        type: String,
        default: "",
      },
      options: {
        type: Array,
        description: "Options",
      },
      disabledSelect: {
        type: Boolean,
        default: false,
      },
      validateOnMount: {
        type: Boolean,
        default: false,
      },
      hasCustomId: {
        type: String,
        default: "",
      },
      hasCustomName: {
        type: String,
        default: "",
      },
      placeholder: {
        type: String,
        default: "",
      }
    },
    components: {},
    setup(props, {emit}) {
      const { value: inputValue, errorMessage, handleBlur, handleChange, meta } = useField(
        props.name,
        undefined,
        {
          validateOnMount: props.validateOnMount,
          initialValue: props.value,
        }
      );
  
      const onChange = (event) => {
        handleChange(event, true)
        emit('update:modelValue', event.target.value)
      }
  
      return {
        onChange,
        handleChange,
        handleBlur,
        errorMessage,
        inputValue,
        meta,
      };
    }
  };
  </script>
  
  <style lang="scss" scoped>
  @import "../../assets/scss/globals/_variables.scss";
  .select-button {
    position: relative;
  
    @media (max-width: $tablet_m) {
      //margin-bottom: calc(1em * 1.5);
    }
  }
  .select {
    border: 1px solid #7D8067;
    //border-radius: 10px;
    border-radius: 8px;
    //padding: 14px 11px;
    font-size: 11px;
    width: 100%;
  
    padding: 13px 34px 13px 20px;
  
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
  
    background: url(~images/icons/caretDown.svg) no-repeat;
    background-position: right 6px center;
    background-size: 24px;
  
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    outline: 0;
  
    @media (max-width: $mobile_l) {
      font-size: 13px;
    }
  
    @media (min-width: $desktop_s) {
      /*padding: 14.82px 20px;
      font-size: 14px;
      background-position: right 8px center;
      background-size: 30px;*/
  
      padding: 10.82px 34px 10.82px 20px;
      font-size: 14px;
      background-position: right 8px center;
      background-size: 27px;
    }
  
    /*@media (min-width: $tablet_m) {
      max-width: 168px;
    }*/
  
    @media (max-width: $tablet_m) {
      padding-right: 40px;
      background-position: right 8px center;
    }
  }
  
  [disabled] {
    background-color: #efefef;
    border-color: #efefef;
  }
  
  select {
    color: gray;
  }
  
  label {
      display: block;
      margin-bottom: 4px;
      width: 100%;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #6E6E6E;
  
    @media (max-width: 1750px) {
      font-size: 10px;
    }
  
    @media (min-width: $tablet_m) and (max-width:1366px) {
      height: 24px;
      //margin-bottom: 8px;
      margin-bottom: 4px;
      display: flex;
      //align-items: center;
      align-items: flex-end;
    }
  }
  
  .help-message {
    position: absolute;
    bottom: calc(-1.5 * 1em);
    left: 0;
    margin: 0;
    font-family: $font-family-poppins;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  
  /*.TextInput.has-error input {
    background-color: var(--error-bg-color);
    color: var(--error-color);
  }*/
  
  .select-button.has-error select {
    border-color: $error;
  }
  
  .select-button.has-error .help-message {
    color: $error;
    border-color: $error;
  }
  </style>