import setting from './setting';

export default {

    resetPassword: ($axios, data) => {
        return $axios.post(`${setting.http.base_url}/login/resetear/clave`, data, { headers: setting.http.headers() });
    },

    validateSMS: ($axios, data) => {
        return $axios.post(`${setting.http.base_url}/login/validar/sms`, data, { headers: setting.http.headers() });
    },

    changePassword: ($axios, data) => {
        return $axios.post(`${setting.http.base_url}/login/cambiar/clave`, data, { headers: setting.http.headers() });
    }

}